import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-10-11T00:00:00.000Z",
  "title": "Future Of Work: Should Your Organization Offer (And Pay For) Retraining Opportunities?\n",
  "slug": "future-of-work-should-your-organization-offer-and-pay-for-retraining-opportunities",
  "excerpt": ["Who should be responsible for retraining and upskilling opportunities – employers or employees? A new report by Gallup provides guidance on how to navigate changing workforce needs at a time of rapid technological evolution and disruption.", "Robots taking human jobs is a hot topic among future of work discussions, and clarity is needed in order to successfully fill the skill gaps for the future."],
  "images": ["37-should-your-organization-offer-and-pay-for-retraining-opportunities.jpg"],
  "backlink": "https://allwork.space/2019/10/future-of-work-should-your-organization-offer-and-pay-for-retraining-opportunities/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Gallup recently published the report The Real Future of Work, which seeks to provide guidance on how companies can navigate changing workforce needs at a time of rapid technological disruption. For the issue, Gallup conducted surveys with employed adults in France, Germany, Spain, and the UK.`}</p>
    <p>{`One common finding across the four countries is that employees “feel they need opportunities to build on their current skills or to learn new skills to be more effective in their jobs.” This finding isn’t surprising, as rapid advances in artificial intelligence and other technologies means that “robots’ potential effects on our lives are no longer questions reserved for futurists and science fiction writers.”`}</p>
    <p>{`In fact, the issue of robots taking human jobs has been a hot topic among future of work discussions.`}</p>
    <p>{`Gallup stated that “some analysts foresee alarming levels of employment disruption,” citing Kai-Fu Lee’s prediction that “AI-enabled machines would take over 50% of human jobs within the decade.” This prediction was made in 2017 and, to this day, people are worried that robots will take away our jobs, which has driven discussions about universal basic income.`}</p>
    <p>{`On the bright side, however, Gallup’s survey found that there is a “general optimism about the effect of new technology, with many saying that it will benefit their companies and relatively few feeling anxious about the threat to their own jobs.”`}</p>
    <p>{`In fact, most workers surveyed by Gallup said it “is not at all likely” that their job will be eliminated in the next five years because of technological advances.”`}</p>
    <ul>
      <li parentName="ul">{`20% of UK workers say it is somewhat or very likely`}</li>
      <li parentName="ul">{`19% of French workers say it is somewhat or very likely`}</li>
      <li parentName="ul">{`13% of Spain and US workers say it is somewhat or very likely`}</li>
      <li parentName="ul">{`9% of German workers say it is somewhat or very likely.`}</li>
    </ul>
    <p>{`In short, Gallup found that “employees are far more likely to say technological changes will benefit them and their companies than have negative effects.”`}</p>
    <h2>{`Technology Will Result in More, Not Less, Employment Opportunities`}</h2>
    <p>{`Contrary to popular belief, experts believe that emerging technologies will result in more employment opportunities, not less.`}</p>
    <p>{`“Economists note that in many countries, productivity gains from automation are needed to offset losses from aging workforces.”`}</p>
    <p>{`Moreover, while technological advances will likely reduce and eliminate existing jobs, it will also create new roles for humans. In fact, “The World Economic Forum’s 2018 Future of Jobs study found that while 50% of businesses surveyed expect automation to lead to some reduction in their workforce, 38% expect to add new productivity-enhancing roles in their organization.”`}</p>
    <p>{`As more research and information is revealed about the impact technology will have on jobs, people are becoming increasingly optimistic about how they will fare. The Wellcome Global Monitor by Gallup, found that 58% of people around the world are more likely to say science and technology will increase rather than decrease the number of jobs in their local economy.`}</p>
    <h3>{`Change Is Here`}</h3>
    <p>{`Gallup’s survey found that 36% of employees across France, Germany, Spain, and the UK experienced technology related job changes in 2018. These types of changes are only likely to increase, as more companies adopt AI-based technologies, cloud technology, and other technological advances.`}</p>
    <p>{`It is therefore crucial that leaders develop agile organizational cultures that are able to quickly respond to technological developments. More importantly, leaders “must also ensure that organizational changes to facilitate technology integrations are accompanied by training that prepares employees to work with that technology.”`}</p>
    <h3>{`Retraining and Reskilling Employees`}</h3>
    <p>{`The business world has reached a point where access to different types of training opportunities should be a given, in order to help workers make the transition to a more tech-enabled world. Workers across the whole world need to be able to work alongside technology, and they also need to improve their creativity, empathy, and other cognitive and relational skills that are difficult to automate.`}</p>
    <p>{`Gallup’s survey found that in Spain, France, and the UK “most employees feel they need training opportunities.” These opportunities can take many forms, but mostly workers need to upskill or reskill themselves to move into jobs or industries that are less likely to be automated.`}</p>
    <ul>
      <li parentName="ul">{`58% of employees in France feel the need for training to build on their current skills or learn new skills`}</li>
      <li parentName="ul">{`Only 37% of those took part in training in 2018`}</li>
      <li parentName="ul">{`In Germany 57% participated in training opportunities in 2018`}</li>
      <li parentName="ul">{`In Spain 62% participated in training opportunities in 2018`}</li>
      <li parentName="ul">{`In the UK 64% participated in training opportunities in 2018.`}</li>
    </ul>
    <p>{`Unfortunately, there seems to be a divorce when it comes to who should be responsible for training and “leaders must resolve questions about who should initiate and pay for training opportunities.”`}</p>
    <p>{`According to Gallup, 81% of employees who did not take part in training opportunities in 2018 feel their organization, rather than themselves, should be responsible for initiating those (training) opportunities.`}</p>
    <p>{`In order to successfully fill the education and skill gaps for the future, there needs to be clarity around the subject. “If employers are not incentivized to provide general skills training and employees are unaccustomed to having ownership over their educational paths, a workforce problem arises.”`}</p>
    <p>{`In fact, 11% of employees who didn’t participate in training in 2018 said it was because their organization had not provided clear guidelines about future needs and a similar proportion said that the training offered is not relevant to them.`}</p>
    <p>{`The good news is that even though there’s a lack of clarity, workers are already finding opportunities to meet their reskilling and upskilling needs. Of those who partook in educational opportunities in 2018, 65% said their organization initiated it while 35% said they themselves did.`}</p>
    <h3>{`Managing Change and Reskilling Efforts`}</h3>
    <p>{`According to Gallup, “business leaders across Europe will need to optimize the resources they devote to employee training. This will require HR leaders to be increasingly focused on identifying educational opportunities that address organizational needs in a cost-effective way and help employees feel prepared for future changes.”`}</p>
    <p>{`However, employees must also be willing to make the transitional shifts required to help a company succeed in the future of work.`}</p>
    <p>{`To make the change process easier, Gallup argues there are two main things that managers can focus on: (1) clear, frequent communication and (2) maintain a culture of high development.`}</p>
    <p>{`The former means that leaders should find effective ways to communicate the organization’s core values and mission, including how short-term changes are aligned with a broader, long-term vision.`}</p>
    <p>{`Clear communication is needed as it can help alleviate employees’ anxiety about their place within an organization. More importantly, “employees are also more motivated to address their own skill gaps when they understand and identify with their organization’s mission.”`}</p>
    <p>{`The latter, maintaining a culture of high development, is important as “employees are less likely to view change as threatening if they already work in a culture of high development.”`}</p>
    <p>{`Development should also be high on a leader’s agenda. As it currently stands, “less than a third of employees in Germany, Spain and France strongly agree their company supports them in developing the skills they need for the future, while the U.K. fares only slightly better with 37% strongly agreeing.”`}</p>
    <p>{`Investing in employees’ retraining and reskilling opportunities tells them that they are a part of the company’s future.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      