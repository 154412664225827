import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-12-18T00:00:00.000Z",
  "title": "Future Of Work: Is \"Snooping Software\" Our Next Normal\n",
  "slug": "is-snooping-software-our-next-normal",
  "excerpt": ["Microsoft recently launched a Productivity Score feature for remote staff.", "Video and AI-based surveillance tools are also now in development and use.", "WHO warns of “infringement of fundamental human rights and freedoms”."],
  "images": ["is-snooping-software-our-next-normal.png"],
  "backlink": "https://allwork.space/2020/12/future-of-work-is-snooping-software-our-next-normal/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "https://allwork.space/2020/11/hybrid-working-is-changing-the-meaning-of-being-at-work/"
      }}>{`Hybrid working`}</a>{` is here to stay and this has left the world’s employers with a tricky conundrum. How can you monitor staff productivity when your staff aren’t in the office?`}</p>
    <p>{`There are many options available and, according to `}<a parentName="p" {...{
        "href": "https://nypost.com/2020/12/03/microsoft-patents-software-that-detects-lazy-employees/?utm_source=NYPTwitter&utm_medium=SocialFlow&utm_campaign=SocialFlow"
      }}>{`reports in The New York Post`}</a>{`, Microsoft has one answer, patenting “new software that can detect when employees are lazy.”`}</p>
    <p>{`“United States Patent and Trademark Office `}<a parentName="p" {...{
        "href": "http://appft.uspto.gov/netacgi/nph-Parser?Sect1=PTO1&Sect2=HITOFF&d=PG01&p=1&u=%2Fnetahtml%2FPTO%2Fsrchnum.html&r=1&f=G&l=50&s1=%2220200358627%22.PGNR.&OS=DN/20200358627&RS=DN/20200358627"
      }}>{`records indicate`}</a>{` that Microsoft is staking claim over novel software that allows employers to monitor staffers’ body language and facial expressions during virtual and in-person meetings, and delivers a numeric ‘productivity score,’ topping out at 800, for companies overall.”`}</p>
    <p>{`Microsoft denied the Productivity Score was invasive `}<a parentName="p" {...{
        "href": "https://www.microsoft.com/en-us/microsoft-365/blog/2020/10/29/power-your-digital-transformation-with-insights-from-microsoft-productivity-score/?irgwc=1&OCID=AID2000142_aff_7794_1246483&tduid=%28ir__dh21sexixokfqxukkk0sohz3zu2xsqlt2tjmipgk00%29%287794%29%281246483%29%28%280b0ed049958a7b55541648f8bf5c3669%29%28266696%29%281503186%29%2893051X1547088X0da420120c4c08a9e4a1655b967e2638%29%28%29%29%280b0ed049958a7b55541648f8bf5c3669%29&irclickid=_dh21sexixokfqxukkk0sohz3zu2xsqlt2tjmipgk00"
      }}>{`in a recent company blog post`}</a>{`, which states: “Let me be clear: Productivity Score is not a work monitoring tool… It focuses on actionable insights about the ways in which people and teams are using the `}{`[Microsoft 365]`}{` tools so you can make improvements or provide training to further your digital transformation.”`}</p>
    <p>{`Whether the Productivity Score is a monitoring tool or not, Microsoft is not the only company exploring this area.`}</p>
    <p>{`Employee monitoring software firm ActivTrak recently raised `}<a parentName="p" {...{
        "href": "http://siliconhillsnews.com/2020/11/18/activtrak-raises-50-million-to-expand-its-employee-monitoring-software-platform/"
      }}>{`$50 million in funding`}</a>{`, following a huge surge in demand for its software. It’s not alone. Monitoring programs such as StaffCop, Teramind and Time Doctor have all seen `}<a parentName="p" {...{
        "href": "https://www.vox.com/recode/2020/4/2/21195584/coronavirus-remote-work-from-home-employee-monitoring"
      }}>{`similar spikes in demand`}</a>{`. In fact, research reveals demand for employee surveillance software was up 55% in June 2020, `}<a parentName="p" {...{
        "href": "https://www.zdnet.com/article/employee-surveillance-software-demand-increased-as-workers-transitioned-to-home-working/"
      }}>{`compared to pre-pandemic figures`}</a>{`.`}</p>
    <p>{`While these tools rely mostly on your mouse clicks and browsing activities to monitor productivity, there are also always-on video systems to keep tabs on your activities. `}<a parentName="p" {...{
        "href": "https://www.businessinsider.com/work-from-home-sneek-webcam-picture-5-minutes-monitor-video-2020-3?r=US&IR=T"
      }}>{`Sneek`}</a>{`, for example, lets your boss monitor you through your webcam. Audit firm PwC has also reportedly created a `}<a parentName="p" {...{
        "href": "https://www.personneltoday.com/hr/pwc-facial-recognition-tool-criticised-for-home-working-privacy-invasion/"
      }}>{`facial recognition tool`}</a>{` to help financial institutions track employees as they work from home. `}</p>
    <p>{`Just to add to our paranoia, Darktrace `}<a parentName="p" {...{
        "href": "https://www.bloomberg.com/news/articles/2020-12-04/darktrace-says-its-ai-can-be-used-for-performance-monitoring"
      }}>{`recently said`}</a>{` its AI can be used to automate performance monitoring. “The company’s technology works by learning how companies function, monitoring different processes such as employee email patterns to discover irregularities that might flag potential fraud or hacking vulnerabilities. Darktrace’s ability to autonomously respond to such irregularities could lead to other applications for businesses,” the company’s Chief Strategy Officer Nicole Eagan said, `}<a parentName="p" {...{
        "href": "https://www.bloomberg.com/news/articles/2020-12-04/darktrace-says-its-ai-can-be-used-for-performance-monitoring"
      }}>{`speaking in a statement`}</a>{`.`}</p>
    <h3>{`Data hungry`}</h3>
    <p>{`In many ways, this announcement comes as no surprise. Automation is already a standard practice across many business applications. In 2021, its use to streamline the hybrid world of work seems logical – but also one where companies must tread carefully.`}</p>
    <p><a parentName="p" {...{
        "href": "https://go.forrester.com/blogs/predictions-2021-privacy-becomes-an-imperative-in-a-year-of-transition/?utm_source=zdnet&utm_medium=pr&utm_campaign=predictions_2021"
      }}>{`Analyst house Forrester`}</a>{` predicts “regulatory and legal activity related to employee privacy will increase 100%” in the year ahead.`}</p>
    <p>{`“Pandemic management, as well as a growing desire to improve workforce analytics and insights, will drive organisations to hungrily collect more and more employee data. We predict that in the next 12 months, regulatory and legal activity will double and overwhelm organisations that fail to take a thoughtful approach to employee data — one that respects and protects employee privacy.`}</p>
    <p>{`“Companies must develop a privacy by-design approach to their initiatives that entails the collection, processing, and sharing of their employees’ personal data.”`}</p>
    <p>{`What does this mean for flexible workspaces?`}</p>
    <p>{`Should you jump on the monitoring bandwagon and start spying on your members using a network of cameras and bots? Of course not. But you should be aware of the potential backlash for any monitoring tools in the year ahead – from both your members and the authorities.`}</p>
    <h3>{`Testing times`}</h3>
    <p>{`As `}<a parentName="p" {...{
        "href": "https://allwork.space/2020/12/the-problem-with-hybrid-work-arrangements/"
      }}>{`hybrid working initiatives`}</a>{` continue to skyrocket, this puts flexible workspaces under pressure to try and maximise occupancy rates, while ensuring the cleanliness of their spaces and providing members with an optimised and easy-to-use desk booking system.`}</p>
    <p>{`Occupancy monitoring tools – including `}<a parentName="p" {...{
        "href": "https://allwork.space/2020/11/occupancy-sensors-the-answer-to-your-spaces-prayers/"
      }}>{`desk-placed sensors`}</a>{` – are one option to achieve this, capturing anonymised data and providing you with insights into usage of your space. However, just because this data is anonymised does not mean you’re out of the woods.`}</p>
    <p>{`In the UK, Barclays is currently under investigation by the Information Commissioner’s Office (ICO) over allegations the British `}<a parentName="p" {...{
        "href": "https://www.reuters.com/article/us-barclays-surveillance-probe-privacy-idUSKCN25500P"
      }}>{`bank spied on its staff`}</a>{`. “If organisations wish to monitor their employees, they should be clear about its purpose and that it brings real benefits. Organisations also need to make employees aware of the nature, extent and reasons for any monitoring,” a spokesperson for the ICO said.`}</p>
    <p>{`The World Health Organization (WHO) also voiced its concerns over the use of all of the information collected during the pandemic.`}</p>
    <p>{`In a `}<a parentName="p" {...{
        "href": "https://www.who.int/news/item/19-11-2020-joint-statement-on-data-protection-and-privacy-in-the-covid-19-response"
      }}>{`recent statement`}</a>{`, the WHO said this phenomenon could have “significant effects beyond the initial crisis response phase, including, if such measures are applied for purposes not directly or specifically related to the COVID-19 response, potentially leading to the infringement of fundamental human rights and freedoms.”`}</p>
    <p>{`This is sound advice. The world of work is changing at breakneck speed, but flexible workspaces must pause for thought and check any monitoring tools are used for the right reasons. And with full disclosure.`}</p>
    <p>{`“One of the biggest holdbacks of remote work is trust — managers simply don’t trust their people to work untethered,” said Kate Lister, president of Global Workplace Analytics, `}<a parentName="p" {...{
        "href": "https://allwork.space/2020/06/employers-risk-breach-of-trust-by-monitoring-remote-workers-computers/"
      }}>{`speaking in a statement`}</a>{`. “They’re used to managing by counting butts in seats rather than by results.”`}</p>
    <p>{`This is where the world of flexible work must do things differently.`}</p>
    <p>{`Yes, you need to count butts on seats to make sure you maximise your occupancy rates. But first, you need to reassure people that it’s safe to return to leave the home office and re-enter the world of work. Otherwise, there won’t be any butts on seats to count.`}</p>
    <h3>{`What next?`}</h3>
    <p>{`The year ahead will be a difficult balancing act. Flexible workspaces must increase their awareness of data privacy legislation and best practices, keeping up with any new security standards as and when they are rolled. The `}<a parentName="p" {...{
        "href": "https://www.lexology.com/library/detail.aspx?g=5a7edce9-26af-487c-8877-7a815945954d"
      }}>{`California Privacy Rights Act (CPRA)`}</a>{`, for example, will go into effect on 1 January 2023. And in China `}<a parentName="p" {...{
        "href": "https://www.digitaltrends.com/social-media/china-global-data-security-initiative/"
      }}>{`a new set of data security standards`}</a>{` were recently announced to address growing concerns against Chinese tech companies.`}</p>
    <p>{`The legislative landscape is changing fast.`}</p>
    <p>{`As a result, your members will also demand transparency about what data is collected and how it is used. This is an important consideration for flexible workspaces. According to a PwC poll, `}<a parentName="p" {...{
        "href": "https://www.techrepublic.com/article/data-privacy-what-consumers-want-businesses-to-know/"
      }}>{`84% of consumers`}</a>{` said they would switch services if they don’t trust how a company uses their data. `}<a parentName="p" {...{
        "href": "https://allwork.space/2020/11/our-new-reality-the-hybrid-work-day/"
      }}>{`Trust`}</a>{` is the foundation on which the hybrid work experience will be built in the years ahead. If managers don’t trust their staff, it simply isn’t going to work. But if flexible workspaces can win the trust of the world’s workforce, then the sky’s the limit.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      