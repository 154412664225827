import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-02-20T00:00:00.000Z",
  "title": "The Future Workplace Is Here: How To Design Success Into Every Square Foot\n",
  "slug": "the-future-workplace-is-here-how-to-design-success-into-every-square-foot",
  "excerpt": ["In an increasingly competitive market for talent, providing an unparalleled work environment can be a critical differentiator for your company.", "Companies across all industries, a combination of ever-expanding growth, shrinking real estate footprints and pressing employee demands are forcing a change to the way work gets done.", "The rate of change itself is even changing, accelerating the need for workplaces to adapt and thrive quickly and cost-efficiently."],
  "images": ["4-FUTURE-WORKPLACE-DESIGN.png"],
  "backlink": "https://allwork.space/2019/02/the-future-workplace-is-here-how-to-design-success-into-every-square-foot/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`At companies across all industries, a combination of ever-expanding growth, shrinking real estate footprints and pressing employee demands are forcing a change to the way work gets done. The rate of change itself is even changing, accelerating the need for workplaces to adapt and thrive quickly and cost-efficiently.`}</p>
    <p>{`But a tactical approach, like embracing the “open office” concept, is not enough. The challenge is to create a diverse mix of thoughtfully designed physical spaces that are flexible, aligned with your growth strategy, and supported by the latest technology and services.`}</p>
    <p>{`This evolution in workspace design represents the next step in the evolution of the workplace. A well thought-out workplace design is an investment in your company’s ability to innovate, compete and grow – here’s what your organization can do to ensure success.`}</p>
    <h2>{`Prepare your organization to succeed`}</h2>
    <p>{`Before any design begins, size up your future workforce to gain a realistic picture of the workspaces you need to plan. Understand your organization’s three-year growth strategy. Where will growth occur in your company? Do departments plan to add just a few people over the next few years, or double in size?`}</p>
    <p>{`Engage employees in an end-to-end experience focused on how a new workspace design can support their success. Create ways to collect employee input, and use the input to drive innovative thinking.`}</p>
    <p>{`Be sure you can articulate a compelling business case that resonates with employees and leadership. A business case that is only about cost efficiency misses a rare opportunity to boost the bottom line in ways such as improving productivity, innovation, retention and recruiting.`}</p>
    <h2>{`Make the most of implementation`}</h2>
    <p>{`With a strong business case in hand, identify committed leaders to spearhead the transition. These individuals will be active, visible, supportive and engaged, versus individuals who inherit the sponsor role because of their job title, but do very little to drive the transition. Committed leaders also take a critical step in demonstrating their support by adopting the new workplace themselves.`}</p>
    <p>{`Form a cross-functional team to implement change, including people who represent the business, HR, IT, project management, change management, workspace designers, facilities management and security. Ancillary groups to pull in include architects, ergonomic designers, and engineers. Bring key stakeholders together early on to build awareness and investment in the outcome.`}</p>
    <p>{`Transitioning to any new environment, however positive, represents a fundamental shift that may be tough for some to navigate. A fully integrated team, together with closely-aligned work streams, can help ensure a smoother transition. Keep people informed; transparency helps build credibility and buy-in. Thoughtful change management can anticipate many of the questions that come up with any major workplace transition: Where do I park? Where is the coffee station? What about privacy and security?`}</p>
    <p>{`Once employees have made the move to a new work environment, reinforcing the transition is crucial to long-term success. Share quick wins and stories about the new work environment and what it means to people. Give employees an ongoing process to share feedback, and have transparent ways to recognize and respond to input. Feedback mechanisms are not just an exercise to make employees “feel” heard; they give valuable input to make adjustments as needed.`}</p>
    <h2>{`Resiliency: Design with the future in mind`}</h2>
    <p>{`A new workplace design is an opportunity to develop a delivery model scaled to the organization and sized for future growth. It is also a strategic opportunity to consider your future workforce and accommodate the diverse work styles of multiple generations of employees. Consider: Who are the workers of the future? What type of work will they do? While you cannot precisely predict employees’ needs three or five years from now, you can design to evolve with them. Favor solutions that offer flexibility and choice over time — workspaces that can adapt quickly to changing needs. Plan a mix of workspaces that encourage a natural flow through connection and collaboration, along with quiet spaces for individual, focused work.`}</p>
    <p>{`One company recently transformed a traditional desk/chair/cube environment to a more collegial, creative workplace. It is now furnished with comfortable chairs, couches and electric desks to accommodate standing or seated users. Movable walls let the company flex these collaborative workspaces easily to fit their needs. Quiet rooms are available for work that requires individual focus. Workspaces are enhanced by the latest technology. At the touch of a button, users can bring up teams living and working around the world and collaborate with them. Touch screens in small conference rooms support idea generation. Mobile apps make it easy for employees to attend meetings from their phones, do their work on mobile devices and navigate buildings through integrated GPS sensors. Red or green lights outside conference rooms let employees glance down the hall and see which rooms are available for an impromptu meeting.`}</p>
    <p>{`The result? A work environment that adapts to people’s needs — not the other way around.`}</p>
    <p>{`An inspired workspace design is a powerful tool for productivity and growth. It is also a tangible recruitment asset. In an increasingly competitive market for talent, providing an unparalleled work environment can be a critical differentiator for your company as an employer of choice. As you plan a new workspace, stay close to your growth strategy and your employees. They are the key touchstones to boost productivity and equip dedicated people to do their best work.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      