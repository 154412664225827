import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-10-16T00:00:00.000Z",
  "title": "Future Of Work: What Is Shadow HR And Why Does It Matter\n",
  "slug": "future-of-work-what-is-shadow-hr-and-why-does-it-matter",
  "excerpt": ["One main shift in the business world today is the increasing number of remote, contractual, and independent workers.", "While a more flexible workforce enables tasks to be completed faster, it comes with certain management challenges.", "This has led to the rise of ‘shadow HR’, which refers to people conducting work for a company but who lack governance and control."],
  "images": ["AWS-ARTICLE-IMAGES-5-800x450.png"],
  "backlink": "https://allwork.space/2020/10/future-of-work-what-is-shadow-hr-and-why-does-it-matter/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The coronavirus has dramatically changed the way people work and expect to work in the future. In some cases, the pandemic has only come to accelerate movements and shifts that were already taking place in some organizations. `}</p>
    <p>{`One main shift in the business world today is the increasing number of remote and independent workers. As organizations have experienced first-hand that working remotely can be effective and as workers realize that they can now work from anywhere, the number of remote workers is expected to increase exponentially over the next 5 years. `}</p>
    <p>{`While this shift offers various benefits, it also comes with its own set of challenges and risks. `}</p>
    <p>{`Allwork.Space spoke with Shahar Erez, CEO and co-founder of `}<a parentName="p" {...{
        "href": "https://www.stoketalent.com/"
      }}>{`Stoke Talent`}</a>{`, to talk about shadow HR, what it is, why it’s relevant today, and some of the risks associated with it. `}</p>
    <p>{`As more organizations lean on a more flexible and agile workforce, it’s imperative that they make sure that all business processes are being met to prevent compliance issues. Below are the highlights of our conversation. `}</p>
    <h3>{`Allwork.Space: Let’s start with the basics – what exactly is ‘shadow HR’? Can you share with us where the term came from or how it was coined?`}</h3>
    <p>{`Shahar Erez: The term `}<a parentName="p" {...{
        "href": "https://urldefense.proofpoint.com/v2/url?u=https-3A__en.wikipedia.org_wiki_Shadow-5FIT-23-3A-7E-3Atext-3DIn-2520big-2520organizations-252C-2520shadow-2520IT-2Cof-2520the-2520central-2520information-2520systems.&d=DwMFaQ&c=euGZstcaTDllvimEN8b7jXrwqOf-v5A_CdpgnVfiiMM&r=azgrNXdgjddp5csOaD7IQN5Cnc11pSrD7dPvKNF3mU8&m=0HlP9eSPIQXXubiuMqn2m24sJOsfpGEfKYlLu5WS_Yw&s=NfLKprqY-CnKRE2u1L2RgtWUKSaSMX804dvk7tkxDz8&e="
      }}>{`Shadow IT`}</a>{` was coined during the cloud inception, when people were working throughout digital and IT transformation. It was sourced in organizations that were bypassing their internal IT department’s formal processes, policies and instructions and started sourcing IT services themselves.`}</p>
    <p>{`Amazon Cloud started that way; most IT organizations objected to the usage of Amazon cloud, but engineering departments found it valuable and useful and bypassed formal IT. `}</p>
    <p>{`The motivation was clear — it enabled departments to take full ownership of their needs without needing to go through the slow tedious processes IT imposed. The downside was lack of control, overspend, application performance, data leakage, time wasted, etc.`}</p>
    <p>{`Shadow HR is a very similar phenomenon, but with workers. `}</p>
    <p>{`The majority of HR departments in a company are focused on payroll employees, or W-2. But an estimated 20% of the workforce are people that are doing work for the company, but are not on its payroll — freelancers, independent contractors, service providers, gig workers. `}</p>
    <p>{`An estimated 20% of the workforce are people that are doing work for the company, but are not on its payroll — freelancers, independent contractors, service providers, gig workers.
These are individuals doing work for the company, but they do not have the visibility, governance or control that traditional employees have.`}</p>
    <p>{`When it comes to these ‘non-employees’, there is no single owner for this portion of workers. Ownership is split between finance, legal, HR, procurement and the hiring managers.`}</p>
    <p>{`The result is that there is a growing number of these workers within the company that are out of compliance and governance.`}</p>
    <p>{`In fact when interviewing CFOs and CEOs, many claim they do not have IC or freelancers at all, or maybe just a few, only to later find there is 2-3 times, even 5 times what they initially thought existed.`}</p>
    <p>{`Shadow HR therefore refers to people conducting work for your company, in various forms, but who lack governance and control. The issue here is that they could potentially expose the company to risks.`}</p>
    <h3>{`Allwork.Space: Shadow HR is a relatively new concept, but why is it so important and relevant today?`}</h3>
    <p>{`There are a few reasons why Shadow HR is becoming so important today. Here are a few of the reasons why I believe organizations need to start thinking about shadow HR.`}</p>
    <ol>
      <li parentName="ol">{`The exponential growth in the number of freelancers and the estimate that over 50% of the US job market will be freelancing within 5 years.`}</li>
    </ol>
    <p><img parentName="p" {...{
        "src": "https://allwork.space/wp-content/uploads/2020/10/aws-shadow-hr.png",
        "alt": null
      }}></img></p>
    <p>{`Shadow HR reflects huge changes in the way organizations hire and manage people.`}</p>
    <p>{`The exponential growth in the number of freelancers and the estimate that over 50% of the US job market will be freelancing within 5 years.`}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">
        <p parentName="li">{`As a consequence of the pandemic, many organizations are looking to gain more agility and keep up with the pace of change. These organizations are leaning on a more flexible workforce to accomplish these goals and scale their businesses. `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`As the number of freelancers grows there are new legislations coming out, similar to AB5 in California and other states. This sets a new workforce classification rule to assure that independent contractors are correctly classified. `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The IRS is becoming more strict about freelance and independent contractors’ tax payments; it recently raised the penalty for a late 1099 form submission to up to $3M in fines per company. If organizations aren’t clear on who is working for them, they could potentially be submitting the wrong forms. `}</p>
      </li>
    </ol>
    <h3>{`Allwork.Space: To prevent shadow HR and potential risks on compliance, what are some strategies that organizations can implement to better manage remote and on-demand workers?`}</h3>
    <p>{`The main reason Shadow HR emerges is for the very similar reasons shadow IT emerged — internal processes and bureaucracy are too slow to keep up with market needs and pace of change. Leaders who want to get the organization moving and get things done are bypassing the complexities of the organizational processes.`}</p>
    <p>{`The first thing organizations need to recognize is that they have shadow HR. While not a popular name, heads of finance, general counselors, and heads of operations need to assume there are people that are doing work for the company outside ‘standard’ or ‘traditional’ channels. `}</p>
    <p>{`The second thing is to recognize that if you want to have full visibility, the organization needs to make it simple for its leaders to follow the rules and be compliant. If leaders will need to spend hours every time they need a new freelancer or contractor, they will find a different, easier way around it. `}</p>
    <p>{`For example, if leaders or managers will need to spend 10-15 monthly hours approving invoices over email and updating numbers in a spreadsheet, they will find a simpler way to fulfill their talent needs. `}</p>
    <p>{`Heads of finance, legal procurement and operations, need to simplify and streamline the process. This can be easily accomplished by implementing technology that automates and connects the various elements of working with an alternative workforce. `}</p>
    <p>{`It’s all about making it simple and easy for managers to work within the boundaries that the organization has in place. `}</p>
    <h3>{`Allwork.Space: What will happen if organizations fail to recognize and do something about Shadow HR?`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.irs.gov/government-entities/federal-state-local-governments/increase-in-information-return-penalties"
          }}>{`Late submission of 1099`}</a>{` can lead to significant IRS penalties that reach out to $3M in penalty.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Failing to have proper representation of legal agreements (NDA, IP ownership) slows down investment due diligence and increases legal costs.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Not having all IP documents can lead to lawsuits down the line which will require hefty fines to settle.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Not tracking ICs with system access and effective off boarding processes might result in independent contractors getting access to data they should not, which is violating GDPR and CCPA.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Not tracking independent contractor engagement by state may lead to potential workforce misclassification, which is `}<a parentName="p" {...{
            "href": "https://urldefense.proofpoint.com/v2/url?u=https-3A__www.dol.gov_agencies_whd_flsa_misclassification&d=DwMFaQ&c=euGZstcaTDllvimEN8b7jXrwqOf-v5A_CdpgnVfiiMM&r=azgrNXdgjddp5csOaD7IQN5Cnc11pSrD7dPvKNF3mU8&m=0HlP9eSPIQXXubiuMqn2m24sJOsfpGEfKYlLu5WS_Yw&s=9gOkNAREhIchqJz-wtQPIUsG1m5stP61aJIW94psvP4&e="
          }}>{`a heated area that leads to significant penalties`}</a>{`. `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Other, small consequences may include budget overages, data leakage, and significant manual overhead.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      