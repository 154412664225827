import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-01-31T00:00:00.000Z",
  "title": "Future Of Work: The Office Of The Future Is About Connecting, Communicating, And Wellbeing.\n",
  "slug": "future-of-work-the-office-of-the-future-is-about-connecting-communicating-and-wellbeing",
  "excerpt": ["At this year’s Paperworld Conference in Frankfurt, Germany, speakers discussed the workplace of the future.", "Presentations centered mostly around people, and why we need to take a human-centric approach to create a smart office.", "With digital natives already in the workforce, “smart buildings are expected to be tailored and customized for all end-users.”"],
  "images": ["the-office-of-the-future.png"],
  "backlink": "https://allwork.space/2020/01/future-of-work-the-office-of-the-future-is-about-connecting-communicating-and-wellbeing/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Allwork.Space recently attended the Paperworld trade show in Frankfurt, Germany. This year, part of the event was dedicated to the future office, with expert presentations from architects, consultants, designers, and engineers.`}</p>
    <p>{`Interestingly, `}<a parentName="p" {...{
        "href": "https://allwork.space/2020/01/the-human-element-of-digital-transformation-key-takeaways-from-paperworld-2020/"
      }}>{`while the focus was the smart office, the presentations centered mostly around people`}</a>{`, and why the workplace of the future needs to have people at its heart in order to truly be smart.`}</p>
    <p>{`The Tech Components of Smart`}</p>
    <p>{`Though people should be at the heart of the future office, we do need technology to create smart buildings. Dr. Klaus Dederichs, partner at Drees & Sommer SE, gave a presentation about how buildings are learning to think.`}</p>
    <p>{`Imagine the following scenario.`}</p>
    <p>{`You’re running late for a meeting, but rather than worry about it and rush through traffic, your car, your phone, and your watch let attendees know you’re running late. Once you get to your destination, you don’t have to worry about finding a parking space or bike spot; rather the intelligent access system has already identified a free spot.`}</p>
    <p>{`You go into the building without having to pull or push any doors, the elevator is already waiting for you on the ground floor. You reach your workplace floor and as soon as you walk into the meeting room, the light and temperature are adjusted to your preferences.`}</p>
    <p>{`At that moment, your devices send a notification that you’ve arrived and everyone walks in for the meeting.`}</p>
    <p>{`According to Dederichs, this scenario is quite possible in the future, especially as more organizations continue to digitize the workplace.`}</p>
    <p>{`To him, digitization will lead to process optimization, efficiency increases, energy optimizations, seamlessness, productivity increases, and, more importantly, to increased wellbeing.`}</p>
    <h3>{`The Tipping Point Is Now`}</h3>
    <p>{`Dederichs argues that we’ve already reached a tipping point in the workplace in regards to digitization and tech infrastructure.`}</p>
    <p>{`With digital natives already in the workforce, “smart buildings are expected to be tailored and customized for all end-users.”`}</p>
    <p>{`This includes elements of mobility (how people get to and from work), as well as how they interact and move within a built environment.`}</p>
    <p>{`To create smart workplaces that work, Dederichs argues that organizations will need to lean on interconnected systems. These systems will enable businesses to have access to data that can be extrapolated and analyzed to find insights, strategize change, and create environments that people thrive in.`}</p>
    <p>{`The more data a company is able to gather, the faster a building will be able to learn by itself and adapt accordingly.`}</p>
    <p>{`Dederichs gave the following example: a smart building will be able to predict after some years that during certain hours only 2 out of 5 elevators should be operating as there are less people coming in and out of the building. The goal is for buildings to be self-thinking and act according to the data they gather.`}</p>
    <p>{`It’s about building a brain into the building.`}</p>
    <p>{`To build such environments, property developers will need to hire more than architects and engineers, they will need to have a team that includes digitization experts, cybersecurity experts, digital real estate managers, and tech-driven facility managers.`}</p>
    <h3>{`The Human Component of Smart`}</h3>
    <p>{`But while technology is the brain, humans will be the heart of buildings.`}</p>
    <p>{`Sandra Breuer from Combine Consulting GmbH gave a presentation titled “Workplaces? Work Takes Place”, in which she argued that “only if the user is at the center can we create smart buildings.”`}</p>
    <p>{`Her belief is that digitization should support employees, as the goal of the office of the future is to bring people together in the office.`}</p>
    <p>{`Digital tools have enabled us to work independently of space and time. However, this has not rendered the office meaningless. Even though the workplace can be anywhere, “the office will never die, and we need them more urgently than ever before.”`}</p>
    <p>{`While they may not die, they certainly need a re-branding.`}</p>
    <p>{`Everything that can be digitized will be digitized, it’s only a matter of time. But, once everything is automated, what exactly is left for humans?`}</p>
    <p>{`The answer is other humans. This is why we need to create workplaces where people can stay in touch with each other and communicate.`}</p>
    <p>{`This is what the office of the future is all about.`}</p>
    <h3>{`Different Spaces for Different Needs`}</h3>
    <p>{`The workplace of today and the future needs to be meaningful, intentional, and diverse. Organizations need to curate the workplace to enable individuals to find whatever it is they need to do their best work.`}</p>
    <p>{`For example, organizations need to create spaces for bonding and connecting, like the kitchen or a lounge area. But they also need to create spaces for individual and focused work.`}</p>
    <p>{`“The built environment has to fit the behavior of the people.”`}</p>
    <p>{`The behavior of people, according to Breuer, is characterized by the room they work in and an organization’s culture.`}</p>
    <p>{`Behavior is also shaped by our wellbeing. And better work environments can be created if we are more mindful of people’s wellbeing.`}</p>
    <p>{`While a robust technology infrastructure is needed to create a smart workplace, the smart workplace won’t be useful if people are not using it. This is why organizations need to think about movement, air quality, materials, surfaces, furniture, color, and much more.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      