import React from 'react';
import { Parallax } from 'react-parallax';
import { DefaultComponentProps } from '../core';
import { Container, Grid } from '@material-ui/core';

export type ParallaxProps = { image: any; strength: number } & Pick<
  DefaultComponentProps,
  'path' | 'classname' | 'children'
>;

const customParallax = ({ ...props }: ParallaxProps) => {
  return (
    <div className={props.classname}>
      <Parallax blur={{ min: -10, max: 10 }} bgImage={props.image} bgImageAlt="IMG" strength={props.strength}>
        <Container>
          <Grid container item xs={12} alignItems="center">
            <Grid item xs={12}>
              {props.children}
            </Grid>
            <div style={{ height: '400px' }} />
          </Grid>
        </Container>
      </Parallax>
    </div>
  );
};
export default customParallax;
