import React from 'react';
import classnames from 'classnames';
import { DefaultComponentProps } from '../core';
import { Menu } from '../components/menu';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
  }),
);

export type NavProps = Pick<DefaultComponentProps, 'path' | 'classname'>;

export const Nav = ({ ...props }: NavProps) => {
  const classes = useStyles();
  return <Menu classname={classnames(classes.root, props.classname)} />;
};

export default Nav;
