import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-07-17T00:00:00.000Z",
  "title": "Future Of Work: The Rise Of Nomadic Work\n",
  "slug": "future-of-work-the-rise-of-nomadic-work",
  "excerpt": ["Remote work does not equal working from home. The reality is that remote work is more synonymous with work from anywhere.", "As people escape the home to work from other locations, this is giving rise to what some are now calling “nomadic work”.", "The idea behind nomadic work is that work becomes decentralized, which will have huge implications for companies, teams, and real estate strategies."],
  "images": ["future-of-work-the-rise-of-nomadic-work.png"],
  "backlink": "https://allwork.space/2020/07/future-of-work-the-rise-of-nomadic-work/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`First things first. `}</p>
    <h3>{`Remote work ≠ work from home.`}</h3>
    <p>{`Although currently most people working remotely are doing so from home, the reality is that remote work is more synonymous with work from anywhere. Due to the current COVID-19 pandemic, most people are choosing to work from home, but as more cities reopen for regular business, we are likely to see an increase in people working from other locations as well.`}</p>
    <p>{`This will give rise to what some are now calling “nomadic work”. Nomadic work is when employees work from both the office and remote locations (i.e. home, a coworking space, a coffee shop, and even Barbados — the country is, afterall, considering giving 1-year visas to allow tourists to work while they visit the beaches). `}</p>
    <p>{`The idea behind nomadic work is that work will become decentralized. This will have huge implications for companies; from changing their real estate strategies to measuring productivity and collaboration. For employees, it means that their typical work practices will be disrupted (albeit they already have been due to the coronavirus). `}</p>
    <p>{`As more organizations realize the value of remote work and consider implementing remote work policies on a more permanent basis, it’s time to shift the focus of the conversation from how to work remotely during a pandemic to how to better support remote workers in the long-run; regardless of whether they’re working from home, a cabin in the woods, or their favorite local coffee shop. `}</p>
    <h3>{`From Reactive to Strategic Remote Work`}</h3>
    <p>{`With nomadic work increasingly growing in popularity among employees and employers, here are some things that organizations must think about.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Real estate.`}</strong>{` `}<a parentName="li" {...{
          "href": "https://allwork.space/2020/07/future-of-work-if-work-from-anywhere-is-the-future-what-does-this-mean-for-office-location/"
        }}>{`If employees are allowed to work remotely part-time or full-time, what does this mean for company headquarters?`}</a>{` Though it’s unlikely that headquarters will disappear, it is likely that many companies will need to downsize their real estate footprint. Some organizations might think about setting up satellite offices in more residential areas, while others might consider sponsoring coworking or flexible workspace memberships. `}</li>
      <li parentName="ul"><strong parentName="li">{`Cybersecurity.`}</strong>{` The coronavirus pandemic forced many organizations to allow their employees to work from home from one day to the other. ]This raised several questions about cybersecurity and best practices that employees should follow.](`}<a parentName="li" {...{
          "href": "https://allwork.space/2020/04/future-of-work-covid-19-reveals-cybersecurity-threats-in-digital-transformation-efforts/"
        }}>{`https://allwork.space/2020/04/future-of-work-covid-19-reveals-cybersecurity-threats-in-digital-transformation-efforts/`}</a>{`) If nomadic work becomes the norm for organizations, this will mean that companies will have to provide robust training on cybersecurity, implement strict policies on connectivity, and have a clear path should there be a security breach. Likely the most important element in cybersecurity is training employees. Recent surveys have found that many employees have purchased a VPN service on their own for cybersecurity issues, but cybersecurity goes beyond VPNs and secure networks. If employees will be allowed to work remotely from anywhere, this means that they should consider steps if they wish to use a public wifi network, password requirements, two-step authentication for use of email or other company platforms, how to identify safe networks, use of bluetooth and other devices, etc. `}</li>
      <li parentName="ul"><strong parentName="li">{`Productivity.`}</strong>{` Though we’ve come a long way from the days where productivity was measured strictly on hours worked, there’s still plenty to do, especially as more people work remotely. Nomadic work means that people can work from anywhere and, ideally, with a flexible schedule. Though it’s important for employees’ working hours to overlap with regular business hours, depending on a person’s role and responsibilities, the hours they work shouldn’t matter much as long as people are meeting deadlines, turning in projects, and meeting individual and company goals. `}</li>
      <li parentName="ul"><strong parentName="li">{`Communication.`}</strong>{` What will be the company’s preferred method of communication with remote employees? Will there be time limits on when to contact other employees or management during the week and weekend? What about in-person meetings, will some still be required to go into the office every now and then? What platforms will be used by the company? How should each platform be used? These are some of the things that employers will need to address. Clear communication is key to success for remote teams, having clear guidelines and setting clear expectations can help create a better nomadic work experience for managers and employees alike. `}</li>
      <li parentName="ul"><strong parentName="li">{`Devices and other equipment.`}</strong>{` Will the company provide the necessary equipment and devices for employees to do their work or are employees expected to bring their own devices? This applies to laptops, printers, scanners, headphones, microphones, and any other device that a person might require to do his or her job. Should devices used meet certain requirements?`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      