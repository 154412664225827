import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-09-27T00:00:00.000Z",
  "title": "Future Of Work: How To Attract Gen Z To The Workplace\n",
  "slug": "future-of-work-how-to-attract-gen-z-to-the-workplace",
  "excerpt": ["The oldest of Gen Z is beginning to enter the workforce and companies need to prepare for new and different work styles.", "Companies will need to offer additional flexibility in order to attract top Gen Z talent, along with flexible design and social interaction.", "Since Gen Z is the first generation of true digital natives, technology will play a key role in the workplace. Experts say that access to top-end tech is a must."],
  "images": ["33-HOW-TO-ATTRACT-GEN-Z-TO-THE-WORKPLACE.jpg"],
  "backlink": "https://allwork.space/2019/09/future-of-work-how-to-attract-gen-z-to-the-workplace/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Generation Z already accounts for 32% of the U.S. population and they have just surpassed Millennials as the largest age demographic in the country. Generation Z is made up of those born between 1995 and 2010, making them the first generation that grew up with the Internet.`}</p>
    <p>{`The oldest of Gen Z is beginning to enter the workforce and companies need to prepare for new and different work styles.`}</p>
    <p>{`While there are some similarities between Millennials and Gen Z — both generations support companies that align with their values and both value experiences — there are some key differences that will greatly impact the work environment and how young workers engage with brands.`}</p>
    <p>{`For example, Gen Z workers are competitive and independent; they value in-person communication; and they value access to services more than owning possessions. To cater to this generation in the workplace, companies could emphasize private workspaces as opposed to collaborative areas; they could incorporate more break out rooms so people can have impromptu meetings; and they could provide benefits and perks like ride-sharing services and food delivery.`}</p>
    <p>{`Furthermore, a Deloitte survey found that “Gen Zs show deeper loyalty to employers who boldly tackle the issues that resonate with them most, such as protecting the environment and unemployment. (…) `}<a parentName="p" {...{
        "href": "https://allwork.space/2019/06/the-future-of-work-should-you-focus-on-finding-your-business-purpose/"
      }}>{`Gen Zs want all of the talk business gives to purpose to become meaningful action`}</a>{`, and for business leaders to serve as agents for positive change.”`}</p>
    <h2>{`Key Stats and Facts about Gen Z`}</h2>
    <ul>
      <li parentName="ul">{`76% of Gen Z professionals feel that the skills in today’s workforce are different from the skills necessary in past generations.`}</li>
      <li parentName="ul">{`59% of Gen Z believe that their job will not exist in the same form 20 years from now.`}</li>
      <li parentName="ul">{`59% of Gen Z would learn new skills to make more money — unlike Millennials, financial incentives matter for Gen Z.`}</li>
      <li parentName="ul">{`43% prefer self-directed and independent learning.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://allwork.space/2019/06/future-of-work-the-top-10-soft-skills-you-need-to-succeed/"
        }}>{`Contrary to HR trends and what experts are saying about the future of work`}</a>{`, 62% of Gen Z stated that hard skills have changed faster than ever and are more important than soft skills.`}</li>
    </ul>
    <p>{`The above stats were taken from a `}<a parentName="p" {...{
        "href": "https://learning.linkedin.com/blog/learning-thought-leadership/gen-z-is-shaping-a-new-era-of-learning--heres-what-you-should-kn"
      }}>{`LinkedIn survey`}</a>{`.`}</p>
    <p>{`A `}<a parentName="p" {...{
        "href": "https://www2.deloitte.com/global/en/pages/about-deloitte/articles/millennialsurvey.html#info"
      }}>{`Deloitte survey`}</a>{` found that Gen Z would switch jobs for the following reasons:`}</p>
    <ul>
      <li parentName="ul">{`34% because they are dissatisfied with pay and financial rewards`}</li>
      <li parentName="ul">{`33% because there are not enough opportunities to advance`}</li>
      <li parentName="ul">{`27% for lack of learning and development opportunities`}</li>
      <li parentName="ul">{`21% because they are bored and are not challenged enough by their work`}</li>
      <li parentName="ul">{`19% for poor work/life balance and lack of flexibility`}</li>
      <li parentName="ul">{`16% because they don’t like the workplace culture`}</li>
      <li parentName="ul">{`15% feel that they are not appreciated.`}</li>
    </ul>
    <p>{`Allwork.Space spoke with Mark Stratz and Brian Zurek from `}<a parentName="p" {...{
        "href": "https://transwestern.com/"
      }}>{`Transwestern`}</a>{` to learn about what drives Gen Z and what companies can do to attract Gen Z talent.`}</p>
    <h3>{`Allwork.Space: What are some key characteristics of Gen Z professionals? What is it that drives them?`}</h3>
    <p>{`Transwestern: Beyond the characteristics, we hear so much about – strong multi-taskers, heavily reliant on technology – what has stood out to me is Gen Z’s sense of responsibility. We see this in their work ethic, the value they place in gaining hands-on experience, and their commitment to personal and business practices that demonstrate sincere respect for the environment and prudent use of resources.`}</p>
    <p>{`Additionally, they have a natural proclivity to seek out diverse points of view – a skill it takes some individuals years to perfect. Perhaps this is the result of what they have learned in school, cultural shifts, or a combination.`}</p>
    <h3>{`Allwork.Space: How will these characteristics and traits impact their workplace needs and wants?`}</h3>
    <p>{`I believe Gen Z, more so than the generations that came before, desires authentic, personal experiences. A company’s workplace quickly communicates its culture and where its values lie.`}</p>
    <p>{`For example, Transwestern recently completed a new transformative office space to house its Phoenix team, and we focused on creating an environment that is classic and timeless, yet modern. The “look and feel” of the office is consistent throughout, which speaks to our high-touch, value-driven service delivery. And we’ve encouraged collaboration by making the space outward-facing and vibrant, helping to draw people in, welcoming team members and clients with a variety of gathering places and facilities for hosting events.`}</p>
    <h3>{`Allwork.Space: Speaking about collaboration, studies have found that Gen Z values privacy more than Millennials.`}</h3>
    <p>{`Yes, open layouts played well among Millennials, who enjoy a communal office spirit. Gen Z workers value collaboration, but also enjoy having personal space to work and think.`}</p>
    <p>{`A mix of cubicles, open space and private rooms or offices where workers can escape office distractions will better meet the needs of Gen Z – as well as appeal to Gen X workers who will continue to make up a large percentage of the workforce for quite some time.`}</p>
    <h3>{`Allwork.Space: Other than providing more private work areas, what other workplace elements will companies need to address in order to attract Gen Z talent?`}</h3>
    <p>{`It’s not so much that corporate America needs change, but rather a greater emphasis on how the workplace demonstrates sustainability, mindfulness and responsibility to the community. Having witnessed their parents’ struggles through the Great Recession, Gen Z is less about excess and more about function and culture.`}</p>
    <p>{`For example, wasteful use of a company’s resources may make some employees question their job security. If a workplace does not engage in environmentally sustainable programs and initiatives, that’s a big strike against it. And of course, because younger generations are more accustomed to having their needs met quickly, amenities will remain vital in attracting and retaining talent. This is a trend we believe will only get stronger, and why in Phoenix we were drawn to an adaptive reuse opportunity in a former Morton’s Steakhouse that sits adjacent to Starbucks and offers great community experience.`}</p>
    <h3>{`Allwork.Space: What other insights can you provide in regards to creating a workplace that speaks to Gen Z?`}</h3>
    <p>{`Going back to what I said earlier, we believe strongly that Gen Z – and frankly everyone – is looking for a workplace that feels authentic. Authenticity is all around us now, permeating businesses that impact every aspect of our lives.`}</p>
    <p>{`For us, this means staying away from gimmicks, costly accessories that serve no function, and space that sits unused. A company should build, design and/or occupy physical space that reinforces its culture.`}</p>
    <h3>{`Allwork.Space: With authenticity in, are there any workplace elements that you think will be ou as a result of Gen Z?`}</h3>
    <p>{`I’d say any design features without a clear purpose.`}</p>
    <p>{`Is a slide necessary in an office lobby? Could a fitness room be better utilized if the office building already has an exercise facility for tenants?`}</p>
    <h3>{`Allwork.Space: Change is always a challenge and Gen Z will, without a doubt, change the workplace. What do you think will be the biggest challenges companies will face in incorporating Gen Z into the workplace? Are there any areas in which companies are still lagging behind?`}</h3>
    <p>{`Like everything else, human nature leads many companies to jump on a bandwagon when it comes to their real estate, and that can result in workplaces that may be trendy now but could prove costly to reconfigure or renovate later.`}</p>
    <p>{`No doubt, the “cool factor” is hard to resist. Yet look how WeWork has been criticized mercilessly for its now-dated designs. Purpose should be the lodestar for any organization making space decisions. Likely, the companies that are behind in capitalizing on a multi-generational workforce have not clearly articulated a collaborative culture through their physical space.`}</p>
    <h3>{`10 Strategies to Attract and Retain Gen Z Talent`}</h3>
    <ol>
      <li parentName="ol">{`Embrace existing and emerging technologies`}</li>
      <li parentName="ol">{`Offer competitive salary and financial incentives; Gen Z want stability`}</li>
      <li parentName="ol">{`Provide opportunities for growth`}</li>
      <li parentName="ol">{`Give them some privacy`}</li>
      <li parentName="ol">{`Walk the talk; share your purpose and values and take action based on them`}</li>
      <li parentName="ol">{`Provide regular, one-on-one feedback`}</li>
      <li parentName="ol">{`Be flexible; Gen Z want work-life balance`}</li>
      <li parentName="ol">{`Focus on community`}</li>
      <li parentName="ol">{`Emphasize the importance of soft skills (and help them develop them)`}</li>
      <li parentName="ol">{`Embrace purposeful and thoughtful design: natural light, ergonomic furniture, and greenery.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      