import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-08-28T00:00:00.000Z",
  "title": "Future Of Work: Top 10 Most Desirable Skills For Workers Following Covid-19\n",
  "slug": "future-of-work-top-10-most-desirable-skills-for-workers-following-covid-19",
  "excerpt": ["Amid the uncertainty created by the coronavirus pandemic, one thing is certain- the way people work is changing.", "New research explores the talent and skills that are in high demand now, and will most likely be a key part of the future of work.", "Among them, there is rising demand for human and ‘power’ skills, which are incredibly important in leaders as we navigate transformative times."],
  "images": ["Future-of-Work-Template-Articles-1280-x-720-2-800x450.png"],
  "backlink": "https://allwork.space/2020/08/future-of-work-top-10-most-desirable-skills-for-workers-following-covid-19/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`“A small proportion of the population will be infected by COVID-19, almost all (93%) will be impacted.” – ManpowerGroup`}</p>
    <p>{`Amid the uncertainty created by the coronavirus pandemic, one thing is for certain: the way people work is changing.`}</p>
    <p>{`This applies to where we work from, how we work, how we engage with one another, how we interact with the workplace, and what skills companies are most interested in.`}</p>
    <p>{`ManpowerGroup’s “`}<a parentName="p" {...{
        "href": "https://go.manpowergroup.com/hubfs/What_Workers_Want_2/MPG_Future_for_Workers_By_Workers_1.pdf?hsLang=en"
      }}>{`The Future for Workers, By Workers: Making the Next Normal Better for All`}</a>{`”, explores what workers want post COVID-19 and provides a glimpse into the talent skills that will be in high demand now and in the future.`}</p>
    <p>{`According to ManpowerGroup, “this crisis should be a catalyst for a new future of work, that is more flexible, more diverse and more wellbeing-oriented than we could ever have imagined.”`}</p>
    <p>{`The report found that workers “are united in what they want for the future”. Top concerns and priorities for workers around the world include:`}</p>
    <ul>
      <li parentName="ul">{`Keeping their job.`}</li>
      <li parentName="ul">{`Health concerns; they want to be reassured that their health, safety, and wellbeing will come first when returning to the workplace.`}</li>
      <li parentName="ul">{`They want to keep the flexibility they have gained by the onset of the pandemic and they want to avoid returning to old ways of working.`}</li>
      <li parentName="ul">{`8 in 10 want to better balance work and their family in the future.`}</li>
      <li parentName="ul">{`Most workers want to go to the office 2-3 times a week, but not daily.`}</li>
    </ul>
    <p>{`The above can be summed up in the following statement:`}</p>
    <p>{`Workers want to “to keep their job, stay healthy, learn and keep developing skills, and to not go back to the old way of working.”`}</p>
    <p>{`For organizations, this means that they need to prioritize trust and transparency with their employees. ManpowerGroup argues that “trust and transparency have never been more important and the best employers know they need data and insight to instill confidence. Pulse surveys, feedback channels, consultation and communication are critical.”`}</p>
    <p>{`Though workers are united in what they want, the report found that not all workers have the same leverage.`}</p>
    <p>{`“Those with in-demand skills can call the salary shots, work remotely, avoid the commute and stay safe at home. Those with declining skills are required to commute to work onsite, and are subject to even greater exposure.”`}</p>
    <p>{`The report found that there is increased demand for cyber security experts, business transformation experts, data analysts, software and app developers, and new roles like contact tracers, distance monitors, and temperature checkers. At the same time, ManpowerGroup reports a decline in aviation, hospitality, and entertainment workers.`}</p>
    <h3>{`Top Skills for the Future of Work`}</h3>
    <p>{`“The biggest workforce shift and reallocation of skills since World War II began in March. Skills that were en vogue in 2019 might now be out of fashion; and the shift is swift — even those skills most in demand in the early phase of the crisis are different to demand emerging now and expected in future.”`}</p>
    <p>{`Roles That Are “En Vogue”:`}</p>
    <ul>
      <li parentName="ul">{`Business transformation`}</li>
      <li parentName="ul">{`Health and wellbeing `}</li>
      <li parentName="ul">{`Logistics`}</li>
      <li parentName="ul">{`eCommerce.`}</li>
    </ul>
    <p>{`Roles That Are “Out”:`}</p>
    <ul>
      <li parentName="ul">{`General sales and marketing`}</li>
      <li parentName="ul">{`Hospitality and leisure`}</li>
      <li parentName="ul">{`Office administration and maintenance`}</li>
      <li parentName="ul">{`Retail in real life. `}</li>
    </ul>
    <p>{`The report found that the pandemic has accelerated the demand for technical and human skills; a trend that was first observed a couple of years ago. `}</p>
    <p>{`While demand for acute skills in tech continues unabated, “there is rising demand too for human or power skills — in times of rapid transformation and uncertainty these so-called soft skills are more important than ever in workers and in leaders.”`}</p>
    <p>{`Below are the top 10 skills that will be most in demand now and in the future:`}</p>
    <ul>
      <li parentName="ul">{`Communication`}</li>
      <li parentName="ul">{`Prioritization`}</li>
      <li parentName="ul">{`Adaptability`}</li>
      <li parentName="ul">{`Initiative taking`}</li>
      <li parentName="ul">{`Integrity`}</li>
      <li parentName="ul">{`Analytical thinking`}</li>
      <li parentName="ul">{`Relationship building`}</li>
      <li parentName="ul">{`Empathy`}</li>
      <li parentName="ul">{`Coaching`}</li>
      <li parentName="ul">{`Resilience. `}</li>
    </ul>
    <h3>{`Making the Next Normal Better for All: Tips from ManpowerGroup`}</h3>
    <p>{`The pandemic has provided the world with a great opportunity to re-shape the world of work and better align it to what workers have been asking for some time now: more flexibility, digital transformation, more trust and transparency, work-life integration. `}</p>
    <p>{`Below are five tips from ManpowerGroup to make the next normal better for all stakeholders. `}</p>
    <p><strong parentName="p">{`1. Ask the right questions.`}</strong>{` Why should your organization go back to the way things used to be? To better plan and prepare for the future, organizations should be asking themselves how they can better support leaders, managers, and workers to enable them to be more productive in new work settings and arrangements. `}</p>
    <p><strong parentName="p">{`2. Skills are evolving, organizations need to be ready for that.`}</strong>{` “The skills employers need in the future will be different to the past.” This is why organizations need to start thinking about providing employees with access to learning and development opportunities. `}</p>
    <p><strong parentName="p">{`3. Flexibility is a must, for everyone.`}</strong>{` Flexibility is about more than allowing employees to work remotely. Other ways organizations can offer flexibility is by allowing for flexible schedules, staggered shifts, and offering a combination of in-office and remote work. `}</p>
    <p><strong parentName="p">{`4. Physical and mental wellbeing are key.`}</strong>{` “Feelings of isolation, stress, fear and anxiety will be a COVID-19 legacy, and so too will be our reflections on the value of health, wellbeing, family and community.” Organizations need to prioritize the wellbeing of employees, both mental and physical, if they want workers to feel confident and productive. `}</p>
    <p><strong parentName="p">{`5. Resilience is key.`}</strong>{` “The initial adrenalin of workers needs to shift to resilience for the`}</p>
    <p>{`long term and employers must lead this charge.” In order to cultivate resilience, organizations need to focus on their communication efforts, ensure that they have strong leadership, and that employees feel supported.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      