import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2021-01-29T00:00:00.000Z",
  "title": "Future Of Work: From A Workplace Centric To A Worker Centric World\n",
  "slug": "a-workplace-centric-to-a-worker-centric-world",
  "excerpt": ["During the pandemic, organizations worldwide are experiencing a profound workplace transformation.", "This evolutionary change is generating people-centric work environments.", "New research from JLL highlights four key strategic priorities for organizations, and how this will impact CRE in the future."],
  "images": ["from-a-workplace-centric-to-a-worker-centric-world.png"],
  "backlink": "https://allwork.space/2021/01/future-of-work-from-a-workplace-centric-to-a-worker-centric-world/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The coronavirus pandemic has accelerated, at a tremendous rate, the future of work. As a result, organizations worldwide are experiencing a profound transformation to the notion of work and how people work.  `}</p>
    <p>{`This is leading to a “decoupling of traditional work and workstyles from the office and an evolution toward the workplace being a people-centric environment,” according to a recent JLL report, `}<a parentName="p" {...{
        "href": "https://www.jll.co.uk/en/trends-and-insights/research/reimagine-the-new-future-of-work-to-shape-a-better-world"
      }}>{`Reimagine: the new future of work to shape a better world`}</a>{`. `}</p>
    <p>{`While the future of work is not new, the way companies approach it is. Companies are now faced with the challenge of reimagining the workplace and its purpose.  `}</p>
    <h3>{`From Workplace-centric to a Worker-centric World`}</h3>
    <p>{`“In envisaging what the new world of work will look like, it has become clear that real estate is enabling work, workforce and workplace (the 3Ws), the three key dimensions of any successful business strategy.” `}</p>
    <p>{`The business world will never return to the pre-COVID normal. Companies have realized by now that to be successful in the future, they will need to master the ability of adaptability, elasticity, and transformation.  `}</p>
    <p>{`JLL has identified four key strategic priorities that are helping organizations reimagine the future of work: `}</p>
    <ol>
      <li parentName="ol">{`People Strategy `}</li>
      <li parentName="ol">{`Business Strategy `}</li>
      <li parentName="ol">{`Workplace Strategy `}</li>
      <li parentName="ol">{`Corporate Real Estate Strategies `}</li>
    </ol>
    <p>{`The first, people strategy, is about delivering a memorable work experience for employees, regardless of where they are working from. The second, business strategy, requires that companies think about new talent models and objectives, including the shift towards remote/hybrid work.`}</p>
    <p>{`Workplace strategy loops back into the idea that companies need to reimagine the workplace and its purpose so that they can better support the future of work.  `}</p>
    <p>{`CRE strategies will focus on repositioning corporate real estate portfolios, with an increase in the number of companies using flexible space in order to cater to the needs of workers as they increasingly work from anywhere. JLL predicts that health and wellness of employees and technology investment will take center stage for CRE. `}</p>
    <p>{`JLL argues that “the pandemic has accelerated the digital transformation of businesses and brought individual health and well-being to the forefront as a critical priority.” `}</p>
    <p>{`In the future, the workplace will be all about providing a seamless experience. Technology will be the main enabler for this, and it will help enhance performance and productivity in the workplace—regardless of where that may be for individual workers. `}</p>
    <p>{`“The big winners will be responsible and future-fit companies that lean into workforce preferences and break the silos, disperse their footprint and reinforce their workplace model while enabling work, the workforce and the workplace.” `}</p>
    <h3>{`Hybrid Work Ecosystems and Their Impact on CRE`}</h3>
    <p>{`“We predict the speed of the trend toward hybrid work ecosystems to increase, with offices acting as collaboration spaces and centers of innovation.” `}</p>
    <p>{`To support the hybrid work ecosystem, companies will increasingly lean on flexible space solutions to meet new expectations around working and living patterns. These types of workplaces will help meet the new purpose of the office: to become the center of social interaction and engagement while allowing companies to have a liquid, dispersed footprint.  `}</p>
    <p>{`The hybrid work model will lead many organizations to rationalize their workplace portfolio; at the same time, other companies will prioritize portfolio size and stability.  `}</p>
    <p>{`“Portfolio transformation will drive a wide range of outcomes, including the growth of hybrid models.”`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      