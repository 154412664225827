import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import { DefaultComponentProps } from '../core';
import imagePathGenerator from '../../../../src/utils/imagePathGenerator';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography, Button, Dialog, Slide } from '@material-ui/core';
import { Container, Section } from '.';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
    image: {
      width: '300px',
      height: '300px',
      [theme.breakpoints.up('md')]: {
        width: '425px',
        height: '425px',
      },
      objectFit: 'cover',
      objectPosition: '0px 0px',
      boxShadow: theme.shadows[5],
      borderRadius: '1.5px',
    },
  }),
);

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export type ModalProps = { isOpen: boolean; onRequestClose: any; data: any } & Pick<
  DefaultComponentProps,
  'path' | 'classname' | 'children'
>;

const Modal = ({ ...props }: ModalProps) => {
  const classes = useStyles();

  return (
    <Dialog
      className={classes.root}
      fullScreen
      open={props.isOpen}
      onClose={() => props.onRequestClose(false)}
      TransitionComponent={Transition}
    >
      <Grid container justify="flex-end">
        <Button className="py-3 px-3" autoFocus color="inherit" onClick={() => props.onRequestClose(false)}>
          <CloseIcon />
        </Button>
      </Grid>
      <Grid container item xs={12} alignItems="center">
        <Container>
          <Section spacing={1}>
            <Grid container item xs={12} justify="center">
              {props.data && (
                <>
                  <Grid container item md={12} lg={4} justify="center" className="mx-2 py-2" alignItems="center">
                    <img className={classes.image} src={imagePathGenerator(props.data.image)} />
                  </Grid>
                  <Grid container item md={12} lg={6} direction="column" justify="center" className="mx-2">
                    <Section spacing={2}>
                      <Typography variant="h2" className="black-text">
                        {props.data.name}
                      </Typography>
                    </Section>
                    <Section spacing={1}>
                      <Typography variant="h4">{props.data.position}</Typography>
                    </Section>
                    <Section spacing={1} classname="justify">
                      <Typography variant="caption" className="secondary-dark-text">
                        {props.data.description}
                      </Typography>
                    </Section>
                  </Grid>
                </>
              )}
            </Grid>
          </Section>
        </Container>
      </Grid>
    </Dialog>
  );
};

export default Modal;
