import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-07-29T00:00:00.000Z",
  "title": "Proptech Will Be The Tipping Point For The Real Estate Industry\n",
  "slug": "proptech-will-be-the-tipping-point-for-the-real-estate-industry",
  "excerpt": ["Although real estate is one of the largest industries in the world, it has struggled to move forward in innovation, but property technology may be the tipping point the market needs."],
  "images": ["22-proptech-will-be-the-tipping-point-for-the-real-estate-industry.jpg"],
  "backlink": "https://allwork.space/2019/07/proptech-will-be-the-tipping-point-for-the-real-estate-industry/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Although real estate is one of the largest industries in the world, it has struggled to move forward in innovation, but property technology may be the tipping point the market needs.`}</p>
    <p>{`Proptech is the use of technology that makes buying, selling and managing properties simple. For example, companies such as Zillow have changed the way we can buy and rent homes, while WeWork has disrupted the commercial real estate sector with on-demand flexible workspaces.`}</p>
    <p>{`Proptech hit a record high in global investments in 2018 with $9.6 billion, so all eyes have been on the industry as consumers wait for its next step. To compare, highly publicized industries such as blockchain and cryptocurrency startups raised just $1.3 billion globally in the same year.`}</p>
    <p>{`Going forward, Proptech will likely turn its focus towards finding ways to optimize the internet of things (IoT), sensors, data, and artificial intelligence (AI) in order to better manage and operate properties.`}</p>
    <p>{`“I believe it’s important to grow with the industry to ultimately keep customers satisfied,” said Eric Trabold, CEO at Nexkey. “You may have happy customers today, but what about five years from now? Think about how you can use technology in ways that will add value to your business.”`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.forbes.com/sites/forbestechcouncil/2019/07/29/the-new-frontier-that-could-outrun-ai-and-blockchain-real-estate-tech/#4e8d453d22bb"
      }}>{`Read The Original Article on Forbes`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      