import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-09-11T00:00:00.000Z",
  "title": "Future Of Work: How Technology Can Increase The Value Of Cre\n",
  "slug": "future-of-work-how-technology-can-increase-the-value-of-cre",
  "excerpt": ["“COVID-19 could be one of the most transformative events the built environment has ever experienced” – Faisal Butt, Pi Labs", "With organizations rethinking their real estate strategy, providing value has become a top priority for landlords and developers across the globe.", "A new whitepaper examines the future of commercial real estate and how proptech will play a key role in the value of built environments."],
  "images": ["how-technology-can-increase-the-value-of-cre.png"],
  "backlink": "https://allwork.space/2020/09/future-of-work-how-technology-can-increase-the-value-of-cre/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`When will things go back to normal?`}</p>
    <p>{`This is a question that many keep asking themselves and others. While slowly we are seeing some things returning to normal, the reality is that (some) things will never quite be the same. `}</p>
    <p>{`One area where this is true is CRE, specifically, the workplace. `}</p>
    <p>{`Pi Labs’ recent whitepaper “`}<a parentName="p" {...{
        "href": "https://www.pilabs.co.uk/research/proptech-and-the-future-of-work"
      }}>{`Proptech and the Future of Work`}</a>{`” delves into this specific topic and how proptech will play a key role in the value of built environments in the future. `}</p>
    <p>{`“In this report, we aim to chart the state of proptech innovation during these volatile times and forecast how long-running workplace trends are evolving. Remote work has challenged the way business leaders think about their real estate assets and the very purpose of physical offices is under review at many companies.`}</p>
    <p>{`“The impact of workplaces on human productivity and wellbeing is coming under scrutiny. Economic instability has reshuffled workforce needs, establishing a new scramble for talent and surging interest in robotics and automation.”`}</p>
    <h3>{`A Sharper Focus on the Importance of Tech`}</h3>
    <p>{`Historically, the commercial real estate industry has been slow to adapt to new solutions. Luckily, this may be changing as property owners respond to the pandemic. `}</p>
    <p>{`According to Pi Labs, the CRE sector’s appetite for innovative ideas and technologies has grown as a response to the current health crisis. More importantly, the pandemic “has brought a sharper focus on how technology can increase the value of CRE.”`}</p>
    <p>{`With organizations rethinking their real estate strategy and footprint, providing value has become a top priority for landlords and developers across the globe. `}</p>
    <p>{`There’s good reason to believe that proptech will see a boost in adoption in the coming months and years.`}</p>
    <p>{`“The clearest parallel is the 2008 downturn, which led to a boost in proptech as real estate companies sought to find competitive advantage and maximise savings.”`}</p>
    <p>{`As people and organizations continue to understand the “new normal”, proptech will become a vital offering for tenants and end-users alike. `}</p>
    <h3>{`The Perfect Time to Innovate CRE Environments`}</h3>
    <p>{`“Broad trends in consumer, employer and worker sentiment have shifted dramatically over recent months and created the perfect environment for new innovations to emerge. Those that do will undoubtedly define the workplaces of tomorrow and transform the future of CRE.”`}</p>
    <p>{`In terms of the workplace, flexibility is now the holy grail. `}</p>
    <p>{`This means that the CRE sector has to be more efficient in delivering productive and inspiring environments for tenants. For this reason, Pi Labs expects the office to undergo a period of rapid transformation. `}</p>
    <p>{`“Tomorrow’s offices are likely to look physically very different because they’ll serve much more specific purposes.`}</p>
    <p>{`“As workers and managers better grasp what needs to be done in person and what can be done online, the focus will switch to increasing the quality of office interactions.”`}</p>
    <p>{`This has several implications for the workplace. `}</p>
    <p>{`One, which we are already seeing, is a growing reluctance to centralize the workforce. Companies are dispersing their workforce across multiple locations, providing them with more choice and autonomy while working remotely. `}</p>
    <p>{`“The right space can make all the difference to worker productivity and job satisfaction: staff are happier when they have access to different locations to suit their habits and preferences.”`}</p>
    <p>{`Another key area of focus is health and safety. `}</p>
    <p>{`“Thermal scanners, primarily used in airports until recently, have been installed in reception areas to scan workers as they arrive to work for indications of fever. Touchless elevator and door controls are also being introduced, as well as plexiglass partitions separating desks and, in some cases, bathroom sinks.”`}</p>
    <p>{`Technology can make the above implementations more efficient and user-friendly, therefore creating a better workplace experience. `}</p>
    <p>{`Pi Labs also expects a growing demand for call rooms and semi-private booths as business travel is reduced. `}</p>
    <h3>{`Wellbeing: the Ultimate Value Proposition`}</h3>
    <p>{`“The social understanding of being ‘at work’ has fundamentally shifted. Now, a sharper focus on health and mental wellbeing is rewriting the rulebook for CRE.”`}</p>
    <p>{`Wellbeing had already been gaining traction in the CRE sector. The pandemic has dramatically accelerated the rate at which the sector incorporates and focuses on the wellbeing of the end-user. `}</p>
    <p>{`“While technology investments have a well-established track record of improving operational efficiency and lowering commercial property costs, the emphasis on increasing the experience, comfort and mental wellbeing of end-users is new.`}</p>
    <p>{`“One MIT study, for example, calculated that offices which rank well on wellbeing measures command 28% higher effective rent premiums on average.”`}</p>
    <p>{`Workplaces will no longer be valued and assessed based on desk space, rather, they “will be judged by how successfully they enable workplace focus, collaboration, learning, and organisational culture.”`}</p>
    <blockquote>
      <p parentName="blockquote">{`“COVID-19 could be one of the most transformative events the built environment has ever experienced”`}</p>
      <p parentName="blockquote">{`Faisal Butt, Pi Labs`}</p>
    </blockquote>
    <p>{`Digital technologies can help property owners keep up with shifting demands, particularly as they pertain to the tenant experience. `}</p>
    <p>{`“Smart building infrastructure makes it easier to optimise environmental conditions within CRE, but there’s a growing need for proptech solutions to deliver environmental agency to end-users. Among the features and services that properties can offer, half of CRE leaders now believe smartphone and tablet integration is crucial to the success of their real estate.”`}</p>
    <p>{`CRE requirements are changing quickly. The only way for property owners to keep up with this demand is by embracing and deploying the right technologies. `}</p>
    <p>{`“COVID-19 could be one of the most transformative events the built environment has ever experienced. The workplace has been disrupted by lockdown, and will be further impacted by the subsequent economic downturn, changing the status quo of the real estate industry for years and perhaps decades to come.`}</p>
    <p>{`“It is now hugely important to understand both the threats and the opportunities facing the built environment in order to adapt and mitigate the challenges that organisations of all sizes have and will struggle with. Technology will play a big part in shaping the future of work providing an abundance of opportunities, and the winners in a post COVID world will be those players that make the right investments to unlock the full potential of what Proptech can offer.” – Faisal Butt, CEO and Founder of Pi Labs`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      