import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-11-06T00:00:00.000Z",
  "title": "Future Of Work: Bringing Humanity Back To The Hiring Process\n",
  "slug": "future-of-work-bringing-humanity-back-to-the-hiring-process",
  "excerpt": ["Greg Besner, founder of CultureIQ, believes that current hiring processes are letting candidates down.", "Remote work has impacted recruitment and onboarding, and many people have suffered as a consequence.", "If firms want to attract top talent, their hiring strategy must change. Here, Besner offers advice on how to bring humanity back to the hiring process."],
  "images": ["Future-of-Work-Template-Articles-1280-x-720-800x450.png"],
  "backlink": "https://allwork.space/2020/11/future-of-work-bringing-humanity-back-to-the-hiring-process/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The coronavirus pandemic has caused great shifts in the way we work. One potential permanent shift is the increase in remote and work from anywhere positions. As a result, the hiring process has been impacted. `}</p>
    <p>{`But Greg Besner, Founder and Vice Chairman of `}<a parentName="p" {...{
        "href": "https://cultureiq.com/"
      }}>{`CultureIQ`}</a>{`, believes that the changes in hiring processes aren’t all positive. And candidates aren’t happy about this. `}</p>
    <p>{`Jobseekers have reported being dragged through months-long interview processes only to be ghosted by prospective employers in the end.`}</p>
    <p>{`This cannot continue; especially if companies hope to hire top talent. `}</p>
    <p>{`Allwork.Space spoke with Greg Besner to learn how companies can bring humanity back to the hiring process and how the interview process can be more streamlined, compassionate and communicative for both employer and prospective employee.`}</p>
    <p><strong parentName="p">{`Allwork.Space: What shifts have you observed in the hiring process this year?`}</strong></p>
    <p>{`Greg Besner: The future of work has arrived early. Most employees, including hiring managers, are working from home, which has accelerated the need to effectively use technology tools for hiring. Interviewing, hiring and even onboarding are being performed virtually. `}</p>
    <p>{`A new hire can feel especially isolated without a traditional onboarding process. `}</p>
    <p>{`Historically, a new employee would show up at the office their first day of work and be welcomed by new colleagues. To address this shift, organizations are helping to welcome new employees through more frequent team meetings, phone calls and video calls. Assigning a mentor to help the new hire assimilate into your organization has become even more important, as well as actively introducing new hires to leaders throughout the company.`}</p>
    <p>{`In addition, given the uncertainty of the economy, many companies have less clarity regarding hiring budgets and timing of filing roles. This uncertainty can be very frustrating for candidates during the hiring process. Keeping candidates up-to-date is especially important right now. `}</p>
    <p>{`Honest and thorough communication is critical. `}</p>
    <p><strong parentName="p">{`Allwork.Space: One issue you’ve mentioned in the past is that jobseekers are complaining about the hiring process, what are some of the top or most common complaints you’ve come across?`}</strong></p>
    <p>{`Candidates often complain about poor follow-up, poor communication, and/or lack of transparency. Also comments like, “The company cares more about my resume than they care about me”, and “I’m peppered with interview questions, but I’m not given the opportunity to ask many questions.”`}</p>
    <p><strong parentName="p">{`Allwork.Space: What are some best practices companies can adopt to address these complaints?`}</strong></p>
    <ul>
      <li parentName="ul">{`Carve out time for candidates to ask questions during the interview process. `}</li>
      <li parentName="ul">{`Have transparent conversations with candidates to eliminate ambiguity, and train all hiring managers how to have these conversations. `}</li>
      <li parentName="ul">{`Make sure to communicate updates to the applicants throughout the hiring cycle. `}</li>
    </ul>
    <p><strong parentName="p">{`Allwork.Space: You talk about bringing back humanity to the hiring process, what exactly does this mean and how can it be done?`}</strong></p>
    <p>{`It’s more important than ever to determine mission and value alignment when hiring. When employees feel aligned to their company’s mission and values, they feel inspired to contribute their best effort. `}</p>
    <p>{`To accomplish this during the hiring process, companies can make sure to share their mission and values anywhere candidates might encounter them––in job descriptions, on the company’s website recruiting page, and during interviews to demonstrate the company’s commitment, and to give candidates a chance to assess alignment. `}</p>
    <p>{`During the interview process, ask the candidates about a time they exhibited each value.  `}</p>
    <p>{`After an employee is hired, consider the rest of the employee life cycle–– onboarding, promotions, ongoing training, and look for ways to embed the company’s values throughout the employee life cycle. Every policy, process, and program is an opportunity to reiterate mission and values. `}</p>
    <p><strong parentName="p">{`Allwork.Space: Why should companies prioritize improving and humanizing their hiring process?`}</strong></p>
    <p>{`When the hiring process is effective, the candidates that are hired are more likely to be satisfied, motivated, and committed. Successful recruits are motivated to exceed their goals, they are proactive about learning new skills and starting new projects, they are positive in their approach to work, creative in solving problems and committed to developing their careers at the organization. `}</p>
    <p>{`For candidates that are not hired, they will more likely feel they were treated fairly, and they may become advocates for the company and maybe even apply again in the future and successfully fill a role.`}</p>
    <p><strong parentName="p">{`Allwork.Space: You shared some best practices to address the top complaints of jobseekers. What are some additional best practices that organizations can implement when hiring candidates?`}</strong></p>
    <p>{`Companies should create company-specific hiring standards, accountability measures, and behavioral expectations. The following are some tips that I recommend: `}</p>
    <ul>
      <li parentName="ul">{`Develop an internal program to certify every interviewer before they interview candidates.`}</li>
      <li parentName="ul">{`Create a set of behavior-based interview questions dedicated to determining a candidate’s mission and value alignment. This is often referred to as “culture fit”.`}</li>
      <li parentName="ul">{`Encourage candidates to review the company’s mission and values before interviewing.  `}</li>
      <li parentName="ul">{`Use assessment tools with each candidate. Assessment tools help eliminate unintentional hiring bias.`}</li>
      <li parentName="ul">{`Assign a mentor to new hires to help the new employee assimilate into the organization.`}</li>
      <li parentName="ul">{`Employ an onboarding process that includes company training and introductions to leaders throughout the company.`}</li>
    </ul>
    <p><strong parentName="p">{`Allwork.Space: Are there any tactics you believe organizations should get rid of?`}</strong></p>
    <ul>
      <li parentName="ul">{`Cold, clinical interviews: Candidates hope to evaluate company culture and culture fit during each interaction, including the interview process. A cold and clinical interview can result in a candidate losing interest in an organization.`}</li>
      <li parentName="ul">{`Stress interviews: Some companies purposely introduce stress into the interview process to test candidates’ ability to perform under extreme pressure. This old-school interview tactic often results in candidates being turned off by the hiring process, and can even lead to resentment toward the company due to the hostile interview tactics.   `}</li>
      <li parentName="ul">{`Unstructured Interviews: A laid-back interview style can leave the impression that the interviewer was not prepared to conduct the interview. This lack of structure can also leave candidates feeling uninformed. This can be solved by making sure that interviewers are trained and prepared for the hiring process.`}</li>
    </ul>
    <p><strong parentName="p">{`Allwork.Space: When you talk about bringing back humanity to hiring, you talk about streamlining the process. What are some available technologies that can help streamline and improve the hiring process?`}</strong></p>
    <p>{`Video conferencing software has become critical. Tools such as Zoom, Slack, Microsoft Teams and other communication solutions are more important than ever given the ubiquity of remote workers and hiring managers.`}</p>
    <p>{`Assessment tools such as Myers-Briggs, Hogan, DiSC, Wonderlic, and other solutions that can help identify candidates’ personality and motivations, and to assess company and team fit. `}</p>
    <p><strong parentName="p">{`Allwork.Space: How does the hiring process impact company culture?`}</strong></p>
    <p>{`It’s essential to attract and retain top talent, but it’s also critical that new hires buy-in to the company’s mission and values. This buy-in will ensure that the new employees feel inspired to contribute their best effort. Conversely without mission and value buy-in, employee engagement wanes negatively impacting overall team performance and culture.  `}</p>
    <p>{`The mix of employees are the key ingredients for creating a high-performance culture. Each organization has a unique recipe to achieve its desired culture, and each new hire impacts that outcome. Another analogy I like is that the mix of your employees becomes the personality of your company. Hiring the right mix of employees results in a happy, engaged, and effective workforce allowing an organization to achieve its business strategy.`}</p>
    <p><em parentName="p">{`Besner’s new book, `}<a parentName="em" {...{
          "href": "https://u7061146.ct.sendgrid.net/ls/click?upn=4tNED-2FM8iDZJQyQ53jATUdVDchOmam4EzRnFuaW5ZRosnGV6HDYt1LDe9uppQayN4OE2_9mffVNmXguNakQ9-2Fwzc0o5M1vp3drJs7wgRNAw223-2BXpRO7cSDl18Dywg3QOowPB1sU7Bt2TGsWwBY-2FimUu9zVMpVfu4fwKW31jXyd2jyXVuA9dT1NxZyK6MXN4uU3c3kkl60-2FPykcqtoHhMhYIFsbcf95OWcPp-2FYP-2FAbqDzk7AM1Y7ClXLfJLzbWckNocznadtNL6WcMPGkTowGMUyCmGq3ftob5nm4JbNtNl9bjOrnD9w5EwpjyiE1IvZRLxbGrREjo5RPIzK9QpeYhUGoJ1UO6LL5HXVvxHJSGYu-2FlWBZoln1QEhwJPN1hRjAQ7-2FRViVSGJ32XFXB9ASh90wdIhkH8-2BzZBuXEVPPfWyVR0c8-3D"
        }}>{`The Culture Quotient: Ten Dimensions of a High-Performance Culture`}</a>{` (out November 10, 2020 from Ideapress) is a handbook for building a high-performance culture that drives employee engagement and job satisfaction, creates big opportunity for innovation, and boosts the bottom line.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      