import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-11-22T00:00:00.000Z",
  "title": "Future Of Work: AI Is Reshaping Managers’ Traditional Role\n",
  "slug": "future-of-work-ai-is-reshaping-managers-traditional-role",
  "excerpt": ["A recent report found that artificial intelligence is being increasingly used in business operations.", "The report also found that people are less unsure and fearful of AI and are warming up to the idea of using it in the workplace.", "AI use in the workplace will have huge implications for managers, whose role is shape-shifting as AI can better handle more administration-related coordination and control tasks."],
  "images": ["53-future-of-work-ai-is-reshaping-managers-traditional-role.png"],
  "backlink": "https://allwork.space/2019/11/future-of-work-ai-is-reshaping-managers-traditional-role/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Oracle & Future Workplace recently published the findings of their AI@Work 2019 Study. The report, titled “`}<a parentName="p" {...{
        "href": "https://go.oracle.com/LP=86149?elqCampaignId=230263"
      }}>{`From Fear to Enthusiasm`}</a>{`” found that artificial intelligence is winning more hearts and minds in the workplace.`}</p>
    <p>{`New technologies are rapidly being embraced in business operations, including artificial intelligence (AI). By 2030 it’s predicted that AI could contribute up to a whopping $15.7 trillion to the global economy, of which $6.6 trillion will likely come from increased productivity. It’s no wonder then, that companies and employees alike are warming up to the idea of AI and robots in the workplace.`}</p>
    <p>{`“As organizations eagerly embrace these new technologies, the relationship between human beings and machines in the workplace is undergoing major transformation.”`}</p>
    <h3>{`AI in the Workplace`}</h3>
    <p>{`Oracle and Future Workplace surveyed over 8,000 Human Resources leaders, managers, and employees across 10 countries to gauge their attitudes and behaviors regarding AI.`}</p>
    <p>{`Currently, 50% of survey respondents are already using some form of artificial intelligence at work. Just last year (2018), that number stood at 32%. More importantly, however, is the fact that “people’s perceptions of these technologies–and how they interact with them–are changing as swiftly as the rate of adoption.”`}</p>
    <p>{`Up until recently, talk about artificial intelligence and robots was typically met with fear, particularly how it would affect or potentially eliminate human jobs. This year, however, only 24% of respondents said they felt “unsure” about AI, a significant decrease from 38% last year. Moreover, such fears are not as prominent and the research found that “the majority of employees feel enthusiastic about these technologies,” with 38% of respondents using terms like amazed or excited when talking about AI.`}</p>
    <p>{`“People are no longer afraid of the robots. They have experienced how AI and machine learning can improve the way they work in a very pragmatic way. And the more they use these technologies, the more excited they are about them.”`}</p>
    <p>{`– Emily He, Senior Vice President, HCM Marketing, Oracle`}</p>
    <p>{`The more companies embrace and workers use these technologies, the more likely they are to see the benefits and advantages that they offer. “AI has become more prominent in the workplace, suggesting readiness to embrace the technology and an ability to see its potential.”`}</p>
    <h4>{`AI Is Changing the Relationship Between People and Technology at Work`}</h4>
    <p>{`According to survey findings, 43% of managers, HR leaders, and employees say they are now excited about how AI will affect the future of work. People are much more comfortable with AI in the workplace than they were a year ago, with workers believing that AI can present them with important opportunities.`}</p>
    <ul>
      <li parentName="ul">{`36% of respondents said that AI can create opportunities to master a new skill`}</li>
      <li parentName="ul">{`46% of respondents believe that AI can help them gain more free time`}</li>
      <li parentName="ul">{`28% of respondents believe AI can help expand their current role so it is more strategic`}</li>
      <li parentName="ul">{`20% of respondents believe AI can help them have better and healthier work relationships
Interestingly, demographics make a difference in perceptions and behaviors towards AI. The survey found that Millennials are most excited about AI (31%), followed by Gen Z (24%) and Gen X (22%), with Baby Boomers coming in last place (14%); interestingly, the survey found that men are more excited and optimistic about AI than women.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      