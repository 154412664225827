import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-11-29T00:00:00.000Z",
  "title": "Future Of Work: The Rise Of The Digital Workforce\n",
  "slug": "future-of-work-the-rise-of-the-digital-workforce",
  "excerpt": ["A digital worker refers to technology, such as artificial intelligence or robotics, that performs tasks previously accomplished by a human worker.", "These processes enable digital transformation within organizations, and free up employees to carry out work that requires human skills.", "In the future workplace, we will likely see increased automation and greater collaboration between human workers and digital ‘co-workers’."],
  "images": ["44-future-of-work-the-rise-of-the-digital-workforce.png"],
  "backlink": "https://allwork.space/2019/11/future-of-work-the-rise-of-the-digital-workforce/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A recent whitepaper, titled “`}<a parentName="p" {...{
        "href": "https://www.abbyy.com/en-eu/solutions/content-iq/content-intelligence-for-the-future-of-work/"
      }}>{`Content Intelligence for the Future of Work`}</a>{`” looks at the role that content intelligence (CI) is playing in driving and scaling digital transformation.`}</p>
    <p><strong parentName="p">{`Content Intelligence`}</strong>{`: also known as content IQ, it is “a set of technologies and services that leverage artificial intelligence to carry out tasks such as reading and categorizing a document, routing a document, extracting and validating data from documents, and other tasks related to understanding and processing unstructured content to enable quicker, more accurate decision making and deliver greater business value.”`}</p>
    <p>{`The whitepaper, created by IDC and sponsored by ABBYY, argues that there is a fundamental shift in the work model. This shift is fostering human-machine collaboration and it has the potential to create and enable new skills and worker experiences, as well as support an environment “unbounded by time or physical space.”`}</p>
    <p>{`While this shift is often referred to as the future of work, the whitepaper argues that this is already happening today, with the rise of digital workers.`}</p>
    <p><strong parentName="p">{`Digital Workers`}</strong>{`: “A digital worker refers to technology — including artificial intelligence, intelligent process automation (including content intelligence), robotics, and augmented reality and virtual reality — that performs tasks, jobs, and activities previously accomplished by a human worker.”`}</p>
    <p>{`While digital workers are just starting to be deployed by businesses around the world, ABBYY expects that the contribution of digital workers will increase by over 50% in the next two years. The great news is that these workers will not replace human capabilities, but rather enhance them.`}</p>
    <p>{`One of the main benefits and a main motivation for companies to implement content intelligence and digital workers is that they can drive productivity and free up time for humans to work on higher-value tasks.`}</p>
    <p>{`“In the future workplace, organizations will maximize value through automation and augmentation.” Content intelligence and digital workers will play a key role in enabling this to happen. As a result, employees will find themselves increasingly working side by side with a digital coworker.`}</p>
    <p>{`“The percentage of activities related to evaluating information performed by technology will grow by 28% in just two years, and 18% of activities related to reasoning and decision making will be performed by machines.”`}</p>
    <p>{`As more tasks and roles become automated or augmented within the next 12 to 18 months, IDC expects manual processes to significantly decline. While ample opportunities will come from automation, companies will need to focus on training and reskilling their existing workforce.`}</p>
    <p>{`“A new generation of required skill sets are in short supply. In a recent IDC survey, 75% of respondents said that their organization was finding it difficult to recruit digital skills.”`}</p>
    <p>{`In addition to inadequate worker skills, cost is cited as one of the top barriers for CI deployment, as well as siloed business units, disparate legacy systems, and lack of upper management support.`}</p>
    <h3>{`Deploying Content Intelligence Technologies`}</h3>
    <p>{`In addition to increased productivity and increased time to focus on high-value tasks, businesses are deploying or plan to deploy CI technologies to improve business operations, enable better decision making, improve the customer experience, reduce costs, and mitigate security risks.`}</p>
    <p>{`Moreover, digital workers powered by CI technologies can help companies overcome some of their top pain points, including: “manual sorting and classification of documents, manual data extraction from documents, inadequate compliance with security/privacy regulations, and poor data, errors, and inaccuracy of information.”`}</p>
    <p>{`The whitepaper found that of the businesses that have already deployed these technologies, 40% noted increased employee productivity or increased customer satisfaction. Furthermore, over one-third of businesses using content intelligence “saw an improvement in responsiveness to customers, new product or revenue opportunities, increased visibility and accountability, or increased customer engagement.”`}</p>
    <p>{`Moreover, previous research has shown that companies that deploy technologies related to digitizing, automating, and optimizing document workflows (like CI) reduced costs by 35%, reduced the amount of time spent on document-related tasks by 17%, and reduced errors by almost 25%.`}</p>
    <p>{`Content intelligence technologies deliver structured content to companies and their employees. By providing structured content that can be consumed and analyzed by other automation technologies, digital workers can provide more accurate customer information and more accurate data to support decision-making to businesses and managers.`}</p>
    <p>{`As a result of the above, employees have more free time to focus not only on more high-value tasks, but also on key corporate initiatives. The deployment of digital workers can improve employee engagement, work transformation, and overall digital transformation.`}</p>
    <p>{`Over the next year, the average expected increase in spending for content intelligence technologies will be 31%. That number should increase year on year.`}</p>
    <p>{`“Digital transformation and work transformation will be ongoing initiatives, and intelligent process automation will continue to evolve, becoming even more “intelligent,” driving growth of the digital workforce.”`}</p>
    <p>{`Organizations that are able to foster a culture where human and digital workers can collaborate will have a workforce that is able to work productively, and more importantly, securely.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      