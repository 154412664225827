import React from 'react';
import clsx from 'clsx';
import { DefaultComponentProps } from '../core';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { Section, Container } from '.';

type Contact = {
  company: string;
  name: string;
  contactNumber: string;
  address: {
    street: string;
    city: string;
    state: string;
    zipcode: string;
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      maxWidth: '100%',
      height: 'auto',
    },
    title: {
      marginLeft: theme.spacing(3),
    },
    copyright: {
      fontSize: '12px',
      marginTop: theme.spacing(5),
    },
  }),
);

export type FooterProps = { logo: string } & Pick<DefaultComponentProps, 'children' | 'path' | 'classname'>;

export const Footer = ({ ...props }: FooterProps) => {
  const data: any = useStaticQuery(graphql`
    {
      footer: allContactDataJson {
        edges {
          node {
            address {
              city
              state
              street
              zipcode
            }
            company
            contactNumber
            name
          }
        }
      }
    }
  `);

  const contactsData = data.footer.edges;
  const classes = useStyles();
  return (
    <Grid className={clsx(props.classname, 'dark-green')} container justify="center">
      <Container>
        <Section spacing={5}>
          <Grid container item xs={12} justify="center" spacing={3}>
            <Grid container item xs={12} md={6} spacing={4} alignItems="flex-start">
              <Grid container item xs={12}>
                <Grid item xs={2} md={1}>
                  <Link to="/">
                    <img className={classes.logo} src={props.logo} alt="LOGO" />
                  </Link>
                </Grid>
                <Grid item xs={10} md={11}>
                  <Section spacing={2}>
                    <Typography variant="h3" className={clsx(classes.title, 'uppercase white-text')}>
                      Alliance Capital
                    </Typography>
                  </Section>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption" className="white-text">
                    People, Place and Technology, Accelerated by Experience, Innovation and Capital.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} md={6} spacing={4} alignItems="flex-start">
              <Grid container item xs={12} alignItems="center">
                <Grid item xs={12}>
                  <Section spacing={2}>
                    <Typography variant="h3" className="white-text uppercase">
                      Contact
                    </Typography>
                  </Section>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption">
                    <a className="primary-main-text" href="mailto:info@futureofworkfunds.com">
                      info@futureofworkfunds.com
                    </a>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={4}>
                {contactsData.map((contact: { node: Contact }, i: number) => (
                  <Grid item xs={12} md={6} key={i}>
                    <Typography variant="h4" className="white-text">
                      {contact.node.name}
                    </Typography>
                    <Section spacing={1}>
                      <Typography variant="caption" className="semi-bold primary-main-text">
                        <div>{contact.node.company}</div>
                      </Typography>
                    </Section>
                    <Typography variant="caption" className="white-text">
                      <div>{contact.node.address.street}</div>
                      <div>
                        {`
                            ${contact.node.address.city},
                            ${contact.node.address.state}
                            ${contact.node.address.zipcode}
                          `}
                      </div>
                      <div>{contact.node.contactNumber}</div>
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs justify="center" alignContent="flex-end">
            <Typography className={clsx(classes.copyright, 'secondary-light-text light')}>
              © Copyright {new Date().getFullYear()} · Future of Work® Funds
            </Typography>
          </Grid>
        </Section>
      </Container>
    </Grid>
  );
};

export default Footer;
