import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2021-02-26T00:00:00.000Z",
  "title": "Future Of Work: Five Strategies To Drive Diversity Equity And Inclusion In The Workplace\n",
  "slug": "five-strategies-to-drive-diversity-equity-and-inclusion-in-the-workplace",
  "excerpt": ["Diversity, equity, and inclusion (DEI) efforts are failing, according to research from Josh Bersin.", "The coronavirus pandemic has also played a part, as the crisis has caused inequalities to grow.", "These 5 essential strategies, including commitment by senior leaders and creating accountability for results, can help companies achieve DEI excellence."],
  "images": ["five-strategies-to-drive-diversity-equity-and-inclusion-in-the-workplace.png"],
  "backlink": "https://allwork.space/2021/02/future-of-work-five-strategies-to-drive-diversity-equity-and-inclusion-in-the-workplace/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Research from Josh Bersin analysts found that diversity, equity, and inclusion efforts are failing and they are not delivering meaningful business and workforce outcomes.  `}</p>
    <p>{`This is concerning, especially considering the various benefits that DEI has for employers and employees alike: `}</p>
    <ul>
      <li parentName="ul">{`Diverse companies outperform their non-diverse counterparts.  `}</li>
      <li parentName="ul">{`Diverse companies report greater levels of innovation, customer service, employee engagement, employee retention, and long-term growth.  `}</li>
      <li parentName="ul">{`Diversity can improve financial performance by as much as 25%.  `}</li>
    </ul>
    <h3>{`Why Diversity Matters Now more than Ever`}</h3>
    <p>{`Demand for diversity, equity, and inclusion in the workplace has exploded due to social, political, and economic issues according to Josh Bersin’s `}<a parentName="p" {...{
        "href": "https://joshbersin.com/dei-research-report/"
      }}>{`Elevating Equity: The Real Story of Diversity and Inclusion report`}</a>{`.  `}</p>
    <p>{`Research has also found that diversity is backsliding in the U.S.  `}</p>
    <p>{`“In the United States, progress has reversed since Reagan’s presidency in the late 70s. The affirmative action goals that helped people of color and women get equal access to housing, voting rights, and business opportunities were abandoned, and without dedicated focus on increasing diversity, equity and inclusion, the problem has worsened.” `}</p>
    <p>{`The coronavirus pandemic has also played a part, as the crisis has caused inequalities to grow.  `}</p>
    <p>{`“People of color contracted the virus in higher numbers and experienced terrifying disease disparities, women exited the workforce disproportionally, and racial issues exploded in many cities.” `}</p>
    <p>{`Companies should take note.  `}</p>
    <p>{`“If people don’t feel included or equitably treated in the pandemic, company performance will suffer even more.”`}</p>
    <p>{`Diverse organizations have fared far better during the COVID-19 pandemic than non-diverse ones; these companies have managed to foster an engaged workforce even while working remotely. `}</p>
    <h3>{`Diversity without Inclusion Will Fail`}</h3>
    <p>{`According to Josh Bersin, creating an inclusive culture is the real answer.  `}</p>
    <p>{`“While diversity is the metric companies use to measure equality, the business issue is actually inclusion.”  `}</p>
    <p>{`If employees don’t feel like they belong, they won’t feel comfortable bringing their entire selves to work, which can hinder productivity, innovation, and collaboration at an organizational level. Eventually, it can lead to employees leaving the company.  `}</p>
    <p>{`The main problem with DEI efforts is that companies are looking at it “as a game of numbers”. `}</p>
    <p>{`Their DEI efforts are geared towards pushing the numbers higher: the amount of minority engineers in the company and promoting more women or minorities into leadership. The issue with these practices is that they are not adding enough value; they are simply a number that needs to be reached for compliance issues.  `}</p>
    <h3>{`The State of DEI in the Workplace`}</h3>
    <ul>
      <li parentName="ul">{`76% of companies have no diversity or inclusion goals at all.    `}</li>
      <li parentName="ul">{`Only 12% of companies hold managers responsible for recruiting diverse candidates, and only 11% of recruiters are evaluated based on sourcing from underrepresented groups. `}</li>
      <li parentName="ul">{`75% of companies do not have DEI included in the company’s leadership development or overall L&D curricula. Rather, DEI is treated as a compliance issue. `}</li>
      <li parentName="ul">{`Only 32% of companies mandate any form of DEI training for employees and only 34% offer such training to managers.  `}</li>
      <li parentName="ul">{`Only 22% of companies believe they are recognized by their employees, customers, or suppliers as leaders in DEI.  `}</li>
    </ul>
    <h3>{`DEI: from HR Programs to Business Strategy`}</h3>
    <p>{`For DEI efforts to work, they need to be deeply rooted in business strategy.`}</p>
    <p>{`Josh Bersin identified the 5 essential strategies that can help companies achieve DEI excellence: `}</p>
    <ol>
      <li parentName="ol">{`Listen, hear, and act `}</li>
      <li parentName="ol">{`Strengthen HR capabilities in all roles `}</li>
      <li parentName="ol">{`Engage senior leadership commitment `}</li>
      <li parentName="ol">{`Set goals and measure `}</li>
      <li parentName="ol">{`Create accountability for results. `}</li>
    </ol>
    <h3>{`Listen, hear, and act`}</h3>
    <p>{`To create an inclusive environment, companies need to first know what they need to do to help employees feel safe so that they feel comfortable to share their lived experiences, speak their truths, and contribute meaningfully to projects/meetings.  `}</p>
    <p>{`To find out what needs to be done, employers need to listen to employees. For this to happen, they need to create an environment where employees can speak up. This is not an easy thing to do, especially on an ongoing basis.  `}</p>
    <p>{`Companies can start listening by: `}</p>
    <ul>
      <li parentName="ul">{`Conducting employee listening forums `}</li>
      <li parentName="ul">{`Create polls, surveys, discussion boards, and focus groups.  `}</li>
      <li parentName="ul">{`Lean on technology to conduct sentiment analysis.  `}</li>
    </ul>
    <h3>{`Strengthen HR Capabilities`}</h3>
    <p>{`“HR has a key role to play. HR should not be viewed as the enforcer of rigid rules or mandated quotas, but as a consultant to the business, helping leaders address key DEI issues.” `}</p>
    <p>{`To strengthen HR capabilities, companies can start by reviewing HR programs and policies through a DEI lens. Reviewing existing programs will help identify gaps and areas of opportunity in areas such as recruiting, succession, and performance management. Another strategy is to consider external resources, like coaching, mentoring, and learning networks.  `}</p>
    <h3>{`Engage Senior Leadership`}</h3>
    <p>{`“Senior leadership commitment is a prerequisite of DEI success, and it is a factor that has bigger impact on outcomes than practices around training or recruitment.” `}</p>
    <p>{`Company leaders need to be personally involved and accountable; they need to talk about inclusion, believe in it, and hold themselves accountable. If company leaders don’t spearhead efforts to create an inclusive environment, any program will be seen as discretionary instead of central to the company.   `}</p>
    <p>{`“Leadership engagement is critical, as with any business function. Without it, any progress with be fleeting and short-lived.” `}</p>
    <h3>{`Set Goals and Measure`}</h3>
    <p>{`The report found that 76% of companies have no diversity or inclusion goals at all. Without goals and measurement, it’s impossible to hold a company and its leaders accountable.  `}</p>
    <p>{`Setting goals can also help in creating plans and prioritizing areas of opportunity. Organizations can benefit from looking outside company boundaries and using others within their industry to set benchmarks.  `}</p>
    <p>{`Once benchmarks are identified, companies need to invest in them, set goals, and—most importantly—communicate DEI metrics internally.  `}</p>
    <h3>{`Create Accountability for Results`}</h3>
    <p>{`The report found that roughly 80% of companies are just going through the motions and not holding themselves accountable. This is a problem, as accountability is key to moving a company into the highest level of DEI maturity.  `}</p>
    <p>{`“Accountability needs to happen at all levels – within and outside the organization. No one person or group can create significant DEI results. Just like with any other business imperative, progress and outcomes need to be shared to make a meaningful impact.” `}</p>
    <p>{`Without accountability, progress will be short-lived.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      