import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2018-08-07T00:00:00.000Z",
  "title": "Understanding The Future Of Work And Its Implications On The Workplace\n",
  "slug": "understanding-the-future-of-work-and-its-implications-on-the-workplace",
  "excerpt": ["The future of work is “the topic of the day” according to various workplace experts.", "It’s not just about technology, AI and automation – it’s also about the contingent workforce, the gig economy, and globalisation.", "If how we work is changing, it’s also necessary that we change where we are working."],
  "images": ["9-UNDERSTANDING-THE-FUTURE-OF-WORK.png"],
  "backlink": "https://allwork.space/2018/08/understanding-the-future-of-work-and-its-implications-on-the-workplace/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`According to McKinsey & Company, the future of work was one of the hottest topics of 2017. `}<a parentName="p" {...{
        "href": "https://www.mckinsey.com/featured-insights/future-of-organizations-and-work/what-is-the-future-of-work"
      }}>{`In a podcast hosted by Peter Gumbel, McKinsey Global Institute’s Editorial Director`}</a>{`, James Manyika, McKinsey Global Institute’s Chairman and Director, stated that the future of work “comes up in almost every conversation with students, workers, CEOs, and policymakers. It’s the topic of the day.”`}</p>
    <p>{`But what exactly is the future of work?`}</p>
    <p>{`In its “`}<a parentName="p" {...{
        "href": "https://www.pwc.com/gx/en/services/people-organisation/workforce-of-the-future/workforce-of-the-future-the-competing-forces-shaping-2030-pwc.pdf"
      }}>{`Workforce of the future: the competing forces shaping 2030`}</a>{`” report, PwC argues that:`}</p>
    <p>{`“The future of work asks us to consider the biggest questions of our age. What influence will the continuing march of technology, automation and artificial intelligence (AI) have on where we work and how we work? Will we need to work at all? What is our place in an automated world?”`}</p>
    <p>{`In other words, the future of work is a combination of factors that are changing the current work models and work structures. And although the vast majority of people associate the future of work with robots, machines, and AI, the future of work is about more than that. It’s also about the contingent workforce, the gig economy, globalisation, and much more.`}</p>
    <p>{`Deloitte believes there are three main forces driving the future of work: technology, demographics, and the power of pull. Below is quick glimpse into each of these as detailed in the `}{`[D]`}{`eloitte Review, Issue 21, July 2017](`}<a parentName="p" {...{
        "href": "https://www2.deloitte.com/insights/us/en/deloitte-review/issue-21.html?icid=dcom_promo_featured%7Cglobal;en"
      }}>{`https://www2.deloitte.com/insights/us/en/deloitte-review/issue-21.html?icid=dcom_promo_featured%7Cglobal;en`}</a>{`).`}</p>
    <p><strong parentName="p">{`Technology: Artificial intelligence, robotics, sensors, and data`}</strong>{`. Technological advances—for example, in the areas of robotics, artificial intelligence (AI), sensors, and data—have created entirely new ways of getting work done that are, in some cases, upending the way we use and think about our tools and how people and machines can complement and substitute for one another.`}</p>
    <p><strong parentName="p">{`Demographics: Longer lives, growth of younger and older populations, and greater diversity`}</strong>{`. In most places, people are living longer than ever, and overall, the population is becoming both older and younger, with individual nations becoming more diverse. The prospect of older generations working for longer periods as their physical capability to remain employed improves, could affect the pace at which younger talent and ideas renew organizations.`}</p>
    <p><strong parentName="p">{`The power of pull: Customer empowerment and the rise of global talent markets`}</strong>{`. Individuals and institutions can exert greater “pull”—the ability to find and access people and resources when and as needed. Institutions and prospective workers alike now have access to global talent markets, enabled by networks and platforms opening up new possibilities for the way each interacts with the other.”`}</p>
    <h3>{`What this means for work and the workplace`}</h3>
    <p>{`Work-wise, `}<a parentName="p" {...{
        "href": "https://www.weforum.org/agenda/2017/12/predictions-for-freelance-work-education/"
      }}>{`Stephane Kasriel, CEO of Upwork, believes that the number of freelancers will continue to grow`}</a>{`. In the US alone, Kasriel predicts that “the majority of the US workforce will freelance by 2027.” This, however, doesn’t necessarily mean that people will be solo workers.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.forbes.com/sites/zackfriedman/2018/08/06/ibms-hr-chief-shares-best-advice-on-the-future-of-work/#2b7bc4b33dc4"
      }}>{`Diane Gherson, Chief Human Resource Office at IBM`}</a>{`, believes that “work will be highly collaborative, iterative and performed by teams that can assemble and disassemble quickly, responding in real time to external and internal signals.”`}</p>
    <p>{`Gherson’s prediction is backed by John Hagel, co-chairman for Deloitte Center for the Edge, and Tom Friedman, a well-known Pulitzer Prize-winning weekly columnist for the New York Times. In a Deloitte Review article, Hagel poses the following question to Friedman:`}</p>
    <p>{`“To the extent that it evolves toward more creative problem-solving tasks, do you see it (gig economy/freelancing) moving beyond just individuals doing isolated tasks on a contract basis, which is what most people think of when they think of the gig economy? As the focus increases on creativity and lifelong learning, do you see a tendency, perhaps, for these people to come together into more sustained work groups that will work together on these challenging tasks? Or will they just stay isolated individuals?”`}</p>
    <p>{`To this, Friedman answers that people coming together into work groups “makes total sense.”`}</p>
    <p>{`If how we work is changing, it’s also necessary that we change where we are working. According to Deloitte, “organizations will not only need to redesign work—they will likely need to redesign work environments to support this new kind of work.`}</p>
    <p>{`There’s been a lot of effort to reshape environments to make them more enjoyable and flexible to accommodate changing worker preferences and needs, but what if we took the need to accelerate learning and performance improvement as our primary design goal? What would work environments look like then?”`}</p>
    <p>{`Gherson for her part believes that “work will need to be co-located in agile workplaces.” Moreover, the future workplace should also serve the purpose of a connecting platform, where freelancers can easily connect with one another and find potential business partners or work groups.`}</p>
    <p>{`We cannot leave behind Deloitte’s future of work driving forces. The future workplace will need to cater to an aging force, while at the same time cater to an entrant workforce. It will also need to be a well-balanced environment where technology — robots, AI, machines — and humans can interact and complement one another.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      