import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-07-10T00:00:00.000Z",
  "title": "Future Of Work: What Ufos Can Teach Us About Remote Work\n",
  "slug": "future-of-work-what-ufos-can-teach-us-about-remote-work",
  "excerpt": ["Post COVID-19, science fiction is now evolving into science fact.", "Google has boldly gone into the VR space.", "Advanced robotics could help our post-COVID existence."],
  "images": ["future-of-work-what-ufos-can-teach-us-about-remote-work.png"],
  "backlink": "https://allwork.space/2020/07/future-of-work-what-ufos-can-teach-us-about-remote-work/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`We’ve all felt the effects of lockdown skew our grip on reality. But I didn’t expect to see everyone from Former Senate Majority Leader Harry Reid to Economist Tyler Cowen saying `}<a parentName="p" {...{
        "href": "https://www.morningbrew.com/daily/stories/2020/07/01/prominent-americans-say-take-ufos-seriously"
      }}>{`we should take UFOs more seriously`}</a>{` the other week. `}</p>
    <p>{`Is the truth really out there? `}</p>
    <p>{`While the likelihood of anything coming from Mars is a million to one*, I’d argue remote working was regarded as an alien culture until very recently. And, in light of the COVID-19 crisis, we’ve seen a host of remote working trends accelerate to unprecedented warp speeds – with the number of coworking spaces `}<a parentName="p" {...{
        "href": "https://allwork.space/2020/07/number-of-coworking-spaces-worldwide-to-double-within-the-next-4-years/"
      }}>{`predicted to double`}</a>{` within the next four years. `}</p>
    <p>{`So, let’s suspend reality a little further and turn the world of science fiction on its head (or heads, if you’re from a planet in the vicinity of Betelgeuse). What could UFOs and alien cultures teach us about remote work to help the human race survive and thrive in our post pandemic work?`}</p>
    <h3>{`Greetings, programs!`}</h3>
    <p>{`Why travel millions of parsecs when you can meet people in the virtual realm? We’ve all become more adept at Zoom calls recently, but it looks like the big tech players are starting to step up to the virtual reality plate.`}</p>
    <p>{`Google’s recent acquisition of human interface and smart glasses pioneers `}<a parentName="p" {...{
        "href": "https://www.bynorth.com/"
      }}>{`North`}</a>{` potentially heralds a new digital dawn for remote working. Writing on the company blog, North states it was founded in 2012 “with an optimistic vision for the future in which technology becomes an invisible, helpful part of our everyday experience. Technology seamlessly blended into your world: immediately accessible when you want it, but hidden away when you don’t.”`}</p>
    <p>{`“Early on, our focus was on new forms of interaction with `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=A8lGstYAY14"
      }}>{`Myo`}</a>{`, a gesture based input device that directly coupled neuro-muscular impulses into signals computers could understand. We then shifted focus to Focals, our everyday smart glasses with direct retinal projection and prescription compatibility.” Now, the company is continuing to develop its virtual technologies under Google’s X-Wing.`}</p>
    <p>{`Over at Facebook HQ, the company is `}<a parentName="p" {...{
        "href": "https://www.theverge.com/2020/5/21/21266945/facebook-ar-vr-remote-work-oculus-passthrough-future-tech"
      }}>{`reportedly`}</a>{` investing in “supercharging remote work and productivity” using VR and AR technologies, according to Andrew “Boz” Bosworth, Facebook’s head of AR and VR.`}</p>
    <p><img parentName="p" {...{
        "src": "https://allwork.space/wp-content/uploads/2020/07/Screen-Shot-2020-07-10-at-12.14.55.png",
        "alt": null
      }}></img></p>
    <p>{`Bosworth shared a short `}<a parentName="p" {...{
        "href": "https://twitter.com/boztank/status/1263573928816988160"
      }}>{`video`}</a>{`, featuring real footage of an experimental test using prototype headsets where the user plays with floating and resizable screens. It’s enough to get Tom Cruise filing a Minority Report.**`}</p>
    <p>{`I do not fear computers. I fear the lack of them.`}</p>
    <p>{`Digitisation is an industry-wide trend and the flexible workspace sector is no exception. Operators that don’t capitalize on their data `}<a parentName="p" {...{
        "href": "https://allwork.space/2020/02/the-digital-workspace-is-here-what-are-you-doing-to-help-your-members/"
      }}>{`will, ultimately, fail`}</a>{`. But big data is such a 2020 concept. I’m sure our extraterrestrial neighbours eat data for breakfast. `}</p>
    <p>{`So, what’s the next step? `}</p>
    <p>{`If the movie Surrogates is anything to go by, we could soon control robotic avatars to enter the workplace for us. Seriously. Humanoid robots are starting to move out of the labs and `}<a parentName="p" {...{
        "href": "https://www.cnbc.com/2018/04/18/your-next-coworker-soon-may-be-an-avatar-humanoid-robot.html"
      }}>{`into our lives`}</a>{` in their droves – with the market entering `}<a parentName="p" {...{
        "href": "https://coleofduty.com/news/2020/07/09/covid-19-impact-on-humanoid-robot-market-research-report-industry-analysis-size-share-growth-trends-and-forecast-2026/"
      }}>{`a renewed period`}</a>{` of growth thanks to COVID-19.`}</p>
    <p>{`Alternatively, the OS from Her may be a good compromise, where you control your workstation using your voice. It’s like a Super-Siri system, where you don’t need a keyboard or a mouse to control your computer. That would certainly solve a lot of hygiene problems for the world’s workspaces.`}</p>
    <p>{`Personally, I’d favour something between the two. A paranoid android who can navigate those dull meetings on my behalf and organise my day faster than an FTL Drive. Whatever our next-generation machines look like and do, the rise of the robots is coming – and they’re `}<a parentName="p" {...{
        "href": "https://allwork.space/2020/05/post-covid-19-could-robots-keep-your-workspace-clean/"
      }}>{`coming with a mop and bucket`}</a>{` in their cyberdyne-designed hands.`}</p>
    <h3>{`They mostly come out at night. Mostly.`}</h3>
    <p>{`Time is an illusion. Lunchtime doubly so. With the notion of a regular 9 to 5 job now defunct, we need to open up spaces to members as and when they want to work.  This can have positive repercussions from both a financial and social distancing standpoint – where you can flatten out periods of peak demand, allowing you to keep a steady stream of members in your space.`}</p>
    <p>{`The technology exists to achieve this too. Everything from `}<a parentName="p" {...{
        "href": "https://allwork.space/2020/06/forget-paperless-are-you-ready-for-the-touchless-office/"
      }}>{`touchless door entry systems`}</a>{` to `}<a parentName="p" {...{
        "href": "https://allwork.space/2020/06/post-covid-19-the-value-of-5g-for-your-building/"
      }}>{`5G-enabled solutions`}</a>{` already exist are transforming our smartphones into tricorders, capable of giving us access to our workspaces, stay connected, and detect hidden force fields. Maybe.`}</p>
    <p>{`Whatever the future holds, the coming years will bring remarkable change for the world’s workspaces, merging the world of science fiction and science fact. To resist such change would be… highly illogical.`}</p>
    <ul>
      <li parentName="ul">{`According to H.G. Wells in The War of the Worlds. I’ll buy anyone a Spice Beer who can identify every sci-fi reference in this article too.`}</li>
    </ul>
    <p>{`** Apologies, that one really was shoehorned in. Also, I’m in no way suggesting Tom Cruise is an alien.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      