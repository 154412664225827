import React from 'react';
import { Grid } from '@material-ui/core';
import { DefaultComponentProps } from '../core';
import { Nav } from '.';
import { Link } from 'gatsby';
import classnames from 'classnames';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: '80vh',
    },
    staticLayer: {
      position: 'absolute',
      zIndex: 1,
      padding: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
      },
    },
    logo: {
      marginLeft: '12px',
      maxWidth: '100%',
      height: 'auto',
    },
  }),
);

export type HeaderProps = { logo: string } & Pick<DefaultComponentProps, 'children' | 'path' | 'classname'>;

export const Header = ({ ...props }: HeaderProps) => {
  const classes = useStyles();
  return (
    <>
      <Grid container className={classnames(props.classname, classes.staticLayer)}>
        <Grid container item xs={6}>
          <Link to="/">
            <img className={classes.logo} src={props.logo} alt="LOGO" />
          </Link>
        </Grid>
        <Grid container item xs={6} justify="flex-end">
          <Nav />
        </Grid>
      </Grid>
    </>
  );
};

export default Header;
