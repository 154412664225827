import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-09-06T00:00:00.000Z",
  "title": "Future Of Work: Why Brand Storytelling Is Key\n",
  "slug": "future-of-work-why-brand-storytelling-is-key",
  "excerpt": ["Brand storytelling enables companies to share their purpose with their investors, consumers, staff, and future recruits.", "Not only can storytelling help share your business purpose and improve sales, it can also be used to drive engagement and help people learn new skills for the future of work.", "Allwork.Space spoke with Cat Johnson, expert storyteller and content strategist, to learn about storytelling and how flexible workspace operators can leverage it."],
  "images": ["30-WHY-BRAND-STORYTELLING-IS-KEY.jpg"],
  "backlink": "https://allwork.space/2019/09/future-of-work-why-brand-storytelling-is-key/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Earlier this year, we learned that `}<a parentName="p" {...{
        "href": "https://allwork.space/2019/06/the-future-of-work-should-you-focus-on-finding-your-business-purpose/"
      }}>{`purpose is key to business success`}</a>{`; some argue it’s because it can help companies navigate disruption, while others argue purpose can help organizations attract and retain talent, especially considering that the younger workforce generations are interested in finding meaning and purpose at work, rather than just a big paycheck.`}</p>
    <p>{`But the quest for business purpose goes deeper than this. Modern day consumers care about what they purchase and how that purchase impacts society and the environment at large.`}</p>
    <p>{`However, it’s not enough to have a purpose. Rather, organizations need to share that purpose with all stakeholders: investors, board directors, staff, and consumers. One effective way to do this is through brand storytelling, so that everyone involved with your company is eager to retell your story.`}</p>
    <p>{`Though storytelling has become a buzzword recently, it’s always been an important part of business and it will remain a key part of business in the future. Not only can storytelling help share your business purpose and improve sales, it can also be used as a way to drive engagement and teach talent how to overcome challenges and learn new skills for the future of work.`}</p>
    <p>{`Stories describe transformations. Considering how the world of work is rapidly evolving and changing, having the ability to tell your organization’s story and how a company is coping with this change and transforming itself can help organizations remain relevant and successful.`}</p>
    <p>{`Allwork.Space spoke with Cat Johnson, expert storyteller and content strategist, ahead of her upcoming training session “`}<a parentName="p" {...{
        "href": "https://www.crowdcast.io/e/brand-storytelling"
      }}>{`Intro to Brand Storytelling for Coworking Spaces`}</a>{`” to learn about why storytelling is important, how flexible workspace operators can leverage it, and why storytelling is important for the future of work.`}</p>
    <h2>{`Allwork.Space: Hi Cat, let’s start with the basics. What exactly is brand storytelling and why is it such a relevant and hot topic nowadays?`}</h2>
    <p>{`Cat Johnson: Brand storytelling is a way of surfacing and sharing the truth of your company, your values and your community. Storytelling is a great equalizer and a powerful tool for small and purpose-driven companies. Big businesses can always outspend you on ads, but if you can tell a real story that resonates, people will find you.`}</p>
    <h2>{`Allwork.Space: So, why do you think storytelling is so powerful and effective? Why does it resonate with people?`}</h2>
    <p>{`Stories help us understand our world, ourselves and each other. Storytelling is as old as humanity. There’s also brain chemistry at work with stories: When we engage in a story, our brain synthesizes oxytocin. Oxytocin is a hormone that plays a role in social bonding. As we swap stories with each other, we’re actually connecting physiologically.`}</p>
    <h2>{`Allwork.Space: I didn’t know that our brain synthesized oxytocin when we engaged with a story, that’s both beautiful and amazing. Speaking about engagement, you’re both an expert storyteller and content marketer, so tell us a bit about the differences and similarities between the two. After all, in both instances, organizations want people to engage with their content and stories.`}</h2>
    <p>{`The way I see it, storytelling is part of any good content marketing strategy. I think of content marketing as having two distinct jobs: one is SEO — getting found for keyword phrases you want to be found for. But that content can be a bit dry and utilitarian. The other side of content marketing is storytelling. Storytelling is the content tool we use to connect with each other, express our values, share our purpose and our vision, and let people in on our journey. A good content strategy is all about striking a balance between those two.`}</p>
    <h2>{`Allwork.Space: Do you have any favorite examples of companies that strike that balance just right?`}</h2>
    <p>{`Examples of great storytelling are all around us. Any time we feel moved by a story — whether a blog post, commercial, social media post, video, conversation, whatever, that’s great storytelling. I have some great examples of coworking-specific storytelling that I’ll share in my `}<a parentName="p" {...{
        "href": "https://www.crowdcast.io/e/brand-storytelling"
      }}>{`upcoming brand storytelling for coworking spaces training`}</a>{`.`}</p>
    <h2>{`Allwork.Space: You’ll probably also address this in your training session, but what is a key element that brands need to have clearly defined before they can start storytelling?`}</h2>
    <p><a parentName="p" {...{
        "href": "https://allwork.space/2018/11/how-to-build-a-customer-persona-for-your-coworking-space/"
      }}>{`Know your audience`}</a>{`. You can’t connect with people if you don’t know who they are. You also have to be clear about your values and purpose, and be willing to be real. People can tell when you’re being real and when you’re being false.`}</p>
    <h2>{`Allwork.Space: Definitely! One last question, why do you believe brand storytelling will be relevant in the future of work?`}</h2>
    <p>{`With traditional advertising, big brands broadcasted messages to the masses like a cropduster. Storytelling gives people and companies a way to share messages in an authentic, relatable, nuanced way. My hope is that the future of work is more purposeful, more values-driven and more focused on serving people. In that world, storytelling becomes a primary vehicle to connect and co-create.`}</p>
    <p>{`Don’t miss Cat’s upcoming training session “Intro to Brand Storytelling for Coworking Spaces”. You can register here!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      