import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-06-12T00:00:00.000Z",
  "title": "Future Of Work: Most Workers Want To Come Back To The Office\n",
  "slug": "future-of-work-most-workers-want-to-come-back-to-the-office",
  "excerpt": ["A recent survey by Genlser found that the majority of U.S. workers want to go back to the office.", "People want to go to the office to collaborate more effectively and to experience social connections.", "For people to continue opting for the office vs working from home, the workplace needs to adapt to new paradigms."],
  "images": ["future-of-work-most-workers-want-to-come-back-to-the-office.png"],
  "backlink": "https://allwork.space/2020/06/future-of-work-most-workers-want-to-come-back-to-the-office/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Gensler recently published the results of its `}<a parentName="p" {...{
        "href": "https://www.gensler.com/research-insight/workplace-surveys/us-work-from-home-survey/2020"
      }}>{`2020 U.S. Work From Home Survey`}</a>{`. The report, according to Gensler, can guide strategies on a successful return to the office. `}</p>
    <p>{`The main takeaway from the survey is that the majority of workers want to go back to the office, with only 12% of U.S. workers saying they want to work from home full-time. Those who want to go back to the office, however, want to see some critical changes take place. `}</p>
    <p>{`Gensler found that workers “want more space (less density) for social distancing and assigned workspace.” These measures can not only help ease anxieties about returning to the office, but they can also address other workplace issues like noise and distraction, which lead to hindered performance and decreased effectiveness. `}</p>
    <p>{`Other than less density, workers reported they want flexible work arrangements, arguing that their spaces could be used for other functions when not in use as a workspace. Granted, for this to work, cleaning protocols will be a crucial part of returning to the office. `}</p>
    <p>{`Working from home has given businesses continuity in times of crisis. But as organizations move forward, there are some lessons about working from home that can help leaders rethink the future of the physical workplace. `}</p>
    <p>{`Gensler found that “only one in ten U.S. office workers had worked from home regularly before this experience, and less than a third had the choice to work from home.”`}</p>
    <p>{`Though presented with the opportunity to continue working from home, most workers do not want that to be their permanent arrangement. `}</p>
    <p>{`“The preference for working in the office is consistent with Gensler’s workplace research data collected regularly since 2005. Workers with choice in where to work prior to COVID-19 spent 72% of their average work week in the office compared to only 12% working from home, overwhelmingly choosing the office as their preferred place to work.”`}</p>
    <h3>{`Why People Miss the Office`}</h3>
    <p>{`“Meeting and connecting with colleagues face-to-face and access to the tools and spaces that support their work.” `}</p>
    <p>{`Though the office is still the preferred place to work, `}<a parentName="p" {...{
        "href": "https://allwork.space/2020/06/what-is-the-office-for-a-look-at-the-changing-role-of-the-workplace/"
      }}>{`its role is changing`}</a>{`. `}</p>
    <p>{`People want to go to the office to collaborate more effectively; they expect social connections. Despite the fact that virtual technologies allow teams to collaborate and meet virtually, Gensler found that “people still clearly value face-to-face interactions over virtual ones.” `}</p>
    <p>{`What this shows us, and organizations need to take note, is that work and the workplace are all about the people.`}</p>
    <p>{`Work is human. And a big part of being human is being physically present to connect and work with others.`}</p>
    <p><img parentName="p" {...{
        "src": "https://allwork.space/wp-content/uploads/2020/06/gensler1.png",
        "alt": null
      }}></img>{`
Human interaction is the main reason people want to get back to the office.`}</p>
    <p>{`Not only can being physically present help people collaborate more effectively, but it also makes it easier for workers to find synergy with one another. `}</p>
    <p>{`Many workers said they find it harder to stay up to date on what others are working on when they’re at home vs when they are in the office. `}</p>
    <h3>{`Work from Home = Less Satisfaction for Younger Workers`}</h3>
    <p>{`Gensler found that “younger generations are less productive at home and less satisfied with the work from home experience.”`}</p>
    <p>{`This is interesting because Millennial and Gen Z workers should be more accustomed to working and socializing in a virtual setting. They also tend to have a leg-up in navigating new technologies and platforms that enable remote work. `}</p>
    <p>{`According to Gensler, the dissatisfaction could stem from the fact that they likely have less optimal work from home environments, meaning they have a harder time maintaining work-life balance. `}</p>
    <p>{`Moreover, Gensler found younger workers are less aware of “how their work contributes to organizational goals” when they are working from home. For these generations, feeling a part of the company of feeling like their work matters is key to job satisfaction. `}</p>
    <h3>{`What Should the Office Look Like?`}</h3>
    <p>{`If people expect to return to a different workplace, what will this workplace look like? What should it offer? `}</p>
    <ul>
      <li parentName="ul">{`More space`}</li>
      <li parentName="ul">{`Less desk sharing`}</li>
      <li parentName="ul">{`Increased support for mobile and virtual work.`}</li>
    </ul>
    <p>{`For people to continue to prefer the office, the workplace needs to adapt to new paradigms. `}</p>
    <p>{`Gensler found that “the most important workplace changes appear to be policy–based and include stricter policies about staying home when sick and increasing opportunities to work from home. Cleaning and other efforts to establish social distancing rank next.”`}</p>
    <p><img parentName="p" {...{
        "src": "https://allwork.space/wp-content/uploads/2020/06/gensler2.png",
        "alt": null
      }}></img>{`
Having stricter policies against coming to work sick will give people more confidence to return to the office.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      