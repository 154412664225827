import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-01-01T00:00:00.000Z",
  "title": "4 Proptech Trends Set To Impact Flexible Workspace In 2019\n",
  "slug": "4-proptech-trends-set-to-impact-flexible-workspace-in-2019",
  "excerpt": ["Drones and 3D printing disrupt the status quo for commercial real estate.", "Smart technologies start to streamline, enabling their widespread adoption.", "More blockchain-based tools enter the flexible workspace arena."],
  "images": ["5-PROPTECH-TRENDS.png"],
  "backlink": "https://allwork.space/2019/01/4-proptech-trends-set-to-impact-flexible-workspace-in-2019/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`In the coming year, advancing digitisation will put technology at the heart of the flexible workspace industry. It’s the key to building a destination workplace and there are several important trends you must be aware of.`}</p>
    <p>{`“The ability of our industry to aggregate technology as a value-added benefit of our service model will be critical to sustaining growth and increasing our members’ lifecycle,” according to Frank Cottle, chairman and CEO of `}<a parentName="p" {...{
        "href": "https://www.abcn.com/"
      }}>{`Alliance Business Centers`}</a>{`.`}</p>
    <p>{`So, what Proptech trends are set to impact the flexible workspace market? Here are our top predictions for the year ahead:`}</p>
    <h3>{`Smart tech starts to step up`}</h3>
    <p>{`From your fridge to your car, `}<a parentName="p" {...{
        "href": "https://allwork.space/2018/11/3-technologies-that-will-greatly-impact-the-workplace-by-2020/"
      }}>{`smart sensors`}</a>{` are now integrated with a vast range of commercial products. But smart technologies will `}<a parentName="p" {...{
        "href": "https://www.bisnow.com/london/news/office/property-owners-and-developers-can-chill-about-having-the-most-advanced-smart-buildings-94659"
      }}>{`start to grow in popularity`}</a>{` as they shape the way our buildings are constructed and managed in 2019.`}</p>
    <p>{`According to `}<a parentName="p" {...{
        "href": "https://www.linklaters.com/en/insights/publications/real-estate-horizon-scanning/uk-real-estate-horizon-scanning-2019/prop-tech"
      }}>{`law firm Linklaters`}</a>{`: “Smart building technology will generate cost savings and increase environmental efficiencies as well as allow landlords to offer tenants advanced features and services (at higher rents).”`}</p>
    <p>{`But there is still a lack of cohesion in the smart tech market, which needs to be addressed. Speaking in a statement, Kurt Ramirez, general partner at Proptech-focused Nine Four Ventures, said: “Right now we’re very gadget-heavy… you need to plug `}{`[your hardware]`}{` in and have it sit on your table. Most devices must be retrofitted and installed, which is challenging.”`}</p>
    <p>{`“In the future, I expect that we won’t see or need to interact with the sensors and technologies that will respond to us,” he added.`}</p>
    <p>{`For now, operators need to focus on the basics of smart tech to attract tenants. “If landlords spend too much time focusing on every sensor and every piece of infrastructure, there is `}{`[a]`}{` risk that it will be the wrong hardware, and in two to three years’ time someone else will come in with something new and take the opportunity away,” according to EY EMEIA real estate leader Andrew O’Donnell, `}<a parentName="p" {...{
        "href": "https://www.bisnow.com/national/news/commercial-real-estate/told-ya-so-looks-like-bisnow-nailed-its-real-estate-predictions-for-2018-95532"
      }}>{`speaking in a statement`}</a>{`.`}</p>
    <h3>{`3D printing moves into construction`}</h3>
    <p>{`Fancy 3D printing your next workspace? As fanciful as this may sound, the South by Southwest Conference & Festivals featured a `}<a parentName="p" {...{
        "href": "https://www.datatree.com/insights-blog/proptech-trends-in-real-estate-technology"
      }}>{`3D-printed house`}</a>{` built by construction tech startup ICON and the non-profit New Story.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.iconbuild.com/"
      }}>{`ICON’s printer`}</a>{` can produce a 650 square foot home in up to 24 hours, which creates concrete walls layer by layer. The company just `}<a parentName="p" {...{
        "href": "https://www.3dnatives.com/en/icon-raises-9-million-to-create-affordable-3d-printed-houses-061120184/"
      }}>{`raised $9 million`}</a>{` in investment and `}<a parentName="p" {...{
        "href": "https://www.datatree.com/insights-blog/proptech-trends-in-real-estate-technology"
      }}>{`reportedly wants to reduce the build time to a mere six hours`}</a>{`. While ICON is building on a residential scale, `}<a parentName="p" {...{
        "href": "https://cybe.eu/"
      }}>{`CyBe Construction`}</a>{` is also pushing the boundaries of 3D concrete printing for large-scale constructions projects.`}</p>
    <p>{`At the other end of the scale, 3D printing is a useful tool for startups when it comes to prototyping and low-volume manufacturing of products. The cost of 3D printers has dropped considerably over the past three years, with prices `}<a parentName="p" {...{
        "href": "https://3dinsider.com/cost-of-3d-printer/"
      }}>{`starting at $200`}</a>{` and the average 3D printer costing $700. So, if you want to attract an entrepreneurial demographic, you may want to invest in a 3D printer.`}</p>
    <h3>{`Drones and VR upscale virtual tours`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.cushmanwakefield.com/en/research-and-insight/2018/proptech-trends"
      }}>{`Cushman & Wakefield`}</a>{` predicted drone technology would be prevalent in 2018 as these hovering robots can safely survey and scan construction sites, mitigating the risks and reducing the cost of deploying a human working at height.`}</p>
    <p>{`In the flexible workspace arena, drone technology could easily be used to give potential customers a view of your space and let them explore the surrounding areas.`}</p>
    <p>{`This technology could be extended into a workspace. Combined with a virtual reality headset, you could create an immersive virtual tour and let customers explore your space – without ever stepping a foot inside your building. `}<a parentName="p" {...{
        "href": "https://giraffe360.com/"
      }}>{`GIRAFFE 360°`}</a>{` is one company working in this arena, where you can capture a space and create virtual tours, floor plans and wide-angle photography.`}</p>
    <h3>{`Blockchain and cryptocurrencies`}</h3>
    <p>{`Blockchain and cryptocurrencies have been bubbling away over the last 12 months and the Proptech community is `}<a parentName="p" {...{
        "href": "https://allwork.space/2018/02/weird-tech-how-blockchain-will-keep-your-workplace-kitchen-clean/"
      }}>{`now finding ways to utilise these technologies`}</a>{`. `}<a parentName="p" {...{
        "href": "https://www2.deloitte.com/content/dam/Deloitte/us/Documents/financial-services/us-fsi-rec-blockchain-in-commercial-real-estate.pdf"
      }}>{`Deloitte’s latest Real Estate paper`}</a>{` claims the main benefits of blockchain centre around property management and lease transactions. As such, the main application area is making payments – blockchain negates the need for a middleman and, therefore, reduces transaction costs.`}</p>
    <p>{`We can expect more blockchain-based tools in the coming year. `}<a parentName="p" {...{
        "href": "https://www.forbes.com/sites/yoavvilner/2018/07/24/where-blockchain-meets-the-trend-of-co-working-spaces/#3a15271a2494"
      }}>{`Speaking in a statement`}</a>{`, startup mentor Yoav Vilner, said: “Considering the rise of blockchain technology disrupting a wide range of industries, from communication, to gaming, to global commerce – it only makes sense for it to make its mark in coworking spaces as well.”`}</p>
    <p>{`In the flexible workspace world, blockchain-based tools can enhance `}<a parentName="p" {...{
        "href": "https://allwork.space/2018/03/introducing-two-very-different-ways-to-use-smart-contracts/"
      }}>{`processes such as managing your members and procurement`}</a>{` using smart contracts.`}</p>
    <p>{`Other offerings are disrupting the flexible workspace market in different ways. For example, `}<a parentName="p" {...{
        "href": "https://primalbase.com/en/"
      }}>{`Primalbase`}</a>{` lets you share, rent or sell a space using digital tokens. `}<a parentName="p" {...{
        "href": "https://allwork.space/2018/01/meet-full-node-berlins-blockchain-only-coworking-space/"
      }}>{`Full Node`}</a>{` also opened its doors in Berlin in 2018, which is a specialist coworking space for blockchain companies.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      