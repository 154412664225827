import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-01-29T00:00:00.000Z",
  "title": "The Human Element Of Digital Transformation: Key Takeaways From Paperworld 2020\n",
  "slug": "the-human-element-of-digital-transformation-key-takeaways-from-paperworld-2020",
  "excerpt": ["A major theme at this year’s Paperworld Conference was the future office and smart solutions.", "Digital transformation took center stage, but speakers also focused on the importance of sustainability and why employees need to continue learning new skills.", "Allwork.Space’s top three takeaways include the link between digitization and emotion; the need for lifelong learning; and why we need to be more active at work"],
  "images": ["56-the-human-element-of-digital-transformation-key-takeaways-from-paperworld-2020.png"],
  "backlink": "https://allwork.space/2020/01/the-human-element-of-digital-transformation-key-takeaways-from-paperworld-2020/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Established in 1997, Paperworld is one of the largest international trade fairs for office supplies. Over the past few years, the event has evolved to include conferences about current workplace trends.    One of this year’s themes in the event was the future office, which focused on smart workplace solutions. The speaker program included a variety of topics that addressed:`}</p>
    <ul>
      <li parentName="ul">{`Digital transformation `}</li>
      <li parentName="ul">{`Artificial intelligence `}</li>
      <li parentName="ul">{`How to promote cooperation between decentralized team`}</li>
      <li parentName="ul">{`How to better process large amounts of data`}</li>
      <li parentName="ul">{`How technology and wellbeing are connected.`}</li>
    </ul>
    <p>{`Allwork.Space attended the event and below you will find our key takeaways. `}</p>
    <h3>{`1. Digitization and Emotion`}</h3>
    <p>{`Sussane Busshart, Managing Director at SBCdigital GmbH, gave a presentation about the area of tension between digitization and emotion.   `}</p>
    <p>{`Busshart argues that there is an emotional component to digital transformation. A cultural component that is not being addressed enough in current conversations around technology and digitization. `}</p>
    <p>{`“We don’t talk enough about feelings,” Busshart said. And it’s about time we start talking about them.`}</p>
    <p>{`“We need to consider how to better use our spaces and how they can create an emotional connection with employees.”
Her reasoning is that people are a decisive factor for how spaces are created, therefore people’s emotions should also be decisive, especially in today’s fast-changing world.`}<br parentName="p"></br>{`
`}{`“We’re in a constant state of beta.”`}</p>
    <p>{`We’ve reached a point in time where organizations cannot do without change. Organizations are still figuring out how to handle this change in the workplace. One of the best ways to handle it is by creating spaces that are flexible, that are customizable; spaces that create an emotional connection with employees.    `}</p>
    <p>{`Busshart believes that organizations need to design workplaces with wellness in mind.`}<br parentName="p"></br>{`
`}{`Workplaces need to be agile and incorporate elements such as movable and stackable furniture. The goal is to create workplaces where everyone can work in the best way possible for them.    `}</p>
    <p>{`A space within a workplace should be able to support individual and team work, online and offline work, learning opportunities, and more.`}<br parentName="p"></br>{`
`}{`Spaces need to be customizable. It’s become an expectation of employees, and with the war for talent, organizations are feeling increased pressure to create workplaces people feel in control of.    Technology and smart solutions can play a big role in establishing this connection. Technologies like sensors, the IoT, and voice assistants can make it easier for organizations and individuals to personalize their space.  `}</p>
    <h3>{`2. Lifelong Learning is Now More Important than Ever`}</h3>
    <p>{`Jörg Bakschas, change coach and design thinker at Headroom Consult, gave a presentation around sustainability in the workplace.
While a good portion of his presentation focused on environmental sustainability, Bakschas spoke of sustainability in regards to human resources.
Emerging technologies are creating a variety of new jobs, jobs that will require humans to develop new skills.`}<br parentName="p"></br>{`
`}{`Unfortunately, humans are having a hard time keeping up with the rapid change of smart technologies; afterall, computer systems can learn much faster than us.`}<br parentName="p"></br>{`
`}{`To ensure the sustainability and long-term success of workers, companies need to start thinking of ways in which they can encourage employees to learn.    `}</p>
    <p>{`“We need to learn to learn… and we need to do it quickly.” said Bakschas.    `}</p>
    <p>{`85% of what is done in an office today could already be replaced by robots and AI. However, these smart technologies can complete tasks, but they cannot fulfill all of the responsibilities of a job role.    `}</p>
    <p>{`The future office needs to create an environment where humans and robots can collaborate together. But more importantly, it needs to create an environment where people have access to learning tools (classrooms, technologies like AR and VR that can help train people and that can make it easier for them to develop a new skill, coaches, teachers, mentors, digital tools, etc.)  `}</p>
    <h3>{`3. Keep on Moving`}</h3>
    <p>{`Burkhard Remmers, Head of International Communications at Wilkhahn, gave a presentation ‘Digital Office – flexibility rather than physical inactivity’, which focused on how movement can help us produce better work.    Work requires knowledge and competence, Remmers argued. Both knowledge and competence are part of the minds of people, which is why a focus on wellness is imperative in the workplace.    `}</p>
    <ul>
      <li parentName="ul">{`While smart technologies have made us less active (we spend hours upon hours sitting while typing away on our phones and computers), digital technologies also have the power to help us design better analogue environments.    Since people are the source of value-creation, our environments should focus on people.    “We need to make sure that people stay healthy and are able to work for a long time. We need to start thinking about sustainability in terms of health,” said Remmers.    Digitization and digital tools affect our physical and mental wellbeing:      `}</li>
      <li parentName="ul">{`They create stress    `}</li>
      <li parentName="ul">{`Th ey have led to increased back problems (most people don’t maintain correct posture while sitting and working on a computer)    `}</li>
      <li parentName="ul">{`They have led to musculoskeletal diseases (mainly overweight caused by prolonged seating, which slows down our metabolism).      `}</li>
    </ul>
    <p>{`“Our movements today are confined to a tap in our smartphone or typing on our computers.” Luckily, new technologies that can be deployed at home and at work are making it easier for individuals to keep track of their health; i.e. smart watches that remind us it’s time to move, sensors that can pick up our stress levels, refrigerators that remind us we’re low on certain foods, etc.
Remmers argues that to achieve and maintain wellbeing, the body and mind require certain levels of action.`}</p>
    <p>{`“Dopamine is triggered through motion. We need to keep moving to generate happy hormones in order to produce better work.”
Organizations and individuals need to create a different understanding about digital tools and how they can be used not only to drive productivity and performance, but also to boost wellbeing.  `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      