import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-02-28T00:00:00.000Z",
  "title": "Future Of Work: How To Build A Data-driven Company In 5 Steps\n",
  "slug": "future-of-work-how-to-build-a-data-driven-company-in-5-steps",
  "excerpt": ["Data helps companies improve accuracy, increase efficiency, augment the workforce’s capabilities, and boost productivity.", "Yet, new research suggests that between 60 and 73 percent of all enterprise data is never analyzed, which is partly due to data illiteracy.", "Organizations need to develop data literacy training programs to help workers become more confident analyzing and using data effectively."],
  "images": ["how-to-build-a-data-driven-company.png"],
  "backlink": "https://allwork.space/2020/02/future-of-work-how-to-build-a-data-driven-company-in-5-steps/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A recent `}<a parentName="p" {...{
        "href": "https://www.accenture.com/us-en/insights/technology/closing-data-value-gap"
      }}>{`report from Qlik and Accenture`}</a>{` found that businesses are still just scratching the surface of data’s full potential.`}</p>
    <p>{`“While organizations continue to collect, store and analyze data, its real potential remains largely untapped. In fact, research from Forrester found that between 60 and 73 percent of all enterprise data is never analyzed.”`}</p>
    <p>{`The above is problematic because data now underpins success. Tapping into the data gold mine and, more importantly, analyzing it correctly can improve accuracy, increase efficiency, augment the workforce’s capabilities, and boost productivity.`}</p>
    <p>{`Research supports the above; organizations that lead with data reap its advantages.`}</p>
    <p>{`“Qlik’s Data Literacy Index found that data-driven organizations benefited from increased corporate performance, resulting in a higher total enterprise value of 3-5 percent, equating to US$500 million when applied to the organizations included in the study.”`}</p>
    <h3>{`Key Stats from the Report`}</h3>
    <ul>
      <li parentName="ul">{`Only 32 percent of business executives surveyed said that they’re able to create measurable value from data, while just 27 percent said their data and analytics projects produce actionable insights.`}</li>
      <li parentName="ul">{`Just 21 percent of the global workforce are fully confident in their data literacy skills — i.e. their ability to read, understand, question and work with data.`}</li>
      <li parentName="ul">{`Only 37 percent of employees trust their decisions more when those decisions are based on data, and almost half (48 percent) frequently defer to making decisions based on gut feeling over data-driven insight.`}</li>
      <li parentName="ul">{`An eye-opening three quarters (74 percent) of employees report feeling overwhelmed or unhappy when working with data. This has a negative impact on their performance: more than one-third (36 percent) of overwhelmed employees globally report spending at least one hour a week procrastinating over data-related tasks. These feelings result in some employees avoiding data.`}</li>
      <li parentName="ul">{`61 percent of respondents report that feeling overwhelmed by data has contributed to their workplace stress.`}</li>
      <li parentName="ul">{`When accounting for data-induced procrastination and sick leave due to stress resulting from information, data and technology issues, companies lose an average of more than five working days (43 hours) per employee each year.`}</li>
      <li parentName="ul">{`Three-quarters (75 percent) of C-suite level respondents believe that all or most of their employees have the ability to work with data proficiently, and even more (79 percent) believe that their employees have access to the tools they need to be productive. However, middle managers and below are less optimistic, with half feeling that all or most employees have the right abilities and 50 percent echoing the same sentiment about access.`}</li>
    </ul>
    <h3>{`Addressing the Data Literacy Gap`}</h3>
    <p>{`Today, individuals across all business functions are expected to become self sufficient with data and make data-driven decisions on a regular basis.`}</p>
    <p>{`Unfortunately, most workers don’t feel comfortable enough working with data, with many saying they’d follow their gut feeling more over data-driven insights. Not only will this affect their productivity and professional performance, but also their wellbeing.`}</p>
    <p>{`It’s high time that organizations invest in data-ready skills of their employees and that they foster a culture that empowers data-driven decisions.`}</p>
    <p>{`For this to happen, businesses need to democratize data. Rather than having a small group of specialists gathering and analyzing data, leaders should encourage everyone in the organization to work with data.`}</p>
    <p>{`Doing this will help workers feel more comfortable and confident when working with data.`}</p>
    <p>{`“Many organizations have simply put data in the hands of employees and expected them to make a success of it. This can affect some individuals’ understanding of the potential of data to support their work, how comfortable they feel using it and, in turn, their appetite to use it.”`}</p>
    <p>{`Not only will it make employees more comfortable, but having a team of data-driven employees can also improve organizational performance.`}</p>
    <p>{`Encouraging workers to use data “will help firms harness the opportunity of up to 5 percent growth in enterprise value associated with data-driven businesses, while also removing the productivity cost of data illiteracy in their organizations.”`}</p>
    <h3>{`Beyond Just Capturing Data`}</h3>
    <p>{`Organizations are already capturing data. But they need to do more.`}</p>
    <p>{`For data to reach its full potential, organizations need to turn data into actionable insights in order to make informed decisions, improve productivity, and drive competitive advantage.`}</p>
    <p>{`Qlik and Accenture identified five key steps that organizations should follow to create a data-driven organizational culture and help close the data literacy gap.`}</p>
    <h4>{`1. Set clear data expectations`}</h4>
    <p>{`Organizations need to clearly outline their goals. More importantly, they need to identify how each role within an organization will need to work with data to achieve said goals.`}</p>
    <p>{`“Setting clear expectations means that everyone—whether they’re in product development, marketing or business intelligence—understands what is and isn’t expected of them. This must go beyond the processes they should be using data for, encouraging them to collaborate and communicate with data, as well as to challenge its insights.”`}</p>
    <h4>{`2. Create a roadmap to achieve your data goals`}</h4>
    <p>{`The report found that leaders overestimate the data capability of their workforce and their readiness to work with data.`}</p>
    <p>{`An organization’s roadmap to become data-driven should focus on:`}</p>
    <ul>
      <li parentName="ul">{`Assessing individual levels of data literacy`}</li>
      <li parentName="ul">{`Understanding current availability and required adoption of technology and tools`}</li>
      <li parentName="ul">{`Defining the data that users need to access`}</li>
    </ul>
    <h4>{`3. Arm your employees for data-driven working`}</h4>
    <p>{`For employees to feel more comfortable working with data, businesses need to provide them with access to the right tools and resources to meet data goals.`}</p>
    <p>{`Some key resources include:`}</p>
    <ul>
      <li parentName="ul">{`Solutions that deliver insights`}</li>
      <li parentName="ul">{`Delivering consumable insights (dashboards, communication tools, CRM systems)`}</li>
      <li parentName="ul">{`Providing employees with frameworks and methodologies that will guide them in the data analyzing process.`}</li>
    </ul>
    <h4>{`4. Close the data literacy skills gap`}</h4>
    <p>{`“No matter how consumable the data is, employees need to be curious and capable of understanding, questioning and taking the right action based on the insights delivered.”`}</p>
    <p>{`Therefore, organizations need to think about how to upskill the data capabilities of their employees. This should include training that focuses on hard skills (technical skills for data processes) and soft-skills (collaboration, storytelling, critical thinking).`}</p>
    <h4>{`5. Create a culture of coevolution`}</h4>
    <p>{`Data is transforming and will continue to transform businesses.`}</p>
    <p>{`Data literacy is not a destination, rather it’s an ongoing journey. Data literacy training programs need to be revisited often to ensure that they reflect the evolving use of data.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      