import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-10-14T00:00:00.000Z",
  "title": "AI Shouldn’t Be Feared: The Future Of Work Needs Human Values, Skills, And Communities\n",
  "slug": "ai-shouldnt-be-feared-the-future-of-work-needs-human-values-skills-and-communities",
  "excerpt": ["Many people fear technological change, but the way we work and live with technology and AI is already a fundamental part of our lives.", "At the HR Conference Barcelona 2019, Robert Kropp learned that technology can help liberate our human skills, which is vital for the future of work.", "AI “is the great story of our time” and will help people transform the world in a way that makes a positive difference."],
  "images": ["38-the-future-of-work-needs-human-values-skills-and-communities.jpg"],
  "backlink": "https://allwork.space/2019/10/ai-shouldnt-be-feared-the-future-of-work-needs-human-values-skills-and-communities/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`When we think about work, workplaces, and the future of work, many of us think about intelligent machines that run our lives and maybe Terminator.`}</p>
    <p>{`Jokes aside, many of us fear losing our jobs or just keeping up with the changes. Others might not be concerned or are about to retire and think it doesn’t impact them.`}</p>
    <p>{`One thing is for certain.`}</p>
    <p>{`The way we work and live with technology and AI will continue to grow as a fundamental part of our lives.`}</p>
    <p>{`This past week, I had the opportunity to go to the 6th Annual HR Conference Barcelona where we discussed many things including: Moonshots for HR, AI, Cyborgs, Humanity in Work, Leading Agile from HR, Blockchain, Startups, and Leadership & Culture. Here is an overview of the event.`}</p>
    <p>{`One of the most important takeaways from the event was that technology will help us get back to being more human in the workplace and we shouldn’t be afraid.`}</p>
    <p>{`The first speaker, Manish Bahl, who is the Associate VP for the Center for the Future of Work at Cognizent described this future for many people as fearful.`}</p>
    <p>{`He said that we hope there is no inequality, that we still have jobs, and that our future generations will have work.`}</p>
    <p>{`However, Manish also stated that we “need to decide between jobs of the past or jobs of the future.”`}</p>
    <p>{`Are we dwelling on what was, focusing on what is, or dreaming of what will be?`}</p>
    <p>{`“AI is the great story of our time”.`}</p>
    <p>{`According to Manish, in the next few years AI will be all around us.`}</p>
    <p>{`It will help to educate our children and even save us on energy costs. Today “we take AI for granted… It is already being used in chats, shopping, and as a daily helper to manage our lives”.`}</p>
    <p>{`There are many more use cases and examples to offer but the advantages of AI and machine intelligence is here, and will exponentially impact us.`}</p>
    <h2>{`Why is this not a doomsday prediction for the future of work?`}</h2>
    <p>{`Manish believes there are 3 scenarios for jobs and work:`}</p>
    <ol>
      <li parentName="ol">{`Job is Replaced – 12% at risk`}</li>
      <li parentName="ol">{`Job is Enhanced – 75% at risk`}</li>
      <li parentName="ol">{`Job is Invented – 13%`}</li>
    </ol>
    <p>{`Right now, the vast majority (90%) of the conversations are about replacement of jobs instead of the need to enhance or even invent a job that hasn’t been thought of yet.`}</p>
    <p>{`This is the main reason why so many fear the discussion.`}</p>
    <p>{`The projected net job elimination / creation is near 0. There are various studies but most research is between a 10% reduction to even a 10% addition in jobs.`}</p>
    <h2>{`The Real Potential of AI`}</h2>
    <p>{`This shift to using AI and machine intelligence will eliminate the mundane and monotonous work that we shouldn’t be doing in the first place.`}</p>
    <p>{`Another speaker, Martin Wezowski who is the Chief Designer & Futurist for SAP, put it this way:`}</p>
    <p>{`We need to “find people a purpose away from these subhuman jobs” and give them “new values, skills, and perspectives on human work.”`}</p>
    <p>{`“The future of human work requires imagination, creativity, and strategy.”`}</p>
    <p>{`Martin continued saying that “the workplace is killing people and nobody cares… If you don’t have a sense of me (yourself), you become stressed and disengaged at work.`}</p>
    <p>{`“Stress is the biggest lead of chronic diseases… People want to feel they are transforming the world in a way that makes a positive difference.”`}</p>
    <p>{`Sophie Theen who is the VP of People at Verve said that when hiring and developing an employee “what you are really looking for is a person’s value.” Their natural and human gifts are what matter. It’s not about how fast can they do the same thing over and over. It’s not about a human trying to act like a machine.`}</p>
    <p>{`Pedro Moneo, the CEO and Founder of Opinno, further emphasized this message.`}</p>
    <p>{`He said: “Creativity and critical thinking will be key… If your job requires empathy or compassion, then you will be less automated.” However, if your job is “repetitive and without empathy then it will probably be replaced.”`}</p>
    <h2>{`What’s Next for the Future of Work and AI?`}</h2>
    <ul>
      <li parentName="ul">{`The combination of humans and AI will improve many workers’ productivity and efficiency.`}</li>
      <li parentName="ul">{`We will begin to eliminate the “confusion between job and task”. According to Manish, the message should be, “the task of this job will be automated, the job will not be automated.” This in turn is important for reducing the fear of change which is a critical challenge to the future of AI, work, and humans.`}</li>
      <li parentName="ul">{`Organizations will need to share all AI targets across an organization.`}</li>
      <li parentName="ul">{`As a leader or manager, upskill yourself first so that the people you lead will be more inclined to follow suit.`}</li>
      <li parentName="ul">{`Most workers and workforces will need to upskill especially for those that entail many tasks that will be automated.`}</li>
    </ul>
    <h2>{`Impact of AI on Recruitment`}</h2>
    <p>{`Manish also said that the recruitment of people will “become fairer, smarter, and more engaging for candidates.” What makes us human will make us employable in the future with “80% of employers saying human centric skills (problem solving, flexibility, context) is going to become much more important.”`}</p>
    <p>{`I’ll end with this from Martin:`}</p>
    <p>{`The Future of Work “surfaces, fosters, and optimizes our individual gifts to create a higher value, in feelings, wisdom, self-actualization, for ourselves and the communities around us.”`}</p>
    <p>{`What do you think? How will AI continue to transform how, when, where, and what we work on? How can flexible workspaces help people do more human work?`}</p>
    <p>{`Tell me about how you are helping to create a new future of work. I would love to hear from you.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      