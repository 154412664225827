import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-02-07T00:00:00.000Z",
  "title": "Future Of Work: The Jobs Of Tomorrow And The Skills Needed To Succeed In Them.\n",
  "slug": "future-of-work-the-jobs-of-tomorrow-and-the-skills-needed-to-succeed-in-them",
  "excerpt": ["A report by the World Economic Forum, \"Jobs of Tomorrow, Mapping Opportunity in the New Economy\", takes an in-depth look into new job creation.", "The report analyzes emerging professions of the future, and the skills that individuals need to develop to thrive in these emerging roles.", "Interestingly, while tech knowledge will be critical for the future of work, so will ‘human’ skills including caregiving, leadership, learning, and development."],
  "images": ["the-jobs-of-tomorrow.png"],
  "backlink": "https://allwork.space/2020/02/future-of-work-the-jobs-of-tomorrow-and-the-skills-needed-to-succeed-in-them/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Some future of work scenarios paint a bleak picture for humans: robots will take over our jobs and automation will make human beings redundant.`}</p>
    <p>{`Though there’s no way to tell whether that will be true 100 or 500 years from now, recent research has found that this will not be true in the next 10-20 years.`}</p>
    <p>{`In fact, `}<a parentName="p" {...{
        "href": "https://allwork.space/2019/10/future-of-work-should-your-organization-offer-and-pay-for-retraining-opportunities/"
      }}>{`new technologies are expected to create more jobs`}</a>{`.`}</p>
    <p>{`A recent report by the World Economic Forum, ‘`}<a parentName="p" {...{
        "href": "https://www.weforum.org/reports/jobs-of-tomorrow-mapping-opportunity-in-the-new-economy"
      }}>{`Jobs of Tomorrow: Mapping Opportunity in the New Economy`}</a>{`’, takes an in-depth look into new job creation, emerging professions of the future, and the skills that individuals need to develop to thrive in these emerging roles.`}</p>
    <p>{`The report identified seven key professional clusters that are set to yield 6.1 million new job opportunities in the coming three years alone (2020-2022). What’s more, if current growth trends hold, “these emerging professions will provide 1.7 million new jobs in 2020 — and that figure will see a significant increase of 51% to 2.4 million opportunities by 2022.”`}</p>
    <p>{`Interestingly, and quite positively, the report found that while technology skills will be critical for the future of work, so will caregiving, leadership, learning, and development skills.`}</p>
    <p>{`“In other words, the transition to the new world of work will be both human- and tech-centric.”`}</p>
    <h3>{`The Seven Key Professional Clusters of the Future`}</h3>
    <ol>
      <li parentName="ol">{`Data and AI`}</li>
      <li parentName="ol">{`Care Economy`}</li>
      <li parentName="ol">{`Green Economy`}</li>
      <li parentName="ol">{`Engineering and Cloud Computing`}</li>
      <li parentName="ol">{`People and Culture`}</li>
      <li parentName="ol">{`Product Development and Sales`}</li>
      <li parentName="ol">{`Marketing and Content`}</li>
    </ol>
    <p><img parentName="p" {...{
        "src": "https://allwork.space/wp-content/uploads/2020/02/The-Jobs-of-Tomorrow.png",
        "alt": null
      }}></img></p>
    <p>{`The number of job opportunities is rising, but workers will need to develop new skills if they want to succeed in the future of work.`}</p>
    <p>{`According to the report, these seven clusters will represent the highest-growth jobs of tomorrow. The research also identified particular strong job growth in Green and Care Economy jobs, with the Care Economy being by far the most in-demand.`}</p>
    <p>{`Data and AI, Green Economy, and Engineering and Cloud Computing professional clusters are expected to experience annual growth rates of 41%, 35%, and 34% respectively.`}</p>
    <h3>{`Job Growth Estimates by Cluster`}</h3>
    <ul>
      <li parentName="ul">{`37% of job openings in emerging professions will be in the Care Economy`}</li>
      <li parentName="ul">{`17% of job openings in emerging professions will be in Sales, Marketing, and Content`}</li>
      <li parentName="ul">{`16% of job openings in emerging professions will be in Data and AI`}</li>
      <li parentName="ul">{`12% of job openings in emerging professions will be in Engineering and Cloud Computing`}</li>
      <li parentName="ul">{`8% of job openings in emerging professions will be in People and Culture.`}</li>
      <li parentName="ul">{`1.6% of job openings in emerging professions will be in Green Economy professions.`}</li>
    </ul>
    <p>{`It is worth noting that “the absolute number of opportunities in Green Economy jobs and Care Economy jobs are most likely to change in tandem with new and unpredictable shifts in government legislation and business practices.”`}</p>
    <h3>{`Top 5 Most in-Demand Roles by Cluster`}</h3>
    <h4>{`Care Economy`}</h4>
    <ol>
      <li parentName="ol">{`Medical Transcriptionists`}</li>
      <li parentName="ol">{`Physical Therapist Aides`}</li>
      <li parentName="ol">{`Radiation Therapists`}</li>
      <li parentName="ol">{`Athletic Trainers`}</li>
      <li parentName="ol">{`Medical Equipment Preparers`}</li>
    </ol>
    <h4>{`Data and AI`}</h4>
    <ol>
      <li parentName="ol">{`Artificial Intelligence Specialist`}</li>
      <li parentName="ol">{`Data Scientist`}</li>
      <li parentName="ol">{`Data Engineer`}</li>
      <li parentName="ol">{`Big Data Developer`}</li>
      <li parentName="ol">{`Data Analyst`}</li>
    </ol>
    <h4>{`Engineering and Cloud Computing`}</h4>
    <ol>
      <li parentName="ol">{`Site Reliability Engineer / Cloud Computing`}</li>
      <li parentName="ol">{`Python Developer / Engineering`}</li>
      <li parentName="ol">{`Full Stack Engineer / Engineering`}</li>
      <li parentName="ol">{`Javascript Developer / Engineering`}</li>
      <li parentName="ol">{`Back End Developer / Engineering`}</li>
    </ol>
    <h4>{`Green Economy`}</h4>
    <ol>
      <li parentName="ol">{`Methane/Landfill Gas Generation System Technicians`}</li>
      <li parentName="ol">{`Wind Turbine Service Technicians`}</li>
      <li parentName="ol">{`Green Marketers`}</li>
      <li parentName="ol">{`Biofuels Processing Technicians`}</li>
      <li parentName="ol">{`Solar Energy Installation Managers`}</li>
    </ol>
    <h4>{`People and Culture`}</h4>
    <ol>
      <li parentName="ol">{`Information Technology Recruiter`}</li>
      <li parentName="ol">{`Human Resources Partner`}</li>
      <li parentName="ol">{`Talent Acquisition Specialist`}</li>
      <li parentName="ol">{`Business Partner`}</li>
      <li parentName="ol">{`Human Resources Business Partner`}</li>
    </ol>
    <h4>{`Product Development`}</h4>
    <ol>
      <li parentName="ol">{`Product Owner`}</li>
      <li parentName="ol">{`Quality Assurance Tester`}</li>
      <li parentName="ol">{`Agile Coach`}</li>
      <li parentName="ol">{`Software Quality Assurance Engineer`}</li>
      <li parentName="ol">{`Product Analyst`}</li>
    </ol>
    <h4>{`Sales, Marketing, and Content`}</h4>
    <ol>
      <li parentName="ol">{`Social Media Assistant / Content Production`}</li>
      <li parentName="ol">{`Growth Hacker / Marketing`}</li>
      <li parentName="ol">{`Customer Success Specialist / Sales`}</li>
      <li parentName="ol">{`Social Media Coordinator / Content Production`}</li>
      <li parentName="ol">{`Growth Manager / Marketing / + Sales Development Representative / Sales`}</li>
    </ol>
    <h4>{`Skills of the Future`}</h4>
    <p>{`The report found that the jobs of tomorrow require both technical and cross-functional skills, and individuals who want to succeed in the future of work will need to develop a range of distinctive skill sets.`}</p>
    <p>{`The World Economic Forum divided the most in-demand skills into five clusters:`}</p>
    <ol>
      <li parentName="ol">{`Business Skills`}</li>
      <li parentName="ol">{`Specialized Industry Skills`}</li>
      <li parentName="ol">{`General and Soft Skills`}</li>
      <li parentName="ol">{`Tech Baseline Skills`}</li>
      <li parentName="ol">{`Tech Disruptive Skills`}</li>
    </ol>
    <p>{`However, it’s important to highlight that “the type of opportunities that are set to materialize are also changing fast, in tandem with the evolving needs of the technological and economic context.” This means that professionals will need to develop `}<a parentName="p" {...{
        "href": "https://allwork.space/2019/05/learnability-the-most-important-skill-to-succeed-in-the-future-of-work/"
      }}>{`effective processes and mechanisms to learn new skills in a fast-changing environment`}</a>{`.`}</p>
    <h3>{`Top 5 Skills for Each Professional Cluster`}</h3>
    <h4>{`Care Economy`}</h4>
    <ol>
      <li parentName="ol">{`Respiratory Therapy (Industry Specialized skill)`}</li>
      <li parentName="ol">{`Caregiving (Industry Specialized skill)`}</li>
      <li parentName="ol">{`Sterile Procedures / Techniques (Industry Specialized skill)`}</li>
      <li parentName="ol">{`Transcription (Industry Specialized skill)`}</li>
      <li parentName="ol">{`Radiation Treatment (Industry Specialized skill)`}</li>
    </ol>
    <h4>{`Data and AI`}</h4>
    <ol>
      <li parentName="ol">{`Data Science (Tech Disruptive skill)`}</li>
      <li parentName="ol">{`Data Storage Technologies (Tech Baseline skill)`}</li>
      <li parentName="ol">{`Development Tools (Tech Disruptive skill)`}</li>
      <li parentName="ol">{`Artificial Intelligence (Tech Disruptive skill)`}</li>
      <li parentName="ol">{`Software Development Life Cycle (SDLC) (Tech Baseline skill)`}</li>
    </ol>
    <h4>{`Engineering and Cloud Computing`}</h4>
    <ol>
      <li parentName="ol">{`Development Tools (Tech Disruptive skill)`}</li>
      <li parentName="ol">{`Web Development (Tech Baseline skill)`}</li>
      <li parentName="ol">{`Data Storage Technologies (Tech Baseline skill)`}</li>
      <li parentName="ol">{`Software Development Life Cycle (SDLC) (Tech Baseline skill)`}</li>
      <li parentName="ol">{`Computer Networking (Tech Baseline skill)`}</li>
    </ol>
    <h4>{`Green Economy`}</h4>
    <ol>
      <li parentName="ol">{`Digital Marketing (Business Skill)`}</li>
      <li parentName="ol">{`Wind Turbines (Industry Specialized skill)`}</li>
      <li parentName="ol">{`Landfill Gas Collection (Industry Specialized skill)`}</li>
      <li parentName="ol">{`Social Media (Tech Baseline skill)`}</li>
      <li parentName="ol">{`Equipment Inventory (Tech Baseline skill)`}</li>
    </ol>
    <h4>{`People and Culture`}</h4>
    <ol>
      <li parentName="ol">{`Recruiting (Industry Specialized skill)`}</li>
      <li parentName="ol">{`Human Resources (Business Skill)`}</li>
      <li parentName="ol">{`Business Management (Business Skill)`}</li>
      <li parentName="ol">{`Employee Learning & Development (Business Skill)`}</li>
      <li parentName="ol">{`Leadership (General and Soft skill)`}</li>
    </ol>
    <h4>{`Product Development`}</h4>
    <ol>
      <li parentName="ol">{`Software Testing (Tech Baseline skill)`}</li>
      <li parentName="ol">{`Software Development Life Cycle (SDLC) (Tech Baseline skill)`}</li>
      <li parentName="ol">{`Development Tools (Tech Disruptive skill)`}</li>
      <li parentName="ol">{`Project Management (Business Skill)`}</li>
      <li parentName="ol">{`Business Management (Business Skill)`}</li>
    </ol>
    <h4>{`Sales, Marketing, and Content`}</h4>
    <ol>
      <li parentName="ol">{`Digital Marketing (Business Skill)`}</li>
      <li parentName="ol">{`Social Media (Tech Baseline skill)`}</li>
      <li parentName="ol">{`Business Management (Business Skill)`}</li>
      <li parentName="ol">{`Digital Literacy (Tech Baseline skill)`}</li>
      <li parentName="ol">{`Advertising (Business Skill)`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      