import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-12-25T00:00:00.000Z",
  "title": "Future Of Work: 6 Ways The Pandemic Is Changing Flexible Workspace\n",
  "slug": "6-ways-the-pandemic-is-changing-flexible-workspace",
  "excerpt": ["What are the new requirements of the returning workforce, and how will the workplace live up to them in 2021?", "This was the topic of discussion for a panel featuring Liz Elam from GCUC, John Williams from Instant Offices and Mark Furness from essensys.", "From using aggregators to make workspace easier to procure, to converting empty space for better use, here are 6 ways the pandemic will change the workplace going forward."],
  "images": ["6-ways-the-pandemic-is-changing-flexible-workspace.png"],
  "backlink": "https://allwork.space/2020/12/future-of-work-6-ways-the-pandemic-is-changing-flexible-workspace/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The pandemic has changed every aspect of work and life, and this looks set to continue for the foreseeable future. But sooner or later we will return to some form of normality — and in some cases this is happening already — which means a steady return to the workplace.`}</p>
    <p>{`What are the new requirements of the returning workforce? How will flexible workspaces respond to these needs? And what can we expect in 2021, and beyond?`}</p>
    <p>{`To answer these questions, essensys hosted a webinar in December 2020 featuring a panel of experts from the flexible space industry:`}</p>
    <ul>
      <li parentName="ul">{`Liz Elam – founder, `}<a parentName="li" {...{
          "href": "https://gcuc.co/"
        }}>{`GCUC Global`}</a></li>
      <li parentName="ul">{`John Williams – Chief Marketing Officer, `}<a parentName="li" {...{
          "href": "https://www.instantoffices.com/"
        }}>{`Instant Offices`}</a></li>
      <li parentName="ul">{`Mark Furness – founder and CEO, `}<a parentName="li" {...{
          "href": "https://essensys.tech/"
        }}>{`essensys`}</a></li>
    </ul>
    <p>{`Here are the highlights from the discussion. Watch the recording in full `}<a parentName="p" {...{
        "href": "https://essensys.tech/webinars/the-future-of-the-flexible-workspace-industry/"
      }}>{`here`}</a>{`.`}</p>
    <h3>{`Flexible Space to Grow 21% in 2021`}</h3>
    <p>{`Instant’s John Williams believes the next 12 months will see an increase in supply of 21% globally for flexible space. “There is still a pervasive sense of bullishness around the market and what the future holds,” he said. “By 2021 we feel we’ll be in a much better place.”`}</p>
    <p>{`For Mark Furness, there is a general sense of pent up demand that is set to be released later in the year. “We are having many more positive conversations, and we’re also seeing more activity from the landlord side. I believe we’ll return to growth by the back end of 2021, if not a little earlier.”`}</p>
    <p>{`Furness added that “there is definitely a feeling of confidence” about the opportunities developing in 2021. Much of this has come from a gradual realisation that remote work is a temporary measure, and to do our jobs properly, we need the right workspace and the right people around us. “We need to think about this differently and think about our space requirements and how people work.” Undoubtedly, he added, businesses need agility and flexibility — and the workplace plays an integral role in that.`}</p>
    <h3>{`Will Entry to the Workplace be Policed?`}</h3>
    <p>{`With vaccinations now being rolled out, could there be new requirements for people to be vaccinated before entering a workplace? These conversations are now taking place, but there is no easy answer.`}</p>
    <p>{`“Can your company require you to have the vaccine?” asked Liz Elam. “There are lots of discussions going on. It’s definitely going to have an impact on where and how we work.”`}</p>
    <p>{`Furness added that confidence will drive people back into spaces, and while a vaccination policy may help bring more people back to work, policing it will be both a challenge “and a big ask”.`}</p>
    <h3>{`People Want Choice, and are Voting with their Feet`}</h3>
    <p>{`After spending most of the year working from home, many people have become weary of the isolation, discomfort, and lack of separation between work and life. The answer has been to offer employees a workplace closer to home — such as a local coworking space — and this trend is gaining traction.`}</p>
    <p>{`Williams noted that the last 6 months has seen demand for suburban locations increasing, and workspaces in these areas have also held their pricing the best. He likens it to a “bookend week”, with people spending time in a central workplace some of the time and then working from home or near home at either end of the week.`}</p>
    <p>{`“Choice has switched to the worker,” added Elam. “A lot of people are so desperate to get out of their home, some are even paying for it out of their own pocket. They are voting with their feet.”`}</p>
    <h3>{`2021: The Year of the Aggregator?`}</h3>
    <p>{`Despite growing demand for regional and suburban workspaces, there is still a huge obstacle to overcome: how do potential occupiers find spaces close to where their employees live?`}</p>
    <p>{`How can occupiers be sure these spaces offer what they need, without dedicating significant time and resources visiting each and every space?`}</p>
    <p>{`The answer could be to focus on partnerships or to join local alliances.`}</p>
    <p>{`“Operators are seeing new opportunities in franchise models, aggregator models, and alliance models,” said Furness. “It’s a growth opportunity for regional players and small operators.”`}</p>
    <p>{`Elam added that GCUC is planning to launch ‘GCUC Places’ in January 2021, which will be a global network of small and independent coworking spaces to make it easier for people to travel between workspaces. It will also help smaller spaces compete with larger brands.`}</p>
    <p>{`Ultimately, becoming part of a network means corporate firms don’t need to manage multiple contracts with multiple operators. By dealing with one network, it should offer easy and flexible access. But as Furness noted: “We haven’t cracked that yet.”`}</p>
    <h3>{`Workspace Will Be More Transactional`}</h3>
    <p>{`We have the technology to enable seamless, touchless transactions, and the pandemic has accelerated its usage in workspaces. This is set to continue next year, says Furness: “We’re definitely seeing a move to access control technology and touchless tech, something that gets people into a space through their smartphone.”`}</p>
    <p>{`Space awareness technology, enabled by sensors, is making its way into the workplace. Sensors provide essential data on usage and occupancy, such as how close a space is coming to its occupancy threshold — which is crucial during the pandemic.`}</p>
    <p>{`Elam added that this type of interaction with a workspace is becoming more popular among occupiers. “We’re going to see a shift in how people utilise space and how they utilise technology. People do want the buzz of people around them. But they also want to be able to come in without touching anything, sit down, and just get to work.”`}</p>
    <h3>{`Major Cities will Bounce Back`}</h3>
    <p>{`With so much talk of suburban locations and working near home, what does this mean for city centres? The panel concluded that sooner or later, cities will bounce back, because people crave these environments.`}</p>
    <p>{`“We’re all missing out on collaborating and being with people and sharing ideas,” said Williams. “There will be rocky periods but the fundamentals remain; cities like London, New York and San Francisco are amazing places to live. We’ve had a year of city detox, and it’s human nature that we’re going to relish going back. We’ve missed it.”`}</p>
    <p>{`On that note, there will be a change in the way space in city centres is utilised, which will most likely result in rising vacancy levels. However, empty space can be put to good use with the right planning and leadership.`}</p>
    <p>{`Williams added: “For office, residential or retail space we don’t need, there’s a real opportunity with the right governmental approach or innovation from the private sector to convert that space into affordable housing, and to create positive change for the future.”`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      