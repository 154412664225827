import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-07-26T00:00:00.000Z",
  "title": "The Real Impact Of Automation On The Gig Economy\n",
  "slug": "the-real-impact-of-automation-on-the-gig-economy",
  "excerpt": ["In the future of work, automation will play a key role in our transition to prioritising tasks, not jobs.", "Automation will enable the gig economy, a report reveals.", "We will work in shape-shifting organisations that prioritise tasks, not jobs.", "Leaders need to prepare now for automation – with Amazon leading the charge."],
  "images": ["20-the-real-impact-of-automation-on-the-gig-economy.jpg"],
  "backlink": "https://allwork.space/2019/07/the-real-impact-of-automation-on-the-gig-economy/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "https://allwork.space/2019/07/how-technology-will-shape-work-in-the-future/"
      }}>{`Automation`}</a>{` is often portrayed as the bad guy for the future of work, where legions of robots steal our jobs and livelihoods. For the gig economy, this prediction could not be further from the truth, according to a recent report.`}</p>
    <p><a parentName="p" {...{
        "href": "https://go.forrester.com/future-of-work/"
      }}>{`The Future of Work report`}</a>{` from analyst house Forrester states: “Automation will accelerate the need for and size of the gig economy, as it displaces jobs and sources the talent able to deliver on certain competencies and tasks. Automation will also enable the gig economy to exist by connecting buyers and sellers.”`}</p>
    <p>{`So, in the world of flexible work, automation could well save our future workforces – not destroy them.`}</p>
    <p>{`This is because the future world of work will not be siloed into the specific hierarchies and organisations that we see today. “Instead, it will have a powerful core of purpose and culture with a central control framework for automation — a task- (not job-) driven organisation leveraging both the gig economy and digital outsourcing. This shape-shifting organisation gives context to the structure and role of automation,” the report reveals.`}</p>
    <p>{`Humans will still play a vital role in the future world of work. However, the report also predicts job losses of 29% by 2030 thanks to automation with only 13% job creation to compensate. As a result, those remaining employees are the “critical cultural glue and internal force in the future of work,” the report states.`}</p>
    <p>{`“With fewer employees, they are self-initiating, adaptable brand and culture ambassadors. They keep the core organisation whole and maintain the soul of the company’s front-and-centre gig economy workers, who come and go while robots make more and more decisions.”`}</p>
    <p>{`However, the Forrester report’s stat on job loss versus creation thanks to automation is at odds with other research. For example, `}<a parentName="p" {...{
        "href": "https://www.gartner.com/en/newsroom/press-releases/2017-12-13-gartner-says-by-2020-artificial-intelligence-will-create-more-jobs-than-it-eliminates"
      }}>{`Gartner predicts`}</a>{` AI-related job creation will reach two million net-new jobs in 2025. The `}<a parentName="p" {...{
        "href": "https://www.weforum.org/reports/the-future-of-jobs-report-2018"
      }}>{`World Economic Forum`}</a>{` predicts automation will displace 75 million jobs by 2022 – but generate 133 million new ones.`}</p>
    <p>{`Whatever the final figure, it is clear that automation will play a pivotal role in the future of work and that the gig economy could fill the gaps, increasingly providing businesses with the skills and services they require on an ad hoc basis.`}</p>
    <h2>{`Preparing for the future`}</h2>
    <p>{`The report concludes by urging organisations to prepare their workforce and leadership teams for the “sweeping, sensible, and sometimes cruel changes” automation will have.`}</p>
    <p>{`The report recommends that organisations prepare for automation by identifying its risks and rewards, preparing their leadership teams and introducing a “Robotics Quotient” to measure how well humans can work with (and sometimes for) robots.`}</p>
    <p>{`Finally, organisations need to adopt a “learning enterprise” approach, according to the report, which states: “Going forward, change will be constant … an employee’s ability (and, in some ways, desire) to learn will be a differentiating competency for firms — moving learning from a tactical hygiene to an economic engine.”`}</p>
    <p>{`Amazon is one step ahead in this regard, having recently announced plans to spend $700 million to upskill 100,000 of its US employees by 2025 to help them “move into more highly skilled roles within or outside Amazon”.`}</p>
    <p>{`Such change is only the start for the world’s enterprises as the report concludes: “Automation will drive significant change, but it will not change what a company is and how it works. The basic meaning and purpose of the company remain intact — to prosper by creating differentiated value for customers.`}</p>
    <p>{`“In other words, leaders must guide change and performance in a remarkably different context, mastering the obvious and nuanced in the future of work.”`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      