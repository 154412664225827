import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-07-03T00:00:00.000Z",
  "title": "Future Of Work: If Work From Anywhere Is The Future What Does This Mean For Office Location\n",
  "slug": "future-of-work-if-work-from-anywhere-is-the-future-what-does-this-mean-for-office-location",
  "excerpt": ["It’s likely that the future of work will be a hybrid blend of work from home and work from anywhere.", "While city centers and CBDs have traditionally been prized locations, all this could be about to change.", "Research suggests that businesses are increasingly looking at locations with a “live-work-play environment” where they have access to talent."],
  "images": ["if-work-from-anywhere-is-the-future-what-does-this-mean-for-office-location.png"],
  "backlink": "https://allwork.space/2020/07/future-of-work-if-work-from-anywhere-is-the-future-what-does-this-mean-for-office-location/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Recently, there have been numerous headlines arguing that the future of work isn’t work from home, it’s actually work from anywhere. At the same time, many experts have argued that `}<a parentName="p" {...{
        "href": "https://allwork.space/2020/05/future-of-work-the-office-isnt-dead-and-never-will-be/"
      }}>{`the office is far from dead`}</a>{`, and that people will continue to choose to come into the office to get work done. `}</p>
    <p>{`In this sense, the role of the office is changing. But there might be something else that’s about to change:`}</p>
    <p>{`Office location.`}</p>
    <p>{`Many organizations typically set up their company headquarters and offices in a Central Business District or in a city’s downtown. We only need to look at cities like New York, London, Sydney, Frankfurt, Chicago, Los Angeles, and Mexico City, to see that this is usually a top pick for global companies to set up their ‘home’. `}</p>
    <p>{`Afterall, Central Business Districts are often a city’s leading center of economic power and besides being home to many corporates, they are also home to banks, retail, culture, and entertainment. `}</p>
    <p>{`These areas tend to also be very well connected, making it easier for workers from different areas to commute to and from downtown or Central Business Districts. This provides organizations with a competitive advantage: access to talent. `}</p>
    <p>{`But this might be about to change. `}</p>
    <p>{`In fact, it has already been changing over the past couple of years; the pandemic will only accelerate this change or shift. `}</p>
    <p><a parentName="p" {...{
        "href": "https://www.ey.com/Publication/vwLUAssets/ey-the-attractiveness-of-world-class-business-districts/$FILE/ey-the-attractiveness-of-world-class-business-districts.pdf"
      }}>{`A recent study by EY and ULI Europe`}</a>{` found that the traditional “single-use business district — deserted after the end of the working day — is no longer an attractive proposition.” Their research found that businesses are looking at locations where they can have access to talent, and for this to happen, “business districts must create a live-work-play environment.” `}</p>
    <h3>{`How the Pandemic Might Impact Office Location`}</h3>
    <p>{`Talent attraction has long been a key priority for businesses across the globe. `}</p>
    <p>{`In the past, members of the workforce saw huge benefits in moving to big cities to find a good job. Many were, up until recently, willing to commute for a long time in order to get to and from work. `}</p>
    <p>{`But the pandemic might permanently change this. `}</p>
    <p><a parentName="p" {...{
        "href": "https://www.pbs.org/newshour/show/why-millennials-are-moving-away-from-large-urban-centers#:~:text=But%20recent%20census%20data%20indicates,in%20suburbs%20and%20smaller%20cities."
      }}>{`Recent census data from the US`}</a>{` found that “millennials and more Americans of all ages are increasingly choosing to live in suburbs and smaller cities.”`}</p>
    <p>{`This is important to note because these are the people that were mostly enduring long commutes by car or public transportation on a daily basis to get to and from work. `}</p>
    <p>{`Because of the COVID-19 pandemic, there is a high possibility that people will be less willing to commute for long periods of time, especially those that typically commute by way of public transportation.`}</p>
    <p>{`There are two main reasons for this:`}</p>
    <ol>
      <li parentName="ol">{`People have realized that they can work from home or anywhere else and still be as productive as they were in the office. Meaning that going into the office on a daily basis, on a rigid schedule will not be as appealing to workers in the future.`}</li>
      <li parentName="ol">{`A lasting effect from the pandemic will likely be anxiety and fear of being in crowded areas, such  as metro stations, buses, etc. Many will be unwilling to jump on a train for 45 minutes to an hour in order to get to the office from fear of being exposed to viruses and bacteria, especially when they could be working from home. `}</li>
    </ol>
    <p>{`A recent survey from CoreNet Global found that 71% of respondents don’t believe their company will shy away from city-center locations in densely packed urban areas that require the use of public transport. 29% of respondents indicated that their company would shy away from such locations in the future. `}</p>
    <p>{`But this doesn’t necessarily mean that organizations will continue to prefer central business districts. Though it’s unlikely they will fall back from them entirely. `}</p>
    <p>{`Our take is that organizations will likely downsize their footprint in downtown and CBD areas in order to expand their real estate footprint in more suburban areas. There are several reasons for this:`}</p>
    <ul>
      <li parentName="ul">{`Central business districts are a city’s leading center of economic power, afterall. `}</li>
      <li parentName="ul">{`There are still various individuals that live close-by to downtown areas or central business districts. `}</li>
      <li parentName="ul">{`Surveys have found that organizations report being more inclined to hire remote talent following the pandemic. This means that organizations might search for office space closer to suburban or residential areas where a chunk of their workforce is located. `}</li>
      <li parentName="ul">{`People will be unwilling to endure long commutes in crowded spaces, but they will still be willing to have a short commute to work, even if it means using public transportation. `}</li>
    </ul>
    <p>{`Most likely, preferred office locations will change in that organizations will create more regional hubs of office space rather than having one main headquarters in a given city.`}</p>
    <p>{`Considerations of how organizations choose office space might also change. Rather than just looking at a location’s connectivity and access points, they will likely have to consider new employee behaviors that might arise from the pandemic. `}</p>
    <p>{`If a significant number of employees choose to commute by car or bike rather than train or bus, it means an organization would have to look for office space with ample parking, bike paths, bike storage, and the like. `}</p>
    <p>{`If employees will be commuting to an office space that’s closer to home, it might also mean that they will go back home to eat lunch more than previously; this means that proximity to restaurants or dining options might not be as important anymore. `}</p>
    <p>{`There will be long-lasting impacts to the way people work because of the pandemic. Just as how people work will change, so will the where, and when.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      