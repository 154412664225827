import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-06-26T00:00:00.000Z",
  "title": "Future Of Work: How Technology Can Help Create A Contactless Workplace Experience\n",
  "slug": "future-of-work-how-technology-can-help-create-a-contactless-workplace-experience",
  "excerpt": ["As we move into our ‘new normal’, organizations are figuring out how to create low-touch workplaces with the help of technology.", "From booking desk space to ordering food, tech can help reduce the risk of coronavirus transmission in the workplace but the solution needs to be seamless, rather than layering one feature on top of another.", "Allwork.Space spoke with Andrea Williams from CXApp to find out how to restructure high-exposure areas, and the challenges involved."],
  "images": ["how-technology-can-help-create-a-contactless-workplace-experience.png"],
  "backlink": "https://allwork.space/2020/06/future-of-work-how-technology-can-help-create-a-contactless-workplace-experience/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`As organizations start to welcome employees back into the office, they are considering ways in which they can create a contactless (or at least a low-touch) workplace experience. Low-touch experiences will be a core principle as organizations move forward into the “new normal”.`}</p>
    <p>{`Technology will be a key tool to achieve this and organizations will likely have to turn to platforms that allow for multiple technologies to work together in order to fulfill a variety of use cases and interactions.`}</p>
    <p>{`One such platform is The CXApp, a mobile solution that streamlines all digital touchpoints, which recently released new platform features to enable enterprise organizations to convert physical touchpoints into digital interactions and restructure high-exposure areas in the workplace.`}</p>
    <p>{`Allwork.Space spoke with Andrea Williams, Director of Marketing and Customer Experience at CXApp, to find out how organizations can create a low-touch workplace experience. We discussed the challenges associated with restructuring high-exposure areas into digital ones, which touchpoints organizations should focus on, and more.`}</p>
    <p>{`Below are the highlights of our conversation.`}</p>
    <h3>{`Allwork.Space: The CXApp was created because technology will be a core building block for creating a low-touch work environment. What are some of the key technologies that will be used/deployed to create such experiences?`}</h3>
    <p>{`Andrea Williams: The CXApp is a digital workplace experience platform, purpose-built to keep employees connected from anywhere, anytime.`}</p>
    <p>{`In a recovering business world, the best approach to ease uncertainties and assure employees of their wellness and importance to the company is to promote contactless engagements in just about every aspect of the workplace.`}</p>
    <p>{`Key considerations for organizations include:`}</p>
    <ul>
      <li parentName="ul">{`Increasing communication`}</li>
      <li parentName="ul">{`Influencing sentiment`}</li>
      <li parentName="ul">{`Reducing physical touchpoints`}</li>
      <li parentName="ul">{`Supporting mobility.`}</li>
    </ul>
    <p>{`Putting a focus back on smart, connected spaces will need to leverage a network of technologies in a workplace ecosystem to create these experiences such as. Some of these technologies are:`}</p>
    <h3>{`Beacons`}</h3>
    <p>{`Indoor maps and location services create powerful use cases for live wayfinding, social distancing, contact tracking and other contextual communications that when used with a mobile app/mobile device can facilitate very important real-time information to users within the environment.`}</p>
    <h3>{`NFC Sensors`}</h3>
    <p>{`Near-field communication (NFC) technology, also pairs nicely with mobile apps to create smart, automated experiences that communicate together to perform a task such as booking a desk or booking a conference room.`}</p>
    <h3>{`Mobile Apps`}</h3>
    <p>{`Mobile and cloud platforms with plug and play connection points create a central hub for streamlined experiences across the workplace.`}</p>
    <h3>{`Allwork.Space: What are some of the main touchpoints that will need to be transformed into touchless by technology?`}</h3>
    <p>{`Some specific use cases include:`}</p>
    <ol>
      <li parentName="ol">{`Book a Desk: virtual desks and hot desking is now being utilized to control capacity limits by floor, building, and campus.`}</li>
      <li parentName="ol">{`Book a conference room: This feature eliminates the need for employees to touch a shared scheduling screen outside of each conference room.`}</li>
      <li parentName="ol">{`Book a Space via Beacon: Employees can book conference rooms by walking into a conference room. The app will detect a beacon in the room and pop a message on their phone, asking them if they would like to book that conference room.`}</li>
      <li parentName="ol">{`Book a Space via NFC: Employees can book conference rooms and desks by holding their phone 2 inches from a NFC sticker or barcode.`}</li>
      <li parentName="ol">{`Conference Room Relay: With a proximity beacon, employee access is recognized in the conference room and can activate the room hardware to begin their meeting all directly from the app (lights, monitors, etc.)`}</li>
      <li parentName="ol">{`Digital Badges: The app responds to access controls at various doorways/access points for no-touch entry and is also used as a way to exchange information with a fellow employee or approved visitor.`}</li>
      <li parentName="ol">{`Mobile Food Ordering: Mobile food services can provide an order-and-pick-up option while supplying individual menu information including pictures, pricing, and nutritional information to employees seamlessly through the app.`}</li>
    </ol>
    <p>{`There are, of course, many use cases that are being uncovered and will be identified as organizations plan their back-to-work strategy.`}</p>
    <h3>{`Allwork.Space: In addition to helping ease fears and anxieties about returning to work, what are some other key benefits of creating a low-touch experience through technology?`}</h3>
    <p>{`The question many are currently asking is: how healthy is your workplace? And that is a very broad and also unnerving question to ask many corporations.`}</p>
    <p>{`The tools and capabilities in place right now are a bandaid, therefore we need to think long term about how to heal and get back into a ‘healthy’ workplace state of mind.`}</p>
    <p>{`A huge value-add starting from day one when using a smart campus app is the data. The data comes into the workplace platform and is not singular to contactless interactions in the workplace.`}</p>
    <p>{`When you aggregate all of these use cases together in one platform, your overall site metrics become extremely robust. This gives operations, facilities, and real estate more insights into how physical spaces are being used and how people move about and interact within them.`}</p>
    <p>{`For example:`}</p>
    <ul>
      <li parentName="ul">{`Hourly, daily, weekly desk usage`}</li>
      <li parentName="ul">{`The ability to know which desks are used and deploy cleaning services to those areas first`}</li>
      <li parentName="ul">{`Entry/exit flows and hotspots across your site`}</li>
    </ul>
    <p>{`These data-driven measures arm the workforce with more insights so they can make informed decisions about how and when they interact with others and enter the workplace. This helps to reduce anxiety and confusion but also influences healthy workplace behaviors.`}</p>
    <h3>{`Allwork.Space: What are some potential barriers organizations may run into when creating a low-touch experience?`}</h3>
    <p>{`First and foremost, not everything can be touchless. That in and of itself is a barrier. There will always be some risk associated with every physical environment and the people that have access to it.`}</p>
    <p>{`But when you take that out of the equation then the biggest sticking point will be cost.`}</p>
    <p>{`Technology and connected devices have a price point associated with them. But you have to think of it less as another app and more of a platform. As with the workplace ecosystem, the goal is to get multiple use cases into one solution, rather than continuing to add solution after solution to the company’s technology stack.`}</p>
    <h3>{`Allwork.Space: Do you think platforms like CXApp will have a high adoption rate from the beginning or will it be necessary to train people on how to use them?`}</h3>
    <p>{`Change is hard; adopting new technologies is challenging.`}</p>
    <p>{`Employees will have to learn how to use an app that comes with a lot of power. It’s easy to do, and very user-friendly, but logging in and understanding what you can do with your app is a challenge every organization will face, regardless of the technology or price point.`}</p>
    <p>{`Simple best practices to facilitate onboarding include: focus on messaging, detail unique features, provide clear instructions, collect feedback, provide ongoing support, and use the app regularly.`}</p>
    <p>{`It’s all about communication and guiding people through onboarding.`}</p>
    <p>{`A smart campus app (digital workplace experience) platform is all about engagement and attracting employees into a central hub for information, news, contactless interactions, wayfinding around your venue, networking, mobile ordering, and other immersive experiences that can be done with the power of mobile-first touchpoints.`}</p>
    <h3>{`Allwork.Space: Anything else you’d like to add?`}</h3>
    <p>{`The future of work is indeed connected. People, machines, devices, physical spaces… it’s all part of a smart workplace ecosystem that has arisen. The new normal will see a prioritization of health and wellness beyond anything we’ve ever seen or experienced, at least for a majority of companies.`}</p>
    <p>{`You have to have the right tools in place… and it all starts (or ends) with smartphones and/or mobile apps. These technologies and types of features that can deliver contactless interactions in an effort to build low-touch environments need to be plugged into the right source and capturing/communicating the best data.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      