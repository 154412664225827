import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2021-02-12T00:00:00.000Z",
  "title": "Future Of Work: 5 Ways To Make Network Security Sexy\n",
  "slug": "5-ways-to-make-network-security-sexy",
  "excerpt": ["Network security is key to protect your space and its members.", "But how can you market your network security to members?", "Education, network speed and adaptability are key."],
  "images": ["5-ways-to-make-network-security-sexy.png"],
  "backlink": "https://allwork.space/2021/02/future-of-work-5-ways-to-make-network-security-sexy/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`In our data-heavy times, `}<a parentName="p" {...{
        "href": "https://allwork.space/2021/02/revealed-how-to-nail-network-security-to-win-members/"
      }}>{`network security`}</a>{` is key for flexible workspaces. In fact, data privacy issues are `}<a parentName="p" {...{
        "href": "https://allwork.space/2020/12/4-major-predictions-for-proptech-in-2021/"
      }}>{`predicted to skyrocket`}</a>{` in 2021. To address these challenges, your workspace needs a killer network security strategy.`}</p>
    <p>{`But do members actually care about network security? Don’t they just base their decision on whether to use a coworking space based on the quality of its coffee, not your network? Of course, it’s more complicated than that.`}</p>
    <p>{`As members start to return to work, your network security could be a competitive advantage – especially with coworking spaces predicted to play a key role in post-pandemic recovery.`}</p>
    <p>{`Bernhard Mehl, CEO of `}<a parentName="p" {...{
        "href": "https://www.getkisi.com/"
      }}>{`Kisi`}</a>{`, said: `}</p>
    <p>{`“The difference here is that these are remote workers who weren’t necessarily coworkers before the pandemic started. As a result, a lot more corporate professionals who used to work in large headquarters have the budget and the need to pay for private spaces, while their companies might pay or at least partially reimburse them for it. This will not only increase security concerns around the network but also require a fully auditable IT on the coworking side.”`}</p>
    <p>{`So, how can you capitalise on your network security to get more bums on seats? We asked the experts…`}</p>
    <h3>{`#1 Educate your members`}</h3>
    <p>{`Education is a must, to help members understand why good network security matters. James Shannon, chief product officer at `}<a parentName="p" {...{
        "href": "https://essensys.tech/"
      }}>{`essensys`}</a>{`, said: “The wifi connection at a coffee shop is not the same as the wifi at a flexible workspace with secure networks and infrastructure in place. Educating staff and occupiers about security risks is critical. Not all threats are visible, so communicating how your tech infrastructure and security protects their business can garner trust and add value to your offering.” `}</p>
    <p>{`If you can demonstrate how you are continuously monitoring your network security, you can also build trust with your members. Shannon explained: “Security is an ongoing process, not a product. Outline how you safeguard their business with your technology in marketing materials and sales conversations to build reassurance in your services.”`}</p>
    <p>{`This is where an adaptive security strategy can help, which constantly monitors and analyses your network to proactively protect you against the latest cybersecurity threats and a. Again, member awareness is key for such initiatives to work, as Adrianne Schoen, creative director at `}<a parentName="p" {...{
        "href": "https://realnets.com/"
      }}>{`Realnets`}</a>{`, explained: “Network security should be a business’s top concern when choosing a shared space, but unfortunately, it’s just not something that they’re aware of. Investing in educational resources about network security could provide a lot of value to companies who are looking to move into these spaces.”`}</p>
    <h3>{`#2 Focus on network speed`}</h3>
    <p>{`For those members who think they don’t care about network security, try winning them over with your network speeds. Zampini added: “Members have always demanded “fast wifi” in the pre-COVID-19 era and we fully expect this to even be higher on the member wish list in the post-COVID-19 world.” `}</p>
    <p>{`“What most members don’t realise is that what goes on behind the scenes to make “fast wifi” a reality can be complicated and dependent on multiple factors, some outside of the coworking provider’s control. We emphasise a partnership with our client’s IT department to bring this to fruition.”   `}</p>
    <p>{`You may want to target your network security prowess to the IT departments of your members too. Tom Zampini, chief product officer at `}<a parentName="p" {...{
        "href": "https://convene.com/"
      }}>{`Convene`}</a>{`, said: “While most members may not care about what firewalls we use, their IT departments do. Promoting what flexible network infrastructure and security options must target IT departments directly with the idea that, ultimately, their users (our members) demand a fast and flawless wifi experience.” `}</p>
    <h3>{`#3 Emphasise flexibility`}</h3>
    <p>{`You should also put measures in place to allow members to use their own devices, securely, while prompting the speed of your network. Tony Richey, a senior IT engineer of `}<a parentName="p" {...{
        "href": "https://mailtrack.io/trace/link/53e3e57490b7463c393994309f6f57bf47e689f0?url=https%3A%2F%2Fwww.officeevolution.com%2F&userId=1384920&signature=cd2a94999cdc6d54"
      }}>{`Office Evolution`}</a>{`, said: “When we look at our members and their technology requests, a very low percentage of prospective members ask for ‘more’ security than what we already provide.  The usual question is ‘can we bring our own equipment in?’  the answer should always be’ we will work with you to get it up and running’.”`}</p>
    <p>{`“So, our service is basically providing an open path to the internet at whatever speeds we can give them (or they pay for) and helping them get it connected.  They still need to configure their own equipment and we assist in racking their equipment and changing cabling if necessary,” Richey added.`}</p>
    <h3>{`#4 Dig the “new normal”`}</h3>
    <p>{`Network security is also just one component of your wider work to adjust to `}<a parentName="p" {...{
        "href": "https://allwork.space/2021/01/how-coworking-is-adjusting-to-the-new-normal/"
      }}>{`the new normal`}</a>{` of our post-pandemic world.  Mehl explained: “Coworking spaces should brand themselves as part of the “new normal” workplace – meaning on-demand safe, quiet and private spaces but part of a community. This is probably the biggest opportunity for coworking spaces to “grow up” and extend business to really any type of professional, while pre-pandemic it only appealed to a certain type of worker.” `}</p>
    <p>{`“The key could be to market the corporate brands paying for the spaces which individual users occupy. Many members will think in a way that ‘If this company is a customer, it’s secure enough for me, too’ since security, in general, is too hard to understand for most. To convince those first corporate customers we will likely see more coworking spaces leverage Managed Service Providers (MSPs) to ramp up on their network and physical security,” Mehl added.`}</p>
    <h3>{`#5 Adaptability is key`}</h3>
    <p>{`Finally, don’t forget that your network security must work for every member. Dale Hersowitz, vice president of coworking at `}<a parentName="p" {...{
        "href": "https://www.yardi.co.uk/"
      }}>{`Yardi`}</a>{`, said: “I would recommend that the space promote network security by discussing the enterprise nature of the security and high availability. It’s also important to mention the ability for the network to be able to adapt the varying needs of each member. It’s important that you can easily accommodate the IT needs of a sole proper as well as a large corporate client.”`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      