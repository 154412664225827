import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-08-09T00:00:00.000Z",
  "title": "Why The Future Of Work Will Play Out Differently In Africa\n",
  "slug": "why-the-future-of-work-will-play-out-differently-in-africa",
  "excerpt": ["A new report discusses why the future of work is likely to play out differently in sub-Saharan Africa.", "It focuses on the adoption of digital technologies in the region and how it could lead to greater growth and innovation across all sectors.", "These technologies have the potential to boost productivity, output, and jobs for workers across multiple sectors."],
  "images": ["24-WHY-THE-FUTURE-OF-WORK-WILL-PLAY-OUT-DIFFERENTLY-IN-AFRICA.jpg"],
  "backlink": "https://allwork.space/2019/08/why-the-future-of-work-will-play-out-differently-in-africa/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`“`}<a parentName="p" {...{
        "href": "https://openknowledge.worldbank.org/handle/10986/32124"
      }}>{`The Future of Work in Africa: Harnessing the Potential of Digital Technologies for All`}</a>{`”, published in July of this year, is a companion report to the “World Development Report (WDR) 2019: The Changing Nature of Work”. In it, Jieun Choi, Mark Dutz, and Zainab Usman argue why the future of work will play out differently in the sub-Saharan region of Africa.`}</p>
    <p>{`The World Development Report 2019 presented that at a global level, the changing nature of work will be primarily determined by “the tension between job losses in ‘old’ manufacturing sectors that are susceptible to automation, and job gains driven by product innovation in ‘new’ sectors.” While this may hold true for advanced economies, Choi, Dutz, and Usman state that this is not what the future of work will look like in Africa.`}</p>
    <p>{`According to them, “the region has an opportunity to forge a different path than the rest of the world.” Rather than displacing some jobs and replacing them with new ones, the adoption of digital technologies in sub-Saharan Africa could lead to greater growth and innovation across all sectors. This is based on the fact that there are no old and new sectors in the region, at least not to the extent that they are found in other global economies.`}</p>
    <p>{`“WDR 2019 predicts that automation will reduce the demand for low- and medium-skilled workers in routine jobs, and that innovation will increase the demand for highly skilled workers. Given the low levels of human capital prevalent in Sub-Saharan Africa, coupled with its large, overwhelmingly informal and low-productivity agriculture and service sectors and small manufacturing sectors, as well as its large unmet demand for social protection, the future of work is likely to play out differently in Sub-Saharan Africa.”`}</p>
    <p>{`The authors provide as an example how the arrival of fast internet in Africa increased jobs for all workers, those with university degrees and those whose highest education level was primary school. Technology, after all, is an enabler, and the above serves as a great example. Take for example digital technologies that can help workers boost numeracy skills, which can help them adopt better business practices or be more strategic with their pricing strategy.`}</p>
    <p>{`These worker-enhancing technologies, as the report calls them, have the potential to boost productivity, output, and jobs for low-skilled and high-skilled workers across sectors. The report argues that there are three main reasons why the adoption of digital technologies on lower-skilled and lower-educated workers may be more favorable for sub-Saharan African countries when compared to other developing or developed countries:`}</p>
    <ol>
      <li parentName="ol">{`Supply-side effects of the sectoral composition of production and employment coupled with the relatively low cost of lower-skilled labor.`}</li>
      <li parentName="ol">{`Demand-side effects of the elasticity of demand to productivity improvements.`}</li>
      <li parentName="ol">{`The likely greater relevance of generating and adopting low-skill-biased digital technologies appropriate to worker needs in the region.`}</li>
    </ol>
    <p>{`For example, automation threatens to displace many workers in the manufacturing industry. In sub-Saharan Africa, manufacturing employment is low, which means that even if automation is adopted, it is not likely to displace many workers. Moreover, the sub-Saharan Africa market is more likely to be responsive to productivity changes than other markets because it is not as satiated; therefore demand is likely to be more adjustable to price changes. Last but not least, the low levels of human capital and high levels of informality in the region means that there is greater scope and possibility to adopt digital technologies.`}</p>
    <p>{`However, in order for sub-Saharan Africa to truly reap the benefits of adopting digital technologies, the region will have to overcome certain challenges.`}</p>
    <p>{`According to the report, the adoption of digital technologies can have a positive effect across all sectors only if the region’s digital, transportation, and electricity infrastructure is improved, as well as if the broader business environment improves and if worker skills adapt to the changing needs.`}</p>
    <p>{`In other words, the region is faced with the challenge to close the gap in digital infrastructure and skills. And this, for its part, needs to be complemented with relevant policies. Such policies might include: “increased investment in skills training; measures to facilitate the productivity upgrading of informal enterprises; and targeted social protection and labor policies.” An effort that needs to be spearheaded by the government and the private sector.`}</p>
    <p>{`The future of work in Africa has the potential to be bright. Digital technologies can help reduce poverty levels, drive inclusion, generate more jobs, and lead to higher incomes.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      