import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-09-20T00:00:00.000Z",
  "title": "Future Of Work: The War For Talent Is About To Get Fiercer\n",
  "slug": "future-of-work-the-war-for-talent-is-about-to-get-fiercer",
  "excerpt": ["Gensler recently published its Design Forecast report, which argues that human-centric design will be key to create experiences that connect people.", "Companies are experiencing increasing pressure in creating unique workplace experiences, especially considering there will be a significant talent shortage by 2030.", "Organizations that are able to harness the power of new technologies will be in an advantageous position because they will be able to create spaces that can anticipate people’s needs in real time."],
  "images": ["32-THE-WAR-FOR-TALENT.jpg"],
  "backlink": "https://allwork.space/2019/09/future-of-work-the-war-for-talent-is-about-to-get-fiercer/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Gensler recently published its `}<a parentName="p" {...{
        "href": "https://www.gensler.com/design-forecast-introduction"
      }}>{`Design Forecast`}</a>{`, in which the company argues that design is no longer about creating beautiful things. Instead, “it’s about creating experiences that connect people to the places where they live, work, and play.”`}</p>
    <p>{`In the realm of work, the changing nature of how, when, and where people work is increasingly redefining the workplace. To be successful in the future of work, companies need to focus on human-centered design in the hopes of making the workplace a satisfying place to be. Technology will play a key role in creating these spaces, specifically data, as it can provide companies with valuable information that can help create workspaces that anticipate people’s needs in real time.`}</p>
    <p>{`More importantly, however, is that companies are aware that the workplace can help them attract and retain talent. `}<em parentName="p">{`Considering there will likely be a global talent shortage of 85 million people by 2030, the war for talent is about to get fiercer`}</em>{`.`}</p>
    <h2>{`Key Findings From Gensler’s Design Forecast, the Work Sector`}</h2>
    <ul>
      <li parentName="ul">{`The next generation of office buildings will empower people to do their best work by integrating artificial intelligence, data, and intelligent building systems.`}</li>
      <li parentName="ul">{`Emerging technologies like sensor technology, cloud computing, bandwidth, and storage have opened the door for office spaces to learn and adapt.`}</li>
      <li parentName="ul">{`It’s all about the experience. “People who have a great experience at work are four times more likely to be in an environment that balances focus and collaboration.”`}</li>
      <li parentName="ul">{`Innovation is a key motivator; it’s “that special sauce that gets companies ahead of the curve”. To drive innovation, organizations must create workplaces that encourage collaboration and chance encounters, as well as foster a culture that prioritizes teamwork and diverse opinions.`}</li>
      <li parentName="ul">{`Coworking has its limits. Coworking spaces are valuable, but most people are using them for less than one day a week.`}</li>
      <li parentName="ul">{`Effective workplaces can only be built when companies have an in-depth understanding of how workers use a space. Companies need to think beyond desks, conference rooms, and call booths and start focusing on how to create environments that connect people to other people.`}</li>
      <li parentName="ul">{`The future if flexible and nimble. “Flexible facilities that change over time with minimal capital expense will help companies compete and stay relevant.”`}</li>
      <li parentName="ul">{`The most important aspect of a great workplace is … team building.`}</li>
      <li parentName="ul">{`Modern workplaces are intelligent workplaces, workplaces that “quickly adapt to new technologies and meet the demands of an increasingly tech-savvy workforce.”`}</li>
      <li parentName="ul">{`Companies need branded workspaces; “branded workspaces create a narrative that connects with people on an emotional level.” Branded workspaces can help attract talent and engage the workforce.`}</li>
      <li parentName="ul">{`It takes more than a paycheck to attract top talent. Workers today expect workspaces where the can excel at their jobs and also feel connected to a greater purpose.`}</li>
      <li parentName="ul">{`Organizations that harness the power of data analytics will find themselves in an advantageous position in the future. “To succeed in a rapidly changing market, design will need to begin with data-driven insights and evolve with real-time learning. Predictability and agility will become the new standard.”`}</li>
      <li parentName="ul">{`“The commercial smart building market for IoT is expected to grow nearly tenfold within the next five years to more than $51 billion globally by 2023.]”`}</li>
      <li parentName="ul">{`The next frontier in workplace design is integrating data into real-time and user-accessible 3D models. This will enhance scenario planning capabilities and lead to operational efficiencies.`}</li>
      <li parentName="ul">{`The workplace will become a key tool to connect people, create a sense of commitment and belonging, and help companies remain authentic in the age of change.`}</li>
      <li parentName="ul">{`“The gold standard for office buildings has shifted.” Companies want to occupy buildings that are part of vibrant communities; this is why mix-used developments are magnets to attract new talent.`}</li>
      <li parentName="ul">{`Speaking about mix-used developments, the most effective ones will be those that “mimic the qualities of urban settings that knowledge workers and millenials want: walkability and access to public transit, retail, restaurants, and a mix of housing options.”`}</li>
      <li parentName="ul">{`To win the war for talent, companies need to also offer the right mix of amenities within the workplace.`}</li>
      <li parentName="ul">{`Wellness has become a core element of effective workplaces. “The cost of unwell workers represents 10% to 15% of annual global economic output.”`}</li>
      <li parentName="ul">{`Healthy workplaces — with fresh air, lots of natural light, with natural elements — can boost engagement and productivity, as well as alleviate stress.`}</li>
      <li parentName="ul">{`One area where there is a significant amount of opportunity is in older, underperforming buildings. Developers should give these properties a personality makeover. “Through interventions like modified entrances, curated amenities, and pedestrian-friendly connections, inward-focused properties can be transformed into inviting places that engage tenants and connect them to each other.”`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      