import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-05-17T00:00:00.000Z",
  "title": "Learnability: The Most Important Skill To Succeed In The Future Of Work\n",
  "slug": "learnability-the-most-important-skill-to-succeed-in-the-future-of-work",
  "excerpt": ["According to one report, 84% of organizations will be in the process of upskilling their workforce by 2020.", "As the war for talent intensifies and job loyalty among Generations Y and Z declines, attracting and retaining talent is becoming increasingly difficult.", "Efforts to ‘build’ talent is one solution, and this is projected to increase by 2020. According to one report, 84% of organizations will be in the process of upskilling their workforce by 2020.", "Embracing upskilling and ‘learnability’ is becoming crucial not just for companies, but also for individuals who face losing their job to automation in the near future."],
  "images": ["11-THE-MOST-IMPORTANT-SKILL-TO-SUCCEED.png"],
  "backlink": "https://allwork.space/2019/05/learnability-the-most-important-skill-to-succeed-in-the-future-of-work/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Technology continues to reshape our notions of work and it will continue to do so for years to come. Digital technology advances have reached a point where people and companies can no longer resist change; it’s an adapt or die situation. One of the main challenges with today’s technological advances is that they’re happening faster than ever and its implications run deeper than ever before. This makes it harder for individuals to change, learn, and adapt.`}</p>
    <p>{`“LEARNABILITY: the ability and desire to quickly grow and adapt one’s skillset to stay employable for the long-term.” – ManpowerGroup`}</p>
    <p>{`But it’s the way of the future and this is why learnability is an essential skill for the future of work.`}</p>
    <p>{`We’ve reached a point in time where continuous learning is necessary in order for people to succeed and remain relevant in the world of work. While in the past tenure was one of the greatest assets of an organization, relying on a narrow skill set today does more harm than good, especially in industries where the nature of work is changing and automation is most likely to take over human jobs.`}</p>
    <p>{`The above makes continuous learning even more of a critical skill. If robots are threatening some human jobs, humans need to learn new skills that will allow them to remain relevant in the future, and they need to do so regularly. Skill sets that are useful and relevant today may be obsolete five or ten years from now. Maybe even less. Your capacity to learn, develop, and hone new skills is crucial to employability and long-term success.`}</p>
    <p>{`It’s not just technological advances that have made learnability necessary. It’s also the fact that work has become increasingly collaborative and many roles require that you have a strong degree of understanding into how different areas come together and work optimally.`}</p>
    <p>{`Say for example you are a content writer. Your strongest skill set should be your writing ability, but then you also need to understand how people consume content today, much of that has to do with design and visuals. You also have to understand how search engines rank content, making it important for you to at least understand the basics of SEO, which involves front-end and back-end web development. Then there’s also the fact that people aren’t just consuming written content anymore; they consume audio and video content as well, which requires that you develop an entire new set of content skills.`}</p>
    <p>{`Upskilling will also allow professionals to interact and collaborate with humans and machines. A report by ManpowerGroup found that “rather than reducing employment opportunities (because of automation and robots), organizations are investing in digital, shifting tasks to robots and creating jobs.`}</p>
    <p>{`At the same time, companies are scaling their upskilling so their human workforce can perform new and complementary roles to those done by machines.”`}</p>
    <h2>{`Fostering a Culture of Learning`}</h2>
    <p>{`Training has always been a key component of businesses. According to Training Magazine, organizations spend between $1,075 to $1,886 for training on each employee annually. However, training tended to be focused on company processes, product or service information, operational issues, etc. Companies are now shifting from a training mindset to a learning mindset.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.manpowergroup.com/workforce-insights/world-of-work/skills-revolution-series"
      }}>{`According to the ManpowerGroup report`}</a>{`, “with talent shortages at a 12-year high and new skills appearing as quickly as old ones disappear, more companies are planning to build talent than ever before, and this is projected to increase by 2020. Companies are realizing they can no longer expect to find just-in-time talent, on tap. Eighty-four percent of organizations expect to be upskilling their workforce by 2020.”`}</p>
    <p>{`Though learnability is an individual skill, companies need to take an active role in their employees’ learnability. There is an ongoing war for talent; providing employees with the necessary tools and resources to reinvent and upskill themselves will help foster loyalty and it can also create a clearer career growth path for an individual within a company.`}</p>
    <p>{`Just as it has become crucial for people to embrace learnability, it’s also important that companies promote a culture of learning.`}</p>
    <p>{`“People need to know how to prepare for high growth roles of the future and that their employer supports their learning. The return on investment for upskilling is clear: in North America the cost of turnover and replacement can exceed 30% of wages, while the cost of training remains less than 10% of wages.”`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      