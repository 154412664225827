import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-12-20T00:00:00.000Z",
  "title": "Future Of Work: 70% Of Digital Transformation Efforts Are Failing – Here’s Why\n",
  "slug": "future-of-work-70-of-digital-transformation-efforts-are-failing-heres-why",
  "excerpt": ["There’s currently a 70% failure rate of digital transformation in organizations.", "A successful digital transformation requires organizational change management and individuals who have the support to re-develop their skills.", "Tony Saldanha, former VP of Procter & Gamble’s Global Business Services (GBS), explains where companies are going wrong."],
  "images": ["48-future-of-work-70-of-digital-transformation-efforts-are-failing-heres-why.png"],
  "backlink": "https://allwork.space/2019/12/future-of-work-70-of-digital-transformation-efforts-are-failing-heres-why"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Digital transformation is a key element of the future of work. However, companies across the globe are struggling to see their digital transformation efforts come to life.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.linkedin.com/in/tony-saldanha-200591123/"
      }}>{`Tony Saldanha`}</a>{`, former VP of Procter & Gamble’s Global Business Services (GBS) and Information Technology and future of work author, argues that one of the reasons why companies are failing at digital transformation is because they’re placing too much focus on digital and not enough focus on transformation.`}</p>
    <p>{`Suggested Reading: “Future of Work: Why Digital Transformation Can No Longer Be Ignored”`}</p>
    <p><strong parentName="p">{`In his recent book, “`}<a parentName="strong" {...{
          "href": "https://allwork.space/2019/08/why-digital-transformation-can-no-longer-be-ignored/"
        }}>{`Why Digital Transformations Fail: The Surprising Disciplines of How to Take Off and Stay Ahead`}</a>{`” proposes a five-stage digital transformation model that can help organizations realize their digital transformation efforts by helping leaders understand where they are and where they want to go.`}</strong></p>
    <h3>{`The five stages proposed by Saldanha are:`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Foundation`}</strong>{`. This stage is where organizations are actively automating internal processes. Though this is automation and not transformation, it does use digital platforms that can provide the digital foundation for future digital transformation.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Siloed`}</strong>{`. This stage is where individual functions or businesses start to use disruptive technologies to create new business models. However, because these efforts are siloed, there is no overall strategy driving transformation.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Partially Synchronized Transformation`}</strong>{`. This stage is where leaders have recognized the disruptive power of digital technologies and they have defined their targeted digital future state. The organization has started rowing in the same direction, although the enterprise has not completed transforming to a digital backbone or new business models.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Fully Synchronized`}</strong>{`. This stage marks the point where an enterprise-wide digital platform or new business model has fully taken root. However, you are still one technology (or business model) change away from being disrupted. The only way to prevent this disruption is by embedding transformation into a company’s DNA.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Living DNA`}</strong>{`. This is the last stage and it marks the point where transformation becomes perpetual within the organization. This requires that organizations understand that digital transformation is not a destination, it’s an ongoing journey.`}</p>
      </li>
    </ol>
    <p>{`This is why the focus should be on `}<strong parentName="p">{`transformation`}</strong>{`. The only end-point and goal can be perpetual transformation, particularly as the pace of technological advancement continues to accelerate.`}</p>
    <p>{`Allwork.Space spoke with Saldanha about why digital transformations fail, how companies can tackle this problem, and what changes organizations will have to make to truly reap the benefits of digital transformation.`}</p>
    <p>{`Saldanha stated that there is a 70% failure rate of digital transformation. To address this, he believes that companies need to be much more disciplined in where they want to go and how they will get there. He argues that the first part of the problem “where to go” is neither easy nor intuitive, especially as understanding of what digital transformation is remains unclear.`}</p>
    <p>{`“In writing the book, I talked to hundreds of executives and one of the questions I asked them was ‘what do you mean by digital transformation?’” Saldanha said. “I was met with hundreds of different answers.”`}</p>
    <p>{`“I believe there is only one way to define digital transformation, and it is in the context of the industrial revolution. We are in the midst of a fourth industrial revolution; in prior revolutions, we basically had technology that changed the way work and life was done, so companies and individuals had to rewire everything they did to accommodate that technology.`}</p>
    <p>{`“This is what digital transformation is; the rewiring of organizational management and individuals to accommodate emerging technologies that are changing the way we work and live.”`}</p>
    <p>{`The second part of the problem, “how to get there” requires that companies focus on organizational change management to guide their digital transformation efforts. This is where the focus should be more on transformation and less on digital. In other words, HR departments should lead the way by nurturing an organizational culture where employees are encouraged to explore and adopt emerging technologies.`}</p>
    <p>{`This requires that companies come up with a digital literacy plan, particularly because artificial intelligence and automation will lead to inevitable changes in employee profiles. Companies need to have a plan on how they will navigate these changes. “It’s unrealistic to think that you will fire all of your employees.”`}</p>
    <p>{`“The fact of the matter is that white collar jobs and blue collar jobs will become more and more automated. The people that used to essentially provide information or do specific tasks will be replaced by people that actually have to write algorithms.”`}</p>
    <p>{`Rather than fire employees, forward-thinking companies will focus on retraining their employees. By doing this, they are focusing on HR issues and organizational culture. These are the companies that understand that the transformation part is key, and it needs to happen at an individual and group level.`}</p>
    <p><strong parentName="p">{`Suggested Reading: “`}<a parentName="strong" {...{
          "href": "https://allwork.space/2019/05/learnability-the-most-important-skill-to-succeed-in-the-future-of-work/"
        }}>{`Learnability: the Most Important Skill to Succeed in the Future of Work`}</a>{`”`}</strong></p>
    <p>{`“Organizations need to transform their employees so that they understand how to use technology to do their jobs differently and so that `}<a parentName="p" {...{
        "href": "https://allwork.space/2019/11/future-of-work-the-rise-of-the-digital-workforce/"
      }}>{`they feel comfortable working side by side with robots`}</a>{`.”`}</p>
    <p>{`Three things that organizations need to keep in mind when retraining employees are:`}</p>
    <ul>
      <li parentName="ul">{`Candidates don’t have to meet all the qualifications when they get hired; organizations need to provide robust on the job training and learning processes. Organizations need to switch from a training mentality to a constant learning one.`}</li>
      <li parentName="ul">{`Organizations need to change the reward system; they should switch from a system that rewards how well people do their work, to one that rewards how well people are learning and developing on the job.`}</li>
      <li parentName="ul">{`Organizations should be careful not to overwhelm employees and they should provide the necessary tools and resources for employees to engage in experimentation learning, and learn to navigate new technologies.`}</li>
    </ul>
    <p>{`At the end of the day, Saldanha’s main point is that digital transformation is more of an HR issue than an IT issue. Companies that are able to successfully carry out digital transformation efforts are those that focus on organizational change management and empower individuals to re-develop themselves.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      