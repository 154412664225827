import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-10-30T00:00:00.000Z",
  "title": "Future Of Work: Experts Share Their Outlook On The Implications For Cre\n",
  "slug": "future-of-work-experts-share-their-outlook-on-the-implications-for-cre",
  "excerpt": ["Leaders from Convene, Industrious, and Tishman Speyer’s Studio shared insights at an online event, ‘The Future of Work and Implications for CRE’.", "The pandemic has accelerated trends that were happening under the surface, including a shift toward giving the individual the power to choose where they work.", "“Delivering a differentiated experience that makes employees happier and more productive is more important than ever before.” – Thais Galli, Studio – Tishman Speyer"],
  "images": ["Future-of-Work-Template-Articles-1280-x-720-12-800x450.png"],
  "backlink": "https://allwork.space/2020/10/future-of-work-experts-share-their-outlook-on-the-implications-for-cre/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Pivoting to meet the new demands of a hybrid workplace, while still providing an outstanding, differentiated experience will be the key to success in the era of Covid, according to a panel of flexible workplace leaders who spoke at ‘The Future of Work and Implications for CRE’, organized by the `}<a parentName="p" {...{
        "href": "https://www.thecrimsonconnection.org/"
      }}>{`Crimson Connection`}</a>{`, an organization grown out of the Harvard Business School Real Estate Alumni Association.`}</p>
    <p>{`Covid accelerated any trend that was happening under the surface over the last five to 10 years in a massive way, panelists agreed, yet the goal of flex space providers remains unchanged.`}</p>
    <p>{`“Our mission is still the same and that’s the most important thing: how can we create a great day at work?” declared `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/ryan-simonetti-5873887/"
      }}>{`Ryan Simonetti`}</a>{`, CEO and co-founder of Convene.`}</p>
    <p>{`The difference is that the experience is now tied into the virtual, and work, wherever it now happens, has altered the landscape. Additionally consumerization of real estate has occurred as power moves away from the company or the buyer to the consumer or end user.`}</p>
    <h3>{`Employees have the power to choose where to work`}</h3>
    <p>{`“We see that this shift toward the individual employee having the choice and power to choose where they work, when they work is really setting the stage for what we’re starting to think about as a hybrid future,” Simonetti explained. He noted that the hybrid experience not only redefined thoughts about headquarters and people’s dedicated spaces, but also third places, virtual remote, and at home.`}</p>
    <p>{`For `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/jamie-hodari-4397897/"
      }}>{`Jamie Hodari`}</a>{`, CEO and co-founder, Industrious, whose firm pioneered management agreements on the landlord side, some things are staying the same, and others continue to transition.`}</p>
    <p>{`“The things that are the same on the occupier side are that this is essentially an outsourcing business. The real name of the game is that you’re going to companies and saying ‘you should let us deliver your employees’ workplace experience for you’. And that’s a tall order,” he said, noting the high bars set by firms such as Airbnb and Pfizer and the challenge of promising a better outcome.`}</p>
    <p>{`Hodari sees the biggest change on the occupier side as an eventual move to utilization-based pricing. `}</p>
    <p>{`“It’s another model that shifts choice to the individual user who signs up for the space they want, when they want to use it. While it’s how many other b2b services price outsourced work — such as Amazon web services — but it’s a very strange way to price real estate,” Hodari said. “This has pulled that future forward.”`}</p>
    <p>{`“The industry was going to get there eventually. It just moved from a 2026 thing to a 2020 thing.”`}</p>
    <h3>{`It’s not Covid, it’s the commute`}</h3>
    <p>{`Citing recent reports from McKinsey and Gartner that show the majority of workers want to go back to the office, panelists acknowledged that at-home and remote will become a bigger part of the future of work, and that does not mean every company will enact 100% remote policies.`}</p>
    <p>{`“McKinsey reports show that 74% of people would prefer a hybrid workweek, where there are days you go to your office and there are days you can work from home,” said Simonetti. “Instead of being on the train at 5:30 in the morning, you’re working out at 6 am. And you’re still on the computer at 8. Don’t think that impact can be an understated impact long term.”`}</p>
    <p>{`“People are getting a sense of a certain balance that they’ve never had before, especially if they have really long and complicated commutes,” he added.`}</p>
    <p>{`A hybrid future has broad implications for supply and demand as well as geographical distribution of where people want to work and live, though panelists agree that virtual is here to stay. `}</p>
    <p><a parentName="p" {...{
        "href": "https://www.linkedin.com/in/thais-galli-0291294/"
      }}>{`Thais Galli`}</a>{`, managing director, Innovation, and Head of Studio, Tishman Speyer’s coworking product, foresees a blended future with flex space comprising an even larger portion of real estate holdings. Shifting portfolios to a mix of 50/50 or 75/25 traditional leasing and flex is a way to deal with the uncertainty, she noted.`}</p>
    <p>{`“I absolutely believe companies will come back to the office,” said Galli, noting that demand for remote locations in residential neighborhoods was coming primarily from individuals, including students and executives tired of working from home, not companies.`}</p>
    <p>{`“In March/April/May people were very nervous about it. Everyone was very comfortable working from home, productivity was not getting hit,” she recalled. “But since July we started seeing the opposite. With the reports that came out, and conversations I’ve had with our members, new hires are having a very hard time connecting. The younger workforce that is not married, doesn’t have kids and lives by themselves, they’re really struggling.”`}</p>
    <p>{`“What will change is how they come back – and what workspace looks like.” Galli sees a hybrid model that means many things: a mix of working at the office, having more days where you work from home, where you have more than one location, ultimately providing more flexibility and options to employees. `}</p>
    <p>{`Galli sees companies using their leased and flex space in different ways. Some want it to be primarily workstations, outsourcing meeting and conference space. Others are planning to create spaces for people to come together for training and to feel a sense of community. Whatever the usage model, Galli sees one constant.`}</p>
    <p>{`“Delivering a differentiated experience that makes employees happier and more productive is more important than ever before.”`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      