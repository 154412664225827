import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2018-11-02T00:00:00.000Z",
  "title": "Gensler: “Designing For The Human Experience Is The Future Of Design”\n",
  "slug": "gensler-designing-for-the-human-experience-is-the-future-of-design",
  "excerpt": ["Gensler’s design forecast, ‘Shaping the Future of Cities’, reinforces the idea that the future of work is all about the human experience.", "It’s about building workplaces that focus on inclusion, community, meaning and purpose; with an ‘always in beta’ approach.", "Here are the 6 main trends that will influence the future of workplace design, according to Gensler’s forecast."],
  "images": ["7-HUMAN-EXPERIENCE-DESIGN.png"],
  "backlink": "https://allwork.space/2018/11/3-technologies-that-will-greatly-impact-the-workplace-by-2020/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`“People are the one constant in this era of dramatic change.” Even though `}<a parentName="p" {...{
        "href": "https://allwork.space/2018/08/what-jobs-would-you-delegate-to-a-robot/"
      }}>{`new technological advances threaten human jobs`}</a>{`, it’s humans who continue to do work today, which is why `}<a parentName="p" {...{
        "href": "https://allwork.space/2017/08/why-workplaces-need-to-provide-more-than-just-cool-tech/"
      }}>{`Dr. Marie Puybaraud’s words remain true`}</a>{`: “workplaces must first and foremost still cater to the people who work in them.”`}</p>
    <p>{`Gensler’s recent Design Forecast, “`}<a parentName="p" {...{
        "href": "https://www.gensler.com/research-insight/publications/design-forecast/shaping-the-future-of-cities"
      }}>{`Shaping the Future of Cities`}</a>{`” reinforces that the future of work and workplaces is human; `}<a parentName="p" {...{
        "href": "https://allwork.space/2018/09/the-future-of-work-is-about-fostering-community/"
      }}>{`it’s about fostering community`}</a>{` and inclusion, and creating meaning and purpose. It’s time we rethink and reinvent the spaces that surround us and transform them into unique experiences for each and every user.`}</p>
    <p>{`“More than ever before, there is an opportunity to create a better world through people-centered design.”`}</p>
    <h3>{`Top Trends in Work and Workplace`}</h3>
    <p>{`“We envision a future where workplace design is directly connected to outcomes for people, environmental performance, and business intelligence. It begins and ends with your talent.”`}</p>
    <p>{`Talent acquisition and retention has become one of the top priorities for business today. Technology and globalization have made it easier for people to compete with each other not only locally, but also globally. This means that companies who want to attract the best talent need to offer more than just competitive compensation.`}</p>
    <p>{`This war on talent, in large part, is responsible for the shift in the way companies think about their work experience. There has also been a change in workforce demographics, increased awareness on the importance of wellbeing, and continuous technological disruptions.`}</p>
    <p>{`All of the above have changed the way people do work. In order to support and drive these new ways of work, the workplace must also change and evolve.`}</p>
    <p>{`Gensler identified 6 main trends that will shape the future of workplace design:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Technological advances require an “always in beta” approach (in the workplace)`}</strong>{`
People and organizations should look to technology as an enabler. By harnessing the power of technology, organizations can create workplaces that are agile, smart, and provide useful data that will enhance the worker experience. Moreover, people in workplaces who have the latest technology report a great experience 3.7 times more often than those who don’t.`}</li>
      <li parentName="ul"><strong parentName="li">{`Competition for talent remains tough; getting tougher`}</strong>{`
Companies need to think about the different ways in which they can add value to their people. Individuals today are eager to find meaning in what they do, what they get involved in, and what they work on. Through design and work, organizations can create a mission and purpose that their employees and customers can relate to. Gensler’s 2016 US Workplace Survey found that “employees are four times more likely to attribute job satisfaction with organizational mission rather than compensation.” Organizations need to become enablers not of productivity, but of meaning.`}</li>
      <li parentName="ul"><strong parentName="li">{`Experience will be the reason people come to the office`}</strong>{`
According to Gensler, three factors influence experience at every organization: culture, technology, and the physical workplace. Organizations need to think about both their physical and digital experience; finding a balance between those two that bridges the best of both worlds.`}</li>
      <li parentName="ul"><strong parentName="li">{`Inclusivity drives business performance`}</strong>{`
“Diversity, inclusivity, and knowledge are consistently among the most valued qualities across all generations.” Fostering an environment of authenticity that’s inclusive will help people perform better individually and in teams — which also translates into more opportunities for innovation.`}</li>
      <li parentName="ul"><strong parentName="li">{`People want to be where the action is; that’s in the city`}</strong>{`
According to the report, workers value being a part of the urban core. The best workplaces are those that are able to leverage assets that already exist in their communities by creating local partnerships. Location will become a determinant on whether people go to the office or not — for workspace operators, this means their location can be a deal breaker or maker for prospects.`}</li>
      <li parentName="ul"><strong parentName="li">{`Coworking is in — but not without branding and culture`}</strong>{`
As corporate coworking continues to grow, it’s imperative that coworking operators think about ways in which their members can retain their brand and culture within their locations. If operators are unable to provide this, companies “will risk losing strategic opportunities to reinforce their brand, culture, and corporate mission”, which will drive corporates away from coworking spaces.`}</li>
    </ul>
    <p>{`Gensler believes that “the drive to increase results through innovation is directly tied to the work environment.” As the lines between work and life continue to blur, it’s important that we create spaces that support a wide range of behaviors and that serve multiple functions without requiring hard construction or new furniture; workplaces need to be dynamic.`}</p>
    <p>{`Environments today also need to tap into our values, and our emotions.`}</p>
    <p>{`A word of advice: `}<strong parentName="p">{`to differentiate yourself, you need to establish an emotional connection. This applies to businesses across sectors`}</strong>{`.`}</p>
    <p>{`“The highly competitive nature of the marketplace for talent (or flexible workspace members) drives the need to make the workplace a differentiator. This creates an imperative for a ‘wow’ moment: a deliberate design that evokes a visceral emotional connection with staff and clients.”`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      