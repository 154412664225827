import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-09-10T00:00:00.000Z",
  "title": "Flexible Working Regulations Aren’t Benefiting Women In Scotland, Report Finds\n",
  "slug": "flexible-working-regulations-arent-benefiting-women-in-scotland-report-finds",
  "excerpt": ["A recent report by Close the Gap has found that the right to request flexible working has had very little impact.", "According to the report, home working merely increased from 4.0% to 4.9% and flexitime rose slightly from 12.0% in 2010 to 12.3%.", "The lack of jobs that are openly flexible places women at a particular disadvantage, especially those returning to work after maternity."],
  "images": ["31-FLEXIBLE-WORKING-REGULATIONS.jpg"],
  "backlink": "https://allwork.space/2019/09/flexible-working-regulations-arent-benefiting-women-in-scotland-report-finds/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Close the Gap, a charity focusing on female participation in Scotland’s labour market, has revealed that more needs to be done to improve women’s access to flexible working.`}</p>
    <p>{`Published earlier this year, the `}<a parentName="p" {...{
        "href": "https://www.closethegap.org.uk/content/resources/Flexible-Working-for-All.pdf"
      }}>{`Flexible Working for All`}</a>{`? report analyses how employees in Scotland utilised flexible working between 2010 and 2015, and evaluates the impact of the UK government’s decision to extend the right to request flexible working to all employees.`}</p>
    <p>{`Essentially, the study finds that right to request (implemented in 2014 with the aim of making flexible working more accessible) “`}<em parentName="p">{`has had very little impact on the uptake of flexible working specifically, and therefore gender equality at work more broadly.`}</em>{`”`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "734px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d9ab7ec6e841b5ae3cbfb7147b40ed3f/c6d67/31-img-1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "68.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAD4UlEQVQ4yyWT6VPTVxSGf19sEcJerMOwlLDTsC9CEsMOEqISGrAsQgIWZJEEMOwFwSxVSAOC09riYMW12tqxUrs4VNCKWG1Fijrt9G95eqUfzsy9c8+8c8/zvkdyNZUx06ZnqrWST9urcbVVMd1Vx5keI+5uE5PmRmzHTNgtzbj72+k7oMK8X8210RYWnVZOdtYzYjzIQG0p5spcpKkmLZkKOQmKWFTKTLKyUpHLQwkODiI5WYFGo6J0XwlxCbFUFuUwfqiALp2Kb22duIYtzJz9jNtLP/HjvRV6+weQ3Ed0pMS/x+fzC6w/+YN7yyso1dns9NrB8MgYy8u/8cvP9+nt66OlycitS5e5MOvm5kQb7hEz39y6zcbGFltbf3Pq9Gmk6WYtafHhLCxe4bl4ePjwMZpcFX7+XnR1mbFN2HHY7LR81ET1oWrsdifn52a4OlCPSyC4fO0Gj9ef8efzv7A7HEjOukJSYkP5cmGRp882WP51lb0aJd4+OzkxMcH3d+5yR1SXxUJxdhJ9hnyay1RctFQxaW3dFlxZffS/oNOJNGZQkxgVzPyFRV5svuL3pxuo9ubgKXuL6TOzbL38h83N11gHh9DmKJisL8JUkMaipZK5UQvXb37HgwdrYrqXTNhsSKN6JYrIYM5fuMIPS3cxNtSRu1eJh+cOTk9ObXNdW3tCt9VKSWY8byZqzE9jbrCT1sY6Ll+6yv3VNVaEqM0hfjisV5OWECnczaCifB+1xRoaDBUEBvkJJp9s81l7tM7RzmOUKxOxVWmoyIrDcewI/n5eNNZUcWJsjOgYOWq1EqmnOEm4HEGcPBhTpQ7DAR056UmUl+TjPDXJq9f/svHiJeaeHgqSI+goTKIsNQp7czWl6e8z3G/lrNtNYUYiep0WaaLHjC4njbiI3RgKROY02Xh5v40mO2NbcMp1ius3bmAbHyUtzJ+DRXkc1uZzvCyd9opi+o8PM+OaRZMcTb/RgKQu0hITKUeTv5/oqEg06QqBIIIDeSqsAyM0GY2c+2Ieu4hOdNBOxk+6GBscYag0iQ7BW5mdw9CIkyMfGtifHom0K9Cb8LBgUrPLyFVp6GhpJVYeQkJ8DHtEs8nYwtWvl5icu0h3v4PpczdxifOY9WOMNXUU5OaJbcqno62X9g9KkFKiQlBEheLr70N4eCghYSHIfD3FXSay6EFQkD8Npjbs7q/otIzS3mikrf4wQ0ePMlxbQVZqIoHv+LInI4OWGj1SQKAPMhFiHyEiEwIybw98hXtvate7AQSIZi+xhnkCTe/QFPqKBkpzSyhMjqU4NYGgXf5C0IfQ0N1kpGfyH1avWh8LnhQzAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "31 img 1",
            "title": "31 img 1",
            "src": "/static/d9ab7ec6e841b5ae3cbfb7147b40ed3f/c6d67/31-img-1.png",
            "srcSet": ["/static/d9ab7ec6e841b5ae3cbfb7147b40ed3f/772e8/31-img-1.png 200w", "/static/d9ab7ec6e841b5ae3cbfb7147b40ed3f/e17e5/31-img-1.png 400w", "/static/d9ab7ec6e841b5ae3cbfb7147b40ed3f/c6d67/31-img-1.png 734w"],
            "sizes": "(max-width: 734px) 100vw, 734px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Close the Gap works with policymakers, employers and employees to address gender inequality in the workplace.`}</p>
    <p>{`The report finds that generally speaking, the uptake of flexible working in Scotland changed only slightly over the entire five year period. Individual types of flexible working also experienced some change.`}</p>
    <p>{`For example, home working increased from 4.0% to 4.9% and flexitime rose from 12.0% in 2010 to 12.3%, with a broadly even gender split.`}</p>
    <p>{`There were slight decreases in other types of flexible working – including those more likely to be used by women. Job sharing fell from 3.8% to 2.7% and the number of people engaging in term-time flexible work arrangements went from 1.7% in 2010 to 1.1% in 2015.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "542px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/43261a0539c5aee01962015f533d0d24/c0388/31-img-2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "81%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAADOUlEQVQ4y22RyWsTURzH5w/yqqgnDyJ4ccWDJ8GLVvGgB1HbKNaqCCquoGK1LriiWBRrG9o0yaSZLJNM0uzzkqlZZs1SL+2l8PX3XhJU8PDl9/0tb95nfk96+XkGvluPcfnuJCbuT+HKvWcYJ03cf45rD1/g8p2nonft4ZSoX30wJXrDPs+5Rm8+wqeZBUg3nrzF4dPjOHruBo77buHQqUvYc+w89o+M4cAJH/Yeu4B9x0dFvm9kFAco7qcoagMdPOnDriNncHvyPaTm5AgqZzeBXdwKfWyLEONxdHNfokbRtw36xe3Qx3eAXd9N+VawiZ19T/WybzvsL9chaYkA/N9eYdH/AYHZd5ifedPX9zeY+/qK/FvRjyxOI5XwI+D/iND8Z6ixWczPvkc4MA01PofZb6+RzyqQGpUy8jEFRm4Z7aoOVy8LeWzgWQUOz6sMvfoK9LSKakZD76eBsprECp3rks8rS7CMGhEWS/BHlhCIxaFW9D8qVwZxIPJpnSGopsTs0MtpTXh/NIYcRalVLmBZDoCpCtp6Ad5/lUebFdCpldDKa6im4ujWyqhniTadQM8gn0nCqulEWCjiRzCMUFJFhtUEWYpo/hERpilmqjXIqTTCfJZ8KJFEhHLuF5QYCqwKqV7KIUOEOSWMkhqjPZZod4X/qkNUNS2BUjIqvE5/pafj6BgVlGIyWqzMd1jEXCiMeTkCfzgCJZOFVmFEVUF6QDZUlkg4UTCeEH5JyyBCe1yuGliIKsjTDBHmiXARuWiYKGUUE1F61YIg9f6mZUW0icqgXZWSiqDi+9NJgjAeQZMTZosF+IkwSDsI080LsoyQokCOxyHTjjQi5crQ7cu0o6imIZJMCr9EdFEizhFtkF6+yPgrd7pgtouq7fVluWBCDnTTxkq7h5+dVRhuR/gVr4ua00aj+0vUDLfvGZ21uquQOq4DxzLh2TZa9TocswWbZDYasFstkVvNhpBtmhSbQmajPqg3xTmT1HY9SG3Pg+M4Qh5513XhkWzbopor6rZlw7Is0Xds7m3q92XSJUPfWyXC/pAjBvlhPsA/6vILKPJ8KF7jH7ZMS5wZXr62tob19XVsbGzgN8FEME5FiXW3AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "31 img 2",
            "title": "31 img 2",
            "src": "/static/43261a0539c5aee01962015f533d0d24/c0388/31-img-2.png",
            "srcSet": ["/static/43261a0539c5aee01962015f533d0d24/772e8/31-img-2.png 200w", "/static/43261a0539c5aee01962015f533d0d24/e17e5/31-img-2.png 400w", "/static/43261a0539c5aee01962015f533d0d24/c0388/31-img-2.png 542w"],
            "sizes": "(max-width: 542px) 100vw, 542px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Proportion of employees in Scotland using formal flexible working.`}</p>
    <h2>{`Other key findings from the report`}</h2>
    <ul>
      <li parentName="ul">{`According to the report, part-time work continues to be more popular with women than men and there is little sign of this changing in the near future.`}</li>
      <li parentName="ul">{`70% of women in Scotland said their workplace offered formal flexible working options following the right to request an extension in 2014 (less than three quarters).`}</li>
      <li parentName="ul">{`Over 50% felt they had access to some form of informal flexibility, while 20% said that neither formal nor informal flexibility was available to them at their workplace.`}</li>
      <li parentName="ul">{`Interestingly, the gap between flexible working in the private and public sector is slowly narrowing – with small increases in the private sector and slight decreases in the public sector.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "545px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c61189c3ee77ef91c4656f1919680adc/3ddad/31-img-3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAADCElEQVQ4y02QS28bZRSG5yexRy2ILkECfgEbNohC1SJEHQepi1IoQhVIgSAqBCx6iRMnTZrYqZO0sV0743tsx54Zjy9jz9hxEt9aKdDWl4fPnwLq4tF7znveb+boKLfu+Pnyp7vM/OIRLOISzMwv8dWcB9e8V3rTenZ+EbfIuH72cHVugU9v/skn3/0huSjqj679yuKWihL68SIbV9/D7/4Av+td/JfP47/yNr7Lb7Fx6Ry+Ly4I3mFzOne/z6PZD6U+vHSetc/eZO3zc6xducDSx29wsDqPUtv6Dc17A3P1e/Sl6xTvuNDvzWLc/xrrwTfYvh8oLV6jLObGyk305RtCv0W750a7OyOybhz/LcoLbo6zARRv2mBuO8HtYIa/4gaeYpOFgiNZqXRZt/p4jTZe4f8eznI7NM3pLBzY3D9jvdZlwTgi0x6gWNkkWuQJJTWEoQYx408pxcJ0jBxdQc/MY6VV6vsxOTf2gmIeopyIiGwYJ59kUCnSzMboOhbKUUalsuvHCgU4SYZ5qac5PYgzKu0zNrOMK3leGRn+LqawozuYj3201Cf8I/oXWkrOJtUCQy3J6MhB2cnk8ASjLEdiPIgmWFOTLD9VWVVTbGtlgqUaD+MZfMksKyKzEonL+VJoT9ZTfVw0WU/nyVkNlHS5RqRoEBX8p3t6CVU3hZrES2XZRw40NiMqATXOZlRlIxRhO55CNUySZoVwQafWPkZp5DMUxQ21aFCwSy60w7GWpW8W6JXy8j72foKGQN8LoYucoYblm8NChkG5wLOqjiNufFyvouSaR6hWk0S9RVzSRK05xCxH+M6ZL7xKnUAqSyCdYyuT51Fyn7BRlblko8WuOI/RbKM8e/6cTq9Hp9ujPxjQ7fc56XToCe10u5IXr4YMRyNG4wnD8Vgi69H4jJH0xpMJSt2ysASNukW1UhFapyZ06tuNOlbNouU4NJ0m7cMWju1g2w1skZt6h8KzGw0ckemJxZTOyQmnp6dMxNeHQ7HF9G/DodTxdJPX+qnKTV/z/vfP+n8BnzxsWJSQWUYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "31 img 3",
            "title": "31 img 3",
            "src": "/static/c61189c3ee77ef91c4656f1919680adc/3ddad/31-img-3.png",
            "srcSet": ["/static/c61189c3ee77ef91c4656f1919680adc/772e8/31-img-3.png 200w", "/static/c61189c3ee77ef91c4656f1919680adc/e17e5/31-img-3.png 400w", "/static/c61189c3ee77ef91c4656f1919680adc/3ddad/31-img-3.png 545w"],
            "sizes": "(max-width: 545px) 100vw, 545px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Learnings from the report`}</h2>
    <p>{`According to Close the Gap, the report exposes the UK right to request regulations’ limitations – it is not proving as successful as intended.
Too few jobs are openly flexible and only 12% of jobs with a salary exceeding £20k are advertised as having flexible options, which particularly disadvantages women returning to work after maternity leave, etc.
Currently employees must be in a role for at least six months before they can request flexible working; this disadvantages employees on short-term and temporary contracts.
As it stands, employers can choose from eight fairly broad business reasons to reject a request, making it relatively easy for companies in which there is a cultural resistance to flexibility to turn down a request.
Reasons to reject include things like planned structural changes, the inability to recruit additional staff and a detrimental impact on performance.`}</p>
    <h2>{`Close the Gap’s recommendations`}</h2>
    <p>{`Close the Gap puts forward 10 recommendations that employers in Scotland can use to improve flexible working opportunities for their staff.
These include:`}</p>
    <ul>
      <li parentName="ul">{`“Map the distribution of workers who work part-time and/or flexibly across the organisation to identify whether a lack of flexibility and quality part-time work is preventing women from progressing, or from doing stereotypically male jobs.”`}</li>
      <li parentName="ul">{`“Consider using the Family Friendly Working Scotland strapline, ‘Happy to talk flexible working’ when advertising jobs.”`}</li>
      <li parentName="ul">{`“Large employers should use Close the Gap’s `}<a parentName="li" {...{
          "href": "https://www.closeyourpaygap.org.uk/"
        }}>{`Close Your Pay Gap`}</a>{` online tool to identify how changes to their working practice can reduce their gender pay gap.”`}</li>
      <li parentName="ul">{`“Deliver training to all line managers with responsibility for decision-making on flexible working, including implementing the policy, managing flexible working requests and the business case for flexible working.”`}</li>
    </ul>
    <p>{`Close the Gap’s report comes at an interesting point in the UK’s conversation around flexible working; two months ago MP Helen Whately called for flexible working to be made `}<a parentName="p" {...{
        "href": "https://allwork.space/2019/08/how-the-uks-new-flexible-working-bill-could-impact-flexible-space/"
      }}>{`mandatory for all employers`}</a>{`, rather than it being up to the individual employee to request.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      