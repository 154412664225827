import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-08-23T00:00:00.000Z",
  "title": "Future Of Work: The Booming State Of The Proptech Industry\n",
  "slug": "future-of-work-the-booming-state-of-the-proptech-industry",
  "excerpt": ["According to CRETech, the PropTech sector is “skyrocketing” as multi-billion dollar investments are pouring in.", "During the first two quarters of 2019, PropTech companies raised $14 billion, a 309% increase from the first two quarters of 2018.", "Here we take a closer look at 13 PropTech deals from August alone, which demonstrates the increasing strength and diversity of the sector."],
  "images": ["28-THE-BOOMING-STATE-OF-THE-PROPTECH-INDUSTRY.jpg"],
  "backlink": "https://allwork.space/2019/08/future-of-work-the-booming-state-of-the-proptech-industry/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Earlier this month, CRETech published its PropTech 2019 Mid Year Report, highlighting a year for the record books. The PropTech industry is booming with investment, and Michael Beckerman, CEO of CRETech believes the industry has reached a tipping point.`}</p>
    <p>{`According to the report, “at the mid year point of 2019, the pace of venture capital investing has skyrocketed to record setting highs. Surpassing 2018 totals.”`}</p>
    <p>{`During the first two quarters of this year (2019), PropTech companies raised $14 billion, a 309% increase from the first two quarters of 2018. This, despite the fact that there was a 13.7% decline in the total number of companies funded.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "734px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b592a7280c9ecf0d7c703b0752917d76/c6d67/28-img-1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "68.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACFElEQVQ4y6WTS2sUQRDH5zN4EG9CvoIHEQ8hgpIv4MWDREHwgRAvBhMMAXMS9CDoUUQP4kkEBUHQXfPaTVhFMQbXYAzksdnHvKd7prt3/nb3zM7OxM1FG4qunqr5TXXVfwz8w4rj+MCYsddsYWt7B2q3bBs7jQaarTZMy0a705HnPZjyuWlZ6JgmAkIy6CAz1n7UUZ5fxEJ1GWv1n6jWatJfwdxSBV++rWJR+soqKzWUF5bwXear1e12B1f4P1dmIkDIAlk1heN5+iOGCipHWd+Xe+rnV/+cAAlrwaNttE0bW7u7ED2gClNK4Xqu3oUQYIzrl+obG5h58BAv3rzVZ865jgvBofhxt/8B3UPblZAwRBSFsGXzCQn0C1EU6aR3sm+Hjp3E2M2p5JqMZVC194xxnlzZdh34AZGJ0UBgSQ5r6NQors3MFoCDTANd39NSUImOhBNKCsAPlSqODp/Blek7BSDjIbhgWU//AiqAZVsIgiDtodCpH6ufMTQyiqtphSqP82Q4v343cfb6DUzcvZcN1vAlgNBQX9lxZIUSrhrtUxO+WMer0kscPj6Mi7dua4icBUx/E19bj/C8PIsjJ0ZweuySrjKTTW96asKEBnpo2/YnvN8cx7PSFM6NT+L+4yeJdGThDXcVr9fP4+n8ZVyYmNYqyCpM510Qbd7yWuw9S1WSSqYYM/YDDvoz9sfinPbysT+PuxraDNCXSQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "28 img 1",
            "title": "28 img 1",
            "src": "/static/b592a7280c9ecf0d7c703b0752917d76/c6d67/28-img-1.png",
            "srcSet": ["/static/b592a7280c9ecf0d7c703b0752917d76/772e8/28-img-1.png 200w", "/static/b592a7280c9ecf0d7c703b0752917d76/e17e5/28-img-1.png 400w", "/static/b592a7280c9ecf0d7c703b0752917d76/c6d67/28-img-1.png 734w"],
            "sizes": "(max-width: 734px) 100vw, 734px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Investment in PropTech companies in the first 2 quarters of 2019 has surpassed totals from 2018; average deal sizes have increased by 50%.`}</p>
    <p>{`In the report, CRETech identifies 5 trends shaping the nascent industry:`}</p>
    <ol>
      <li parentName="ol">{`Surge in Dollar Volume`}</li>
      <li parentName="ol">{`Decrease in Deal Volume`}</li>
      <li parentName="ol">{`Corporate Venture Capital (CVC)`}</li>
      <li parentName="ol">{`Goldman Sachs`}</li>
      <li parentName="ol">{`VTS Becomes a Unicorn`}</li>
    </ol>
    <p>{`PropTech activity from this month alone (August, 2019) is a clear example of the booming state of the industry. Some recent notable deals include:`}</p>
    <ul>
      <li parentName="ul">
        <h3 parentName="li">{`LIFENEST partnership with Apple`}</h3>
        <p parentName="li">{`London-based PropTech company, LIFENEST, announced this week a collaboration and brand partnership with Apple. Thanks to the partnership, LIFENEST users will have access to discounts of up to 20% on Apple products.`}</p>
      </li>
      <li parentName="ul">
        <h3 parentName="li">{`FilmoRealty Partnership with Microsoft`}</h3>
        <p parentName="li">{`Nigeria-based real estate services company, FilmoRealty, partnered with Microsoft to accelerate the development of tech-driven real estate solutions. Through the partnership with Microsoft, the PropTech company is better positioned to execute its tech-focused business strategy.`}</p>
      </li>
      <li parentName="ul">
        <h3 parentName="li">{`Really Raises $360K in Seed Funding`}</h3>
        <p parentName="li">{`Singapore-based Really raised around $360K in seed funding from Huntington Partners and angel investors. The company will use the funds to develop its app and marketplace, as well as to expand into new markets including Malaysia and Indonesia.`}</p>
      </li>
      <li parentName="ul">
        <h3 parentName="li">{`JLL Partnership with Invest India`}</h3>
        <p parentName="li">{`JLL and Investment India have partnered to create “JLL IDEAS”, an India-based real estate PropTech accelerator. The organization reportedly has plans to announce a series of PropTech initiatives, starting with a PropTech hackathon.`}</p>
      </li>
      <li parentName="ul">
        <h3 parentName="li">{`COSI Raises €5 million`}</h3>
        <p parentName="li">{`COSI, a Berlin-based PropTech company raised €5 million in seed funding before its official launch. According to reports, the company is focusing on assembling a strong management team before officially launching its hospitality service.`}</p>
      </li>
      <li parentName="ul">
        <h3 parentName="li">{`ZenHomes Raises $14.5 Million`}</h3>
        <p parentName="li">{`ZenHomes, also a Berlin-based PropTech company, raised $14.5 million in a Series A funding round. The deal brings the company’s total fundraising to $21 million. Investors include EWE< Signa Innovations, Momeni, Swiss Life, Deutsche Bank, and Axa.`}</p>
      </li>
      <li parentName="ul">
        <h3 parentName="li">{`Soho Raises $1.6 Million`}</h3>
        <p parentName="li">{`Launched in 2017, Soho is known as LinkedIn for property, as it lets owners and real estate agents create a profile for properties up for rent or sale. Recently, the company raised $1.65 million from BridgeLane Group, bringing its total capital raised to $2.65 million.`}</p>
      </li>
      <li parentName="ul">
        <h3 parentName="li">{`99.co Raises Millions in Series B Round`}</h3>
        <p parentName="li">{`Singapore-based 99.co raised $15.2 million in a Series B funding round led by MindWorks Venture and Allianz X. Other company investors include East Ventures, Sequoia India, and Eduardo Saverin. The PropTech company will reportedly use the funds to extend its reach in current markets, expand into new ones, and launch property-related financing products.`}</p>
      </li>
      <li parentName="ul">
        <h3 parentName="li">{`Alquilando Raises $1.1 Million in Pre-Series Round`}</h3>
        <p parentName="li">{`Argentina-based PropTech startup, Alquilando, raised $1.1 million in a Pre-Series A funding round. In 2017, the company won Argentina’s edition of the Seedstars World competition and it represented the country at the Seedstars Summit in Switzerland.`}</p>
      </li>
      <li parentName="ul">
        <h3 parentName="li">{`VTS to Expand into Toronto`}</h3>
        <p parentName="li">{`The New York-based PropTech unicorn is growing its Canadian operations with an engineering hub in Toronto. In May of this year, the company raised $90 million in a funding round led by Brookfield Asset Management Inc., taking the company’s total capital raised to $197 million and reaching a valuation of $1 billion.`}</p>
      </li>
      <li parentName="ul">
        <h3 parentName="li">{`Properly Raises $22 Million`}</h3>
        <p parentName="li">{`Properly, a Toronto-based PropTech startup closed a $22 million Series A funding round. The round was led by Prudence Holdings, and the company plans to use the funds to expand within Calgary and other Canadian cities.`}</p>
      </li>
      <li parentName="ul">
        <h3 parentName="li">{`Clicbrics Raises $3 Million`}</h3>
        <p parentName="li">{`The Delhi-based PropTech platform raised $3 million in a Pre-Series A funding round. According to reports, the company will use the funds to fuel its expansion in the Indian market, focusing specifically on Tier I and II cities.`}</p>
      </li>
      <li parentName="ul">
        <h3 parentName="li">{`Propzy Prepares for Series B Funding Round`}</h3>
        <p parentName="li">{`Vietnam-based PropTech startup, Propzy, is planning to raise $25 million in a Series B funding round. The company aims to raise funds in order to develop a platform that includes mortgage banking, as well as a tenant access platform. So far, the company has raised a total of $12 million.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      