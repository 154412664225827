import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-12-05T00:00:00.000Z",
  "title": "Future Of Work: Encouraging Diversity And Retaining Talent In 2020\n",
  "slug": "future-of-work-encouraging-diversity-and-retaining-talent-in-2020",
  "excerpt": ["At Web Summit 2019, a panel featuring tech and automation experts revealed a number of ways in which companies can improve talent retention and diversity.", "One benefit is hiring remotely; the absence of office politics means people’s relationships with other coworkers focuses on getting the work done.", "Research shows people are happy to delegate repetitive work to robots; one company who did this saw a 40% increase in productivity and customer satisfaction."],
  "images": ["45-future-of-work-encouraging-diversity-and-retaining-talent-in-2020.png"],
  "backlink": "https://allwork.space/2019/12/future-of-work-encouraging-diversity-and-retaining-talent-in-2020/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The challenge of attracting and retaining talent has dominated conversations around the future of work this year, with many companies finding it difficult to attract the people they need to achieve growth. With this in mind, how can businesses prioritise diversity when it’s a struggle just to find one person qualified for the job?`}</p>
    <p>{`One of the panel discussions at Web Summit 2019 honed in on this topic. In the video below, four experts share their experiences and provide tangible tips for attracting talent, embracing diversity and building software for a modern workplace environment.`}</p>
    <h3>{`About the Panellists`}</h3>
    <ul>
      <li parentName="ul">{`Sarah Nahm, CEO & Cofounder at Lever: Lever builds modern hiring software that helps companies take a more strategic and outbound approach to recruitment.`}</li>
      <li parentName="ul">{`David Yang, Founder at Abbyy: Abbyy is an AI company leading in process intelligence and IQ. They empower software robots and have 50 million users in 200 countries.`}</li>
      <li parentName="ul">{`Polina Montano, Cofounder and COO at Job Today: Job Today is a mobile marketplace for casual jobs; it connects employers with locally available job seekers. It has processed 100 million+ applications since 2015.`}</li>
      <li parentName="ul">{`Yancey Spruill, CEO at DigitalOcean: DigitalOcean is a developer cloud that enables developers to access a portion of the company’s servers to, for example, start a website, build a blog or test an idea.`}</li>
    </ul>
    <p>{`Watch the panel discussion in full here:`}</p>
    <p><a parentName="p" {...{
        "href": "https://vimeo.com/371370913"
      }}>{`Future of work`}</a></p>
    <h3>{`Moving Past the “sabre-rattling” of Demographics`}</h3>
    <p>{`Nahm scaled Lever with a 50/50 gender balance — an extraordinary feat when you consider that in the UK, `}<a parentName="p" {...{
        "href": "https://technation.io/insights/diversity-and-inclusion-in-uk-tech-companies/"
      }}>{`only 19 percent`}</a>{` of tech workers are women. She admits that, as the only woman in the company for the first two and a half years, achieving parity wasn’t easy.`}</p>
    <p>{`She believes that 2020 is going to be about bringing diversity into everyday operations as opposed to talking about demographics in a “big picture” way.`}</p>
    <p>{`She explains: “So many companies are talking about these big, lofty goals that are about the actual demographics of their company but that’s really daunting. For us it was about taking diversity from the demographics to the day-to-day; breaking it down and giving people much more local goals.”`}</p>
    <p>{`She encourages businesses to implement “mirco-inclusions” by considering who’s running meetings and making decisions, and how recognition and reward is managed.`}</p>
    <p>{`Cumulatively, small moments can help shape a company’s culture, and ultimately “that’s how you build upward mobility and diversify thought.”`}</p>
    <h3>{`Accessing Different Perspectives with Remote Teams`}</h3>
    <p>{`Initially, Spruill found himself hiring remote workers because “it’s difficult to hire and retain talent at the rate you need to grow a large software company.”`}</p>
    <p>{`He’s found that working with remote professionals is a good way to promote diversity because it brings different perspectives into the mix. “In technology it’s really important to get diversity of ideas so that you’re coming up with the best solution,” he explains.`}</p>
    <p>{`The panel also discussed how hiring remotely can have a positive impact on productivity; the absence of office politics and “water cooler chat” means people’s relationship with other members of their team centres on getting the work done.`}</p>
    <h3>{`Utilising AI for Inclusion`}</h3>
    <p>{`“We have created technologies that can understand people; how happy they are and how burnt out or frustrated they are based on passive and active data analytics,” says Yang. `}<a parentName="p" {...{
        "href": "https://blog.gethyphen.com/how-ai-is-transforming-hr-the-future-of-people-analytics"
      }}>{`AI powered people analytics`}</a>{`, for instance, has the potential to reduce human bias and improve employee wellbeing and happiness — if used properly and ethically.`}</p>
    <p>{`Spruill points out that because we have access to so much data, we need to distill down and focus on the points that can be used to inform talent initiatives.`}</p>
    <p>{`“The one I like on people is around engagement,” he says. “What are the key drivers of an employee’s happiness and willingness to continue in a company?”`}</p>
    <ul>
      <li parentName="ul">{`Do they trust the management team?`}</li>
      <li parentName="ul">{`Do they have career opportunities?`}</li>
      <li parentName="ul">{`Are they looking for another job?`}</li>
      <li parentName="ul">{`Do they understand the vision and feel connected to it?`}</li>
    </ul>
    <h3>{`An Era of Growing Consumer Expectations`}</h3>
    <p>{`When it comes to searching for a job, “people continuously want faster, more personalised, core efficient tools,” explains Montano. She believes that to make the customer experience truly excellent, the tool or service in question has to be specific to the sector.`}</p>
    <p>{`“I think in the future we’re going to see a growing demand for highly specialised hiring solutions which take one niche, one part of a market and focus on its needs,” she concludes.`}</p>
    <h3>{`Robots as Employees`}</h3>
    <p>{`The future of work in relation to diversity and inclusion will also be about how to make teams comprised of human and digital employees work in an ethical and efficient way, according to Yang. This type of inclusion and diversity should be done ethically and efficiently.`}</p>
    <p>{`An Abbyy/`}<a parentName="p" {...{
        "href": "https://www.idc.com/"
      }}>{`IDC`}</a>{` study reveals that people tend to dislike repetitive work; they want to focus on the creative aspects and are willing to give the “repetitive” part up to robots.`}</p>
    <p>{`“Companies who are introducing digital employees see a 40 per cent increase not only in productivity but in customer satisfaction as well,” he says.`}</p>
    <p>{`Montano concludes by highlighting the need for governments and businesses to provide workers with the tools and knowledge they need to upskill, or indeed re-skill, as automation takes precedence.`}</p>
    <p>{`You can `}<a parentName="p" {...{
        "href": "https://websummit.com/"
      }}>{`pre-register`}</a>{` for Web Summit 2020 now.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      