import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-05-29T00:00:00.000Z",
  "title": "Future Of Work: The Office Isn't Dead And Never Will Be\n",
  "slug": "future-of-work-the-office-isnt-dead-and-never-will-be",
  "excerpt": ["New research finds that employees are enjoying their new-found flexibility and ability to work remotely — but they are missing in-person collaboration.", "The office is deemed a “critical place” for meaningful connections, meetings and chance encounters with coworkers, which cannot be replicated online.", "The survey concludes that while it is possible for people to work remotely effectively, there is nothing that can fully substitute face-to-face interactions."],
  "images": ["future-of-work-the-office-isnt-dead-and-never-will-be.png"],
  "backlink": "https://allwork.space/2020/05/future-of-work-the-office-isnt-dead-and-never-will-be/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A recent survey conducted by The Harris Poll and sponsored by CBRE’s Hana found that U.S. employees may be reexamining their relationship with the office after COVID-19. `}</p>
    <p>{`While 99% of survey respondents say there are aspects of remote work they find valuable — including saving money, foregoing work commutes, and gaining more control over their work schedule — they still believe the office to be a “critical place” for meaningful connections.`}</p>
    <p>{`The office is not dead, but the relationship between workers and the office is going to change following the pandemic. According to the survey, people expect a “new work world that emphasizes flexibility and more intentional time in the office.”`}</p>
    <h3>{`The Office as a Place to Connect`}</h3>
    <p>{`The survey found that “employees view the office as a critical place to meaningfully connect with colleagues, highlighting how in-person meetings and chance encounters with coworkers are difficult to replicate online.”`}</p>
    <p>{`The above is one of the main motivators for people wanting to head back to the office. `}</p>
    <p>{`Interestingly, the survey found that “fun workplace perks” like social events and in-office amenities are “low motivators” for people to go back to the workplace. According to the survey, “this has implications for companies that have invested significant capital in workplace perks over the past decade in an attempt to gain a competitive advantage in a tight labor market.”`}</p>
    <p>{`However, just because people are eager to go back to the office doesn’t mean they will do so under the terms they did before the pandemic.`}</p>
    <p>{`“Working with colleagues/clients online is a pain point for many employees working from home/a remote location due to COVID-19.`}</p>
    <p>{`“The difficulty people have connecting with coworkers while working remotely gets at what people miss from the office: the opportunity for meaningful connection.”`}</p>
    <p>{`But before people flock back to the office, organizations need to understand that a top concern of employees returning to the workplace centers around losing their newfound sense of flexibility. `}</p>
    <p>{`35% of survey respondents working remotely due to the pandemic say that “the loss of a flexible work environment is a primary concern. This is second only to concerns about office cleanliness.”`}</p>
    <p>{`This means that organizations will need to find ways in which employees can keep some of their newfound flexibility as they head back to the office. `}</p>
    <p>{`Some organizations are already doing this; several companies have announced that they will allow employees to continue working remotely if they choose so, others have announced the introduction of staggered shifts and part-time remote work policies. `}</p>
    <p>{`People today are navigating a radically different workday, and this will continue even after lockdowns are lifted and organizations are cleared to open their offices. `}</p>
    <p>{`“With a rising demand for flexible work benefits, organizations need to consider the potential for reduced office occupancy, which has ramifications for how much office space they need — and what types of workspaces they offer employees.”`}</p>
    <h3>{`New Office Dynamics`}</h3>
    <p>{`The way people see and use space is changing and will continue to change as the world continues to navigate the pandemic. Some of these changes will be temporary while others will be long-lasting, if not permanent, changes. `}</p>
    <p>{`The good news is that the office is not dead, and it never will be. `}</p>
    <p>{`While it is possible for people to effectively work remotely, there is nothing that can fully substitute in-person, face-to-face interactions. `}</p>
    <p>{`You can read the full survey findings `}<a parentName="p" {...{
        "href": "https://www.yourhana.com/en-US/resources/covid19-workplace-research/"
      }}>{`here`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      