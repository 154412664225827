import React from 'react';
import clsx from 'clsx';
import { DefaultComponentProps } from '../core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: ArticleProps) => ({
      padding: `${theme.spacing(props.spacing)}px 0px`,
      [theme.breakpoints.up('sm')]: {
        padding: `${theme.spacing(props.spacing) * 1.5}px 0px`,
      },
    }),
  }),
);

export type ArticleProps = { spacing: number } & Pick<DefaultComponentProps, 'path' | 'classname' | 'children' | 'tag'>;

export const Article = ({ ...props }: ArticleProps) => {
  const classes = useStyles(props);
  return <article className={clsx(classes.root, props.classname)}>{props.children}</article>;
};

export default Article;
