import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-11-27T00:00:00.000Z",
  "title": "Future Of Work Is Best\n",
  "slug": "future-of-work-flex-is-best",
  "excerpt": ["A new report by Colliers International predicts continued growth for the flexible space sector.", "Despite the current challenges, flexible space supply is set to double or triple over the next 5 years.", "Colliers set out 6 key reasons why flex will continue to grow, which includes corporate demand, landlord competition, and niche requirements."],
  "images": ["future-of-work-flex-is-best.png"],
  "backlink": "https://allwork.space/2020/11/future-of-work-flex-is-best/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Colliers International recently released its “`}<a parentName="p" {...{
        "href": "https://www2.colliers.com/en/research/2020-us-flexible-workspace-outlook-report"
      }}>{`Flex Forward: the Flexible Workspace Report 2020`}</a>{`”. In the report, Colliers explores the impacts of COVID-19 on the flexible workspace industry and predicts continued growth for the sector regardless of the pandemic’s impact. `}</p>
    <p>{`According to Colliers, the coronavirus pandemic is only a “temporary setback” for the industry. “Many predict the inventory share of flexible workspace of the total office stock to accelerate as the number of flexible workspaces doubles or triples over the next five years.”`}</p>
    <h3>{`A quick overview of flex space growth (2018 – 2020)`}</h3>
    <p>{`According to Colliers, “flexible workspace inventory grew by 36.5% from Q4 2018 to Q2 2020,” with most growth happening in secondary markets. `}</p>
    <p>{`While the industry reported strong growth, it has decelerated in the past 12 months, “particularly in the first half of 2020.” `}</p>
    <p>{`According to data from Colliers, multiple markets posted zero or marginal changes in inventory during H1 of 2020. This slow down in growth is attributed to the COVID-19 pandemic. `}</p>
    <h3>{`6 Key Reasons for Continued Flexible Space Growth`}</h3>
    <p>{`Despite the impact from the global pandemic, Colliers identified 6 key reasons why flexible space will continue to grow in the short and long-term. `}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Corporate demand for flexible workspace will likely increase.`}</strong>{` According to the report, there will be a greater demand for flexibility within CRE departments in general, a trend that started a couple of years ago and will only accelerate in the coming months. `}</li>
      <li parentName="ol"><strong parentName="li">{`Flexible space demand will increase in suburban and secondary/tertiary markets.`}</strong>{` Various organizations have already reported an increase in demand for space outside of CBD areas and large cities; as operators aim to meet this new demand, more inventory will be added. `}</li>
      <li parentName="ol"><strong parentName="li">{`New operating models`}</strong>{` are gaining popularity among property owners and flexible space operators alike. “Operators are now exploring revenue partnerships, management agreements, ownership, and franchise models to expand their fleet of workspaces.”`}</li>
      <li parentName="ol"><strong parentName="li">{`“The new flex space vendor economy”`}</strong>{`, which has lowered the barriers of entry to developing flexible workspace solutions. From design and fit out, to tech infrastructure and facilities management. Landlords and operators now have access to a variety of services, support that they can leverage to create flexible space. `}</li>
      <li parentName="ol"><strong parentName="li">{`Competition from traditional landlords.`}</strong>{` Over the past couple of years, the industry has seen increased interest from property owners to have an active role in the flex space market. In the future, the surge of traditional landlords performing flexible solutions is likely to increase and capture a chunk of flexible space demand. `}</li>
      <li parentName="ol"><strong parentName="li">{`The continued rise of niche.`}</strong>{` Niche operators will continue to grow in the future, catering to a variety of sectors: cannabis, chefs, women, artists, etc. `}</li>
    </ol>
    <h3>{`Flex is Best: Predictions for the future`}</h3>
    <p>{`“Expect flex to be a viable option on every long-list regardless of location.”`}</p>
    <p>{`This holds true despite the fact that many operators are facing financial challenges that will make some of them subject to business closures or merger activity. `}</p>
    <p>{`To weather the storm, many operators have renegotiated their leases, others have laid off and furloughed employees, and some have even filed for bankruptcy. `}</p>
    <p>{`On the other side of the coin, the pandemic “has amplified the many benefits of flex solutions – most notably term flexibility and capital avoidance.”`}</p>
    <p>{`This is why flexible space will continue to grow in the future and why it will be a top choice for many companies. `}</p>
    <p>{`“In a recent Colliers Occupier Services poll, more than 90% of respondents projected lease flexibility will increase in the future. Of these respondents, 25% believed this will take the form of more flexible workspace agreements, with another 44.5% indicating that greater flexibility will be built into traditional leases.”`}</p>
    <p>{`This means that there is a bright future ahead for flexible workspaces across the globe. Especially for operators that are able to facilitate a choice-based workplace strategy for large companies. `}</p>
    <p>{`“The growth of flexible workspace demand will be driven by satellite and on-demand needs with opportunities to support HQs.”`}</p>
    <p>{`Colliers identified 6 key reasons why flex is best for companies:`}</p>
    <ol>
      <li parentName="ol">{`Agility`}</li>
      <li parentName="ol">{`Financial benefits (reduction of capital expenditure and providing expense certainty)`}</li>
      <li parentName="ol">{`Operational benefits, mainly operational efficiencies`}</li>
      <li parentName="ol">{`Transformational (especially for companies expanding into new territories or going through M&A integrations)`}</li>
      <li parentName="ol">{`Urgency (flex space is available almost immediately, which can create efficiencies)`}</li>
      <li parentName="ol">{`Employee engagement (flexible workspaces offer a variety of services and amenities that have been shown to improve engagement and workplace happiness).`}</li>
    </ol>
    <p>{`Increased growth, however, means increased competition. It’s especially worth noting that operators will have to compete not only among themselves, but also with landlords. `}</p>
    <p>{`Colliers predicts that “landlords will increasingly allocate 10 – 25% of their assets for flexible leases – through a combo of furnished pre-builds and more sophisticated customizable offering.”`}</p>
    <p>{`Which operators take a bigger slice of the demand, you may wonder?`}</p>
    <p>{`The ones that deliver the best user experience and quality of service. This is what will ultimately help operators and landlords harness customer loyalty.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      