import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-08-22T00:00:00.000Z",
  "title": "An Hr Expert’s Reaction To The New Uk Flexible Working Bill\n",
  "slug": "an-hr-experts-reaction-to-the-new-uk-flexible-working-bill",
  "excerpt": ["A new flexible working bill was introduced in the UK in July, 2019.", "While flexible working policies are beneficial, making it a default poses many challenges for companies.", "HR expert, Paula Fisher, argues that a “one size fits all” approach to legislation won’t work because every business is different and flexible working options needs to be measured and balanced with the potential impact on business in mind."],
  "images": ["27-AN-HR-EXPERTS-REACTION-TO-THE-NEW-UK-FLEXIBLE-WORKING-BILL.jpg"],
  "backlink": "https://allwork.space/2019/08/an-hr-experts-reaction-to-the-new-uk-flexible-working-bill/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`In July 2019 UK MP Helen Whately introduced a new flexible working bill to parliament, believing that flexible working should be the default position for all employees.`}</p>
    <p>{`Last week we spoke with `}<a parentName="p" {...{
        "href": "https://allwork.space/2019/08/how-the-uks-new-flexible-working-bill-could-impact-flexible-space/"
      }}>{`Giles Fuchs from Office Space in Town`}</a>{` and Katie Whell from Pure Offices to gain an understanding of how the proposed change could affect the flexible workspace industry.`}</p>
    <p>{`Both agreed that flexible working is a positive thing, but making it a default poses challenges.`}</p>
    <p>{`In a bid to get an HR expert’s perspective on the bill, we reached out to Paula Fisher, the founder and MD of `}<a parentName="p" {...{
        "href": "https://practicalhr.co.uk/"
      }}>{`Practical HR Ltd`}</a>{`, an HR consultancy that provides support and advice to SMEs on everything from employee relations to compliance.`}</p>
    <p>{`Fisher is also the author of SMEs – A Practical Guide and has worked in the industry for three decades. During this time she has witnessed a shift in attitudes towards flexible working, particularly since advancements in technology have made telecommuting easier.`}</p>
    <h3>{`Flexible working as a default – the challenges`}</h3>
    <p>{`Like Fuchs, Fisher is a supporter of flexible working and explains that when it makes good business sense, the advantages are clear. However, she believes a “one size fits all” approach to legislation won’t work because every business is different.`}</p>
    <p>{`“While the law needs to support – and even encourage – social change, this needs to be measured and balanced with the potential impact on business.”`}</p>
    <p>{`“As a business owner and someone who provides HR and employment law support to many SME organisations, I think it should be up to the business to decide.`}</p>
    <p>{`“To make flexible working the default position would put an additional burden on SMEs, many of whom already struggle with HR and employment law regulation.`}</p>
    <p>{`“There are so many variations of working that fall under flexible working, and it would be impractical to expect a business to consider every variation for each position.`}</p>
    <p>{`“So, while the law needs to support – and even encourage – social change, this needs to be measured and balanced with the potential impact on business.”`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "449px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/30247b870bb3ba279bb78091e29cd684/93dc1/27-img-1.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "106%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAVABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAMEBQb/xAAWAQEBAQAAAAAAAAAAAAAAAAABAwL/2gAMAwEAAhADEAAAAbtSznLsI1cR8+S1rih//8QAGxAAAgMAAwAAAAAAAAAAAAAAAQIDBBEAEiP/2gAIAQEAAQUCsE5Vc7yVg0vcV5UYOgG25m2SkfD/xAAWEQADAAAAAAAAAAAAAAAAAAARICH/2gAIAQMBAT8BET//xAAYEQACAwAAAAAAAAAAAAAAAAAAARARIf/aAAgBAgEBPwG9lH//xAAgEAABAwIHAAAAAAAAAAAAAAABAAJBESEDEBNCUXGB/9oACAEBAAY/Ag1tqynMJrIy05F6o7uUHCVidIr1f//EABwQAAMAAwADAAAAAAAAAAAAAAABESExQRBhwf/aAAgBAQABPyG2NtqQ0LFS3wpIuYNjWTwlkKpRBV3F+DnfbM5TTn//2gAMAwEAAgADAAAAEOg3wP/EABkRAAEFAAAAAAAAAAAAAAAAAAABEBEhQf/aAAgBAwEBPxCF2wU//8QAFxEBAQEBAAAAAAAAAAAAAAAAAAERMf/aAAgBAgEBPxCU6riP/8QAHhABAQACAgIDAAAAAAAAAAAAAREAMSFhQVFxocH/2gAIAQEAAT8QLU14ADV7xKAEiksS47xXpCngWUnqOAzFqA2Rm8NUXA7Oslnxp3hh8XiPnj6xTkIN9D+5/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "27 img 1",
            "title": "27 img 1",
            "src": "/static/30247b870bb3ba279bb78091e29cd684/93dc1/27-img-1.jpg",
            "srcSet": ["/static/30247b870bb3ba279bb78091e29cd684/e07e9/27-img-1.jpg 200w", "/static/30247b870bb3ba279bb78091e29cd684/066f9/27-img-1.jpg 400w", "/static/30247b870bb3ba279bb78091e29cd684/93dc1/27-img-1.jpg 449w"],
            "sizes": "(max-width: 449px) 100vw, 449px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Paula Fisher’s preference would be to keep the current flexible working legislation while promoting its benefits, to help educate employees who are yet to embrace it.`}</p>
    <h2>{`The current UK legislation`}</h2>
    <p>{`According to Fisher, the current flexible working legislation allows individuals to make an application for flexible working after 26 weeks of employment and the employee must consider all requests.`}</p>
    <p>{`“The current legislation takes a very pragmatic approach; it also requires the employee to think about the potential impact on the business and take some responsibility, rather than the onus being solely on the employer,” she adds.`}</p>
    <p>{`Fisher also explains that the current system acknowledges that there may be commercial situations that would rule out the option of flexible working for employees, including:`}</p>
    <ul>
      <li parentName="ul">{`Burden of additional costs.`}</li>
      <li parentName="ul">{`Detrimental effect on ability to meet customer demands`}</li>
      <li parentName="ul">{`Inability to reorganise work amongst existing staff`}</li>
      <li parentName="ul">{`Inability to recruit additional staff.`}</li>
      <li parentName="ul">{`Detrimental impact on quality`}</li>
      <li parentName="ul">{`Detrimental impact on performance`}</li>
      <li parentName="ul">{`Insufficiency of work during the periods that you propose to work`}</li>
      <li parentName="ul">{`Planned structural changes`}</li>
    </ul>
    <h2>{`Potential barriers for SMEs`}</h2>
    <p>{`Fisher says that, when it comes to the topic of flexible working, the majority of her clients’ concerns revolve around their business’ operational needs.`}</p>
    <p>{`Some employees reject flexibility because they are simply “old fashioned”, but for the vast majority it’s a genuine worry about meeting the needs of the business.`}</p>
    <p>{`“That’s why the current legislation is good, because it requires the employer to seriously consider the application but also gives guidance on what might be a genuine commercial reason to turn down the request,” she adds.`}</p>
    <p>{`Making flexible working a default might also increase the number of disputes, according to Fisher, who explains:`}</p>
    <p>{`“The onus would be on the employer and failure to carry out the legislation’s assessments and requirements may create liability.`}</p>
    <p>{`“This would have a detrimental impact on smaller employers who may be unable to dedicate resources and who cannot offer the same level of flexibility as some larger corporate organisations.”`}</p>
    <h2>{`Offer trial periods`}</h2>
    <p>{`For SMEs who are hesitant because they are not sure if a flexible working pattern will work, Fisher highly recommends trial periods.`}</p>
    <p>{`“Practical HR Ltd encourages businesses to provide the employee with a trial period, so they can revert back to current working practices if it is not successful.`}</p>
    <p>{`“This encourages businesses to at least try new ways of working and gives the employee an opportunity to demonstrate that it can work for both parties.”`}</p>
    <p>{`If you have any questions or concerns about flexible working, you can get in touch with Paula and her team at `}<a parentName="p" {...{
        "href": "admin@practical-hr.co.uk"
      }}>{`admin@practical-hr.co.uk`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      