import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-01-03T00:00:00.000Z",
  "title": "Future Of Work: Are Companies Failing At People Management?\n",
  "slug": "future-of-work-are-companies-failing-at-people-management",
  "excerpt": ["Oxford Economics and SAP recently published a research report that found that organizations need to make workforce management a strategic imperative.", "One of the main issues hindering the effectiveness of people management is that HR issues do not typically drive strategy at the board level and they are not a key factor in business planning.", "HR departments need to create a more strategic, evidence-based approach to people management and this approach should leverage the power of data."],
  "images": ["50-future-of-work-are-companies-failing-at-people-management.png"],
  "backlink": "https://allwork.space/2020/01/future-of-work-are-companies-failing-at-people-management/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A recent report by Oxford Economics and SAP argues that effective workforce management is a strategic imperative. Considering that recent research has found that there will be a talent shortage of more than 85 million people by 2030, `}<a parentName="p" {...{
        "href": "https://www.kornferry.com/institute/talent-crunch-future-of-work?"
      }}>{`which could result in about $8.5 trillion in unrealized annual revenues`}</a>{`, the need for companies to transform their HR strategies is pressing.`}</p>
    <p><strong parentName="p">{`Suggested Reading: “`}<a parentName="strong" {...{
          "href": "https://allwork.space/2019/09/future-of-work-the-war-for-talent-is-about-to-get-fiercer/"
        }}>{`Future of Work: the War for Talent Is about to Get Fiercer`}</a>{`”`}</strong></p>
    <p>{`In their research report, “`}<a parentName="p" {...{
        "href": "https://www.oxfordeconomics.com/workforce2020"
      }}>{`Workforce 2020, the Looming Talent Crisis`}</a>{`”, Oxford Economics and SAP state that their survey results showed that “the 2020 workforce will be different in significant ways from its predecessors.”`}</p>
    <p>{`The good news is that businesses seem to be aware of the big changes that are under way, and — to a certain extent — organizations are already attempting to transform themselves accordingly. The bad news is that there are several key factors constraining this transformation.`}</p>
    <p>{`The main factor, and probably HR’s biggest problem, is the HR department’s inability “to translate the value of workforce excellence into the language of growth and profitability.”`}</p>
    <p>{`This is an issue because the importance of people management to corporate strategy is not usually reflected by its role in the C-suite and, more importantly, in the boardroom, the report argues. According to the report, “barely half of executive respondents say it `}{`[HR issues]`}{` drives strategy at the board level, and nearly one-quarter say that workforce issues are an afterthought in business planning.”`}</p>
    <p>{`“The right mix of people, skills, and culture can create a competitive advantage for companies, reshape industries, and drive prosperity across regions.”`}</p>
    <p>{`The above cannot be achieved if organizations lack the culture and the tools they need to engage employees, track their performance, and measure the effectiveness of human resource initiatives, which brings us to the other key factors constraining people management strategies.`}</p>
    <h4>{`“The impact of these shortcomings is severe; because HR lacks the information, insights, and status to be truly strategic, companies are not making progress toward meeting their workforce goals.”`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "707px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d6fa77b89a328f369b1bf69598748132/8e486/50-img-1.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "98.50000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAQADAQAAAAAAAAAAAAAAAAECAwQF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQAC/9oADAMBAAIQAxAAAAH2pdDnrQNFZCf/xAAZEAADAQEBAAAAAAAAAAAAAAABAhEAAxD/2gAIAQEAAQUCMoOBoN3EOqrYdPP/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAcEAABAwUAAAAAAAAAAAAAAAAAAQIRECAhIjH/2gAIAQEABj8CgWim7pnhmz//xAAZEAEBAQEBAQAAAAAAAAAAAAABEQAhEEH/2gAIAQEAAT8hgYyHRz5pDpKs5lRSr1cBgCd0W+f/2gAMAwEAAgADAAAAEGj4wP/EABkRAAEFAAAAAAAAAAAAAAAAAAABEBEhMf/aAAgBAwEBPxCbEx//xAAZEQABBQAAAAAAAAAAAAAAAAAAARARITH/2gAIAQIBAT8Qihdf/8QAHRABAAICAgMAAAAAAAAAAAAAARExACFBURBhcf/aAAgBAQABPxBSijbNYK1Bux6jNCJNjxlI8gYncaQ+4by+BCO3vNgKzqbDrIVTNHkHj//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "obstacles",
            "title": "obstacles",
            "src": "/static/d6fa77b89a328f369b1bf69598748132/8e486/50-img-1.jpg",
            "srcSet": ["/static/d6fa77b89a328f369b1bf69598748132/e07e9/50-img-1.jpg 200w", "/static/d6fa77b89a328f369b1bf69598748132/066f9/50-img-1.jpg 400w", "/static/d6fa77b89a328f369b1bf69598748132/8e486/50-img-1.jpg 707w"],
            "sizes": "(max-width: 707px) 100vw, 707px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`The Shift towards More Strategic People Management`}</h3>
    <p>{`The world of work has changed and it will continue to change in the coming years. This means that big changes are coming to the workplace, especially as the nature of employment itself undergoes a transformation: talent pools are changing (“a hefty 83% of executives say they are increasingly using consultants, intermittent employees, or contingent workers”), work is increasingly flexible, and employee diversity is increasing.`}</p>
    <p>{`The changing nature of work is naturally having a big impact on organizations.`}</p>
    <ul>
      <li parentName="ul">{`46% of organizations reported needing to implement changes to compensation plans.`}</li>
      <li parentName="ul">{`38% of organizations reported requiring changes in employee technology.`}</li>
      <li parentName="ul">{`44% of organizations reported needing more investment in training.`}</li>
      <li parentName="ul">{`41% of organizations reported requiring more investment in HR technology.`}</li>
    </ul>
    <p>{`Oxford Economics and SAP argue that companies must move decisively on several fronts for their people management, and business at large, to succeed.`}</p>
    <ul>
      <li parentName="ul">{`Prepare for increasing diversity, changing employee demographics, and evolving definitions of work.`}</li>
      <li parentName="ul">{`Gain a better understanding of incoming Millennial and Gen Z employees.`}</li>
      <li parentName="ul">{`Enhance employee engagement by providing the incentives and benefits that people actually want.`}</li>
      <li parentName="ul">{`Improve executive leadership skills and cultivation.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://allwork.space/2019/10/future-of-work-should-your-organization-offer-and-pay-for-retraining-opportunities/"
        }}>{`Foster a culture of continuous learning that develops talent and empowers workers while capturing and retaining vital knowledge.`}</a></li>
      <li parentName="ul">{`Those companies that aren’t able to move on the above fronts could potentially face a talent crisis.`}</li>
    </ul>
    <p>{`“Overall, companies do a so-so job of planning for the future—just over half of respondents have a defined execution plan for achieving their vision of workforce management—but they do even less well in terms of having a strategic, enterprise-wide vision for the workforce they want to build.”`}</p>
    <p>{`For people management efforts to succeed, HR needs to develop a more strategic, evidence-based approach; in other words, an approach that leverages the power of data.`}</p>
    <p>{`As it currently stands, “HR lacks both the clout and the tools to get the job done”. HR departments currently don’t have access to the right tools and metrics to make informed decisions and develop strategies to build the workforce of the future.`}</p>
    <p>{`“While more than half of executives say workforce development is a key differentiator for their firms, they do not have the technology and organization to back it up. Just 38% say they have ample data about the workforce to understand their strengths and potential vulnerabilities from a skills perspective, and 39% say they use quantifiable metrics and benchmarking as part of their workforce development strategy.”`}</p>
    <p>{`Those companies that do have the data available fail to transform it into insights, with only 42% of surveyed organizations saying they know how to extract meaningful insights from the data available to them.`}</p>
    <p>{`HR departments need to refine their message in order to get the required support and funds from C-suite executives and boardroom directors for their people management strategies to succeed in the short and long term.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      