import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-10-08T00:00:00.000Z",
  "title": "The Future Of Work Is About One Thing: Flexibility\n",
  "slug": "the-future-of-work-is-about-one-thing-flexibility",
  "excerpt": ["Flexibility is one of the main drivers of the discussion around the modern workforce and the future of work.", "Companies should embrace a “flexible first” mindset if they wish to attract and retain the best talent.", "The core value of flexibility is the opportunity it offers to remove barriers and limitations."],
  "images": ["36-THE-FUTURE-OF-WORK-IS-ABOUT-ONE-THING-FLEXIBILITY.jpg"],
  "backlink": "https://allwork.space/2019/10/the-future-of-work-is-about-one-thing-flexibility/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`If the future of work is about one thing, it’s flexibility.`}</p>
    <p>{`The growing multitude of discussions around the modern workforce are centered not on the drivers of the movement, but rather their consequences. Yet, these discussions have omitted the most crucial movement that is driving everything: flexibility.`}</p>
    <p>{`You can’t hope to achieve the fruits of flexibility — community, talent retention, productivity, & wellness — without understanding what makes them possible.`}</p>
    <p>{`As a result, many well intentioned strategies aiming to appeal to the modern workforce and to leverage the future of work fail. Bringing flexibility to the forefront of the discussion is the key to achieving the sought after outcomes for the modern workforce.`}</p>
    <p>{`The following stands true now and in the future: organizations that don’t use a “flexible first” mindset will be left behind.`}</p>
    <p>{`The future of work is a consequence of flexibility. “Flexible first” must not be the exception, but the rule.`}</p>
    <h2>{`What does flexibility mean?`}</h2>
    <p>{`Professional flexibility pertains to everything that can impact & potentially improve productivity at work.`}</p>
    <p>{`This includes:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Where we live:`}</strong>{` whether that is within commuting distance to a company headquarters or remotely from several time zones away.`}</li>
      <li parentName="ol"><strong parentName="li">{`When we work:`}</strong>{` from rigid 40+ hour work weeks during traditional business hours to fully flexible schedules around children’s schedules or night owl tendencies.`}</li>
      <li parentName="ol"><strong parentName="li">{`Where we work:`}</strong>{` across the growing ecosystem of physical workspaces are corporate offices, home offices, coffee shops, libraries, hotel lobbies, coworking spaces, and even the occasional hammock. Even within a given space, there are traditional desks in private offices behind four walls, open office plans, or comfortable couches in a common space.`}</li>
      <li parentName="ol"><strong parentName="li">{`How much we work:`}</strong>{` our productivity is not constant or linear, so varying workloads, sabbaticals, personal leave, and vacations all play a role in how much we get done.`}</li>
      <li parentName="ol"><strong parentName="li">{`Who we work with:`}</strong>{` coworkers, teams, managers, reports, and the frequency we are around or in direct contact with them.`}</li>
    </ol>
    <p>{`In all of these, its providing flexibility that matters the most. `}<a parentName="p" {...{
        "href": "https://allwork.space/2019/04/the-future-of-work-introducing-the-flexiverse/"
      }}>{`The flexibility is what allows everything else to be achieved`}</a>{`.`}</p>
    <h2>{`Flexible Workspaces & Community`}</h2>
    <p>{`For example, there is a huge focus in the coworking industry on community. Community has even been asserted to be the key reason for why the industry has grown so quickly. `}<strong parentName="p">{`But community is not what makes the flexible workspace industry viable or sustainable`}</strong>{`. Flexibility does that.`}</p>
    <p>{`Community is a healthy consequence of providing workers with an environment that focuses on the flexibility that they need. Focusing on community alone is not a sound business strategy and could potentially be harmful to the business if it creates exclusivity that hurt the financial sustainability of the business.`}</p>
    <p>{`There is no denying that communities in thriving flexible workspaces are vital; they provide a valuable tool in combating the loneliness that can result from professional isolation in remote teams. A strong sense of community in a coworking space contributes significantly to the loyalty & productivity of its members.`}</p>
    <p>{`Thinking flexible first provides the proper context for how to help foster & create a sense of community in your workspace.`}</p>
    <p>{`Too many workspace operators leap too quickly to creating lots of events, or designing their entire floor plan without being clear on how those choices provide more flexibility for the users of the space.`}</p>
    <p>{`So, what should you do instead?`}</p>
    <p>{`It’s simple, put flexibility at the beginning of the decision making process.`}</p>
    <p>{`Events are the primary driver of growth in the flexible workspace world. Ask yourself: are the majority of your members interested in happy hours? If many of them have small children at home or in school, are they able to attend or will that instead exclude them from the community? What if someone doesn’t drink alcohol? Are you providing a range of events that allow them to enjoy participating as well?`}</p>
    <p>{`Does your floor plan provide enough private space for members to be productive or is it open to try and encourage interactions? If it’s too open, or too private, it’s possible that the effect is actually detrimental to your community. Including a wide variety of options, from spaces to focus to places to socialize, can help your members be more productive no matter what type of work they are doing.`}</p>
    <h2>{`Remote Work Policies & Competition for Talent`}</h2>
    <p>{`In an effort to retain a high performing employee, for example, a company may allow them to work remotely, thinking they are increasing flexibility for that employee. However, if the rest of the team is not remote and still works under the presumption of being face-to-face, that one remote employee will have a difficult time participating in the same way as their in-office colleagues.`}</p>
    <p>{`They may become isolated, negatively impacting their productivity. Their ability to perform has been limited rather than expanded.`}</p>
    <p>{`Flexibility is a competitive edge in acquiring great talent and retaining great people. Flexible work options are one of the most sought after perks by today’s professionals. But, flexibility needs to be more than a perk, it should be a core value of any company not wanting to be left behind by the talent pool.`}</p>
    <p>{`Once again, however, there have been too many cases where jumping to tactics & strategies to attract talent have not taken into account what flexibility is actually needed to achieve the desired outcome.`}</p>
    <p>{`In order to properly increase the available talent pool and retain top talent through a remote work policy, a company must first recognize what flexibility is needed to make that policy successful.`}</p>
    <p>{`How do teams communicate and exchange information? Is it required to be synchronous and in person or is there the opportunity to introduce asynchronicity and still get the work done? What has been done to prepare non-remote employees to work with distributed colleagues? For remote employees, do they have access to workplaces that will enable them to be productive? This may be home offices for some people, coworking spaces for others.`}</p>
    <h2>{`Flexibility Removes Limitations`}</h2>
    <p>{`These examples only scratch the surface of the need for a “flexible first” mindset. Putting flexibility first facilitates decision making that not only provides more options for the modern workforce, but also considers whether or not those options can be fully utilized.`}</p>
    <p>{`It’s not enough to plan events to build community in a coworking space or allow remote work to retain talent at a company if no one can actually leverage those options.`}</p>
    <p>{`The only value in flexibility is in its ability to remove limitations.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      