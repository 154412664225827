import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-11-20T00:00:00.000Z",
  "title": "Future Of Work: How Will The Office Evolve a Q&A With Perkins&Will's Mary Dickinson\n",
  "slug": "how-will-the-office-evolve-a-qa-with-perkinswills-mary-dickinson",
  "excerpt": ["The office isn’t dead, but the way we use it is changing.", "Ultimately, workspace is evolving to make employees feel safe, and to provide physical and emotional support.", "Mary Dickinson, Associate Principal and Regional Sustainability Practice Leader at the Dallas studio of Perkins&Will, discusses the need for adaptability, and how the office will evolve after the pandemic."],
  "images": ["how-will-the-office-evolve.png"],
  "backlink": "https://allwork.space/2020/11/future-of-work-how-will-the-office-evolve-a-qa-with-perkinswills-mary-dickinson/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`There have been a lot of predictions about what will happen to the office in the future. Some have said `}<a parentName="p" {...{
        "href": "https://allwork.space/2020/05/future-of-work-the-office-isnt-dead-and-never-will-be/"
      }}>{`the office might be dead — we disagree`}</a>{` — while others believe that open offices will be a thing of the past. `}</p>
    <p>{`The reality is, it’s much more complicated than that. `}</p>
    <p>{`The office will change, but how it changes will vary greatly depending on company and employee needs. `}</p>
    <p>{`Take flexible workspaces for example. Many have argued that the coronavirus pandemic will be the start of the end of the open office, especially in flexible workspace settings. `}</p>
    <p>{`Mary Dickinson, Associate Principal and Regional Sustainability Practice Leader at the Dallas studio of `}<a parentName="p" {...{
        "href": "https://perkinswill.com/"
      }}>{`Perkins&Will`}</a>{`, disagrees.`}</p>
    <p>{`Dickinson believes that flexible workspaces will not undergo any massive changes — as people will always long for a degree of collaboration.`}</p>
    <p>{`She argues that what we can expect is a change in how these spaces are utilized, especially as more people begin to use them as modes for productivity. `}</p>
    <p>{`Allwork.Space spoke with Dickinson to learn more about her view of the office of the future and how the office footprint will evolve.`}</p>
    <p><strong parentName="p">{`Allwork.Space: There are a lot of predictions out there about what will happen to the office. What is your prediction about the future of the workplace?`}</strong></p>
    <p>{`Mary Dickinson: Prior to the pandemic, the influence of wellness driven design strategies were on the rise and now they are a part of every visioning conversation for the workplace. `}</p>
    <p>{`However, in recent months we’ve focused those efforts on strategies that are the most impactful and that provide an increased sense of safety – such as optimized indoor air quality, robust cleaning programs, and flexible spaces. `}</p>
    <p>{`Thankfully these priorities are bringing to light the laggard building codes on air quality and necessities to update these for better outdoor air exchange rates, mechanical systems, filtrations, media, etc. Cleanability and adaptability is now an additional criteria of every workspace design.`}</p>
    <p>{`While these are the priorities, we are also seeing the desire for additional amenities, such as access to coveted outdoor space, and the inclusion of biophilic design elements to connect employees with the outdoors. `}</p>
    <p>{`Ultimately, workspace is evolving to make employees feel safe, to provide options in daily workstyle needs, and to provide physical and emotional support.  `}</p>
    <p>{`If anything, the workplace of the future has to be flexible and adaptable because no one can predict what will happen as a result of the pandemic.`}</p>
    <p><strong parentName="p">{`Allwork.Space: Speaking about flexibility and adaptability, what is your take on flexible workspace solutions? Will these spaces, which boast a lot of open, shared space, go through dramatic changes?`}</strong></p>
    <p>{`If anything, the pandemic has workplace questioning the utilization of workspaces in the office, especially with so many learning how to efficiently work from home.  `}</p>
    <p>{`As employees return to the office, some may have roles requiring they remain in the office full-time while others may be touching-base for a day, but the majority will be somewhere in-between.  `}</p>
    <p>{`This will change the purpose of the office and demand on workspaces.  `}</p>
    <p>{`While before, the office served a place to work at our computers, to meet, and to collaborate; now it will serve more of the latter. `}</p>
    <p>{`The role of desks will be a place to touchdown between meetings and collaboration, allowing these workspaces to be transferable between staff members. Employees will want choice, delight and protection, thus for the days they are in, they may prefer to checkout a desk or an office as their touch-down space, based on their psychological safety needs.   `}</p>
    <p><strong parentName="p">{`Allwork.Space: If the office will become mostly a place that caters to collaboration, what are some changes we can expect?`}</strong></p>
    <p>{`With necessity for adaptability we will most likely continue to see an increase in the demand of flexible, demountable and reconstructible spaces – with the added benefit of being sustainable.   `}</p>
    <p>{`While the demand for workspaces for each employee will decrease for many employers, the demand for collaborative spaces will rise. With many still working from home, we’ll need new technologies to meet the needs of these spaces – to aid the convergence of the physical and digital world so those on the screen can better participate with the collaboration in the room.  `}</p>
    <p><strong parentName="p">{`Allwork.Space: Besides flexibility and design changes, one issue that many have talked about is office footprint. How do you think that corporate real estate footprint will change? We’ve already seen some large companies reduce their footprint, will this be a permanent change or do you think companies are more likely to turn to other real estate options, like flexible space?`}</strong></p>
    <p>{`This goes back to anticipating how many people companies want back in the workplace and what percentage of time each worker will be in the office. For some this will be based on where employees want to work to feel safe – the office or home.`}</p>
    <p>{`Most likely we are going to see a continued trend in the reduction of real estate footprints to meet employee needs, but that doesn’t necessarily mean they will cost less.  `}</p>
    <p>{`Employees will want spaces that make them feel psychologically and physically well with a variety of choices for working, while meeting the new demands of collaborating. Designs will improve, but they will likely cost more.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      