import React, { useState, useLayoutEffect } from 'react';
import classnames from 'classnames';
import { DefaultComponentProps } from '../core';
import { Section } from '.';
import imagePathGenerator from '../../../../src/utils/imagePathGenerator';
import { Grid, Typography, useTheme } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    portrait: {
      borderRadius: '1.5px',
      cursor: 'pointer',
      width: '300px',
      height: '300px',
      [theme.breakpoints.up('sm')]: {
        width: '325px',
        height: '325px',
      },
      objectFit: 'cover',
      boxShadow: theme.shadows[5],
      objectPosition: '0 0',
      opacity: '1',
      transition: 'opacity 0.25s linear',
      '&:hover': {
        opacity: '0.65',
      },
    },
    information: {
      width: '300px',
      [theme.breakpoints.up('sm')]: {
        width: '325px',
      },
    },
  }),
);

export type MediaCardProps = { name: string; position: string; image: string; openModal?: any; id: number } & Pick<
  DefaultComponentProps,
  'path' | 'classname' | 'children'
>;

export const MediaCard = ({ ...props }: MediaCardProps) => {
  const classes = useStyles(props);
  const positions = ['flex-end', 'center', 'flex-start'] as any;
  const theme = useTheme();
  const [factor, setFactor] = useState(1);

  useLayoutEffect(() => {
    const value = theme.breakpoints.values.lg > window.innerWidth ? 1 : props.id % 3;
    setFactor(value);
  });

  return (
    <Grid item xs={12} sm={12} md={6} lg={4}>
      <Grid container direction="column" alignItems={positions[factor]}>
        <img
          id={`${props.id}`}
          className={classes.portrait}
          src={imagePathGenerator(props.image)}
          onClick={e => props.openModal(e)}
          alt="fowf-teammember"
        />
        <Section spacing={3} classname={classnames(classes.information, 'left')}>
          <Typography variant="h4">{props.name}</Typography>
          <Typography variant="caption" className="secondary-dark-text">
            {props.position}
          </Typography>
        </Section>
      </Grid>
    </Grid>
  );
};

export default MediaCard;
