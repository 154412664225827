import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-05-08T00:00:00.000Z",
  "title": "Future Of Work: How The IOT, AI, And Robotics Can Help Keep Workplaces Clean And Safe\n",
  "slug": "how-the-iot-ai-and-robotics-can-help-keep-workplaces-clean-and-safe",
  "excerpt": ["Cleaning and sanitization are now a top priority for organizations hoping to reopen their workplaces and help workers feel comfortable going back into the office.", "Emerging technologies like the IoT, artificial intelligence, and robotics can help improve cleaning processes and procedures by making them much more efficient.", "Using technology to clean and sanitize the workplace environment can help reduce and slower the spread of viruses, bacteria, and allergens."],
  "images": ["how-the-iot-ai-and-robotics-can-help-keep-workplaces-clean-and-safe.png"],
  "backlink": "https://allwork.space/2020/05/future-of-work-how-the-iot-ai-and-robotics-can-help-keep-workplaces-clean-and-safe/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`As governments lift lockdowns and companies prepare the workplace to welcome back workers, technology could prove to be a key ally in keeping workplaces cleaner and safer.`}</p>
    <p>{`Cleaning and sanitization are now a top priority for organizations hoping to reopen their workplaces and help workers feel comfortable going back into the office.`}</p>
    <p>{`Naturally, improving cleaning and sanitization processes will require time, effort, and money. Luckily, emerging technologies like the Internet of Things (IoT), automation, artificial intelligence, and robotics can help make the process much easier, efficient, and less time-consuming for workers.`}</p>
    <p>{`Over the past five years, humans have been increasingly working alongside new technology such as robots, artificial intelligence and machine learning algorithms. The post-pandemic world will offer a new area of opportunity for such collaboration.`}</p>
    <p>{`Having an interconnected cleaning and sanitization system will enable more efficient protocols and procedures, while saving resources (mainly time and money).`}</p>
    <p>{`Below are some of the potential applications of technology to drive cleaning and sanitization, therefore creating a much  safer workplace environment for workers.`}</p>
    <h3>{`The IoT`}</h3>
    <p>{`By enabling smart lighting, smart temperature changes, and smart access, among many other things, the IoT can help keep workplaces clean and safe by reducing the amount of touch points a person has during a regular working day.`}</p>
    <p>{`Furthermore, sensors and tracking capabilities of the IoT, combined with artificial intelligence, can help organizations determine high traffic areas or times during the day  when certain areas (like bathrooms) are used the most. This data can be valuable so that organizations can make a more informed decision of how often certain areas should be cleaned and whether there’s a time of day cleaning should be increased.`}</p>
    <p>{`In the example of the bathroom, a cleaner (human or robot) could be sent to clean and sanitize the bathroom after 50 people have used it or during a specific time frame.`}</p>
    <p>{`Such data can also help organizations identify  days of the week when traffic decreases and therefore when cleaning procedures do not need to take place as often as usual.`}</p>
    <p>{`Bear in mind that with the IoT, the more “things” that are connected, the better the  information and data available.`}</p>
    <h3>{`Artificial Intelligence`}</h3>
    <p>{`Artificial Intelligence can be a powerful tool for organizations as they seek to improve their cleaning and sanitization practices.`}</p>
    <p>{`One area that artificial intelligence can be beneficial is in layout and space planning. As organizations adapt their workplaces to maintain physical distancing, artificial intelligence, combined with sensors and tracking capabilities, can help identify high and low traffic areas and help create a more efficient floor plan and desk distribution.`}</p>
    <p>{`When combined with the IoT, artificial intelligence can be used to ensure that organizations keep a stock of cleaning supplies and it can inform management when a supply is about to run out, it can notify people when an area is ready to be used again (i.e. a meeting room that has been cleaned and disinfected), and it can notify people when an area has been contaminated, and it can notify a cleaner that an area needs to be cleaned.`}</p>
    <p>{`Another potential use case is AI-handwashing, which could help detect insufficient or incorrect hand-washing practices.`}</p>
    <h3>{`Robotics`}</h3>
    <p>{`Basically, robots could be doing the dirty work for organizations as robots tend to perform extremely well in repetitive tasks (such as cleaning).`}</p>
    <p>{`Robots could be used to scrub or sweep floors, clean surfaces, disinfect common areas, clean touchpoints, clean bathrooms, and more while providing real-time data on performance and analytics.`}</p>
    <p>{`One notable advantage of using robots to clean is that it reduces or eliminates human exposure to bacteria, viruses, and allergens.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      