import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Drawer, List, ListItem, ListItemText, IconButton } from '@material-ui/core';
import { DefaultComponentProps } from '../../core';
import MenuIcon from '@material-ui/icons/Menu';

type Icon = {
  sprite: string;
  name: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: any) => ({
      background: 'rgba(0, 0, 0, 0.80)',
      width: '280px',
      '& div': {
        color: 'white',
      },
    }),
    noHover: {
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
    },
  }),
);

export type MenuProps = DefaultComponentProps;

export const Menu = ({ ...props }: MenuProps) => {
  const data = useStaticQuery(graphql`
    {
      menu: allMenuDataJson {
        edges {
          node {
            id
            name
            link
            icon
          }
        }
      }
    }
  `);

  const menu = data.menu.edges;
  const classes = useStyles();
  const [state, setState] = React.useState({ opened: false });
  const links = [];
  menu.forEach((menuItem: any) => {
    links.push(menuItem.node.link);
  });

  const toggle = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, opened: !state.opened });
  };

  const listitem = (name: string, link: string, icon?: Icon) => (
    <a className="no-decoration" key={name} href={link}>
      <ListItem button>
        <ListItemText primary={name} />
      </ListItem>
    </a>
  );

  const list = () => (
    <>
      <div role="presentation" onClick={toggle()} onKeyDown={toggle()}>
        <List>
          {menu.map(({ node: { name, link } }: { node: { name: string; link: string } }) => listitem(name, link))}
        </List>
      </div>
    </>
  );

  return (
    <>
      <IconButton className={classes.noHover} edge="start" onClick={toggle()} aria-label="Menu">
        <MenuIcon className="white-text" fontSize="large" />
      </IconButton>
      <Drawer anchor="right" open={state.opened} onClose={toggle()} PaperProps={{ classes }}>
        {list()}
      </Drawer>
    </>
  );
};

export default Menu;
