import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-05-01T00:00:00.000Z",
  "title": "Future Of Work: Key Steps To Reopening The Workplace\n",
  "slug": "future-of-work-key-steps-to-reopening-the-workplace",
  "excerpt": ["As governments roll out reopening procedures, organizations are considering how to safely bring people back to work.", "The transition back to the office requires careful thought and planning to ensure workers feel as safe as possible.", "These 5 steps to reopening the workplace include considerations for building access, cleaning protocols, and reducing density in shared spaces."],
  "images": ["key-steps-to-reopening-the-workplace.png"],
  "backlink": "https://allwork.space/2020/05/future-of-work-key-steps-to-reopening-the-workplace/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`As governments around the world begin to lift lockdowns, organizations are thinking about the necessary steps they will need to take to safely reopen their workplaces. `}</p>
    <p>{`The transition back to the office will not be business-as-usual; it will need to take into consideration new procedures and guidelines, as well as new work habits and behaviors.`}</p>
    <p>{`Below are some key steps organizations will have to take to ensure the transition is as smooth and effective as possible. `}</p>
    <h3>{`1. Rethinking Shared Spaces`}</h3>
    <p>{`To ensure that shared spaces remain in use, organizations will need to consider reducing the capacity of these spaces to allow for a 6 feet physical distance between seating options. Additionally, if an organization has a hot-desking or first come first serve seating option, this should be turned into assigned seating. `}</p>
    <p>{`For large meeting rooms, organizations could split the meeting room with panels or they could also consider assigning meeting rooms for specific teams (i.e. marketing teams use meeting room 1, sales teams use meeting 2, executives use meeting 3, etc.). If this is not possible, ensure that you leave enough time between scheduled meetings to clean the space thoroughly. `}</p>
    <p>{`The goal is to reduce the traffic shared spaces typically experience to reduce the amount of people that are in it at the same time, and to reduce the number of people that are exposed to one another. `}</p>
    <h3>{`2. Think about Touchpoints and Entrance Points`}</h3>
    <p>{`Think about how workers enter the workplace. How many touchpoints are there? Can you eliminate any of them or create a hands-free experience?`}</p>
    <p>{`If you use a fingerprint to open doors, consider deactivating it to reduce a point of contact. What about doors within the workplace and halls, can you leave them open or have them activated via a sensor? Can you reduce the number of entrance points? `}</p>
    <p>{`If such measures aren’t an option, consider installing disinfecting stations in all entrance points and throughout the workplace, particularly in heavily used areas. Such stations should provide workers with hand sanitizer, soap and water, cleaning of shoe soles, etc. `}</p>
    <h3>{`3. Revamp Your Cleaning Protocols`}</h3>
    <p>{`As people move back into the office, organizations will need to prioritize their cleaning protocols and procedures. `}</p>
    <p>{`Ensure that your cleaning staff have the necessary protective equipment to do the job safely; additionally, consider reviewing a list of the cleaning products used, are they effective in killing viruses and bacteria?`}</p>
    <p>{`Most likely, organizations will need to take their cleaning practices to a new level. Routine cleaning and disinfecting of high-touch areas and surfaces should be a priority.`}</p>
    <p>{`On top of having your regular cleaning crew frequently disinfecting the workspace, organizations should also consider providing individuals with access to cleaning products so that they can clean tablets, phones, or any other area that they might not feel comfortable touching otherwise. `}</p>
    <p>{`In addition to this, organizations should also place significance throughout the workspace emphasizing the importance of prevention measures, most notably correct hand-washing. `}</p>
    <p>{`Some areas that will need to be paid special attention to include:`}</p>
    <ul>
      <li parentName="ul">{`Shared workspace areas`}</li>
      <li parentName="ul">{`Meeting rooms`}</li>
      <li parentName="ul">{`Restrooms`}</li>
      <li parentName="ul">{`Kitchen and dining areas`}</li>
      <li parentName="ul">{`Reception `}</li>
      <li parentName="ul">{`Elevators`}</li>
      <li parentName="ul">{`Handrails `}</li>
    </ul>
    <h3>{`4. Consider Additional Measures`}</h3>
    <p>{`Will you require employees to wear a mask throughout the day while in the workplace? What about physical distancing within your built environment (including elevators, work areas, dining areas, receptions, etc.)?`}</p>
    <p>{`Other additional measures you might need to consider are the following:`}</p>
    <ul>
      <li parentName="ul">{`If you regularly provided free food for employees, consider switching to pre-packed meals/snacks only. `}</li>
      <li parentName="ul">{`Consider designating one person to be in charge of making the coffee and if possible, use a coffee machine that allows people to serve their coffee by pushing the dispenser with their cup rather than hands. Alternatively, consider removing coffee pots.`}</li>
      <li parentName="ul">{`Discourage employees from sharing their phones, tablets, computers, and other tools with other people. `}</li>
      <li parentName="ul">{`Encourage people to bring their own food and drinks from home. `}</li>
      <li parentName="ul">{`Think about installing sensors for lighting and temperature. `}</li>
      <li parentName="ul">{`Limit the number of external visitors or have a temporary no-visitor policy. `}</li>
      <li parentName="ul">{`Consider reducing the number of staff that come in on a daily basis (this can be achieved by allowing some workers to continue working remotely or by assigning weeks or days to specific teams — for example, marketing teams come in every other week, alternating with the sales teams).`}</li>
    </ul>
    <h3>{`5. Communicate`}</h3>
    <p>{`Whichever methods you decide to implement, you need to make sure that you are clearly and effectively communicating with all stakeholders. `}</p>
    <p>{`Communication efforts should be open and transparent so that employees also feel comfortable voicing their fears, worries, or suggestions. `}</p>
    <p>{`Bear in mind that the working habits of employees have drastically changed over the past few months. Just like it took them some time to adjust to working from home, it will take them some time to re-adjust to being back in the office. Prepare employees for this change by communicating with them early, sharing the details and timeline of the transition back to the office and anticipate any potential questions or concerns that they might have. `}</p>
    <p>{`New policies and practices should help to mitigate workforce anxiety and provide a safer workplace environment for everyone.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      