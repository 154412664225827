import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2018-11-29T00:00:00.000Z",
  "title": "3 Technologies That Will Greatly Impact The Workplace By 2020\n",
  "slug": "3-technologies-that-will-greatly-impact-the-workplace-by-2020",
  "excerpt": ["An overview of 3 technologies that are shaping the future of work - IoT, machine learning (big data) and automation.", "These technologies will enable the modern workplace to support the reorganization of working practices and the “liberation” of creative skills.", "The workplace must also support an increasingly mobile workforce, and these technologies can empower individuals to achieve optimal performance."],
  "images": ["6-TECHNOLOGIES-WORKPLACE.png"],
  "backlink": "https://allwork.space/2018/11/3-technologies-that-will-greatly-impact-the-workplace-by-2020/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`“Workplaces should be places, where employees come – not because they have to – but because they want to. This requires a transition of the workplace from one that supports activities to one that supports value-adding experiences.” – ISS 2020 Vision: Future of Work, Workforce and Workplace.`}</p>
    <p>{`The workplace has become a place where people go to work and share experiences. This has been driven by changes in workforce demographics as well as by revolutionizing technologies. The latter has allowed for work processes to be reorganized, which consequently has allowed for the “liberation of labour for work involving innovation, creativity, research, development, and similar tasks that currently cannot be easily automated or put into formulas.”`}</p>
    <p>{`The modern workplace needs to be able to support this type of work. It also needs to support an increasingly mobile workforce, one where people are free to work from anywhere and anytime. And just as technology is liberating and enabling professionals to do value-added work, technology can enable and empower the workplace to deliver optimal experiences.`}</p>
    <p>{`Some technologies are being used by early adopters in the workplace in order to improve the workplace experience. These technologies are shaping the future of work and by 2020 they will be widespread among leading organizations’ workplaces.`}</p>
    <p>{`Here are 3 of them:`}</p>
    <h3>{`1. IoT`}</h3>
    <p>{`The Internet of Things, the technology behind smart buildings and smart homes, has been around for some years now. IoT “is the network of physical devices, vehicles, appliances, and other items embedded with electronics, software, sensors, actuators, and network connectivity which enable these objects to connect and exchange data.”`}</p>
    <p>{`As the technology is being continually developed and deployed, it has become cheaper, more accessible, and easier to use. In the workplace, IoT is helping to improve the workplace experience through sensors, providing workplace managers and organizations with valuable insights on how people use and interact with the space. Sensors also aid with efficiency and sustainability, reducing costs and reducing their environmental footprint.`}</p>
    <h3>{`2. Machine Learning (Big Data)`}</h3>
    <p>{`IoT is helping organizations gather incredible amounts of information, but that information is not valuable unless it can be analyzed and applied. Enter machine learning, specifically Big Data and Analytics.`}</p>
    <p>{`These technologies analyze data in real time; they find patterns that are not easily discernible to the human eye. Using big data and analytics in the workplace raises awareness of resource utilization, occupant behavior and interactions, service requirements, and underutilized space. This means that organizations and managers will be better equipped to make strategic decisions about the workplace; the correct interpretation of data can help organizations translate insights into actionable items that will improve the workplace and exceed user demands.`}</p>
    <h3>{`3. Automation`}</h3>
    <p>{`Automation means fulfilling a task without human intervention. IoT and data provide the necessary information needed to automate certain tasks and/or processes. In the workplace, automation is and will continue to impact operational elements such as building access, meeting room bookings, welcoming guests, lighting, temperature, and others. If you want, you can even automate your coffee machine and `}<a parentName="p" {...{
        "href": "https://allwork.space/2016/09/flexible-workspace-must-gourmet-coffee-and-lots-of-it/"
      }}>{`make sure workplace users always have access to freshly made coffee`}</a>{`. In fact, there already exists `}<a parentName="p" {...{
        "href": "https://allwork.space/2018/10/this-australian-coworking-space-is-fully-automated-and-has-no-need-for-on-site-staff/"
      }}>{`a fully automated coworking space in Australia`}</a>{` with no on-site staff.`}</p>
    <p>{`These are only three of the various technologies shaping the future of work. However, this ‘future’ is not so far away. These technologies are the most likely to impact your workplace and operations within the next 12 to 18 months.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      