import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-02-22T00:00:00.000Z",
  "title": "Future Of Work: The Rise Of The Digital Workplace\n",
  "slug": "future-of-work-the-rise-of-the-digital-workplace",
  "excerpt": ["What is the digital workplace? It’s defined as a workplace that “enables new, more effective ways of working” by exploiting consumer-oriented styles and technologies.", "The digital workplace market is expected to grow from USD 13.4 billion in 2018 to USD 35.7 billion by 2023.", "A transformational shift to a digital workplace is essential for business success and longevity in today’s technological, consumer-centric world."],
  "images": ["2-THE-RISE-OF-THE-DIGITAL-WORKPLACE.png"],
  "backlink": "https://allwork.space/2019/02/the-future-of-work-the-rise-of-the-digital-workplace/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "https://www.prnewswire.com/news-releases/digital-workplace-market-worth-35-7-billion-by-2023---exclusive-report-by-marketsandmarkets-300796482.html"
      }}>{`According to a new market research report`}</a>{`, the Digital Workplace Market is expected to grow from USD 13.4 billion in 2018 to USD 35.7 billion by 2023. Though the term was first coined in 1993 by Charles Grantham and Larry Nichols, it wasn’t until 2014 that searches for the term peaked in Google.`}</p>
    <p>{`However, across the web, there is no consistent definition of what the digital workplace is (`}<a parentName="p" {...{
        "href": "https://allwork.space/2018/11/coworking-might-not-need-a-new-definition-but-it-needs-to-broaden-the-one-it-has/"
      }}>{`similar to what has happened with coworking and flexible workspace`}</a>{`).`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.information-age.com/time-now-get-grips-digital-workplace-and-manage-consumerisation-gartner-123458214/"
      }}>{`In 2014, Gartner defined the digital workplace`}</a>{` as “an ongoing, deliberate approach to delivering a more consumer-like computing environment that is better able to facilitate innovative and flexible working practices.” Today, Gartner defines the digital workplace as a workplace that “enables new, more effective ways of working; raises employee engagement and agility; and exploits consumer-oriented styles and technologies.”`}</p>
    <p>{`In 2015, `}<a parentName="p" {...{
        "href": "https://digitalworkplacegroup.com/2015/03/31/which-of-these-8-definitions-of-digital-workplace-works-best-for-you/"
      }}>{`Ephraim Freed from The Digital Workplace Group`}</a>{` defined the digital workplace as “the experience of work delivered through the collective use of connected devices, software and interfaces.”`}</p>
    <p>{`Last year, `}<a parentName="p" {...{
        "href": "https://www.cmswire.com/digital-workplace/how-to-define-the-digital-workplace-2030-and-why-it-matters-now/"
      }}>{`Paul Miller, author of ‘The Digital Workplace: How Technology is Liberating Work’ and Founder of CEO and founder of Digital Workplace Group (DWG)`}</a>{` has an operational definition of the digital workplace. Miller’s definition states that “the digital workplace comprises the range of services, applications and devices we use for work: intranets, collaboration, unified communications, HR services, mixed reality, email, mobility, etc.”`}</p>
    <p>{`Freed’s definition, as well as Gartner’s most recent one, shift the focus from the technology to the users. This is important because over the past two years and across industries and departments — sales, marketing, real estate, consumer goods, and plenty others — the emphasis on human aspects and experiences has significantly increased.`}</p>
    <p>{`In other words, digital workplaces enable new, more effective ways of working, and raise employee engagement and agility. More importantly and given that new technological advances are increasingly taking place, it’s important to remember that the digital workplace is a constant, ongoing method and not a final destination.`}</p>
    <h3>{`Why Now?`}</h3>
    <p>{`One of the key drivers of the digital workplace is that new technologies enable worker mobility and flexibility without compromising on productivity. These technologies disrupt and revolutionize the way people work and the way companies operate. These technologies include the cloud, virtual assistant apps, group messaging tools, collaboration tools, the internet of things, bots, analytics, artificial intelligence, CRM software, and plenty others.`}</p>
    <p>{`There are also advances in communications, new lifestyle trends, and a new workforce generation. `}<a parentName="p" {...{
        "href": "https://www2.deloitte.com/content/dam/Deloitte/mx/Documents/human-capital/The_digital_workplace.pdf"
      }}>{`According to Deloitte`}</a>{`, the rise in the popularity of the digital workplace can be attributed to three fundamental trends:`}</p>
    <ul>
      <li parentName="ul">{`Aging workforce: as the baby boomers continue to retire, they are taking key knowledge with them, increasing the need to capture their knowledge.`}</li>
      <li parentName="ul">{`Information overload: information is still growing at an exponential rate and employees can’t find what they need, even with advances in technology.`}</li>
      <li parentName="ul">{`The need for speed: with the rapid pace of today’s work environment, employees increasingly need to work faster and collaborate more effectively to get their jobs done.`}</li>
    </ul>
    <p>{`Because of this, companies have realized that the digital workplace should be an organizational priority. Workplace transformation is essential for business success and longevity in today’s digital, consumer-centric world.`}</p>
    <h3>{`What For?`}</h3>
    <p>{`Company success.`}</p>
    <p>{`Matt Cain, VP analyst at Gartner, argues that “the end result of a successful digital workplace is greater customer-centricity, more employee-led innovation, accelerated process outcomes, higher employee retention and greater appeal to prospective employees.”`}</p>
    <p>{`In the research paper “`}<a parentName="p" {...{
        "href": "https://www.researchgate.net/publication/329844969_The_Need_for_Digital_Workplace_Increasing_Workforce_Productivity_in_the_Information_Age"
      }}>{`The Need for Digital Workplace: Increasing Workforce Productivity in the Information Age`}</a>{`” Mohsen Attaran, Sharmin Attaran, and Diane Kirkland argue that “business leaders expect their digital workplace solutions to raise employee engagement, enable employees to achieve business outcomes faster, and empower employees to reduce cost and increase efficiency.”`}</p>
    <p>{`According to Deloitte, a digital workplace can help with talent attraction, it can improve employee productivity, satisfaction, and retention, and it can improve company culture as it facilitates communication, collaboration, and connections. A digital workplace can also reduce stress and feelings of overload and burnout.`}</p>
    <p>{`A digital workplace can enhance the employee workplace experience as it enables a seamless work experience both in and out of the office and it providers workers with choice and flexibility.`}</p>
    <h3>{`Top Challenges`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "734px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9270b151a0260c13f32859ed627ba819/c6d67/7-img-1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "68.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACnklEQVQ4y4WTTWsTURSGo6CrKlKL/gA3/gRXoggiLly7FneCWIr4N+pXbAstKGrB1lpFQYpWbdMkTdJ2MpPMx72TmXzMdCbp5KtFqavXc28sttLSwOHMncw8857znhMD/WrNTfCgBafRhuVvILFaQEo1sVywKJi8FveSItaKyNB5IaMgpeiw1yNwP0LQ3hIoxHYDS/U27LAFvRriR07F19QKEgT4ksxhPpvHolHCPMG+00eWWBnFRgelrd/gm9sIur/2B4pwI3FuQq8EMgxS7dg1sJlPYG8/wpx6D05ZXLM3s2CvphBU/L1AoYwHERReQb5UQ1bnSGtU6oqKjFODm1hGePUawstXUKcQuXHxEmrXb8Ca+oAgjPYCGSnioh+UVcfDklJEknqUWCtgjTnUQ4bBByO4O/RUxuD9OG7fGcZEfBrVrW343Z//gB4BNdcnZVXk7ao0IqvbMqcprFIZ00YDxyc4YuM2jk7YiI1xnHjh4LXdQa3ehN/aZYpHBwHMGT3IEikTCtNkwCKZopkcs3qAs8/y6I8rOPlEwblxDXNmgHCjBYuq2uOy19qE6TWgUami3BWzhEyRI0sfSBLUsF3M6CH6R1ScHtVw5KGCW58dtDtdgrWkof+NTZdMaYNRDwUorVlI5Q0kKRZo9lSD4R0BBwh4iuLY4zweZT2EzQ5YuC+w57JFKos0JqKXovQczV2S4EKhAJ4Z09AXV3H+uQ7Na8KVc9veH7gzh+5GF4WyT9thSJW9HjJZ8sCoitiwgqFvZfhRBzzsvXMw8O8DPactWX5ql8K+eB43P9ooN3obZR8KlH82odgVmr2yzFkqu0gKX6rruDBpyhJdAu6oOxSo0lasMldujEobk7NccLeKyUKIe3McXj0iIw5W+Adwhs272H0MKQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "7 img 1",
            "title": "7 img 1",
            "src": "/static/9270b151a0260c13f32859ed627ba819/c6d67/7-img-1.png",
            "srcSet": ["/static/9270b151a0260c13f32859ed627ba819/772e8/7-img-1.png 200w", "/static/9270b151a0260c13f32859ed627ba819/e17e5/7-img-1.png 400w", "/static/9270b151a0260c13f32859ed627ba819/c6d67/7-img-1.png 734w"],
            "sizes": "(max-width: 734px) 100vw, 734px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Findings from The Digital Workplace Group and CMSWire’s 2018 “The State of the Digital Workplace”
Source: `}<a parentName="p" {...{
        "href": "https://www-cmswire.simplermedia.com/sv-dw-2018.html?smg"
      }}>{`The State of the Digital Workplace`}</a></p>
    <h3>{`What’s Next?`}</h3>
    <p>{`The digital workplace will continue to break down the barriers of organizations, teams, and industries by enabling digital and physical spaces that allow for frictionless collaboration, creativity and innovation.`}</p>
    <p>{`Paul Miller (DWG) believes the `}<a parentName="p" {...{
        "href": "https://www.cmswire.com/digital-workplace/how-to-define-the-digital-workplace-2030-and-why-it-matters-now/"
      }}>{`Digital Workplace of 2030`}</a>{` will evolve in four dimensions. The first one will explore how physical and digital spaces are becoming better connected. This will be fueled, in part, by advances and increased investment in `}<a parentName="p" {...{
        "href": "https://allwork.space/2019/01/proptech-what-is-it-and-how-does-it-fit-with-coworking/"
      }}>{`PropTech`}</a>{`.`}</p>
    <p><strong parentName="p">{` Suggested reading: `}<a parentName="strong" {...{
          "href": "https://allwork.space/2018/12/what-does-the-recent-influx-of-capital-in-proptech-mean-for-the-flexible-workspace-industry/"
        }}>{`“What Does the Recent Influx of Capital in PropTech Mean for the Flexible Workspace Industry?”`}</a>{` `}</strong></p>
    <p>{`The second dimension is capability. Miller explains this as “the realization that the digital workplace will absorb more and more of what we need to do each day.” This will be powered by technological advances like virtual and augmented reality and wearable devices. Capability also refers to existing technology’s actual capability and making sure it works, eliminating the struggles associated with them today.`}</p>
    <p>{`The third dimension is intelligence. Miller believes that by 2030, artificial intelligence will be more of a reality than a hype. Furthermore, big data and analytics will play a key role in the digital workplace, as they will provide valuable data that can help shape and improve behavior.`}</p>
    <p>{`The last dimension is beauty, “the icing on the cake”. Miller explains that beauty refers to a digital workplace that will look beautiful, but that will also have an ethical foundation and that will enable human development, wellbeing, and purpose. “Beauty here refers to the emotions we will feel, the relationship we will have as human beings with the services we use, empathy, feelings and atmosphere. It also means the ethics of the digital workplace and the way it protects those it serves: its sustainability.”`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      