import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-06-14T00:00:00.000Z",
  "title": "Future Of Work: The Shift Towards Employee-owned Hr\n",
  "slug": "future-of-work-the-shift-towards-employee-owned-hr",
  "excerpt": ["The future workplace is more flexible, and will enable employees to take greater control over the perks and benefits they receive.", "There is a shift towards employee-owned HR. In place of set benefits packages, new collaborative measures are empowering employees to choose their own workplace perks.", "“Choice is the best modern benefit. People want the authority to decide what is going to make a difference for them” - Gillian O’Brien, CEO of Cherry, a platform that lets employees choose their own perks and benefits.", "More personalization and more open conversations about what employees want and expect will continue to drive the shift towards employee-owned HR."],
  "images": ["15-the-shift-towards-employee-owned-hr.jpg"],
  "backlink": "https://allwork.space/2019/06/future-of-work-the-shift-towards-employee-owned-hr/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`As the world of work continues to change, HR departments are going through a transformation.
HR professionals today are tasked with redefining the workplace and company culture, while also focusing on attracting the best talent in a highly competitive global market.
“The power to mold the future of work lies in being able to address the change in generations, handle the challenges of technology, and find the culture that works best for the employees and the business of their respective companies,” `}<a parentName="p" {...{
        "href": "https://www.hrexchangenetwork.com/hr-tech/articles/future-of-work-and-hr"
      }}>{`HR Exchange recently wrote`}</a>{`.`}</p>
    <p>{`Gillian O’Brien, co-founder and CEO of `}<a parentName="p" {...{
        "href": "https://www.startcherry.com/"
      }}>{`Cherry`}</a>{`, a platform that lets employees choose their own perks and benefits, believes that there is a big shift happening towards employee-owned HR. “This isn’t to say that HR departments will be removed, it’s to say that HR is becoming more of an ecosystem of collaboration made up by employees, mentors, and leaders.”`}</p>
    <p>{`O’Brien attributes this shift to increased investment in empathy and empathetic leaders. Deloitte’s 2019 Human Capital Trends argues that 21st-century leadership has unique and new requirements that are key to business success; these include topics like transparency, fairness, social responsibility, and tech-savviness, among others.`}</p>
    <p>{`Empathetic leaders also understand that customization has happened in many areas of a person’s life, and they want that customization experience to also be available at work. As more leaders realize this, more personalization and more open conversations about what employees want and expect will continue to drive the shift towards employee-owned HR.`}</p>
    <p>{`We’ve already seen this happening within the workplace. An increasing number of companies are starting to implement flexible work policies, empowering employees to choose where and when they work. But there’s still a long way to go.`}</p>
    <p>{`Employee perks and benefits packages play a key role in talent attraction and retention. Each year, companies spend thousands of dollars to create benefits packages for employees. The problem with this is that companies typically task one person or a group of people to decide the benefits that all others will get. It’s an inefficient strategy, O’Brien argues; not only is it impossible to please everyone, but also a lot of money is wasted with these efforts.`}</p>
    <p>{`O’Brien, together with her sister Emily, created Cherry to tackle this specific problem. “The idea for Cherry was born when we noticed the wasted time, money, and effort many companies were spending on perks that few used.” For instance, these included free food that not everyone could eat, and gym memberships not many employees were taking advantage of.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "734px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ca01bcca18752c369caade4c573fce0a/c6d67/15-img-1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "68.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC80lEQVQ4y52T/W+TVRTH+1+aZWCADTQGMvmBFmM2oyCwrmwawdLRRgIJwQU2ExVMCAsr7dNGESGyzMHCNtlWnrXPa5/3t4+3T1tDIj95km/uPefc+z3nnnNP5nQ2x6lTWbICucE63OeEL/u2Teh9ZDmdy/HxxAS1Wo2exHGcrpmp6S8pzF1kenaGc3MznP+qwPm5Aud6+myeC18X/rWdvZjnTOECZwvTzBa/4eQnWSanplKiJElSZHqKJeAzlIS3ZRB4qKWIxWnD0th6/YqZQgFN1wnDsJ8hYUzJ+YvPtGfQiZF3W9jmLoHnEocuO68UdrdfY6htIt/B7ao8fHif7xdu8cXkpxw/8RF3lhZpNhsDQpFQ0d3koFxkWzvJmnyLzyeeo2uBcIcYnse+ouG6bnrBsrv8Xq+x5G2xbVuEmi2sXSSpRhhFPcKYtdBiRSmz4UzyvH2Za5c28Hs1SAIUr4sdCDJxLo5D/Chg/fETTsg1bmrz7LYWuHNjg0fVR2ljMnEcpZENL0wLGXk9TelXMw4wVY/W3j6OY6U2VZVpVldodNb4w5zlhXyZu4svqdfqRIIrEycRbxyTH7qbyIhMBP+WIqEnv6U92I8MFNskcW3c/R1ckfrTp0/wTRNEVdYDL21UsyERDZ8sBW2WnG0MUdA41vnb+hEreZE23O46qIpOpy3T1TUMXaFRW8H0bR47La4r6+iC8FdpQBiKiLH4P2pXR5Hf0BYXvcAUTtGSwKGlm+zJHRRBtqN1RABTdLSObztsihSexS/TUjSk+iDD/yGrq39iiw77lsqOsZD+5IbU7BOWyyXKVwXK81QqffT00pVv6fkq8yXmS0UqwnatcpUrxUvcuP4dgR+ieqvsOctp9xvDJx8+Ms7hsT6OjB1l/OgHjI0f472RUQ4J34H3DzEyeoDxYx8yJnwjowfJ5/Nppr74m77bn7FmU0qnJaOLsXkXDMP4z17VNPFUh2q1yoKYlF/u/cy9uz+xtHib5eUHKfE/0erHvJzLw/8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "15 img 1",
            "title": "15 img 1",
            "src": "/static/ca01bcca18752c369caade4c573fce0a/c6d67/15-img-1.png",
            "srcSet": ["/static/ca01bcca18752c369caade4c573fce0a/772e8/15-img-1.png 200w", "/static/ca01bcca18752c369caade4c573fce0a/e17e5/15-img-1.png 400w", "/static/ca01bcca18752c369caade4c573fce0a/c6d67/15-img-1.png 734w"],
            "sizes": "(max-width: 734px) 100vw, 734px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The Cherry platform aims to make it easier for employees to choose their own workplace perks.`}</p>
    <p>{`The company was founded in November 2018, and it later graduated from Y Combinator’s accelerator program in March of this year. Cherry’s goal is to help companies have more efficient budgets by letting people choose their own perks.`}</p>
    <p>{`The platform understands and recognizes that everyone is in a unique place in their lives, and they need different resources and tools to support their lifestyle.`}</p>
    <p>{`Cherry can help attract and retain employees, but it can also provide organizations with data about what people want and value. Popular perks that employees can select include personal development, language, mental health, subscriptions to streaming services, and more.`}</p>
    <p>{`For example, the most popular benefits among Millennial employees are personal development benefits — like language learning and audible books — as well as Netflix and Spotify subscriptions or Uber and Lyft rides.`}</p>
    <p>{`Other popular perks include mental health and grocery delivery services. The list of offerings is continually growing, “we’re seeing people interested in telemedicine and pet insurance, so we’re starting to add things like this into our platform.”`}</p>
    <p>{`To help more companies understand which perks individuals value and engage with, `}<a parentName="p" {...{
        "href": "https://www.startcherry.com/perk-generator"
      }}>{`Cherry launched a Perk Generator`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "734px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b57ad638b3bb5c9b145c2326f80c8041/c6d67/15-img-2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "68.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACsUlEQVQ4y01TyW4TQRCdL+CEECQhLOKC+AMkLlwRiI/gnxAXIAQBPxAhEiNAcApbIgJx7NjjJR7bscezdPfs3Y+qHhNxeKruelXVr6q7nRWzg+vmPdbIXiO7SvYc3uIhvuKm+YB12l81DRtzyWxjDTt4gF3yN3CFcHnJX8Q2zuMdnOdw8RI9bC6xYVw81m1s6RHeoI9XhNdLu0mxz3QXW2aEDVr/y3lB66emiyf6GA4SBQgBSFHbPAN0SajAXBWGQBzDRJHdn3FSWr/NYZS55ZyjQQ/fD5vYa7WsHftTVCaFNjmG0zH22200ey4OOscYzSbQSFHqDO3hAL+7Xfxxu2Q7EGmEkvIcj5IOOx0MxyMcuS4msylgSKUpMA/mOJl49R6FtbpKYHSO6fwU49MJ+XMLo1PLOfNghs5gACEDSqidNokUlgsfVS5pndoELK0hhWXgU5fSHlJztXWqKIDJaTbIlkp4hqktmMgIUoV0WAilIkTxAmWhrEJFPilrThIXiwA5HeCklBTHASJycAIjTWnYKOFRSzyGZtdFu9fDwBvZOG5/MPbQIl+738cxgXkekbMIfbgnQztDhjed2JO5YBD5GNNFsEqVRP+1nMOnZJ4hg+fJ4Bhn5s/Q9+pCTLJ0boHmQIUFQno2ukrPLoTX9lLo8nqjEyumT5bVcq4TkgqpaDZljLyIkOURikLYgkooREFMewmtFRUjLBXW6j3MFlNSx10ENs5JlULsF4QK8aKyVkTK3iS3CEOPWJsalT675SSRFJchT0tUBcfUnFPmKcLsAFP1GRPxBX62S58ltiq4vaSiR1v+skh0y6pkTsQC3vwAc/UTUu9B6aP6HfLJTTzCp+wGPopb+IE7yOkf84m6ktjHfeyoC9iOV/ANt1FUU6tWm5C4u2iIdTTkKq3v2fi/62wMs++ODhkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "15 img 2",
            "title": "15 img 2",
            "src": "/static/b57ad638b3bb5c9b145c2326f80c8041/c6d67/15-img-2.png",
            "srcSet": ["/static/b57ad638b3bb5c9b145c2326f80c8041/772e8/15-img-2.png 200w", "/static/b57ad638b3bb5c9b145c2326f80c8041/e17e5/15-img-2.png 400w", "/static/b57ad638b3bb5c9b145c2326f80c8041/c6d67/15-img-2.png 734w"],
            "sizes": "(max-width: 734px) 100vw, 734px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The future workplace is more flexible, and will enable employees to take greater control over the perks and benefits they receive.`}</p>
    <p>{`“We know perks are especially interesting to millennials (89% would prefer new or additional benefits over a pay raise `}<a parentName="p" {...{
        "href": "https://www.glassdoor.com/blog/ecs-q3-2015/"
      }}>{`according to Glassdoor`}</a>{`) so we decided to create the Perk Generator: an overview of the 100+ company benefits offered today. On shuffle mode.”`}</p>
    <p>{`The list ranges from traditional perks like free food, snacks, and coffee, to paternity leave, in-office manicures, “Yay Days”, and on-site acupuncture.`}</p>
    <p>{`“We hope to show how companies are developing stronger cultures and making a difference for their employees through the benefits they offer,” O’Brien added.`}</p>
    <p>{`“We really believe that choice is the best modern benefit; people want to feel like they are in control, that they have autonomy, that are being recognized and are treated fairly. Giving someone a budget and allowing them to choose for themselves goes a long way. People want to have the authority to decide what is going to make a difference for them.”`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      