import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-05-09T00:00:00.000Z",
  "title": "Smart Workplaces: 4 Technologies Powering The Future Of Work\n",
  "slug": "smart-workplaces-4-technologies-powering-the-future-of-work",
  "excerpt": ["Simon Eastlake, Developments Director at Office Space in Town, discusses the smart technologies that are transforming the workplace.", "Demand for smarter technologies as a part of office design is growing exponentially, and driving digital transformation in the workplace.", "Adaptive technologies are being adopted by businesses and integrated into the very fabric of our buildings.", "From smart window panes to climate control technology, here are 4 technologies powering the workplace of the future."],
  "images": ["10-4-TECHNOLOGIES-POWERING-THE-FUTURE-OF-WORK.png"],
  "backlink": "https://allwork.space/2019/05/smart-workplaces-4-technologies-powering-the-future-of-work/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Simon Eastlake is Developments Director at Office Space in Town (OSiT), a provider of luxury serviced offices in the UK.`}</p>
    <p>{`The average UK office employee will spend an astounding 10,000 days at work in their lifetime, a considerable proportion of which will be spent in the office.`}</p>
    <p>{`The physical office environment, therefore, should promote wellbeing and be a facilitator of productivity. As technology is also a facilitator of productivity, the future office must look to integrate the two.`}</p>
    <p>{`Demand for smarter technologies as a part of office design is growing exponentially. According to the Global Workforce of the Future Insights Survey 2018, 78% of respondents believe that smart technologies will most significantly change the workplace by 2020.`}</p>
    <p>{`The appetite for digital transformation comes not only from business leaders, but from all employees. According to research by Aruba Networks, 71% of employees think the workplace of the future should be fully automated, and 72% think it should automatically update and adjust itself.`}</p>
    <p><a parentName="p" {...{
        "href": "https://allwork.space/tag/future-of-work/"
      }}>{`The future office`}</a>{` will see adaptive technologies being not only adopted by businesses, but integrated into the very fabric of their buildings.`}</p>
    <p>{`Here are some of the technologies we can expect to see:`}</p>
    <h3>{`1. Raise a glass: smart glass`}</h3>
    <p>{`Lighting in the office is one of the most important ergonomic factors, but surprisingly guidelines on office lighting are not legally enforceable. While people want a level of control over their environment, time spent adjusting and re-positioning lamps to provide enough light, or tampering with the blinds to reduce glare causes significant disruption to the office day.`}</p>
    <p>{`It is no surprise that the `}<a parentName="p" {...{
        "href": "https://allwork.space/2019/04/11-tricks-to-maximize-natural-light-in-the-workplace/"
      }}>{`quality of light in the office can impact our wellbeing`}</a>{`. It has been shown that our cortisol levels drop considerably under artificial and poor lighting conditions, causing higher levels of stress and an inability to stabilise our energy levels.`}</p>
    <p>{`Well managed natural light in an office is essential. Studies have shown that natural light in an office reduces headaches, eyestrain and drowsiness by more than 50%, significantly improving the `}<a parentName="p" {...{
        "href": "https://allwork.space/2019/04/11-tricks-to-maximize-natural-light-in-the-workplace/"
      }}>{`health and wellbeing`}</a>{` of employees. But as natural light shifts throughout the day, its impact needs to be adjusted.`}</p>
    <p>{`The development of e.g. ‘View Dynamic Glass’ is enabling us to do that. ‘Smart’ electrochromic windows using this glass can adapt to and control the sun’s energy, automatically tinting to maximise natural light and reduce heat and glare. Not only is this a sustainable development, but importantly can boost employees’ mood, energy levels and overall workplace wellbeing. `}<a parentName="p" {...{
        "href": "https://allwork.space/2019/01/office-space-in-town-to-launch-high-spec-serviced-offices-in-londons-midtown/"
      }}>{`Office Space in Town’s new development at London Blackfriars`}</a>{` will be the first building in the UK to incorporate these intelligent windows, showcasing how technology can be used to optimise natural light to the benefit of businesses.`}</p>
    <h3>{`2. Breathing space: air quality`}</h3>
    <p>{`Like light, air quality in an office can impact productivity. Sick Building Syndrome, or SBS is common in some buildings that have been designed without fully considering the impact of air quality on occupants. The adverse effects of poor air quality contribute to employee fatigue and impaired focus, and can cause an array of health and respiratory problems.`}</p>
    <p>{`Air purifiers that continually adapt to indoor air quality can support an optimal working environment. The adaptive technology is designed to optimize air filtration by removing harmful impurities and pollution as well as providing an efficient ventilation system for greater circulation of fresh air. The result? Better productivity, lower absenteeism and greater overall employee wellbeing.`}</p>
    <h3>{`3. Some like it hot: ambient temperature`}</h3>
    <p>{`Room temperature in the workplace remains a contentious issue. While the Approved Code of Practice suggests the minimum temperature in a workplace should normally be at least 16 degrees Celsius, the Chartered Institute of Building Services Engineers recommends 20°C for offices. Despite recommended figures, according to a survey conducted by CareerBuilder, only 54 percent of workers say their workplaces are just the right temperature.`}</p>
    <p>{`By using a smart climate control technology, the temperature can be maintained at an optimum level which is best suited to promote productivity. These automated systems adapt to the current temperature throughout the workspace and tailor the heating and cooling flows to raise or lower the levels accordingly, to maintain a consistent, appropriate temperature.`}</p>
    <h3>{`4. Virtually there: VR`}</h3>
    <p>{`While `}<a parentName="p" {...{
        "href": "https://allwork.space/2019/03/where-is-all-the-vr-tech/"
      }}>{`still nascent as an office must-have`}</a>{`, Virtual Reality could provide the meeting room of the future. A VR headset offers a collaborative and immersive virtual environment where meetings can be held, without the need for expensive travel or faceless conference calls.`}</p>
    <p>{`Once a ‘nice to have’, now adaptive technologies are becoming an integral part of the digital office of the future, promoting workplace wellbeing and productivity. It’s time to make our buildings, as well as our employees, work smarter.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      