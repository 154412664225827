import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-03-20T00:00:00.000Z",
  "title": "Future Of Work: Remote Workers Want Flexibility, Not Complete Autonomy\n",
  "slug": "future-of-work-remote-workers-want-flexibility-not-complete-autonomy",
  "excerpt": ["Contingent workers can fill vital skills gaps, providing companies give them what they want most, flexibility.", "Most people work remotely out of choice rather than necessity; they value flexibility and autonomy over traditional workplace perks.", "Allwork.Space spoke with Mike Ryan, Client Strategy Director at CR Worldwide, to learn what companies can do to attract remote talent."],
  "images": ["remote-workers-want-flexibility-not-complete-autonomy.png"],
  "backlink": "https://allwork.space/2020/03/future-of-work-remote-workers-want-flexibility-not-complete-autonomy/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Remote work is on the rise and alongside it is the rise in the number of contingent workers across the world.`}</p>
    <p>{`The COVID-19 pandemic seems to be accelerating the adoption of remote work and as companies lay off full-time staff, many may turn to contingent workers in the future to fill skills gaps and carry out projects.`}</p>
    <p>{`While the contingent workforce has been steadily growing over the past several years, there are still various misconceptions about these workers. Mainly that they are contingent due to necessity rather than choice and that they’re not as interested in being a core part of a company.`}</p>
    <p>{`Allwork.Space spoke with Mike Ryan, Client Strategy Director at `}<a parentName="p" {...{
        "href": "http://www.crworldwide.com/"
      }}>{`CR Worldwide`}</a>{`, to clear some misconceptions about remote and contingent workers and what companies can do to keep attracting remote talent.`}</p>
    <p>{`Ryan is of the belief that remote and contingent workers, contrary to popular belief, do not desire separation from the full-time workforce and the isolation that comes with it. Rather, this group of distributed workers values social interaction, peer approval and recognition as much as their full-time counterparts. They are seeking flexibility, rather than complete autonomy.`}</p>
    <p><strong parentName="p">{`Allwork.Space: How is the workforce changing?`}</strong></p>
    <p>{`Mike Ryan: We are witnessing the rise of an increasingly transient workforce with the modern worker increasingly choosing flexible hours over traditional fixed contracts and physical offices. This flexible, free-floating workforce is outside full-time work by desire rather than necessity.`}</p>
    <p><strong parentName="p">{`Allwork.Space: What are some of the things this workforce is looking for from a job or company?`}</strong></p>
    <p>{`The modern worker values flexibility more than a full-time employee and comes with multi-faceted experience from a diverse array of employers.`}</p>
    <p>{`Contingent workers may be physically disconnected from the regular workforce but they are not emotionally disconnected. They desire peer approval, recognition and acknowledgement just as much as their full-time colleagues. Younger contingent workers want the ability to make a tangible impact on corporate performance and value the opinions of coworkers sometimes more so than senior managers.`}</p>
    <p><strong parentName="p">{`Allwork.Space: You said previously that the contingent workforce is outside of full-time work by  desire and not by necessity. Why is this?`}</strong></p>
    <p>{`Generally, most workers nowadays do not anticipate remaining in the same job for more than two years. The new generation of the workforce has a different set of values than previous generations who preferred job stability as a priority. They consider the benefits of job flexibility, variety in type of work, employer brand, and even the corporate social responsibility initiatives of companies.`}</p>
    <p>{`There is researched evidence that the rise of the contingent workforce is abundant – they account for more than one in five workers at over half of US and UK enterprises. We’re also seeing businesses increasingly competing for this new workforce with some large enterprises spending a significant amount of their labour budget on this set.`}</p>
    <p><strong parentName="p">{`Allwork.Space: You say that remote and contingent workers desire peer approval and acknowledgement as much as full-time employees. So, how can organizations engage contingent employees and make them feel part of the company?`}</strong></p>
    <p>{`Pioneering enterprises now include flexibility in their brand values, embodied in everything from office collaboration tools and flexible hours to rewards that can be instantly redeemed from any location via smartphones.`}</p>
    <p>{`The enterprises that attract these workers are ones that create ‘flat’, democratic workforce structures that ensure all voices are heard and provide space for ideas and innovations from rank-and-file employees.`}</p>
    <p>{`In the eyes of every worker, peer-to-peer recognition and getting a message of appreciation from a colleague is one of the best ways to strengthen the consistency and credibility of the brand’s inside identity.`}</p>
    <p><strong parentName="p">{`Allwork.Space: You’ve said before that there are various misconceptions around contingent and remote workers. What are some of the most predominant ones?`}</strong></p>
    <p>{`One common misconception is the belief that, in a full economy, contingent workers have no other choice. The reality is that most people are opting to work as paid outsiders not because they “have to” but because they “want to”. They enjoy the autonomy and self-direction that comes with working as a contingent and in many cases are choosing it over full-time employment.`}</p>
    <p>{`There is also a rise in the importance of what these workers do. Perceived as hired help, contingent workers are not just managing everyday deliverables. Many are taking on more noticeable and more important tasks within those companies they are working with.`}</p>
    <p><strong parentName="p">{`Allwork.Space: Do you think workers will want to go back to the full-time model in the future?`}</strong></p>
    <p>{`Some might, but most won’t. It’s just a question of how they identify as workers. Many contingents have developed both the track record and the inside relationships with corporations that give them a say over what they do; they have gained control over where they do it from, and complete freedom when they work.`}</p>
    <p>{`That type of independence is hard to give up.`}</p>
    <p><strong parentName="p">{`Allwork.Space: Do you have any recommendations for companies employing contingent workers?`}</strong></p>
    <p>{`Just because contingent workers are technically “outsiders” that doesn’t mean they should be treated as such. Companies need to look at (and treat) contingent workers like they do their full-time employees.`}</p>
    <p>{`Not enough companies do so now. Nor do enough have a plan for doing so moving forward.`}</p>
    <p>{`For example, they should be incorporating contingent workers into their employee programs (including and especially their employee recognition programs).`}</p>
    <p>{`Contingents want certain things from a working environment. Such as: to not feel like an outsider; to build up networks with people they work with, and to have a say in both what they do and how they do it. A company’s employee recognition program is generally structured to give them more of each of those things.`}</p>
    <p><strong parentName="p">{`Allwork.Space: Anything else you’d like to add?`}</strong></p>
    <p>{`With the coronavirus starting to show its ugly power, it’s possible that many organizations will have a need for contingent workers as they fill in for sick employees. Keep in mind that how you treat freelancers and consultants will dictate how they view your organization moving forward.`}</p>
    <p>{`Along those lines treat them like you might part of the team. Stop and acknowledge their efforts and reward them for their contributions like you would any of your full-time workers. Do that and you will be giving contingents what they crave. And in the process, you should be able to build up your network of freelance help as they will see your company as a more desirable place to work.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      