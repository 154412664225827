const imagePathGenerator = (image: string, slug?: string) => {
  if (slug) {
    const images = require.context(`../../src/content/posts/`, true);
    return images(`./${slug}/images/${image}`);
  } else {
    const images = require.context('../images/', true);
    return images(`./${image}`);
  }
};

export default imagePathGenerator;
