import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-05-24T00:00:00.000Z",
  "title": "How CRE Can Help Drive Business Resilience And Performance\n",
  "slug": "how-cre-can-help-drive-business-resilience-and-performance",
  "excerpt": ["Are you using your real estate to its full potential? This article takes a closer look at how to leverage your workplace resources to address the needs of your workforce.<ul>The future workplace aims to enhance its user’s lifestyle by providing flexibility, choice, wellness, and technology to create a seamless experience across all touchpoints.", "A new report by JLL, ‘Top 10 Global CRE Trends in 2019’, demonstrates how leading companies are already using these workplace elements as a business growth engine.", "Among them, people want hospitality-style workplace environments powered by amenities; JLL’s research shows that this can greatly contribute to a company’s talent retention efforts."],
  "images": ["12-HOW-CRE-CAN-HELP-DRIVE-BUSINESS-RESILIENCE-AND-PERFORMANCE.png"],
  "backlink": "https://allwork.space/2019/05/how-cre-can-help-drive-business-resilience-and-performance/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`JLL’s “Top 10 Global CRE Trends in 2019” report examines global corporate real estate trends, shedding light on how companies can use the workplace as a growth engine.`}</p>
    <p>{`The concept of work and the workplace has evolved over the past few years, which has driven companies to rethink their workplace strategies. The workplace of the future (and to a certain extent already today) is about creating unique experiences that contribute to an individual’s lifestyle by providing flexibility, choice, carefully designed spaces that contribute to health and wellness, and spaces that incorporate technology to create a seamless experience across all touchpoints.`}</p>
    <h2>{`The Role of CRE and Top 10 Trends`}</h2>
    <p>{`As the war for talent intensifies, a new generation of workers enters the world of work, and technology continues to disrupt the way people work, companies need to take a closer look at how they are using their available real estate to make sure their company is leveraging the right tools and resources correctly, as well as to make sure that they are addressing the needs of the current workforce generation.`}</p>
    <h3>{`1. Future Fit Enterprise`}</h3>
    <p>{`Future fit enterprises are companies that are prepared to pilot innovative workplace approaches, like integrating CRE planning with HR, experimenting with new technologies, using a broad outsourcing strategy, and encouraging collaboration across business units. According to JLL, 48% of companies that have implemented these types of strategies saw their profits grow 48% more than other businesses over the last three years.`}</p>
    <p>{`“Looking ahead, it will be businesses that engage CRE as a lever to drive broader business goals that will lead the future of work.”`}</p>
    <h3>{`2. Digital Imperative`}</h3>
    <p>{`According to JLL, technology will be the number one investment priority over the next 3 years in order to pave the way towards a successful digital transformation.`}</p>
    <p>{`Companies will need to invest in technologies that can integrate CRE with their respective IT units. According to JLL, the top CRE tech priorities are as follows: workspace and employee experience applications, smart technologies and IoT, integrated workplace management systems or integrated data warehouse solutions, automated end-to-end workflow, self-service business intelligence, advanced technologies (e.g. 3D printing, drones, robotics), machine learning, and virtual reality and immersive technology.`}</p>
    <p>{`Investing in the right technology can drive business performance, collaboration levels across employees, and innovation.`}</p>
    <h3>{`3. Inclusive Workplace`}</h3>
    <p>{`According to JLL, an inclusive workplace is one that embraces, respects, and celebrates diversity. Creating an inclusive workplace “drives innovation, creativity, and results.”`}</p>
    <p>{`Inclusive workplaces are ethnically and gender diverse. According to JLL findings, ethnically diverse companies are more likely to perform 35% better and gender diverse companies are more likely to perform 15% better. It’s also important to add that inclusive workplaces cater to the needs of different workforce generations — this is especially important as there will be 4 different generations working together by 2020.`}</p>
    <p>{`One way companies can improve their workplace inclusivity is by embracing alternative work arrangements. These types of arrangements enable certain groups (mothers, women, students, single parents, etc.) to better balance their personal and professional lives.`}</p>
    <h3>{`4. Human Performance`}</h3>
    <p>{`This is about creating an environment where people thrive in order to boost employee performance. This can be achieved by “establishing an optimal relationship between people, place, and technology.” Companies should think about creating spaces that are dedicated to health and wellbeing as they can have a positive impact on employee engagement.`}</p>
    <p>{`According to JLL, 55% of employees believe that access to the latest workplace technology can make them more productive. Similarly, 81% of executives believe that rather than taking away jobs, AI will work next to humans, augmenting human capabilities.`}</p>
    <h3>{`5. Flex`}</h3>
    <p>{`By incorporating flexible and agile space into their operations, companies can respond to the needs of a dynamic workforce. It can also help optimise and maximize a company’s corporate real estate.`}</p>
    <p>{`Although 28% of employees already work flexibly, research by Mercer found that 51% of employees want their company to offer more flexible working arrangements. JLL predicts that “2019 will see flex space concepts become increasingly common across a diverse user base.”`}</p>
    <h3>{`6. Urban Futures`}</h3>
    <p>{`According to JLL, “cities are constantly evolving as new drivers emerge and competitive positions change.” Understanding the dynamics of next-generation cities can impact a company’s CRE decisions, specifically location and portfolio strategies, in order to access the right talent.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/12-img-1",
        "alt": null
      }}></img></p>
    <p>{`JLL’s typology of world cities sets out the ‘innovators’, ‘hybrids’, ‘megahubs’, and more.`}</p>
    <h3>{`7. Responsible Enterprise`}</h3>
    <p>{`There is a mounting pressure for companies to be more responsible and accountable. JLL believes companies should “embed transparency across the company’s operations to build a responsible brand, trusted by stakeholders, clients and wider community.”`}</p>
    <p>{`Key attributes of a responsible enterprise include: responsible procurement, data privacy and ownership, health and wellbeing, environmental impact, transparency and social impact, diversity and inclusion.`}</p>
    <p>{`As a side note, in the flexible workspace industry there are a few operators and organizations that are operating as social enterprises or have been certified as B corporations in order to become responsible and sustainable companies.`}</p>
    <p><a parentName="p" {...{
        "href": "https://allwork.space/2019/04/becoming-a-coworking-benefit-corporation-a-qa-with-hera-hubs-felena-hanson/"
      }}>{`“Becoming a Coworking Benefit Corporation”`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://allwork.space/2019/03/how-all-good-coffee-is-making-a-difference-in-coworking-spaces-one-cup-of-coffee-at-a-time/"
      }}>{`“How All Good Coffee is Making a Difference One Cup of Coffee at a Time”`}</a></p>
    <h3>{`8. Collaborative Ecosystem`}</h3>
    <p>{`JLL’s report found that “reinvention and new ways to innovate are core enterprise priorities.” Collaboration networks can lead to innovation and power a company’s growth.`}</p>
    <p>{`When asked: Which of these approaches do you believe would have the biggest impact on CRE innovation? 67% of JLL respondents answered partnering with innovative companies, 57% answered developing their own internal capabilities, and 32% answered funding their own projects directly with providers and suppliers.`}</p>
    <h3>{`9. Metrics that Matter`}</h3>
    <p>{`JLL found that 30% of CRE leaders acknowledge that a lack of access to effective data and analytics is one of the top three constraints on their ability to add value to business. JLL believes that by 2020, 7 out of 10 top metrics will be non traditional and these include metrics such as: utilization of innovation spaces, employee fulfillment, health and wellbeing, digital intensity of CRE budget, and digital share of CRE budget, among others.`}</p>
    <h3>{`10. Space Activation`}</h3>
    <p>{`People want more vibrant workplace settings and they are increasingly looking to workplaces that are powered by amenities and offer a hospitality-driven experience. There’s still much work to be done, as JLL found that 72% of occupiers wish their office building had better or more amenities. Activating a space and offering the right amenities can be a huge talent driver and it can also greatly contribute to a company’s talent retention efforts.`}</p>
    <p>{`According to JLL:`}</p>
    <ul>
      <li parentName="ul">{`62% of employees want access to fitness and wellness offerings`}</li>
      <li parentName="ul">{`38% want convenience services like dry cleaning and petcare or childcare`}</li>
      <li parentName="ul">{`62% want food deals (e.g. food discounts, coupons)`}</li>
      <li parentName="ul">{`21% want beer and wine experiences`}</li>
      <li parentName="ul">{`38% want networking opportunities`}</li>
      <li parentName="ul">{`14% want beauty offerings`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      