import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-10-09T00:00:00.000Z",
  "title": "Future Of Work: What Does The Ideal Working Week Look Like\n",
  "slug": "future-of-work-what-does-the-ideal-working-week-look-like",
  "excerpt": ["A survey of 1,000 office workers found that younger workers expect a more flexible working week, suggesting that “the growing pursuit of flexibility could continue for generations to come.”", "Despite the fact that workers want a shorter work week and more flexibility, many still see added value in going into the office.", "All age groups miss the office; the top two reasons people miss the workplace is the social aspect, and being able to bounce ideas off other people."],
  "images": ["Future-of-Work-Template-Articles-1280-x-720-9-800x450.png"],
  "backlink": "https://allwork.space/2020/10/future-of-work-what-does-the-ideal-working-week-look-like/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The work environment has dramatically changed since countries went into lockdown in March 2020. As a result, the way people work on a day-to-day basis has shifted.`}</p>
    <p>{`A recent report by `}<a parentName="p" {...{
        "href": "https://www.workthere.com/en-gb/news-guides/research/how-has-the-uk-ideal-working-week-changed-since-2019/"
      }}>{`Workthere`}</a>{` looks at workers’ current working week and what the ideal working week looks like. The report compiled the answers of 1,000 UK office workers. `}</p>
    <h3>{`Work Hours: 2019 vs 2020`}</h3>
    <p>{`Last year, Workthere published a similar report that found that UK workers spend an average of 38 hours per week in the office. Their most recent report found that people are currently working on average only 32 hours, a 16% drop in hours worked. `}</p>
    <p>{`The report noted a difference among worker demographics. “Workers aged 45-54 years currently work the longest week (36 hours), followed by the 35-44 age group (34 hours). The upper and lower age groups of 18-24 and 65+ years are working the shortest hours at the moment.”`}</p>
    <p>{`Despite the fact that workers are, on average, working less hours than last year, and that they are working what many would have considered “ideal” hours last year, “as situations change, so do expectations.”`}</p>
    <p>{`According to this year’s results, the ideal working week is now 29 hours. “The trend of ideal working hours mirrors that of the actual working hours, in the sense that the 35-54 age group would prefer to work slightly longer hours, which could well explain why they actually do.”`}</p>
    <p>{`Additionally, workers aged 45-54 reported to be twice as likely to work more than 38 hours per week when compared to 25-34 year-olds. In fact, 40% of the former group admitted to overworking, compared to just 20% of the latter group. `}</p>
    <p>{`Although they are already working shorter hours, younger workers reported a desire to work fewer hours. 86% of workers aged 25-34 stated that their ideal working week would be less than 38 hours, compared to 78% of 45-54 year-old workers stating the same. `}</p>
    <h3>{`Expectations for Flexible Work`}</h3>
    <p>{`The ideal working week isn’t just about the amount of hours worked; it also brings forth other key elements, like flexibility. `}</p>
    <p>{`The survey found that 29% of workers had access to flexible working arrangements pre-COVID and an additional 47% expect these arrangements going forward.  `}</p>
    <p>{`As with the amount of hours worked per week, expectations vary among workforce demographics. For example, “58% of 35-44 year olds expect their employer to offer flexible working going forwards compared to only 16% of those in the 65+ age group.”`}</p>
    <p>{`Furthermore, according to Workthere, “those aged 25-34 years old are most likely to expect more flexibility in their typical working week in a post COVID-19 world.”`}</p>
    <p>{`Of this group, 87% of workers now expect to have input on flexible working moving forward. Workthere notes that this is a “stark contrast to the older generation of employees”, where only 60% of those 55+ share that same view. `}</p>
    <p>{`Given that younger workers are the ones expecting a more relaxed approach to work, shorter hours, and more flexibility, Workthere predicts that “the growing pursuit of flexibility could continue for generations to come.”`}</p>
    <h3>{`What about the office?`}</h3>
    <p>{`The report identified that workers across all age groups miss the office. When asked about which aspects they miss or have missed the most, here is what workers replied: `}</p>
    <ul>
      <li parentName="ul">{`40% missed/miss the social aspect of my office`}</li>
      <li parentName="ul">{`23% missed/miss being able to bounce ideas off colleagues`}</li>
      <li parentName="ul">{`21% missed/miss having a change of scenery`}</li>
      <li parentName="ul">{`18% missed/miss having a break from home life`}</li>
      <li parentName="ul">{`17% missed/miss having a clear separation between work and home.`}</li>
    </ul>
    <p>{`Interestingly, the report found that younger generations were the most likely to miss the office, with 80% of workers aged 25-34 leading the way.`}</p>
    <p>{`According to Workthere, “the social aspect is, by far, what people miss most about their office (40%) but interestingly it is the 35-44 year olds who miss the social aspect most (44%), with the 25-34 age bracket coming second (42%).” `}</p>
    <p>{`Organizations will need to find ways to accommodate worker expectations. Despite the fact that workers want a shorter work week and more flexibility, many still see added value in going into the office. `}</p>
    <p>{`“With the hybrid working model of office based work and working from home set to continue longer term, these findings suggest developing demand for better flexibility among the future generations of office workers, which Workthere predicts will drive demand for flexible office spaces.”`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      