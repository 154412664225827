import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-09-04T00:00:00.000Z",
  "title": "Future Of Work: Capturing Data And The Rise Of Tex (Tenant Experience) In Cre\n",
  "slug": "future-of-work-capturing-data-and-the-rise-of-tex-tenant-experience-in-cre",
  "excerpt": ["Office buildings and workspaces are here to stay, but this doesn’t mean we’ll go back to business as usual after the pandemic.", "CRE will now have to compete with hybrid and mobile work models, so what can landlords and operators do to ensure their properties remain attractive and full of activity?", "One solution, according to HqO, “resides in the adoption of technology and the practice of capturing tenant data at a scale.”"],
  "images": ["capturing-data-and-the-rise-of-tex-tenant-experience-in-cre.png"],
  "backlink": "https://allwork.space/2020/09/future-of-work-capturing-data-and-the-rise-of-tex-tenant-experience-in-cre/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "https://www2.deloitte.com/us/en/insights/economy/covid-19/covid-19-implications-for-commercial-real-estate-cre.html"
      }}>{`Deloitte’s 2020 commercial real estate outlook`}</a>{`, based on a 2019 global survey, found that capital availability was set to increase in 2020. `}</p>
    <p>{`Then, the pandemic hit. `}</p>
    <p>{`And unlike previous challenges the CRE industry has overcome, Deloitte noted a few months ago that “rather than the typical lag, the CRE industry was affected immediately” by the coronavirus pandemic. “This was because trade activities and occupiers’ businesses were shut down,” according to Deloitte. `}</p>
    <p>{`While COVID-19’s impact on the CRE industry is undeniable, a recent `}<a parentName="p" {...{
        "href": "https://www.hqo.co/resources/downloadable-resources/the-office-isnt-dead/"
      }}>{`eBook from HqO`}</a>{` argues that office buildings are here to stay. `}</p>
    <p>{`Yet, this doesn’t mean life and work will go back to business as usual. `}</p>
    <blockquote>
      <p parentName="blockquote">{`“Until now, the industry has always relied on the mere assumption that you need an office building in order to do work. With the new-age challenge of digital alternatives forced upon the CRE industry, how can property owners continue to maintain a successful business while keeping their offices afloat?”`}</p>
      <p parentName="blockquote">{`HqO`}</p>
    </blockquote>
    <p>{`CRE will now have to compete with hybrid, more mobile work models. This will have a tremendous impact on how people use and interact with commercial space; therefore begging the question of what landlords can do to ensure that their properties remain attractive and full of activity. `}</p>
    <p>{`According to HqO, the solution “resides in the adoption of technology and the practice of capturing tenant data at a scale.”`}</p>
    <p>{`Their recent eBook examines the importance of data, how it relates to the value of the office, and how it can help landlords create a better tenant experience (TeX). `}</p>
    <p>{`When used correctly, data can help property owners understand what tenants really want, what they value, and how they truly use a space.`}</p>
    <p>{`“In order for office buildings to survive, there needs to be a keen focus on attracting and retaining tenants, while creating unique workplace experiences that out-perform the home office.”`}</p>
    <p>{`Data can help landlords better target their efforts to match the desires and needs of the end-user. `}</p>
    <p>{`“If you’re actively collecting data from multiple sources about the needs of your tenants and their employees, you’ll be able to keep pace with the market and adapt to significant disruptors in your industry — even if that disruptor happens to be a global pandemic — while simultaneously making your workplace more attractive to its communities.`}</p>
    <h3>{`TeX: It’s about making informed decisions`}</h3>
    <p>{`If you’re not collecting data as a landlord, you’re already lagging behind. `}</p>
    <p>{`Data is a powerful tool and the right platforms can help landlords analyze human behaviors that can undoubtedly help them create a better user experience; an experience that actually responds to people’s needs and wants. `}</p>
    <p>{`Needless to say, this can increase customer engagement and continue to provide property owners with valuable data that is up to date. `}</p>
    <p>{`Even with remote work on the rise, `}<a parentName="p" {...{
        "href": "https://allwork.space/2020/05/future-of-work-the-office-isnt-dead-and-never-will-be/"
      }}>{`the office is still a core part of work`}</a>{`. `}</p>
    <p>{`Recent surveys have found that `}<a parentName="p" {...{
        "href": "https://allwork.space/2020/06/future-of-work-most-workers-want-to-come-back-to-the-office/"
      }}>{`workers miss the office and want to go back to it`}</a>{`, even if only part-time. This means that creating a well-designed, data-driven workplace is still important, especially as companies realize that the workplace can serve as a social hub that can drive productivity, performance, happiness, and collaboration. `}</p>
    <p>{`“In short, it’s simple: even with options to work remotely, people will be driven to seek out places where they can remain productive, collaborate with one another, experience workplace culture and connection, and immerse themselves in a daily routine outside of their homes.”`}</p>
    <p>{`Technology and, more importantly, data, can serve as a catalyst to improve office buildings. `}</p>
    <p>{`HqO predicts that “tenant experience (TeX) apps will become the primary vehicle for all future building-related updates and features.”`}</p>
    <p>{`These apps will be able to provide telemedicine via healthcare bundles, digital access and visitor management, push notifications for important building information, and digital office capacity management.`}</p>
    <p>{`CRE leaders will need to focus on improving the everyday tenant experience, now and in the future. Focusing on the TeX now can help tenants and end users feel safe, comfortable, and excited about returning to the office.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      