import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-08-21T00:00:00.000Z",
  "title": "Future Of Work: Property Owners Need To Start Thinking About Public Health\n",
  "slug": "future-of-work-property-owners-need-to-start-thinking-about-public-health",
  "excerpt": ["The difficult truth is, many buildings are unfit for occupancy during the pandemic. We need more ‘healthy buildings’.", "CRE groups are now working with tenants to mitigate the health risks inherent to enclosed spaces and large tenant populations.", "Matt McCambridge from Eden Health, which offers solutions for COVID-19 screening and employee health, discusses his belief that property owners are now in the business of public health."],
  "images": ["property-owners-need-to-start-thinking-about-public-health.png"],
  "backlink": "https://allwork.space/2020/08/future-of-work-property-owners-need-to-start-thinking-about-public-health/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The COVID-19 outbreak has brought to light an issue that’s been in the dark for far too long: we need healthier buildings. `}</p>
    <p>{`Eden Health, a US based medical practice that works with organizations to provide employees with medical care by providing a concierge medical service, is all too familiar with this matter. For the last 5 years, the company has been providing a service that integrates the navigation of healthcare (insurance, booking appointment, booking follow-ups, etc.) with the actual clinical delivery. `}</p>
    <p>{`Their service, up until last year, provided a mix of virtual and in-person care. With the outbreak of the coronavirus, the company quickly pivoted and now delivers its service mostly virtually. `}</p>
    <p>{`With the onset of the pandemic, Eden Health realized that many landlords were interested in offering concierge health services as an amenity in their properties. Now, `}<a parentName="p" {...{
        "href": "https://www.edenhealth.com/commercial-real-estate-concierge-health-amenity"
      }}>{`Eden Health is partnering with property owners`}</a>{` to provide on-site medical services and membership to its virtual primary care platform. `}</p>
    <p>{`CRE groups, as a result of the pandemic, are now working with tenants to mitigate the health risks inherent to enclosed spaces and large tenant populations. `}</p>
    <p>{`This means that property owners, whether they like it or not, are now in the business of public health and delivering healthcare. `}</p>
    <p>{`Allwork.Space talked with Matt McCambridge, CEO & Founder of Eden Health, about the new role that property owners have to fulfill: being in the business of public health. `}</p>
    <p><strong parentName="p">{`Allwork.Space: How have property owners and flexible workspace operators responded to the COVID-19 pandemic?`}</strong></p>
    <p>{`Matt McCambridge: Property owners have realized that they need to adopt clear operational models and policies that can support their tenants and the end-users of their buildings. `}</p>
    <p>{`There are two ways landlords could respond to the new needs of tenants. One was to let each tenant confront issues individually; issues like the anxiety of coming back into the building and protocols to help ease that anxiety and prevent the transmission of COVID-19. `}</p>
    <p>{`The problem with this approach is that many tenants, especially smaller organizations, are not necessarily equipped to do this. What’s more, it could create confusion among visitors. `}</p>
    <p>{`Which is why increasingly we are seeing landlords shift their approach to providing that support themselves. Many property owners are now creating and implementing their own models to better support tenants and the end-users when they come into the building. `}</p>
    <p>{`Eden Health is currently working with a variety of CRE groups that have opted to implement a service solution that allows companies to keep their workforces in motion. `}</p>
    <p>{`You can access Eden Health’s Guide to Managing COVID-19 in the Workplace here. `}</p>
    <p><strong parentName="p">{`Allwork.Space: Since landlords are ultimately responsible for creating safe properties, what are some long-term or permanent changes that they are making in their buildings?`}</strong></p>
    <p>{`We’ve seen a lot of improvements in the physical sight area like layout and design; some landlords have improved their HVAC systems and others are focusing on creating a touchless experience in communal areas.`}</p>
    <p>{`This is great for a variety of reasons, mainly that not only can these measures help reduce the risk of transmission of COVID-19, but also of other communicable diseases. `}</p>
    <p>{`As for additional changes, landlords are also having to think about how they are administering amenities. Many of the amenities that had been implemented in the past few years in buildings are not providing any real value at the time, because most people are not going into the buildings. `}</p>
    <p>{`For this reason, property owners are now thinking about implementing amenities that can travel with an individual and provide value to them even when they are not physically present. Services like Eden Health’s concierge offering is one example of how landlords are doing this. `}</p>
    <p>{`Experiences that can be offered both online and offline will also increase in popularity over the coming months and years.`}</p>
    <p><strong parentName="p">{`Allwork.Space: You previously mentioned that property owners are now in the business of healthcare. Why is that?`}</strong></p>
    <p>{`In our experience, anyone who operates a physical site is a provider of healthcare in some degree or another. But now, because of the pandemic, as soon as people enter a space they are thinking about their health. `}</p>
    <p>{`This is why it’s no longer optional for landlords to think about public health in their properties. `}</p>
    <p>{`Here’s the way we see it:`}</p>
    <ul>
      <li parentName="ul">{`If you are operating a space, you need to have a healthcare position. `}</li>
      <li parentName="ul">{`If you, as a landlord, don’t position yourself as a trusted provider, your properties will suffer. In the past, this issue of a trusted provider was more about reliability and security; this has changed now and has extended to questions like “is my landlord taking the right steps to make my employees and visitors safe in the building?”`}</li>
      <li parentName="ul">{`When it comes to renewals and retaining leases, this trust is crucial. But beyond that, landlords will be competing on this piece in the future; if you are not taking the right steps to guarantee the safety and health of tenants and the end-users, then your tenants will look elsewhere.`}</li>
    </ul>
    <p>{`In short, public health will be a key part of every new lease or renewal conversation. It is therefore in the landlord’s best interest to have a clear position on healthcare. `}</p>
    <p><strong parentName="p">{`Allwork.Space: Do you think we will see an increase in landlords pursuing certifications like the WELL Building Standard and Fitwel in the future as a result of focusing more on health and safety?`}</strong></p>
    <p>{`Definitely. We are already seeing more landlords talking about certifications and requirements; something that wasn’t happening even last year. `}</p>
    <p>{`In my view, certifications will become much more mainstream. We are all becoming more aware about what should be included in these standards and the reality is that this may not be the last time where we are confronted with major health issues. `}</p>
    <p>{`What is happening now will serve as a reminder for people of the implications of not taking things like health and safety in built environments seriously. `}</p>
    <p><strong parentName="p">{`Allwork.Space: You were saying that focusing on public health will help landlords in conversations for new leases or lease renewals. Is this to say that focusing on public health can improve their property value in the long-run?`}</strong></p>
    <p>{`Yes. Property value is, to some extent, tied to what tenants want and are looking for. Tenants themselves are moving in the direction of public health, so if landlords move in that direction as well, their property will become more valuable for existing and potential tenants.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      