import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-03-13T00:00:00.000Z",
  "title": "Future Of Work: Three Trends Shaping The Modern Workplace\n",
  "slug": "future-of-work-three-trends-shaping-the-modern-workplace",
  "excerpt": ["A new report shines a light on what C-level executives think about the modern workplace.", "Most believe that working in an office building every day “is quickly going the way of the typewriter”.", "Outsourcing, contractual work and generational change is driving huge shifts in the workplace."],
  "images": ["three-trends-shaping-the-modern-workplace.png"],
  "backlink": "https://allwork.space/2020/03/future-of-work-three-trends-shaping-the-modern-workplace/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Web-based work management and project management software company, Workfront, recently published the “`}<a parentName="p" {...{
        "href": "https://www.workfront.com/resources/work-in-the-modern-enterprise-report"
      }}>{`Work in the Modern Enterprise`}</a>{`” report. Conducted in partnership with Regina Corso Consulting, the report aimed to get a better understanding of how C-level executives feel about the current and future state of the modern workplace.`}</p>
    <p>{`The report identified three main trends contributing to the evolution of work and the workplace.`}</p>
    <ol>
      <li parentName="ol">{`Digital natives are changing the dynamics of the workforce.`}</li>
      <li parentName="ol">{`The nature of work is moving from large, structured projects to unstructured, collaborative work.`}</li>
      <li parentName="ol">{`Enterprise teams are adjusting the ways they manage work to support digitization and the modern workplace.`}</li>
    </ol>
    <h3>{`Key Insights and Figures`}</h3>
    <ul>
      <li parentName="ul">{`Over 3/4 of executives say working in an office building each day is quickly going the way of the typewriter.`}</li>
      <li parentName="ul">{`Four in five executives say work is less structured in the modern workplace and requires people to work together and collaborate more than before.`}</li>
      <li parentName="ul">{`Four in five executives say the workplace needs to be altered to deal with the work habits of Millennials.`}</li>
      <li parentName="ul">{`Over four in five executives say creative thinking will soon be a worker’s most important skill.`}</li>
      <li parentName="ul">{`The number of full-time employees is declining as enterprises increasingly hire freelancers and contractors.`}</li>
    </ul>
    <h3>{`The Shifting Workforce`}</h3>
    <p>{`One of the main drivers changing the way work gets done is the significant shift in the general makeup of the modern workplace. 80% of surveyed executives stated that there are now many more Millennials in the workplace than there are Baby Boomers; because of this, the workplace needs to continue to change to accommodate the work habits of Millennials and future generations.`}</p>
    <p>{`One way this is already changing is through remote work.`}</p>
    <p>{`77% of executives noted that the act of working in the corporate office every day is no longer necessary. In fact, 41% of knowledge workers are now remote and executives expect the percentage of remote workers to increase in the coming years.`}</p>
    <p>{`“On average, almost half (46%) of knowledge workers in companies with between 1,000 and 5,000 employers are remote compared to 36% of those in companies with over 5,000 employees.”`}</p>
    <p>{`Not only is work increasingly remote and made up of younger generations, but also enterprises are increasingly relying on outsourcing.`}</p>
    <p>{`“In conjunction with a more remote workforce, executives also notice an increase in contract work” — with 37% of knowledge workers being contractors.`}</p>
    <p>{`This number is expected to grow in the next five years, with 69% of surveyed executives arguing that this growth will be attributed to the fluctuating workloads and the need of enterprise teams to expand or contract at any given time.`}</p>
    <h3>{`The Changing Nature of Work`}</h3>
    <p>{`The second trend identified by Workfront is that work is increasingly collaborative. “The majority of executives (84%) report that work is now less structured than before and requires people to work together more.”`}</p>
    <p>{`Interestingly, Workfront found that the tools used to fuel collaboration are on the decline — mainly email. Executives believe that collaboration at work will increasingly mirror the social collaboration workers are used to in their private lives.`}</p>
    <p>{`Modern work is not only more collaborative, but increasingly creative. Enterprises in the future will place a much higher value on creative and innovation skills. Already, executives are trying to maximize the amount of time their teams spend on innovation and creative tasks rather than on administrative tasks.`}</p>
    <h3>{`The Way Enterprises Manage Work Is Evolving`}</h3>
    <p>{`As a result of the changing dynamics and demographics of modern work, enterprises have evolved the way they manage work and talent, increasingly favoring automation. This shift, also known as digital transformation, is helping organizations digitize manual work processes, freeing up time for employees to focus on more high-value tasks. Over half of executives say automating knowledge work is among their top priorities over the next two years.`}</p>
    <p>{`Still, Workfront notes that “the average enterprise is still relatively immature in their digital transformation.”`}</p>
    <p>{`In addition to automation, enterprises are increasingly thinking about how to have more agile teams. 71% of executives agreed that “five years from now, knowledge workers will have to regularly respond to changes in the market much faster than they do today.”`}</p>
    <p>{`The need for agile teams is critical, particularly considering that technological advances will continue to evolve at a fast pace and will continue to create low barriers of entry.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      