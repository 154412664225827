import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-10-25T00:00:00.000Z",
  "title": "Future of Work: How Job Crafting Will Change the Way We Work\n",
  "slug": "how-job-crafting-will-change-the-way-we-work",
  "excerpt": ["Job crafting involves making changes to your job role in order for it to better fit your personal interests and skills.", "It can lead to a more fulfilled and engaged workforce. Although the concept has been around for some time, it is only now getting the attention it deserves.", "Allwork.Space spoke to John Hackston, Head of Thought Leadership at the Myers-Briggs Company, to learn about job crafting and why it’s important for the future of work."],
  "images": ["40-future-of-work-how-job-crafting-will-change-the-way-we-work.jpg"],
  "backlink": "https://allwork.space/2019/10/future-of-work-how-job-crafting-will-change-the-way-we-work"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Job crafting has been around for at least a decade. However, it’s only now that the trend is receiving increased attention from workplace and HR groups.`}</p>
    <p>{`For those of you unfamiliar with the concept, job crafting occurs when an employee makes changes to his or her job role in order for it to better fit their personal interests and skills. The central idea behind job crafting is for professionals to explore areas that are of interest to them and grow professionally.`}</p>
    <p>{`While employees benefit from a job they are more engaged and happy with, employers benefit from a happier, more fulfilled, and more productive workforce.`}</p>
    <h2>{`An Introduction to Job Crafting`}</h2>
    <p>{`John Hackston, Head of Thought Leadership at the Myers-Briggs Company, spoke with Allwork.Space about job crafting, what it is, and why it can be beneficial for companies as they navigate the future of work.`}</p>
    <p>{`“The idea, the concept behind job crafting has been around for 10 years, but it really hasn’t made its way into the general public of working people and HR leaders. But basically, it is about providing employees with the opportunity to redesign their jobs so that their jobs better fit with their personalities, motivations, and skills.”`}</p>
    <p>{`Rather than the traditional model of having the company write a job description, with job crafting employees are empowered to do this. There are many ways to go about job crafting; it can be about changing the tasks people take on, how they accept or carry out tasks, or how often they work alongside other people.`}</p>
    <p>{`“At the end of the day,” Hackston says, “the bottom line is to increase job satisfaction.”`}</p>
    <p>{`Increasing job satisfaction can increase motivation and engagement in the workplace. Taking into consideration that the war for talent is among the top challenges of companies (and it is expected to become fiercer), job crafting can be a valuable strategy for companies seeking to attract and retain top talent.`}</p>
    <p>{`“By simply changing the way people perceive and see their jobs, their job becomes much more meaningful.”`}</p>
    <h3>{`Job Crafting: A Case Study`}</h3>
    <p>{`Hackston referenced some case studies that have been conducted by various organizations about the benefits of job crafting. One that has been highly studied and quoted is the Pink Glove Dance, in which hospital janitors expressed their wish to converse and interact with patients.`}</p>
    <p>{`“By crafting their job to include more interaction with patients, the janitors went from feeling like ‘just cleaners’ to becoming an essential part of the hospital’s team of healers.”`}</p>
    <p>{`When done right, Hackston argues, “job crafting is a win-win situation for employees and employers.”`}</p>
    <h3>{`Why now?`}</h3>
    <p>{`Speaking about why he believes job crafting is gaining traction in the workplace and human resources departments, Hackston comments that it has much to do with the war for talent.`}</p>
    <p>{`“Organizations are increasingly aware that they need other ways to motivate employees than simply providing a good pay.`}</p>
    <p>{`“The world of work has changed and is changing. There are rising numbers of self-employed professionals, and these professionals are creating their own opportunities to craft their jobs. In order to continue to attract talent, companies will need to empower individuals to take on jobs and tasks that they identify with and make them feel fulfilled.”`}</p>
    <p>{`Job crafting also allows people to be more flexible with their jobs, providing a greater degree of fluidity.`}</p>
    <h3>{`Job crafting and learnability in the workplace`}</h3>
    <p>{`Technology is changing jobs, but more importantly, it’s eliminating old jobs and creating new job opportunities. By empowering individuals to define their jobs and match them to their personalities and interests, companies can help drive learnability efforts in the workplace.`}</p>
    <p>{`Job crafting should be a part of the solution to upskill and re-skill workers in the workplace as it can provide them with opportunities to try things out and discover potential areas of interest in the future.`}</p>
    <p>{`“Having open mindedness to let employees take the lead and having a job crafting scheme in place is an effective way to let employees know that they have permission to ask, to try new things; it says that the organization trusts them.”`}</p>
    <h3>{`Implementing job crafting and what to keep in mind`}</h3>
    <p>{`Implementing a job crafting scheme comes with its own fair share of challenges. Hackston recommends that you keep the following in mind:`}</p>
    <ul>
      <li parentName="ul">{`The first step is to get rid of rigid, traditional approaches to the way jobs are constructed.`}</li>
      <li parentName="ul">{`Companies need to make an effort to understand what employees like and dislike and what their skills are.`}</li>
      <li parentName="ul">{`In job crafting, there’s no room for the mentality that says “but this is the way we’ve always done things.”`}</li>
      <li parentName="ul">{`Companies and employees need to set realistic expectations. “Not everyone is going to jump on board with this initiative.”`}</li>
      <li parentName="ul">{`Everyone adapts to change in different ways, and companies need to take this into consideration. While some employees will jump at the opportunity, others are likely to resist it.`}</li>
      <li parentName="ul">{`To make job crafting appealing for everyone, it’s important that companies clearly communicate what it is and why it is beneficial. One key aspect that should be included in all communication efforts is how job crafting can improve employee wellbeing.`}</li>
      <li parentName="ul">{`Companies should provide access to self-development tools so that employees can better test and clearly define where their skills and interests lie.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      