import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2021-03-12T00:00:00.000Z",
  "title": "Future Of Work: The End Of The Daily Commute\n",
  "slug": "the-end-of-the-daily-commute",
  "excerpt": ["Rather than drawing workers near to the office, the new mentality is to bring the office to the people.", "23 million American workers are considering relocating as a result of remote work.", "Remote workers are saying goodbye to their urban lives and embracing suburban life with more affordable housing."],
  "images": ["the-end-of-the-daily-commute.png"],
  "backlink": "https://allwork.space/2021/03/future-of-work-the-end-of-the-daily-commute/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The COVID-19 pandemic has revealed that remote, distributed teams are a viable option for business continuity. As organizations made the switch to remote work, many workers moved away from city centers; as organizations now announce plans to allow for remote work permanently—Dropbox, Spotify, Coinbase, Salesforce, Shopify, and Slack—many employees are rethinking their living situation, with a significant percentage of the workforce thinking about moving to more suburban areas.  `}</p>
    <p>{`The shift to remote work has changed the life-work dynamic.  `}</p>
    <p>{`While in the past centralized office locations dictated where employees lived, as companies embrace a hybrid work model, it’s becoming more likely that a worker’s living location will dictate where work gets done.  `}</p>
    <p>{`A recent `}<a parentName="p" {...{
        "href": "https://www.libertyglobal.com/wp-content/uploads/2021/03/2021-03-Liberty-Global-Deloitte-Future-of-Work-discussion-paper.pdf"
      }}>{`Deloitte`}</a>{` report argues that: `}</p>
    <p>{`“This requirement `}{`[centralized offices]`}{` has determined people’s choice of living space, driven hour-long commutes and the dedication of specific cubicles to individual employees. But now organizations have a much more diverse set of options, from employer-sponsored home offices to distributed coworking locations.” `}</p>
    <h3>{`The End of the Daily Commute`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.upwork.com/press/releases/upwork-report-finds-up-to-23-million-americans-plan-to-relocate-amid-rising-remote-work-trends"
      }}>{`Upwork`}</a>{` recently found that: `}</p>
    <ul>
      <li parentName="ul">{`23 million American workers are considering relocating as a result of remote work.  `}</li>
      <li parentName="ul">{`Major cities will see the biggest out-migration; 20.6% of those moving are currently based in a major city.  `}</li>
      <li parentName="ul">{`54.7% of people are moving over two hours away or more from their current location, which is beyond daily or even weekly commuting distances for most. `}</li>
    </ul>
    <p>{`Remote workers are saying goodbye to their urban lives and saying hello to a more suburban life with more affordable housing.  `}</p>
    <p>{`Quality of life seems to be a main driver behind the city exodus. Even if companies cut the wages of employees who choose to relocate (Facebook and Twitter), these workers are likely to still experience improved quality of life for a variety of reasons: `}</p>
    <ul>
      <li parentName="ul">{`Net income is likely to remain the same or higher even with a pay cut due to taxes (dependent on where an employee moves).  `}</li>
      <li parentName="ul">{`Reduced commute time, which can reduce stress and anxiety, and can also help save costs.  `}</li>
      <li parentName="ul">{`A US census study found that 34% of surveyed workers expect to never return to daily commute.  `}</li>
      <li parentName="ul">{`A Deloitte study of the financial sector found that 77% of people surveyed expect to work from home office regularly after the pandemic.  `}</li>
      <li parentName="ul">{`The Instant Group found that 57% of employees want an office that is closer to their home.  `}</li>
      <li parentName="ul">{`The Instant Group found that 77% of employees say that a more conveniently located office is a must-have for their next role.  `}</li>
      <li parentName="ul">{`Commuting increases the incidence of stress and anxiety and can lead to a variety of health problems, including psychosomatic disorders like headaches, backaches, digestive problems, sleep disturbances, and fatigue.  `}</li>
      <li parentName="ul">{`Upwork found that US workers can save up to $2,000 a year in commuting costs. Similarly, the Instant Group found that the average London employee could save £2,200 a year in commuting costs. `}</li>
      <li parentName="ul">{`Increased savings opportunities that stem from more affordable housing and cost of living in suburban areas. `}</li>
    </ul>
    <h3>{`What Does This Mean for the Office?`}</h3>
    <p>{`As geographical boundaries become irrelevant or less important to employers, real estate portfolios are likely to shrink.  `}</p>
    <p>{`But the office will prevail, as it remains a core part of doing business—for creativity, innovation, and culture purposes.  `}</p>
    <p>{`Many employees worldwide have also stated that they miss the office’s social component. However, not all social interactions need to take place in a company’s headquarters.  `}</p>
    <p>{`Businesses will reduce their headquarters’ footprint, but they will likely make greater use of flexible workspace locations that are available both in urban and suburban settings.  `}</p>
    <p>{`The Instant Group’s latest research argues that “based on how and where employees want to work, we believe that corporate headquarters will shrink by up to 40 percent, on average, in the coming years, as a result of this marked pandemic shift.” `}</p>
    <p>{`Rather than bringing people near the office, the new mentality is to bring the office near workers’ living locations. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      