import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-07-31T00:00:00.000Z",
  "title": "Future Of Work: 4 Key Workplace Changes That Need To Happen To Support Hybrid Work Models\n",
  "slug": "future-of-work-4-key-workplace-changes-that-need-to-happen-to-support-hybrid-work-models",
  "excerpt": ["A recent survey report from JLL found that the office will continue to play a significant role post pandemic in the success of businesses.", "Because the purpose of the office has shifted from the place to do work to the place to collaborate, create, and build relationships, organizations need to put human performance at the core of their real estate strategy.", "Workplace changes for business success in the future need to focus on how to better support hybrid working models that include a mix between on-site and remote work."],
  "images": ["key-workplace-changes-that-need-to-happen-to-support-hybrid-work-models.png"],
  "backlink": "https://allwork.space/2020/07/future-of-work-4-key-workplace-changes-that-need-to-happen-to-support-hybrid-work-models/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A July 2020 survey conducted by `}<a parentName="p" {...{
        "href": "https://www.jll.co.uk/"
      }}>{`JLL`}</a>{` found that 71% of employees worked from home during the pandemic. Of these 71%, 58% missed the office and 44% missed human interaction and socializing with colleagues. `}</p>
    <p>{`The results of JLL’s survey report “COVID-19 Impact: offices will find a new purpose” demonstrate that “physical offices will remain critical in ensuring organizational success in the post-pandemic world. It also highlights imperatives for optimizing  remote workers’ experience and enhancing human performance and productivity in the future workplace.”`}</p>
    <p>{`There is no denying that the pandemic has upended office life and that it has rapidly accelerated the pace of workplace transformation. `}</p>
    <p>{`Despite the fact that remote work is here to stay, “the office will continue to play a significant role post pandemic in the success of businesses”. `}</p>
    <h2>{`Why Workplaces Are Still Key to Business Success`}</h2>
    <p>{`Several studies and news outlets have reported that `}<a parentName="p" {...{
        "href": "https://allwork.space/2020/05/future-of-work-the-office-isnt-dead-and-never-will-be/"
      }}>{`the office is far from dead`}</a>{`. However, research has found that `}<a parentName="p" {...{
        "href": "https://allwork.space/2020/06/what-is-the-office-for-a-look-at-the-changing-role-of-the-workplace/"
      }}>{`the purpose of the office has changed`}</a>{` and that there will be some `}<a parentName="p" {...{
        "href": "https://allwork.space/2020/07/future-of-work-if-work-from-anywhere-is-the-future-what-does-this-mean-for-office-location/"
      }}>{`implications for office location as well`}</a>{`. `}</p>
    <p>{`What’s driving this change? `}</p>
    <p>{`JLL identified that key components of office life were missing and impacting productivity among newly established remote workers: “socialization, face to face collaboration and informal communications.” `}</p>
    <p>{`Though the above statement holds true across different regions, some key differences were observed. `}</p>
    <p>{`“In the Americas, respondents missed daily routine, in EMEA, they missed human interaction and in Asia they missed a professional environment that enabled focus. Conversely, views also differed about the advantages of working from home. In the Americas, they relished not having the daily commute, in EMEA, they enjoyed having access to outdoor space and a peaceful environment and in Asia they valued greater work-life-balance.”`}</p>
    <h2>{`The Office’s New Purpose`}</h2>
    <p>{`According to JLL, the purpose of the office will shift from the place to do work to the place to collaborate, create, and build relationships with colleagues, clients, and co-workers. `}</p>
    <p>{`In other words, “the workplace will evolve to serve as a catalyst for employee experience, engagement, and fulfillment and will embrace the future culture of companies.”`}</p>
    <h2>{`Implications for the Workplace`}</h2>
    <p>{`For JLL, this means that organizations will need to put human performance at the core of their real estate strategy. `}</p>
    <p>{`JLL’s “research reveals that in-office work and remote work are complementary when it comes to achieving human performance. Each way of working brings its own value, but neither can replace the other. In the post-pandemic workplace, combining and leveraging the best of both will become the ‘new normal’. Across that journey, the challenge for all enterprises will center on establishing the right mix and balance between the different settings and working patterns.”`}</p>
    <p>{`JLL identified four important changes that need to come to the office to re-imagine it for the future of work.  `}</p>
    <h3>{`1. Rethinking offices into social hubs`}</h3>
    <p>{`Face-to-face interaction will still be a key element of organizational success. “Offices will remain an irreplaceable source of human connection and will become ideas factories where workers come together to innovate and socialise.”`}</p>
    <p>{`Additionally, JLL also found that most people either can’t or don’t want to work entirely remotely. Organizations would also benefit from being weary of sending their workers home to work permanently. `}</p>
    <p>{`“For organizations that had established a tight knit community, technological advances were not able to replace the rich and dynamic human interactions that occur in real life and ‘off-screen’.”`}</p>
    <p>{`JLL notes that organizations “without a physical anchor (i.e. the workplace) there is a real danger that corporate cultures and communities will erode over time, with grave consequences for workforce performance and satisfaction.”`}</p>
    <h3>{`2. The Hub and Club`}</h3>
    <p>{`Not only will organizations have to rethink their workplaces, but they will have to rethink their real estate strategies as well. `}</p>
    <p>{`“The future office network will be a hybrid model combining headquarters along with distributed and liquid spaces. This includes the Hub and Club model –satellite, out-of-town spaces available for employees (the hubs) and a central HQ (club) used for socialization, meeting customers and putting the human experience at the core of the workplace.”`}</p>
    <p>{`To this end, many organizations will likely turn to external workplace providers like coworking spaces and they will also seek to negotiate shorter, more flexible lease terms with their landlords. `}</p>
    <h3>{`3. Humans Are the Top Priority`}</h3>
    <p>{`“In the post-pandemic world of work, organizations should prepare for a permanent rise in employee`}</p>
    <p>{`expectations. Many workers will not want to lose the new rights and freedoms they have experienced during lockdown, and the crisis will accelerate the need to provide talent with greater flexibility, autonomy and remote-working options.”`}</p>
    <p>{`Organizations will be tasked with the challenge of designing a more human and engaging workplace, one that is anchored in strong company values. `}</p>
    <p>{`Workplaces must become more human-centered. This can be achieved by:`}</p>
    <ul>
      <li parentName="ul">{`Introducing more relaxed dress codes`}</li>
      <li parentName="ul">{`Incorporating soft furnishings to make spaces cozier`}</li>
      <li parentName="ul">{`Allowing for more personalization within spaces `}</li>
      <li parentName="ul">{`Prioritizing health and well-being. `}</li>
    </ul>
    <h3>{`4. Remote Work Is Here to Stay`}</h3>
    <p>{`In JLL’s words, the mentality around remote work “will have to switch from surviving to thriving.”`}</p>
    <p>{`To ensure remote work success, employees need to have access to a set of advanced tools that can help them manage work, information, and relationships at a distance. `}</p>
    <p>{`Organizations that will allow employees to continue working remotely as part of a more permanent arrangement will be tasked with “providing a good work environment that will extend into new territories.”`}</p>
    <p>{`“In the future, companies will need to help their employees create more effective home-working environments; for example, through financing an ergonomic workstation or a double screen. `}</p>
    <p>{`Companies will also need to consider providing access to alternative places of work. This may include coworking spaces or satellite locations near where employees live. Not only will this ease the burden of the daily commute, but it will also offer them a change of scene and a vital anchor in an increasingly digital world.”`}</p>
    <p>{`At the end of the day, the physical office will continue to be part of our work lives. However, “to justify its existence, the physical office will evolve into a place that increases the feel-good factor and is a deep source of inspiration.”`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      