import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-06-07T00:00:00.000Z",
  "title": "The Future Of Work: Should You Focus On Finding Your Business Purpose?\n",
  "slug": "the-future-of-work-should-you-focus-on-finding-your-business-purpose",
  "excerpt": ["Purpose is linked with profit, but it shouldn’t be a company’s sole reason for existence.", "A PwC survey found that 79% of business leaders believe that purpose is central to business success. Purpose is linked with profit, but it shouldn’t be a company’s sole reason for existence.", "Today’s workforce generation is looking for more than a good paycheck. They want choice, flexibility, amenities, and positive social impact.", "If your current and future workforce isn’t concerned about money alone, companies shouldn’t be either. However, a company’s purpose must be authentic."],
  "images": ["14-should-you-focus-on-finding-your-business-purpose.jpg"],
  "backlink": "https://allwork.space/2019/06/the-future-of-work-should-you-focus-on-finding-your-business-purpose/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The world of work has changed and experts are increasingly talking about the need for companies to have a purpose. `}<a parentName="p" {...{
        "href": "https://allwork.space/2019/06/the-future-of-work-should-you-focus-on-finding-your-business-purpose/"
      }}>{`An EY survey found that 73% of business leaders think that having the right purpose will help their company navigate disruption`}</a>{`. A PwC survey found that 79% of business leaders believe that purpose is central to business success.`}</p>
    <p>{`Others argue that company purpose will help businesses attract and retain talent, arguing that people want to find meaning and purpose in their work and that the new workforce generation is looking for work that will enrich their lives, and the world. The same PwC survey found that millennials that have a strong connection to the purpose of their organization are 5.3 times more likely to stay in that organization.`}</p>
    <p>{`Millennials that have a strong connection to the purpose of their organization are 5.3 times more likely to stay in that organization.`}</p>
    <p>{`The trend doesn’t stop there. `}<a parentName="p" {...{
        "href": "https://www2.deloitte.com/us/en/pages/human-capital/articles/social-enterprise.html"
      }}>{`Deloitte’s 2018 Global Human Capital Trends Report`}</a>{` focused on the Rise of the Social Enterprise, in which it argues that “organizations are no longer judged only for their financial performance, or even the quality of their products or services. Rather, they are being evaluated on the basis of their impact on society at large — transforming them from business enterprises into social enterprises.”`}</p>
    <p>{`According to Deloitte, social enterprises are businesses that are able to link their profits with their purpose, without compromising one or the other. And it appears to be that businesses are taking heed of the trend.`}</p>
    <p>{`In a recent article titled `}<a parentName="p" {...{
        "href": "https://www.gallup.com/workplace/257744/future-workplace-depends-purpose.aspx?utm_source=workplace-newsletter&utm_medium=email&utm_campaign=WorkplaceNewsletter_June_060419&utm_content=doyoulivepurpose-CTA-1&elqTrackId=1b7ed4fec3704fce88994df050807937&elq=6896fee9d8cc46e8b731a6eab9bb6e1f&elqaid=1699&elqat=1&elqCampaignId=385"
      }}>{`“The Future of Your Workplace Depends on Your Purpose”`}</a>{`, Gallup provided an example. In an annual letter to leaders of companies, Larry Fink, CEO of BlackRock wrote that “profits are in no way inconsistent with purpose — in fact profits and purpose are inextricably linked.”`}</p>
    <p>{`But, what exactly is business purpose?`}</p>
    <p>{`It’s your why. It’s your answer to the question: why are you in business?`}</p>
    <p>{`In the past, the common answer to this question was to make a profit. Today, that answer is no longer good enough, or so some say. Organizations are no longer expected to just make a profit, they are expected to be good citizens, to be role models, and to be entities that address and respond to society’s biggest issues.`}</p>
    <p>{`There are various cases of companies that have successfully established their purpose and that have, as a consequence, increased their overall company value. This is based on the fact that data has shown that `}<a parentName="p" {...{
        "href": "https://www.forbes.com/sites/caterinabulgarella/2018/09/21/purpose-driven-companies-evolve-faster-than-others/#7f59196555bc"
      }}>{`“consumers view purpose-driven organizations as more caring and, as a result, are more loyal to them.”`}</a></p>
    <p>{`It’s a trend that aligns with individual behavior. Today’s workforce generation, made up in large part by millennials, is looking for more than a good paycheck. They want choice, they want flexibility, they want amenities, they want better benefits packages, and so on. So it would make sense that if individuals aren’t just concerned about money, companies shouldn’t be, either.Yet, a recent Harvard Business Review article argues that `}<a parentName="p" {...{
        "href": "https://hbr.org/2019/05/companies-dont-always-need-a-purpose-beyond-profit"
      }}>{`“Companies Don’t Always Need a Purpose Beyond Profit”`}</a>{`. The author Freek Vermeulen believes that it’s okay for businesses to want to focus on competitive advantage, operational efficiency, and profitability.`}</p>
    <p>{`According to Vermeulen, making a profit is purposeful enough and it can have a positive impact on society, arguing that “many of the most important social issues in our world are closely tied to economic wealth.”`}</p>
    <p>{`“In my view, organizations shouldn’t be shy about stating profit as its explicit and ultimate purpose,” the author writes. “In fact, in addition to helping us earn a living, profit may be the best way to do good in the world. Economic growth, for example, is an effective way to reduce poverty — likely more so than aid — because it benefits the lowest income brackets in a country significantly. Moreover, increases in wages and wealth have been shown to have positive effects on other critical societal problems, such as crime, malnutrition, infant mortality, mental health, and general feelings of happiness.”`}</p>
    <p>{`This, however, doesn’t mean that the ends justify the means. Quite the contrary, the author advocates for the opposite approach. Though businesses shouldn’t be ashamed of striving for a profit, they do need to be conscious of how they pursue that profit, making sure that they don’t harm the environment, mistreat employees, and deceive stakeholders.`}</p>
    <p>{`But there’s another reason why finding a business purpose other than profit is truly necessary. It’s the fact that companies struggle to explain their greater purpose. There’s also a gap between what businesses believe their purpose is, and what their behaviour suggests their purpose is. In other words, by forcing a business leader to find a business purpose, there’s an increased risk that the purpose is not authentic and it won’t be weaved into a company’s DNA. This can lead people to feel “somewhat cynical rather than motivated,” which would defeat the purpose of even having a greater purpose.`}</p>
    <p>{`So the question remains, do businesses need to find a greater purpose to survive in the future of work?`}</p>
    <p>{`It’s up to them and how they are able to convey that purpose. Business itself is a worthy purpose and if you choose to make profit your purpose, you need to help people “grasp the social power of profit.” If you choose to give your business a greater social purpose, then it needs to be authentic, and it’s your business responsibility to follow through with that purpose.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      