import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-08-02T00:00:00.000Z",
  "title": "What Is Proptech?\n",
  "slug": "what-is-proptech",
  "excerpt": ["PropTech is a catch-all term for technology that is being developed for the property industry.", "The development of PropTech is changing the way people and companies build and interact with the built environment.", "Its uses and applications include blockchain, data capture, IoT, artificial intelligence, VR, and machine learning, among others."],
  "images": ["23-what-is-proptech.jpg"],
  "backlink": "https://allwork.space/2019/08/what-is-proptech/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Back in 2017, PropTech became a new buzzword. That same year, investors deployed $12.6 billion to the PropTech industry. Fast-forward two years and PropTech remains a relatively unfamiliar concept for many, despite the impact it’s already having in the real estate industry.`}</p>
    <p>{`Although in 2018 PropTech experienced a 23% drop in investment, `}<a parentName="p" {...{
        "href": "https://allwork.space/2019/05/the-future-of-work-proptech-3-0-powered-by-blockchain-and-ai/"
      }}>{`reports have found that 60% of PropTech investors plan on making more investments in 2019`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "734px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9be8c56bf2e54c1f6846292e10498501/c6d67/23-img-1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "68.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACvElEQVQ4y11TS2sTURSeH2Vpnm1ek2Qmr5ncycykSdPUPhPtE7qRLoT6zqKiVUG7EcQqPipqsVS3UhAFKShFF+3GhRupq0KhfJ5zJ+nDxcc9c+6533zfuecqg+enMDgx2cZxPNCcQq1xEtOon5s5jaaHgeZxrJiDDpxxF26jDGvYi+1RF7pTQUpUoFkVpGlNFssIaSbCJ9CTKRIEerOWjMO6CUWzakgT9NIAEfRDo5VzGcdDluFSrlRFJCcQyVuSIEJIGA4SZpnOV6GaNsU2lECqQIXeJq8hzZB/YjX/I6BaCCRIjU6qWJ1eJHKG2VZYhMKFsbxNlvoQLzi0KSRxpK2CY1YUMwSciRz6ZrKIm0SS8faCSQu+WAnhNNcLKNyDaK4ke8TE/BfOef0pSiWhtEDaNfDgUy8eb4eR6S8glBISjUs6rq+qqMzmyAEReqosqIYriXuOCEW74UISphwTy5saVr5pyFQNmWOChSdJrP+JYuKGTkqJkFWx9JTwLHduTdpu2w1rFik0ce9jCA+3/FJhWCshkBS4/MjBxq8Kpq7a8MU7hHSoY7mXCJnAH/f6wzcb0gT0cglPv4xi7ec48jVb1nSrWbx8+xrAAVq3l9AVy0BhAklI1x8r2GSlSGoKqM3lIcZyUnGQJsGonMXOzi7+7u3Bro/JXHcii9U3a8DhIVq37uJMVPdumXunEiGPTlc0i5mLc9j+vYHnH5YRTJsEg2axjs3PW/j+Yxdm/7DMMeHK6hr2D4Ari3eIUPMIvTl0JXxkY3R6Huvvv2Lp/jOyZkiVSdPFtdYibpISfj08AcF0AfMLLbx49Q6N2QvwJ/NQeEw6g8vW+TtVdDHUnESpNiSHnA/zjJXrI6gOjZEj62iQC24V9ZFxaKJ8PNgcdIiZgO341Dz81KeTL4W//cnTuQDV+lOGPMPv+x/w7EAhFRrUMQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "23 img 1",
            "title": "23 img 1",
            "src": "/static/9be8c56bf2e54c1f6846292e10498501/c6d67/23-img-1.png",
            "srcSet": ["/static/9be8c56bf2e54c1f6846292e10498501/772e8/23-img-1.png 200w", "/static/9be8c56bf2e54c1f6846292e10498501/e17e5/23-img-1.png 400w", "/static/9be8c56bf2e54c1f6846292e10498501/c6d67/23-img-1.png 734w"],
            "sizes": "(max-width: 734px) 100vw, 734px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`CRETech’s 2018 End of Year Report`}</p>
    <h2>{`What Is PropTech?`}</h2>
    <p>{`PropTech is property technology, or in other words, technology that is used in the property industry.`}</p>
    <p>{`Some of these technologies include software, hardware, construction materials, big data, automation, the Internet of Things (IoT), augmented reality, and virtual reality.`}</p>
    <p>{`These technologies enable better real estate decision making, and they can make buildings and property transactions more efficient, enhance the end-user’s experience, provide improved security, and significantly lower building maintenance and operational costs.`}</p>
    <p>{`PropTech is an umbrella term used to define technologies that are transforming and revolutionizing real estate and real estate related products, services, and business models.`}</p>
    <h3>{`Why Is PropTech Relevant?`}</h3>
    <p>{`PropTech is effectively changing the way people and companies build and interact with the built environment.`}</p>
    <p>{`It is relevant because those who fail to adopt these technologies risk falling behind and becoming irrelevant in the near future. PropTech is transforming a traditional and conservative industry into a tech-enabled one, and it is making the built environment more desirable and attractive to investors and tenants.`}</p>
    <h4>{`Top Uses and Applications`}</h4>
    <p>{`A KPMG report found that data and automation are the main areas where companies and investors expect PropTech to generate the biggest changes in the real estate industry. It also found that artificial intelligence is being used in areas like property management bots and predictive maintenance, investment selection, and valuations.`}</p>
    <h3>{`Blockchain and real estate transactions`}</h3>
    <p>{`PropTech can help streamline real estate transactions by eliminating the need for intermediaries. Blockchain is one technology that can do this as it has the capacity to send data to all relevant parties.`}</p>
    <p>{`Transactions that can be streamlined and made more efficient by blockchain include leasing, land acquisition, titles, ownerships, and exchange of records. Blockchain is also the technology behind smart contracts. You can read more about this particular application here.`}</p>
    <h4>{`PropTech Companies with Blockchain`}</h4>
    <p>{`Abstract: Abstract uses blockchain technology to create smart contracts in commercial real estate. The platform verifies investors and automates processes, enabling efficient and secure transactions.`}</p>
    <p>{`Ubiquity: Ubitquity is a blockchain SaaS platform that allows title companies, municipalities, and custom clients to input property information in its API; the platform enables contract verification and also creates smart contracts.`}</p>
    <h3>{`Sustainability`}</h3>
    <p>{`One key value proposition of PropTech is that it can help make buildings more efficient. While this represents significant savings, there’s another reason why this is increasingly important: sustainability.`}</p>
    <p>{`Today’s markets are increasingly worried about climate change and sustainable living, and individuals are looking for properties that are engaging in sustainable practices. Some technologies used in this area include solar panels, building and construction materials, smart lighting and temperature, as well as data and analytics. The last two can make buildings more efficient by effectively tracking and monitoring usage and optimizing the building based on data outputs.`}</p>
    <h4>{`PropTech Companies Focusing on Sustainability`}</h4>
    <p>{`Physee: Physee believes technology can be used to create sustainable innovations. The company’s PowerWindow is a clean energy tinted window that generates electricity.`}</p>
    <p>{`Sustainer Homes: Sustainer Homes is a company that uses wooden modules to build houses. Their homes store rather than emit CO2, which can reduce the CO2 footprint of homes.`}</p>
    <h3>{`Big data`}</h3>
    <p>{`Big data helps individuals and companies make informed decisions. In the real estate industry, big data is having an impact on various tasks such as examining a property’s history, evaluating ownership costs, determining the current value, and estimating future valuation. Big data is helping investors successfully monitor and manage the performance of their properties.`}</p>
    <p>{`Big data also has applications in facilities management. When it’s complemented by the right hardware and software, big data can help landlords and facilities managers control building operations, including energy and water usage, and temperature. This can help landlords spot opportunities and areas for improvement. By tracking building usage, landlords can also make informed decisions that will lead to better space utilization and engagement.`}</p>
    <h4>{`PropTech Companies using Big Data`}</h4>
    <p>{`Re-leased: Re-leased is a cloud-based platform company that centralizes a building’s data in one system. It helps streamline property management and accounting processes.`}</p>
    <p>{`Logical Buildings: Logical Buildings is a smart building software and solutions company that uses predictive analytics and provides data-driven insight of building operations.`}</p>
    <h3>{`IoT`}</h3>
    <p>{`The IoT is a network of connected devices and it is the technology behind smart buildings and homes. IoT devices can be controlled remotely and provide predictive maintenance insights.`}</p>
    <p>{`The IoT can also improve tenant experience and lead to efficient building management. Some widespread applications of the IoT include smart lighting, smart heating systems, motion sensors, security (doors that can be locked and unlocked through a smartphone), appliances that turn themselves on/off, etc. The more connected devices a building has, the more robust and accurate data it will provide.`}</p>
    <h4>{`PropTech Companies with IoT focus`}</h4>
    <p>{`IOTAS: IOTAS is a Smart Apartment platform that provides lights, outlets, thermostats, door locks, ceiling fans, sensors and other connected devices. The platform allows for remote access, control, and overall management.`}</p>
    <p>{`Facilio: Facilio harnesses IoT to connect and unify teams, systems, and equipment across multiple facilities. It provides real-time operational insights, predicts equipment issues before they occur, and provides a one stop shop to centrally manage operations.`}</p>
    <h3>{`Building Information Modelling (BIM)`}</h3>
    <p>{`Building Information Modelling “is a smart 3D model-based process that enables architects, engineers, and construction professionals to plan, design, construct, and manage buildings and infrastructures efficiently.” There are two key components to BIM: modeling and information. The former can be done through technologies that can transform a hand drawn sketch into a 3D floor plan. The latter can help individuals determine which materials are being used, where the materials come from, how much they cost, how long they take to install, in what order they need to be installed, etc.`}</p>
    <p>{`BIM is revolutionizing the construction process and it can lead to improved building quality, cost savings, and more accurate building timeline/schedule predictions.`}</p>
    <h4>{`PropTech Companies with a BIM focus:`}</h4>
    <p>{`WiseBIM: WiseBIM digitizes 2D drawings into 3D building information models.`}</p>
    <p>{`Snaptrude: Snaptrude is an intelligence enabled rapid Modeling Platform that automates digital designing by transforming hand drawn sketches into a 3D floor plan.`}</p>
    <h3>{`Artificial Intelligence and Machine Learning`}</h3>
    <p>{`Artificial intelligence (AI) and machine learning are two technologies that go hand in hand. AI generates and provides valuable data, while machine learning provides computers with the ability to learn and identify patterns without human intervention, from there it can make adjustments automatically. In the real estate industry, these two technologies have a plethora of potential applications.`}</p>
    <p>{`Some of these include prospect finding, identifying buying patterns, data management, demographic and market research, environmental analysis, financial analysis, lead management, accurate valuation and appraisal, VR and AR.`}</p>
    <p>{`A note on the last two — virtual reality and augmented reality. These technologies are helping potential tenants get to know a place better and visualize themselves in it. These technologies are immersive and allow individuals to choose furniture or wallcovering and see first-hand how the end result would look like. AR and VR provide a realistic model and view of how a room, house, or office would look.`}</p>
    <h4>{`PropTech Companies Powered by AI and Machine Learning`}</h4>
    <p>{`Skyline AI: Skyline AI is an artificial intelligence investment manager for commercial real estate. The platform uses supervised and unsupervised machine learning to help investors make fast and accurate investments based on market anomaly detection, off-market deal sourcing, instant underwriting, and more.`}</p>
    <p>{`Leverton: Leverton uses AI-based, Deep Learning algorithms that automatically extract relevant information out of documents. The platform provides data that can help in the negotiation process and improves risk assessment.`}</p>
    <h3>{`Conclusion`}</h3>
    <p>{`PropTech is transforming the real estate industry into a tech-enabled industry; it will allow real estate companies and investors to stay ahead of the curve and better deliver value through the built environment; and, most importantly, it will be a key driver of the Future of Work.” – Frank Cottle, Chairman of the Alliance Group of Companies.`}</p>
    <p>{`PropTech is helping real estate stakeholders identify and relieve pain points by developing smart, data-driven solutions. It is a multi-billion dollar industry that’s attracting far more investment than other technologies, and PropTech adoption is expected to accelerate, leading to improved decision-making, building performance, investment returns, and tenant engagement.`}</p>
    <p>{`It is a multi-billion dollar industry that’s attracting far more investment than other technologies, and PropTech adoption is expected to accelerate, leading to improved decision-making, building performance, investment returns, and tenant engagement.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      