import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-07-24T00:00:00.000Z",
  "title": "Future Of Work: Why Agile Workplaces Are Now More Important Than Ever\n",
  "slug": "future-of-work-why-agile-workplaces-are-now-more-important-than-ever",
  "excerpt": ["As the world moves towards the next normal, the agile workplace is now more important than ever.", "Agile working provides employees with a variety of flexible settings as part of an activity-based environment.", "Here we take a closer look at findings from HGA, which re-imagines workplace design through the lens of people’s new experiences, motivations, and behaviors."],
  "images": ["why-agile-workplaces-are-now-more-important-than-ever.png"],
  "backlink": "https://allwork.space/2020/07/future-of-work-why-agile-workplaces-are-now-more-important-than-ever/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The concept of an agile workplace has been around for a few years now, as more companies seek to offer more flexibility to workers within the office environment.  `}</p>
    <p>{`As organizations continue to get ready to welcome back workers into the office and as the world moves towards the next normal, the agile workplace is now more important than ever. `}</p>
    <p>{`“`}<a parentName="p" {...{
        "href": "https://allwork.space/2016/07/infographic-5-elements-of-an-agile-workspace/"
      }}>{`Agile working`}</a>{` is the practice of creating a flexible and productive environment by providing employees with a variety of settings that they can move through quickly and easily in order to support an activity-based environment.”`}</p>
    <h3>{`The Next Normal and Agile Working`}</h3>
    <p>{`A recent report from HGA, “`}<a parentName="p" {...{
        "href": "https://adobeindd.com/view/publications/6bbd8a90-fe96-458b-85bc-96a61a7ef849/f2ha/publication-web-resources/pdf/Work_from_Home_Survey_Report_MASTER.pdf"
      }}>{`Social Connectivity and the Future of Workplace, Insights from the 2020 Work from Home Survey`}</a>{`”, aims to re-imagine workplace design through the lens of people’s new experiences, motivations, and behaviors. `}</p>
    <p>{`The report proposes 5 core insights that could help shape the narrative about the evolution of the workplace:`}</p>
    <ol>
      <li parentName="ol">{`Authorship`}</li>
      <li parentName="ol">{`Quality`}</li>
      <li parentName="ol">{`Fellowship`}</li>
      <li parentName="ol">{`Membership`}</li>
      <li parentName="ol">{`Resilience.`}</li>
    </ol>
    <p>{`To understand why agile workplaces will be a key part of the next normal, here we’ll focus on three of HGA’s core insights.`}</p>
    <p>{`The first core insight is authorship.`}</p>
    <p>{`The report argues that, “people appreciate controlling their own workday, and the trust required to do it. Losing this autonomy is one of their main concerns about returning to the office.”`}</p>
    <p>{`Furthermore, some people report feeling more productive at home; this is due to fewer distractions, flexibility of work hours, and flexibility of the environment in which people work from.  `}</p>
    <p>{`As a result, HGA believes that people who return to the workplace “expect more choice over where and when they work at the office.”`}</p>
    <p>{`Enter the agile workplace. `}</p>
    <p>{`The agile workplace in and out of itself seeks to provide people in the office with choice. The agile workplace supports the different types of workers and the different types of work that need to be done in any given day; taking into consideration that some work activities require specific settings. `}</p>
    <p>{`As workers return to the office, having choice in the workplace will be highly valued. Organizations will benefit as they will continue to see increased productivity levels, as well as more engagement, greater collaboration, and increased workplace happiness.`}</p>
    <p><a parentName="p" {...{
        "href": "https://allwork.space/2016/07/infographic-5-elements-of-an-agile-workspace/"
      }}>{`Agile workplaces include`}</a>{`:`}</p>
    <ul>
      <li parentName="ul">{`Shared areas`}</li>
      <li parentName="ul">{`Quiet and private workspaces`}</li>
      <li parentName="ul">{`Breakout and lounge areas`}</li>
      <li parentName="ul">{`Resources `}</li>
      <li parentName="ul">{`Touchdown areas. `}</li>
    </ul>
    <h3>{`Beyond Choice in the Workplace`}</h3>
    <p>{`Although the main benefit of agile workplaces in the next normal is to empower workers to keep some of their authorship over their workday, there are additional benefits. `}</p>
    <p>{`The second core insight presented by HGA is quality. `}</p>
    <p>{`“Working from home has allowed people to take high-quality, restorative breaks. This control over one’s own well-being is more important than ever.”`}</p>
    <p>{`Agile workplaces include breakout and lounge areas that could help individuals take better breaks at work. `}</p>
    <p>{`The report found that people enjoy taking breaks at home because “breaks at home include more than getting a cup of coffee, chatting with a colleague, or checking social media.” If the workplace offers people access to different types of environments, including areas that are conducive to relaxation and that can help workers unplug, the wellbeing of employees is likely to improve. `}</p>
    <p>{`Plus, the report found that some people oftentimes feel guilty, observed, or judged for taking a break at work. By creating a workplace environment that encourages break-taking, organizations could help reduce stress, anxiety, and burnout, while increasing trust levels among workers. `}</p>
    <p>{`Let’s take a look at the third core insight: fellowship. `}</p>
    <p>{`“People appreciate the focus and control of working from home, but their primary motivation for returning to the office is being together.”`}</p>
    <p>{`HGA’s findings align well with what other recent research has found. People miss in-person collaboration, and this is in fact one of the main reasons why some workers are eager to come back to the office. `}</p>
    <p><a parentName="p" {...{
        "href": "https://allwork.space/2020/05/future-of-work-the-office-isnt-dead-and-never-will-be/"
      }}>{`The role of the office is changing`}</a>{`. It is no longer just a place to work; rather it is a place for meaningful connections, meetings, and chance encounters. The workplace environment needs to reflect and support this new role. `}</p>
    <p>{`The agile workplace can accommodate this. It can help offices fulfil the role of being a critical place for connection while also being a dynamic place for work. `}</p>
    <p>{`As more organizations move towards a hybrid model that includes both remote and in-person work, providing an environment that can support and empower workers to feel in control will be key to business success.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      