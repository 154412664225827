import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-10-23T00:00:00.000Z",
  "title": "Future Of Work: Tech-companies-are-rethinking-workplace-density\n",
  "slug": "future-of-work-tech-companies-are-rethinking-workplace-density",
  "excerpt": ["More than half of tech firms are considering shedding office space in the next twelve to eighteen months, according to new research.", "De-densification is driving an increase in flexible and remote work, and companies expect their workplace needs to shift.", "Though traditional office space may take a hit from an increase in remote work, flexible workspace operators could stand to benefit."],
  "images": ["Future-of-Work-Template-Articles-1280-x-720-10-800x450.png"],
  "backlink": "https://allwork.space/2020/10/future-of-work-tech-companies-are-rethinking-workplace-density/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "https://www.savills.us/"
      }}>{`Savills’`}</a>{` latest research report, “Technology Practice Group: COVID-19 Impacts and Sentiment Survey”, found that more than half of technology firms are considering shedding office space in the next twelve to eighteen months. `}</p>
    <p>{`The goal of the survey was to gauge the anticipated impacts of the coronavirus pandemic on companies’ plans for office space, in the short and long-term. `}</p>
    <p>{`“Unsurprisingly, uncertainty is the most prevailing theme among the responses, with regards to space needs, workplace designs, and their organizations’ approaches towards flexible and remote work in the future.”`}</p>
    <p>{`Despite the uncertainty, Savills was able to identify a few emerging trends:`}</p>
    <ul>
      <li parentName="ul">{`Expected dispositions: Technology companies are looking to dispose of at least some of their portion of office space in the near future. `}</li>
      <li parentName="ul">{`A rise in flexible and full-time remote work: Tech companies expect an increase in the percentage of their workforce working remotely when compared to pre-pandemic levels. `}</li>
      <li parentName="ul">{`Lack of consensus on future workplace density: More organizations expect they will need more rentable square feet (RSF) per seat in the future. `}</li>
    </ul>
    <h3>{`Returning to the Workplace and Workplace Density`}</h3>
    <p>{`The report found that 26% of technology organizations do not plan to return to the office in a pre-vaccine environment. As it currently stands, many companies are planning their return to the office for January 2021 “at the earliest”; however, many companies noted that the timeline for the return to the workplace could be pushed further out depending on infection rates and when a vaccine is deployed. `}</p>
    <p>{`Interestingly, “many tech tenants are approaching office re-openings on a location-to-location basis.” Others remained steadfast on their decision to work remotely “until they (can) guarantee the safety of their employees.” `}</p>
    <p>{`Either way, organizations expect their workplace needs to change as a result of the pandemic and an increase in remote work. `}</p>
    <p>{`Savills found that:`}</p>
    <ul>
      <li parentName="ul">{`64% of companies’ headcount growth projections were impacted by COVID-19. `}</li>
      <li parentName="ul">{`82% of organizations anticipate needing less space than planned for the next 12 to 18 months. `}</li>
      <li parentName="ul">{`55% of organizations anticipate to dispose of some office space in the next 12 to 18 months.`}</li>
    </ul>
    <p>{`As organizations rethink their real estate strategy and footprint, there seems to be a popular theme going around:`}</p>
    <p>{`De-densification. `}</p>
    <p>{`“Before COVID-19, a majority of the tech organizations surveyed had a density planning target of less than 150 RSF per seat.”`}</p>
    <p>{`As a result of the pandemic, 34% of organizations reported that they will require more RSF per seat in the future. Still, it is worth noting that 40% of organizations stated that they had not yet decided on an updated density planning target. `}</p>
    <p>{`Based on survey responses, Savills identified “two camps of tech tenants” when it comes to imagining their organization’s workplace of the future:`}</p>
    <ul>
      <li parentName="ul">{`Some tenants are confident that workplace design will return to pre COVID-19 density.`}</li>
      <li parentName="ul">{`Others believe that past space targets will no longer work in a post pandemic world.`}</li>
    </ul>
    <p>{`Even if a company chooses to stay with the same percentage of RSF per seat, it is likely that a company’s overall real estate footprint will decrease, at least in the short-term. This will be a result of cost cutting efforts, an increase in remote work (which means organizations will have less need for space), and adherence to local guidelines on physical distancing. `}</p>
    <h3>{`Remote Work and Its Impact on Office Space`}</h3>
    <p>{`“59% of tenants responded that less than 10% of their workforce was full-time remote pre-COVID-19. When asked what share of their workforce they anticipate working remotely full-time in a post-pandemic world, only 16% of organizations expect less than 10% of their workforce being remote.”`}</p>
    <p>{`Additionally, 94% of organizations stated that they expect remote work to be normalized in their organizations in a post-vaccine environment. 76% believe that full-time remote work will be normalized in their organizations. `}</p>
    <p>{`With an increase in flexible and remote work on the cards, workplace needs are likely to shift. Specifically, workplace design is expected to change. “Pre-COVID, 46% of respondents operated in an all-open office environment. Post-COVID, only 22% expect to keep that layout.”`}</p>
    <p>{`Though traditional office space may take a hit from an increase in remote work, it seems that flexible workspace operators stand to win in this scenario. `}</p>
    <p>{`“62% of companies with a footprint in flexible office providers report that their approach towards flexible office space will not be impacted.” `}</p>
    <p>{`More importantly, 26% of organizations stated that they expect to use flexible office providers for more of their office space locations, and 38% anticipate requiring more space per seat when using flexible workspace providers.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      