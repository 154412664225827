import React from 'react';
import classnames from 'classnames';
import { DefaultComponentProps } from '../core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: SectionProps) => ({
      padding: `${theme.spacing(props.spacing)}px 0px`,
      [theme.breakpoints.up('sm')]: {
        padding: `${theme.spacing(props.spacing) * 1.5}px 0px`,
      },
    }),
  }),
);

export type SectionProps = { spacing: number } & Pick<DefaultComponentProps, 'path' | 'classname' | 'children'>;

export const Section = ({ classname, children, ...props }: SectionProps) => {
  const classes = useStyles(props);
  return <div className={classnames(classes.root, classname)}>{children}</div>;
};

export default Section;
