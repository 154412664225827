import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-05-15T00:00:00.000Z",
  "title": "Future Of Work: The New Normal Requires New Leadership Models\n",
  "slug": "future-of-work-the-new-normal-requires-new-leadership-models",
  "excerpt": ["Most leaders agree that remote work is, and will continue to be, the new normal.", "However, few companies have had time to prepare for this change, and it’s a challenge that managers must overcome.", "These 5 leadership models are essential for managing a remote workforce successfully, now and in the future."],
  "images": ["the-new-normal-requires-new-leadership-models.png"],
  "backlink": "https://allwork.space/2020/05/future-of-work-the-new-normal-requires-new-leadership-models/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`If there is one thing many leaders agree on, it’s that remote work is the new normal and it will continue to be the normal following the health crisis.`}</p>
    <p>{`While many organizations are already laying down the foundation to get workers back into the office, the reality is that many companies will continue to allow employees to work remotely, at least some of the time. `}</p>
    <p>{`Already, we’re seeing companies like Google and Twitter announcing that employees will be able to work from home permanently if they choose to do so. The companies’ offices will remain open should workers choose to go into the office. `}</p>
    <p>{`This type of new work model, naturally, will require a new set of leadership skills and expectations. `}</p>
    <p>{`While the COVID-19 pandemic threw a curveball to managers around the world when organizations were forced to embrace the remote work model, by now leaders should have identified management practices that are effective (and those that are not effective) when managing a remote workforce. `}</p>
    <h2>{`New Leadership Models for Remote Work Success`}</h2>
    <h3>{`1. Say Goodbye to Micromanaging`}</h3>
    <p>{`With a remote workforce, it’s harder for managers to keep a close eye on what everyone is working on. Though there are various platforms that enable managers to keep tabs on and monitor remote workers, such practices can be counterproductive and can, in fact, dampen company culture. `}</p>
    <p>{`One of the key pillars of remote work is trust; managers need to trust that their workers are doing their job. If there is no trust, then constantly checking in on employees can negatively impact their productivity as they respond to management messages, emails, or send updates. `}</p>
    <h3>{`2. Measure by Results, not Time Worked`}</h3>
    <p>{`On a similar line to stopping micromanaging practices, managers should measure the performance of employees by results and deliverables, rather than by the amount of hours they worked in a given day or week. `}</p>
    <p>{`This is particularly important for people who are working from home, who may find it harder to sit down and work for 8 straight hours while they also have to tend to house chores and family responsibilities. Bear in mind also that some people are much more productive when working remotely than when they  are in the office — there are no chats with coworkers by the water cooler, they aren’t distracted by other people’s work, and they aren’t interrupted as much by passing coworkers. `}</p>
    <h3>{`3. Be Flexible`}</h3>
    <p>{`Leaders of remote teams need to be flexible and understand that some people work better at off hours than during normal business hours. `}</p>
    <p>{`As long as workers are meeting goals and delivering results, the time or day at which they fulfill their responsibilities should not be as important. `}</p>
    <h3>{`4. Lead by Example`}</h3>
    <p>{`Leading by example is one of the most important things a manager can do while managing a remote team. If you want workers to be connected, to communicate openly, and to participate in meetings, then you — as a leader — need to take the first steps and lead by example.`}</p>
    <p>{`Send that first message, provide some time before meetings for coworkers to catch up, ask about how people are doing. If you’re late with sending notes or providing feedback, be upfront about it; doing so will encourage workers to be upfront when they may be late turning something in or when they run into a problem. `}</p>
    <p>{`If you’re asking team members to be logged in at 8:00 a.m. every day, then you need to adhere to the same demands. If you want people to step away from their computers to eat lunch, then avoid eating and working yourself; if you’re encouraging workers to wear more casual attire during online meetings, then dress casually yourself, etc. `}</p>
    <p>{`What you do as a leader will greatly impact what your workers do and how they behave. It will set the tone for company culture while working remotely. `}</p>
    <h3>{`5. Become a Mentor`}</h3>
    <p>{`As a manager of remote teams, you need to break down emotional barriers. Some employees may find it harder than others to adapt to the new normal. Ask them: How is their transition going? Do they need help with anything? What pieces of advice can you offer to make remote work work for them?`}</p>
    <p>{`Rather than being solely a manager, consider your position as that of a mentor or guidance counselor.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      