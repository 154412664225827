import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-02-27T00:00:00.000Z",
  "title": "Holoportation Hits The Workplace To Save AR\n",
  "slug": "holoportation-hits-the-workplace-to-save-ar",
  "excerpt": ["Holoportation is a  clever hybrid between the worlds of VR and AR.", "The technology promises to change “the way work gets done” by creating interactive holograms.", "Holoportation is aimed at the workplace market to bring remote teams together."],
  "images": ["3-HOLOPORTATION.png"],
  "backlink": "https://allwork.space/2019/02/holoportation-hits-the-workplace-to-save-ar/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "https://allwork.space/2018/10/how-ar-and-vr-will-change-our-workspaces/"
      }}>{`Virtual Reality and Augmented Reality`}</a>{` are having a tough time. A lack of commercial sales has led investment levels to fall and both technologies are `}<a parentName="p" {...{
        "href": "https://www.theverge.com/2018/12/30/18159462/2018-tech-recap-vr-ar-virtual-augmented-reality-beat-saber-oculus-hololens-magic-leap"
      }}>{`struggling to break into the mainstream`}</a>{` consumer market.`}</p>
    <p>{`However, an emerging development could provide much-needed salvation for these struggling technologies. It’s a clever hybrid between the worlds of VR and AR called holoportation.`}</p>
    <h2>{`What is Holoportation?`}</h2>
    <p>{`In 2016, Microsoft launched its HoloLens “mixed reality” headset where VR images are overlaid on top of the real world. Unlike other VR technologies, you can manipulate and interact with these “holograms”. For example, a virtual projection of you and your colleagues could share the same space in real-time and with other AR assets. The original promo video for HoloLens was certainly impressive:`}</p>
    <iframe width="100%" height="380" src="https://www.youtube.com/embed/7d59O6cfaM0"></iframe>
    <p>{`But HoloLens is a very different beast compared to other technologies in the VR/AR space, not just in terms of the capabilities of the technology but also because it’s been developed for the world of work, and not for the everyday consumer.`}</p>
    <p><a parentName="p" {...{
        "href": "https://blogs.microsoft.com/blog/2019/02/24/microsoft-at-mwc-barcelona-introducing-microsoft-hololens-2/"
      }}>{`Speaking in a statement`}</a>{`, Microsoft said: “Since the release of HoloLens in 2016 we have seen mixed reality transform the way work gets done… From construction sites to factory floors, from operating rooms to classrooms, HoloLens is changing how we work, learn, communicate and get things done.”`}</p>
    <p>{`When the next-generation HoloLens 2 launched at the Mobile World Congress (MWC) in Barcelona earlier this week, it was clear that Microsoft wants to retain its focus on the world of work.`}</p>
    <p>{`HoloLens 2 is designed for people who work with their hands but may struggle to use a computer or device in their day-to-day work. The `}<a parentName="p" {...{
        "href": "https://blogs.microsoft.com/blog/2019/02/24/microsoft-at-mwc-barcelona-introducing-microsoft-hololens-2/"
      }}>{`latest promo video`}</a>{` gave us a glimpse of how the device could be used in a range of different scenarios:`}</p>
    <p>{`HoloLens 2 was also demoed at MWC by Sven Gerjets, CTO of toymaker Mattel, as a tool to design and develop products. Speaking at the event, Gerjets said: “By bringing people together to collaborate in the same virtual room, we’re overcoming a natural barrier to our collective success. That’s people’s desire: for direct face-to-face interaction when building commitment and trust.”`}</p>
    <p>{`You can see how Gerjets used holoportation to demo how creative teams of remote workers could exchange ideas and information in a shared virtual environment `}<a parentName="p" {...{
        "href": "https://blogs.microsoft.com/blog/2019/02/24/microsoft-at-mwc-barcelona-introducing-microsoft-hololens-2/"
      }}>{`in this video`}</a>{`.`}</p>
    <h3>{`How HoloLens 2 is Different`}</h3>
    <p>{`Compared to the first HoloLens iteration, the HoloLens 2 headset is not only more comfortable, but its interface is also far more intuitive and the field of view is more than doubled to provide a more immersive experience.`}</p>
    <p>{`HoloLens 2 also makes it far easier to detect real objects and manipulate holograms thanks to the built-in AI and the advanced hand and eye tracking functionality. An 8MP front-facing camera and inbuilt speakers further improve its video conferencing capabilities.`}</p>
    <p>{`“Immersion is about more than the holograms and their place in the world. It is also about how you interact with them. The Hololens 2 adapts to you, it adapts to your hands and goes beyond gestures to give you the satisfaction of direct manipulation,” according to Alex Kipman, Microsoft technical fellow and team lead of HoloLens, `}<a parentName="p" {...{
        "href": "https://www.cnet.com/news/hololens-2-ar-announced-for-3500-available-to-preorder-now-ships-later-this-year/"
      }}>{`speaking at MWC`}</a>{`.`}</p>
    <h3>{`Cost and competition`}</h3>
    <p>{`The HoloLens 2 is now available to preorder for $3,500 and is expected to ship later this year. It’s more expensive than the consumer VR devices currently on the market (for example, Oculus Rift typically retails at around $350). However, it `}<a parentName="p" {...{
        "href": "https://mspoweruser.com/magic-leap-admits-they-have-been-leapfrogged-by-hololens-2/"
      }}>{`reportedly leaps ahead`}</a>{` of the Magic Leap One mixed reality headset, which retails at $2,295 and is more focused on the entertainment space.`}</p>
    <p>{`Also targeting the world of work, Google `}<a parentName="p" {...{
        "href": "https://www.x.company/glass/"
      }}>{`Glass`}</a>{` is a similar wearable device that provides a “hands-free device, for hands-on workers”. Based on AR technology, the Glass Enterprise Edition is already used across a range of sectors, including manufacturing, logistics and healthcare, and there are `}<a parentName="p" {...{
        "href": "https://www.wareable.com/ar/google-glass-enterprise-edition-2-release-date-price-specs-6727"
      }}>{`rumours that another edition is due for release later this year`}</a>{`.`}</p>
    <p>{`Holoportation is a powerful technology that’s transforming the world of AR/VR and eyeing up the enterprise space as its core, target market. While the world of work becomes the testing ground for this technology, holoportation is also providing us with a tantalising glimpse into the workplace of tomorrow.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      