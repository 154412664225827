import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { DefaultComponentProps } from '../core';
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      marginLeft: 'auto',
      boxSizing: 'border-box',
      marginRight: 'auto',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
      },
    },
  }),
);

export type ContainerProps<T> = {
  fixed?: boolean;
  styles?: typeof useStyles;
} & DefaultComponentProps;

export const Container = <T extends HTMLElement = HTMLElement>({
  children,
  classname,
  Tag = 'div',
  fixed = false,
  ...rest
}: ContainerProps<T>) => {
  const classes = useStyles();
  const { path } = rest;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return <Tag className={clsx(classes.container, classname)}>{children}</Tag>;
};

export default Container;
