import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-12-11T00:00:00.000Z",
  "title": "Future Of Work: Workplace Compliance And Remote Work\n",
  "slug": "workplace-compliance-and-remote-work",
  "excerpt": ["Remote work is firmly established as the norm. But many employers are not ready to deal with the legal implications of switching to an all-remote or hybrid model.", "The unfortunate reality is that only a few companies have given their remote workers some sort of assistance in the form of a one-time or monthly stipend.", "In this Q&A, Carlos Escutia of GroWrk discusses WFH compliance and why flexibility over the location of their work — be it home, a satellite office or a coworking space — is essential in our new normal."],
  "images": ["workplace-compliance-and-remote-work.png"],
  "backlink": "https://allwork.space/2020/12/future-of-work-workplace-compliance-and-remote-work/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Remote work is here to stay, and with it comes a new set of challenges and issues organizations must think about. `}</p>
    <p>{`One issue of particular importance for businesses is workplace compliance. Throughout the years, companies have invested millions into making their workplace environments `}<a parentName="p" {...{
        "href": "https://www.osha.gov/"
      }}>{`OSHA`}</a>{` compliant. `}</p>
    <p>{`With most people working from home because of the coronavirus pandemic, what does this mean for workplace compliance? Especially considering that many organizations cannot guarantee or prescribe a standard ergonomic workspace due to the wide variety of home environments workers have. `}</p>
    <p>{`Allwork.Space spoke with Carlos Escutia, CEO of `}<a parentName="p" {...{
        "href": "https://growrk.com/"
      }}>{`GroWrk`}</a>{`, a remote employee workspace management platform, about what employers can do to prepare for new OSHA work-from-home guidelines. `}</p>
    <p><strong parentName="p">{`Allwork.Space: With most people working remotely due to the pandemic, what does this mean for workplace compliance?`}</strong>{` `}</p>
    <p>{`Carlos Escutia: In a traditional office space, there are clear guidelines regarding suitable work equipment. It’s understandable that, back in March, very few companies took it upon themselves to ensure their team was telecommuting from a safety-compliant environment. `}</p>
    <p>{`After all, many remained skeptical of remote work, and believed the pandemic would be under control in a matter of weeks; a couple of months, tops. `}</p>
    <p>{`Remote work isn’t new, however, it’s now firmly established as the norm. But many employers are not ready to deal with the legal implications of switching to an all-remote or hybrid model.`}</p>
    <p><strong parentName="p">{`Allwork.Space: What are some of the legal implications organizations should keep in mind as they pursue remote working models?`}</strong></p>
    <p>{`Employers may soon be legally required to provide ergonomic workstations to workers, wherever they may be. In the US, California employment law already requires that “all working employees shall be provided with suitable seats when the nature of the work reasonably permits the use of seats,” and there’s strong legal precedent to back it.`}</p>
    <p>{`Overlooking workplace safety practices such as proper ergonomics puts not only the team at risk, but the company, as well. The health issues that can arise due to sustained time at an unsuitable workstation are numerous and could lead to litigation and worker’s comp claims. `}</p>
    <p>{`Giving employees the basics so they can work safely and properly from their home is not only what a great working environment should provide, but also a step that can save companies an expensive lawsuit in the future. As we move into the new normal, this will become less of an option and more of an obligation. `}</p>
    <p><strong parentName="p">{`Allwork.Space: In your experience, are employers taking any steps to ensure that the home office of employees is safe/compliant?`}</strong></p>
    <p>{`Amid the frenzy to report on which companies were switching to remote, an important and necessary conversation has been largely swept under the rug: When entire teams start fully or partially working remotely, what are the responsibilities that a company has to take on in order to take care of their teams? `}</p>
    <p>{`The unfortunate reality is that only a few companies have given their remote workers some sort of assistance in the form of a one-time or monthly stipend. `}</p>
    <p>{`While the intention is noble, the fact is, employees are far from experts on ergonomics or workspace design and, realistically, a couple hundred dollars a month won’t cut it to invest on proper equipment. `}</p>
    <p>{`And that’s just the basics. `}</p>
    <p>{`Within companies, entire departments are dedicated to figuring out how to boost morale and build a healthy corporate culture that will attract new talent, and making the transition to remote only means leaders need to adapt to what they must offer their workers from a distance.`}</p>
    <p>{`Make no mistake, there are bare-minimum requirements of what a remote team should be offered, but success is never the result of bare-minimum effort. Remote work is here to stay, even post COVID-19, therefore it’s time to talk about what a business that embraces remote work will need to truly thrive in the long-term.`}</p>
    <p><strong parentName="p">{`Allwork.Space: Are there currently any work from home guidelines that companies should be aware of?`}</strong></p>
    <p>{`In the United States, OSHA states that all home-based workers have the same workers’ compensation benefits as in-office workers regarding any injuries they may sustain on the job, such as tripping over cables or misplaced objects, falling down stairs, or back, neck or wrist injuries due to poor ergonomics.`}</p>
    <p>{`Though companies have more control over preventing safety hazards in one physical office space than in the individual homes of each of their employees, it’s worth considering that a remote work model drastically cuts the possibilities of commute-related injuries while on the clock, which can be eligible for workers’ compensation.`}</p>
    <p>{`Because the law doesn’t differentiate between traditional office buildings and home offices, there have been many cases of courts siding with workers when it comes to injuries sustained at home during office hours.`}</p>
    <p><strong parentName="p">{`Allwork.Space: In other words, employers are responsible for the health and safety of their remote workers while they work from home, correct?`}</strong></p>
    <p>{`While they are on the clock, technically yes. `}</p>
    <p>{`Beyond comfort and productivity, taking the initiative to ensure that remote workspaces are safe and ergonomically compliant will save companies money in the long run. `}</p>
    <p>{`In the US, almost a third of dollars spent on workers’ compensation costs come from claims involving ergonomic injuries. This is equivalent to $20 billion USD in worker’s compensation payments, medical payments and legal expenses. `}</p>
    <p>{`Furthermore, the indirect costs are 5x this amount in the form of lost productivity, training replacement employees, and costs associated with lower employee morale and absenteeism. It is critical that organizations switching to remote work, fully or partially, plan ahead.`}</p>
    <p><strong parentName="p">{`Allwork.Space: You mention that there is precedence where courts have sided with workers over safety-related lawsuits filed by remote workers. Can you cite some examples?`}</strong></p>
    <p>{`Workplace lawsuits happen all the time. `}</p>
    <p>{`Take Walmart for example, which settled a suit brought under California’s Private Attorneys General Act for $65 million in 2018. `}</p>
    <p>{`Their mistake? Not providing proper seating for their employees.`}</p>
    <p>{`As mentioned earlier, because the law doesn’t differentiate between traditional office buildings and home offices, there have been many cases of courts siding with workers when it comes to injuries sustained at home during office hours.`}</p>
    <p>{`Oregon’s Court of Appeals, for example, ruled in favor of the worker in the case of Sandberg vs. JC Penney, after the worker tripped over her own dog while walking to her garage. Because this was the space where she stored the fabric samples needed to carry out her work tasks, it was determined that she was owed workers’ compensation.`}</p>
    <p>{`As more people continue working remotely, the legal community will be ready to pounce as injuries become documented by medical professionals. `}</p>
    <p>{`Providing remote employees with a proper workspace at home is one thing companies can tackle head-on to prevent a difficult fallout, in the best-case scenario, or a hefty lawsuit, in the worst. `}</p>
    <p><strong parentName="p">{`Allwork.Space: Do you think that in order to remain compliant, even as remote work increases in popularity, employers are more likely to encourage work near home vs work from home settings?`}</strong></p>
    <p>{`The question of whether remote work will continue long-term no longer applies: it has been validated, not just because of the pandemic, but because most people now prefer it after having experienced the benefits of remote work for the first time. `}</p>
    <p>{`The most attractive perk a company can offer to attract and retain top talent (and remain competitive) moving forward, is to provide team members with flexibility over the location and time of their work. `}</p>
    <p>{`This means working from home when they feel that it makes them more productive or meeting with colleagues at a satellite office or coworking space for creative work or client meetings when needed. `}</p>
    <p>{`More than forcing team members to work in an office to remain safety compliant, companies must take the initiative to provide their employees with the resources to ensure they operate from safe workspaces, whether they are working from home (WFH) or working from anywhere (WFA). It really is about making team members feel like autonomous, trusted adults, without leaving them to their own devices. `}</p>
    <p><strong parentName="p">{`Allwork.Space: Anything else you’d like to add?`}</strong></p>
    <p>{`The fact that a team is working from home as opposed to commuting to one shared space every day is no excuse for being negligent about the basic health and safety protocols of a traditional office. `}</p>
    <p>{`Forgoing an office lease will yield considerable savings for the company, and it’s important for a portion of that to be invested back into the workspaces of a remote team.`}</p>
    <p>{`Living up to company values should be a constant goal within your organization, and part of it is letting all new remote employees know that their wellbeing is a top priority, and that they’re not expected to either work from unsuitable workspaces or pay for a decent workstation out of their own pocket. As the world embraces remote work, it’s crucial for companies to also embrace more responsibility regarding the workspaces their employees will be performing from.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      