import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-04-05T00:00:00.000Z",
  "title": "Future Of Work: Introducing The Flexiverse\n",
  "slug": "future-of-work-introducing-the-flexiverse",
  "excerpt": ["Technology platform Werk, which helps companies become flexible through data and insights, has set out 6 flexible work arrangements that help improve employee performance.", "Werk claims that companies should offer at least 2.5 of these arrangements, starting with location variety — giving employees the ability to work from alternative workplaces.", "Together, these arrangements form the ‘flexiverse’, a framework that enables better work performance and a more balanced, healthier lifestyle."],
  "images": ["1-INTRODUCING-THE-FLEXIVERSE.png"],
  "backlink": "https://allwork.space/2019/04/the-future-of-work-introducing-the-flexiverse/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Werk, a technology platform that helps companies become flexible through data and insights, recently published the report `}<a parentName="p" {...{
        "href": "https://werk.co/research"
      }}>{`“The Future is Flexible: The Importance of Flexibility in the Modern Workplace”`}</a>{`. In it, Werk introduces the concept of Flexiverse.`}</p>
    <p>{`Flexiverse is a framework that is made up of 6 flexible work arrangements. According to Werk’s research, employees need access to at least 2.5 types of flexible arrangements in order to feel that his or her job allows and enables flexibility.`}</p>
    <p>{`Before we go into the different types of flexibility, it’s important to note that “there is a significant gap between the supply and demand of workplace flexibility.”`}</p>
    <p>{`96% of professionals need some form of flexibility, however only 42% report having access to the flexibility they need. Even less, only 19% report having access to a range of flexible options.`}</p>
    <p>{`The report also found that this gap is more pronounced in women than in men (34% vs 51%).`}</p>
    <p>{`This lack of flexibility is negatively impacting employee performance, productivity, engagement, `}<a parentName="p" {...{
        "href": "https://allwork.space/2019/04/how-flexible-working-policies-can-improve-workplace-wellness/"
      }}>{`health, and wellness`}</a>{`. For companies, it’s negatively affecting their ability to attract and retain talent and their net promoter scores (eNPS).`}</p>
    <p>{`Even though companies are already starting to adopt flexible work practices and a human-centric approach to workplace design and the work experience — flexible schedules, flexible workspaces, wellness programs, better parental leave, extended vacations days — there’s still a lot that can be done.`}</p>
    <p>{`According to Werk, one of the main issues is that “innovation in terms of how the workplace and jobs are structured has been slow.” On the one hand, companies are starting to offer better benefits packages and increased workplace amenities. Yet, they haven’t “reconsidered the fundamentals of how jobs are designed and how, where, and when work gets done.”`}</p>
    <p>{`As has been greatly documented and reported on, technology has revolutionized the way people work. It’s enabled the rise of the gig economy and of the digital nomad lifestyle. Technology has made it possible for people to successfully work without having to be glued to an office desk. While this is beneficial, it has also come with a new set of challenges that need to be overcome. People are more connected than ever and they’re having a hard time disconnecting to take care of themselves and those they care for. This is particularly challenging for professionals who don’t have access to flexibility.`}</p>
    <h2>{`The Flexiverse`}</h2>
    <p>{`Werk’s flexible types seek to address the issue of flexibility by providing companies and employees with a structured set of time and/or location-based modifications that “ensure high compatibility between the needs of an employee and the employer.”`}</p>
    <p>{`Providing structured flexibility can optimize productivity and loyalty across genders, generations, and skill sets.`}</p>
    <p>{`Below you will find a brief introduction to Werk’s`}<em parentName="p">{` proposed flexiverse types. `}</em>{`These terms are trademarked by Werk.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`TimeShift`}</strong>{` – meaning flexible schedules. Companies should allow for employees to work during times that are the most convenient and productive for themselves in order to avoid long commutes, attend medical appointments, take care of family affairs, etc.`}</li>
      <li parentName="ul"><strong parentName="li">{`MicroAgility`}</strong>{` – meaning allowing employees to come and go depending on their needs. Werk suggests micro increments of 1-3 hours.`}</li>
      <li parentName="ul"><strong parentName="li">{`DeskPlus`}</strong>{` – meaning location variety. Companies should provide employees with different work environments they can use on a regular basis (coworking spaces, cafes, headquarters, etc.) in addition to the company’s offices.`}</li>
      <li parentName="ul"><strong parentName="li">{`Remote`}</strong>{` – meaning location independence. Employees can work from wherever they want. Some remote arrangements require occasional in person meetings while others ask for specific regions (based in Europe, Americas, Asia, etc.) and some are fully independent (anywhere in the world as long as they have a connection).`}</li>
      <li parentName="ul"><strong parentName="li">{`TravelLive`}</strong>{` – meaning minimal travel. Technologies like video conferencing and collaboration apps have reduced the need for business travel.`}</li>
      <li parentName="ul"><strong parentName="li">{`PartTime`}</strong>{` – meaning a reduced workload. Reducing an employee’s work hours should by no means, however, mean that they aren’t in the advancement track. Quite the contrary, employees in this category “have the experience and skills to meet their objectives on a reduced hours schedule. `}</li>
    </ul>
    <p>{`Werk's research found that, ideally, employees should have access to at least 2.5 of these flexibility types in order to perform their best without negatively impacting their personal lives. The report found that location variety is the most in-demand flexibility type, followed by microagility, and flexible schedules.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "656px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2394f440a8bde5916f438b087b507398/748f4/9-img-1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "116.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAYAAAALHW+jAAAACXBIWXMAAAsTAAALEwEAmpwYAAADsklEQVQ4y5WVyW8bZRjG8wdygbJITQEVqULiAOLCoQcSQ0NbtQE5paGboGITaRsncVKX0CxsQjSJs28NaRM7IXYWx/ss9sz34/2+cRwD7qGHx894ZP/meZeZaXGsEna5SMW1cUWV/8mh4ohXXCqeR6VabSIPV36jWS12uUCpWIDCHmp/A3WQqGkz8P2nqEIaNTmA+v4s6u5HqDvt4qHARdxpw9v5C0cu2qLTWSIOt1B/L8LOimi5JjnW5w4TMNwNF16Az16DT1+pS2m/fAJvYxZH0hpguSTA7DZKICq9Giil/XFwTl9s9Kb5s/r8TVS4FXXlFKpLdOV1EPc2558BFBBHEjA7S5IwCSM3TRK634IbbwcuMsDwyecBLgdAnbDzZVT3abj9PurbD1BfvSeJ33gOYOo4oRq5YYC6PF02t94BSae6WmvAuWf30CRrSKh7qBOaIVyVhF0nsX7toRK5GAypaQ/NlJckoUxWVHd9zvRQEl56ETpPUO18lfTYXfLftUlfXxJga5OEuRRqdw219wT21sVr0knzO6Zkrp2h8vM3eF+coRo+RTF2Df/Ld4OSEwsNQL2HstQkpiE5g9qaM05y1pSuwUwP4Y/eIp9cwx+5Lomvk3myTHX8NtzvxNvdwHHdI2AJnj5CzQyilsdgdhB/cQQWfyKTWMfdFPDkPZx7IQo/tKF626j0naPY0y7eAYMX8NLrjcCyAVorv8tApeSlYfKpJNbqH+QnotiL4zDRCxG5zWKXqU5F5c4JQ/85GPjE+DGwmAsSbk5RfhQhOzGEG49Sisew40MwI/fwiqSe6oPedrwfw+yuLeCPyV72yX0dPS/Qjgbg4S5WMW+AarofNTuEmrtfd10+K+PycJCEfSH8/g7K8oDwdDKBKYEx0Jgwu4dVKhigTsPCAwPg8S+mh2omKgnluwBVJCQQAY3Kg+K3r1HDXTVgY8LcQQDc0AmlvPkHBqDLVEsP5SIBUE1GpIchU6IBPbwqfWwGzOqSg4RK92suBrpUrflYvWQ0sC9kyqwPQ6SMdzQrOR6UpxMKSIPNse7lqiScisgQPkbJipiU0SM/b9pQBzr725QLOVmbCZlkrykx0EDgcZmuLv3PHnkynw1WR/bw3/oQL7VWA+YPsCWhXzzAzyTxD7cDZY58Cz+bwt9P4Cfn8bcWm6payuLYFi2ufDiWvAYyaSw9oFymwWvHslaWbWM5blOVtcsuu7a8pFx5U+m3lfmTLPmx8g3nxEv5hnP/Vc7MwbXL/AOdhmIr0BkBRwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "9 img 1",
            "title": "9 img 1",
            "src": "/static/2394f440a8bde5916f438b087b507398/748f4/9-img-1.png",
            "srcSet": ["/static/2394f440a8bde5916f438b087b507398/772e8/9-img-1.png 200w", "/static/2394f440a8bde5916f438b087b507398/e17e5/9-img-1.png 400w", "/static/2394f440a8bde5916f438b087b507398/748f4/9-img-1.png 656w"],
            "sizes": "(max-width: 656px) 100vw, 656px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Demand vs offer of flexibility types. Werk’s report found that professionals have an increased appetite for fully remote positions, location variety, microagility, and flexible schedules.`}</p>
    <p>{`Unfortunately, the report found that companies are significantly under-delivering to their employees across every flexibility type. This is negatively impacting employee and company performance.`}</p>
    <ul>
      <li parentName="ul">{`29% of people believe that the structure of their work day makes it somewhat/very challenging to perform optimally in their role.`}</li>
      <li parentName="ul">{`29% of people believe that the structure of their work day makes it somewhat/very challenging to perform in a sustainable way over time.`}</li>
      <li parentName="ul">{`37% of people believe that the structure of their work day makes it somewhat/very challenging to feel inspired or energized by their physical workspace.`}</li>
      <li parentName="ul">{`29% of people believe that the structure of their work day makes it somewhat/very challenging to bring their whole selves to work.`}</li>
      <li parentName="ul">{`32% of people believe that the structure of their work day makes it somewhat/very challenging to foster skills to develop in their career.`}</li>
      <li parentName="ul">{`Employees without access to flexibility are 2 times more likely to be dissatisfied at work.`}</li>
      <li parentName="ul">{`Companies who provide access to flexibility have employee net promoter scores (eNPS) 48 points higher than those who don’t enable flexibility.`}</li>
      <li parentName="ul">{`eNPS correlates with employee engagement, talent retention, and revenue.`}</li>
    </ul>
    <h2>{`Mind the Gap`}</h2>
    <p>{`Not only is there a gap between the supply and demand of workplace flexibility, there’s also a gap between men, women, and employees’ organizational levels.`}</p>
    <p>{`The research found that “the higher the respondent’s organizational level, the more likely they were to have access to flexibility. This gap is also more pronounced among women; 95% of women need access to flexibility, but only 34% of them have access to the flexibility they need.`}</p>
    <p>{`This is important as research shows that “of the 30% of women who drop out of the workforce after having a child, 70% would have stayed if they had access to flexibility.” Moreover, companies with more women are more likely to introduce radical innovations to the market.`}</p>
    <p>{`In order for companies to meet their diversity and inclusion goals, they need to become more flexible in order to attract and retain a diverse talent pool.`}</p>
    <p>{`The future of work is flexible. “Companies that can effectively leverage flexibility as part of their talent attraction strategy will be at a unique advantage.”`}</p>
    <p>{`Today and in the future, flexibility will be a defining characteristic of successful companies as they will have a workforce that is able to sustain a high level of performance over time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      