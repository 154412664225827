import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-01-10T00:00:00.000Z",
  "title": "Future Of Work: To Win The War For Talent, Organizations Need To Focus On Culture\n",
  "slug": "future-of-work-to-win-the-war-for-talent-organizations-need-to-focus-on-culture",
  "excerpt": ["A recent report by HRExchange argues that HR needs to become more strategic in order for companies to succeed.", "The report also identified the top 5 HR priorities for 2020, including talent attraction and retention, as well as leadership development.", "One particular area HR departments need to focus on is company culture, as it can help talent attraction and retention efforts, but also drive employee engagement."],
  "images": ["51-future-of-work-to-win-the-war-for-talent-organizations-need-to-focus-on-culture.png"],
  "backlink": "https://allwork.space/2020/01/future-of-work-to-win-the-war-for-talent-organizations-need-to-focus-on-culture/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "https://www.hrexchangenetwork.com/employee-engagement/reports/the-state-of-hr"
      }}>{`A recent report by HRExchange`}</a>{` examines the current and future state of HR, arguing that “HR is moving from being transactional to strategic.” One of the main drivers behind this shift is the fact that employees today are more in control of who they want to work for and why.`}</p>
    <p>{`The above, combined with the fact that technology has revolutionized the way HR professionals interact and engage with employees, means that the role of HR departments needs to evolve.`}</p>
    <p>{`The HRExchange report identified the top 5 HR priorities for 2020:`}</p>
    <ol>
      <li parentName="ol">{`Align HR with the Business Objectives`}</li>
      <li parentName="ol">{`Retaining Top Talent`}</li>
      <li parentName="ol">{`Attracting Top Talent`}</li>
      <li parentName="ol">{`Leadership Development`}</li>
      <li parentName="ol">{`Prepare the Workforce for the Future of Work.`}</li>
    </ol>
    <p>{`To address all 5 priorities and ensure success, the report suggests that organizations primarily focus on their company culture. Why? Because in order to succeed, an organization needs to make sure that it has the right talent.`}</p>
    <p>{`Some of this talent is already within the organization, but some isn’t.`}</p>
    <h3>{`The Talent Shortage`}</h3>
    <p>{`According to the report, “For the first time since job numbers were tracked by the federal government in the United States, we are seeing a time when there are quite literally more jobs than there are people. This has caused what people are referring to as the labor shortage.”`}</p>
    <p>{`This has led a power play in recruitment efforts, particularly as HR departments search for talent within their industry, but also outside of it.`}</p>
    <p>{`But the talent shortage isn’t just due to there being more jobs than people; it’s also due to skills gaps. Workers today are struggling to learn new skills fast enough to keep up with technological advances and the abilities needed in today’s age.`}</p>
    <p>{`This is why retention efforts are not enough. Companies need to focus as much on attracting talent as they do on retaining it.`}</p>
    <p>{`This requires that HR pay special attention to detail when it comes to their company culture.`}</p>
    <h3>{`The Role of Culture in Talent Attraction and Retention`}</h3>
    <p>{`Culture plays a key role not only in talent attraction and retention, but also in leadership development and preparing the workforce for the future of work. This creates a talent cycle of sorts, as the best talent is more likely to stay in an organization that prioritizes personal and professional growth and development.`}</p>
    <p>{`Specifically, the report found that culture is incredibly important to Millennial and Generation Z workers. This matters because in 2020 Millennials will remain the largest group of workers in the world’s top four economies, while Gen Z is the most populous generation since the boomers, and `}<a parentName="p" {...{
        "href": "https://www.financialexecutives.org/FEI-Daily/August-2019/How-Generation-Z-Is-Transforming-the-Workplace.aspx"
      }}>{`research projects that Gen Z`}</a>{` will comprise about 36% of the workforce next year.`}</p>
    <p>{`Job candidates in 2020 and beyond will look at culture as one of the determining factors for accepting and keeping any particular job.`}</p>
    <h3>{`How to Improve Company Culture`}</h3>
    <p>{`The challenge with culture is that it is always in flux.`}</p>
    <p>{`One key way organizations can begin to improve their company culture is by offering workers tangible benefits (flexibility, vacation, competitive pay, wellness offerings, etc.) and opportunities to grow. But beyond that, the report found that the best way to improve culture is by cultivating strong employee relationships, followed by increasing individual and team collaboration and increasing leadership development opportunities.`}</p>
    <p>{`Company culture is reliant on employee engagement. To improve employee engagement, organizations need to rethink performance management, starting by outgrowing the idea of yearly performance reviews.`}</p>
    <p>{`One of the best ways to engage employees is to provide them with meaningful work, work that they believe serves the overarching purpose of the company they are working for.`}</p>
    <p>{`Performance management can help engage employees by setting clear responsibilities and expectations. Having a clear definition of what needs to be done and what it should achieve can help employees feel like an important part of the organization by letting them know that their work contributes to the success of the company.`}</p>
    <p>{`With this in mind, HRExchange argues that a good performance management strategy should include:`}</p>
    <ul>
      <li parentName="ul">{`Clear job descriptions`}</li>
      <li parentName="ul">{`New employee orientation`}</li>
      <li parentName="ul">{`Performance standards`}</li>
      <li parentName="ul">{`Ongoing learning and development`}</li>
      <li parentName="ul">{`Performance planning`}</li>
      <li parentName="ul">{`Rewards`}</li>
      <li parentName="ul">{`Potential promotions and/or lateral moves.
The above should help organizations build strong employee engagement, which not only drives performance, but also improves and nurtures culture.`}</li>
    </ul>
    <p>{`It’s an ongoing cycle, a cycle in constant flux.`}</p>
    <p>{`“`}<em parentName="p">{`As the future evolves, so too must the function of HR and with it, the workforce.`}</em>{`”`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      