import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-08-30T00:00:00.000Z",
  "title": "Future Of Work: The Top 7 Skills For Success In Cre\n",
  "slug": "future-of-work-the-top-7-skills-for-success-in-cre",
  "excerpt": ["The world of work is changing, and CRE teams must also change their approach and skillset to accommodate new requirements.", "A new report by Building Engines outlines the top 7 CRE skills that will be critical for property managers, CRE executives, engineers and tenant coordinators in the next 5 years.", "Among them, teams must learn to embrace hospitality and custom service, and learn how to evaluate changing technology solutions."],
  "images": ["29-THE-TOP-7-SKILLS-FOR-SUCCESS-IN-CRE.jpg"],
  "backlink": "https://allwork.space/2019/08/future-of-work-the-top-7-skills-for-success-in-cre/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The world of work is changing, and this is greatly impacting the commercial real estate (CRE) industry. A recent report by Building Engines titled “`}<a parentName="p" {...{
        "href": "https://www.buildingengines.com/landing/impacts-of-generational-shifts-on-the-cre-workplace"
      }}>{`Impacts of Generational Shifts on the CRE Workplace: Understanding Gaps in Talent and How to Fill Them`}</a>{`” argues that there are two main changes impacting the industry.`}</p>
    <p>{`“The first change is how tenants view the workplace (…). The second is that Baby Boomers still make up about 60% of the CRE workforce. As they age out, they are leaving large gaps in technical knowhow on building management teams.”`}</p>
    <p>{`Not only are baby boomers aging, but also, and more pressing, is the fact that younger generations, which are digital natives, aren’t attracted to the CRE industry; they see it as a tech laggard. CRE companies are then faced with the twofold challenge of attracting younger generations to the industry and making sure that they fill the skill-set gap that might be left behind as boomers retire.`}</p>
    <p>{`On the bright side, the CRE industry is undergoing a revolution itself; a revolution driven in part by the evolution of PropTech and by changing tenant needs.`}</p>
    <p>{`The way tenants view the workplace is changing, and CRE teams “must view their building occupants as customers similarly to how B2C companies view their customers. Hospitality and intensive customer service will be vital to delivering the experiences that keep tenants happy in the future.”`}</p>
    <p>{`With all this change happening, it’s important that CRE teams understand which skills they will need to thrive in the future.`}</p>
    <h2>{`Key CRE Skills Required in the Next 5 Years`}</h2>
    <p>{`According to Building Engines’ survey, “team management and problem solving will be crucially important to CRE teams in the coming years.”`}</p>
    <p>{`In fact, 69% of executives believe team management will be one of the most important skills for CRE properties in the next 5 years. Building engineers, for their part, believe problem solving is a critical skill for success in the future, as this will allow teams to understand the uniqueness of each property and find creative solutions when things don’t go to plan.`}</p>
    <p>{`Below are the top CRE skills — in addition to team management and problem solving — that teams will require to be successful in the next five years according to executives, property managers, building engineers, and tenant coordinators.`}</p>
    <h3>{`Executives`}</h3>
    <ul>
      <li parentName="ul">{`Evaluating technology solutions`}</li>
      <li parentName="ul">{`Financial analysis`}</li>
      <li parentName="ul">{`Data analysis`}</li>
      <li parentName="ul">{`Verbal communication`}</li>
      <li parentName="ul">{`Marketing communications`}</li>
    </ul>
    <h3>{`Building Engineers`}</h3>
    <ul>
      <li parentName="ul">{`Project management`}</li>
      <li parentName="ul">{`Evaluating technology solutions`}</li>
      <li parentName="ul">{`Data Analysis`}</li>
      <li parentName="ul">{`Verbal communication`}</li>
      <li parentName="ul">{`Hospitality`}</li>
      <li parentName="ul">{`Property Managers`}</li>
    </ul>
    <h3>{`Project management`}</h3>
    <ul>
      <li parentName="ul">{`Financial analysis`}</li>
      <li parentName="ul">{`Evaluating technology solutions`}</li>
      <li parentName="ul">{`Data analysis`}</li>
      <li parentName="ul">{`Verbal communication`}</li>
    </ul>
    <h3>{`Tenant Coordinators`}</h3>
    <ul>
      <li parentName="ul">{`Verbal communication`}</li>
      <li parentName="ul">{`Hospitality`}</li>
      <li parentName="ul">{`Project Management`}</li>
      <li parentName="ul">{`Evaluating technology solutions`}</li>
      <li parentName="ul">{`Data analysis`}</li>
      <li parentName="ul">{`Event planning and management.`}</li>
    </ul>
    <p>{`It’s important to note here that “tenant coordinators have the most distinctive view of which skill will be important in the next 5 years.” Though tenant coordinators tend to be more junior, Building Engineers argue that they tend to be the ones that best understand the needs of the market.`}</p>
    <p>{`Tenant coordinators “tend to be the closest to the largest segment of building occupants, both proximately and generationally. Their prioritization of communication, hospitality, and event management skills lines up closely with a number of headlines about the direction CRE is moving.”`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      