import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2021-01-22T00:00:00.000Z",
  "title": "Future Of Work: A Q&A With Jeff Shaw Bridge Commercial Real Estate CEO On The Future Of Flex Space\n",
  "slug": "a-qa-with-jeff-shaw-bridge-commercial-real-estate-ceo-on-the-future-of-flex-space",
  "excerpt": ["Suburban offices provide spacious floor plans, proximity to employees’ homes and park-like settings. Will these be the new must-haves in a post-pandemic world?", "With demand for suburban locations and hub-and-spoke continuing to grow, what will happen to tier one cities and CBDs?", "Allwork.Space spoke with Bridge Commercial Real Estate CEO, Jeff Shaw, to dive deeper into the current and future workspace trends, and what it means for flexible space."],
  "images": ["a-qa-with-jeff-shaw-bridge-commercial-real-estate-ceo-on-the-future-of-flex-space.png"],
  "backlink": "https://allwork.space/2021/01/future-of-work-a-qa-with-jeff-shaw-bridge-commercial-real-estate-ceo-on-the-future-of-flex-space/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`With the vaccine rollout already underway, employees will start to slowly return to the office, either in a full-term or part-time capacity.  `}</p>
    <p>{`The question never was whether people would return to the office. Rather, the question that we should have asked all along was: `}</p>
    <p>{`Where will people return to the office? `}</p>
    <p>{`While some companies will ask employees to go back to their headquarters, likely located in tier 1 city CBDs, other companies have already started to evaluate the possibility of moving to suburban and secondary markets.  `}</p>
    <p>{`This is where office activity has concentrated during the pandemic.  `}</p>
    <p>{`Allwork.Space spoke with Bridge Commercial Real Estate CEO, Jeff Shaw, to dive deeper into which areas are experiencing accelerated demand for enterprise flex solutions and office leasing activity. Below are the highlights of our conversation.  `}</p>
    <p><strong parentName="p">{`Allwork.Space: Why do you believe secondary suburban markets are experiencing an increase in flexible office inventory?`}</strong></p>
    <p>{`Jeff Shaw: Suburban markets are experiencing an increase in flexible offices due to the inherent location and logistical advantages they hold over their CBD counterparts. Spacious floor plans, a more tangible sense of community, closer proximity to the homes of employees and outdoor, park-like settings are now valuable commodities in a post-pandemic world.  `}</p>
    <p>{`More flexible lease terms and urban-style amenities are also drawing office users toward the suburbs as many companies begin to reduce real estate costs by gravitating toward a “hub and spoke” model of satellite offices rather than a single, large headquarters in the urban core.  `}</p>
    <p><strong parentName="p">{`Allwork.Space: How have flexible space providers responded to more companies gravitating toward the hub and spoke model? More importantly, are flexible space providers able to deliver on this product in suburban markets and if so, how is it different from their execution in CBDs?`}</strong></p>
    <p>{`If you look at the numbers over the last several months, it’s clear that momentum is growing for suburban office space in general, which bodes well for the more specific category of suburban flex space.  `}</p>
    <p>{`At Bridge, we saw prospective activity and rent growth continue in our portfolio through 2020, with activity levels in the fourth quarter surpassing that of the fourth quarter of 2019 and the number of leases signed in 2020 exceeding 2019.  `}</p>
    <p>{`A new report from the National Association of Realtors reveals that office prices in suburban markets are up 35% from one year ago, while prices in CBDs are down by 14%. Another recent study by CBRE found that a considerable amount of available downtown office supply will cause high vacancy to persist through 2021 at a rate only 80 basis points below its peak after the Great Financial Crisis. `}</p>
    <p>{`While the U.S. office market as a whole has certainly seen a slowdown, the data shows that the stark collapse in leasing activity that has taken place in urban core areas does not necessarily hold true in many suburban markets that are much better positioned to endure and rebound from the pandemic. `}</p>
    <p>{`These are areas that were already trending pre-pandemic due to the rise of amenity-rich environments with a renewed focus on walkability, and we anticipate that demand in suburban submarkets will continue to rise over the next year as the economy recovers and the impact of the virus subsides. Flex office operators understand where the growth is happening and will adapt their strategies accordingly. `}</p>
    <p><strong parentName="p">{`Allwork.Space: Just as companies have shifted their attitudes about flex space, can the same be said about landlords?`}</strong></p>
    <p>{`Landlord attitudes are continuing to warm up toward flexible space as they understand the tremendous growth opportunities that are available. Brand operators like WeWork and IWG account for less than 25% of total flex inventory, helping to fuel a surge of traditional landlords seeking to fill the flex space vacuum that exists, particularly in secondary markets.  `}</p>
    <p>{`A recent CBRE survey of almost 80 international companies found that 86% anticipate using flex space as a key part of real estate strategies going forward. Additionally, 82% favor buildings that include flex-space offerings. `}</p>
    <p>{`Many building owners are responding by launching their own branded flex offerings or forming management agreements with coworking providers. A landlord may have to invest more in capital expenditures on the front end, but ultimately receives a greater share of revenue compared to a conventional lease while also avoiding the pitfalls and risk in traditional coworking models most commonly associated with WeWork. As these trends continue to evolve, you may see many landlords allocate anywhere from 10 to 25% of their properties for flexible leases over the next decade. `}</p>
    <p><strong parentName="p">{`Allwork.Space: Let’s go back to the growing popularity of suburban markets. In your opinion, what are the key characteristics making suburban markets so appealing?`}</strong></p>
    <p>{`Many companies are especially attracted to the perks offered in suburban markets, such as cheaper rent, shorter commutes, abundant parking, outdoor workspaces, health and wellness services and more. Flexibility, both in square footage of physical office space and leasing terms, is the bridge best suited to address the existing void caused by full-time remote work as companies seek hybrid solutions that are now in demand by a workforce that continues to gravitate and move toward suburban areas. `}</p>
    <p><strong parentName="p">{`Allwork.Space: How are these changes in office and location preferences impacting the CRE world?`}</strong></p>
    <p>{`While the pandemic has undoubtedly impacted the industry, office work isn’t going away. We know firsthand that companies truly thrive when they have in-person collaboration so they can maintain a strong culture. As larger corporations take a fresh look at their office space, location and ample space to accommodate social distancing guidelines will be the driving factors. `}</p>
    <p>{`Companies must learn how to best allocate the space for work that can’t be done from home which is creating a shift toward the hub-and-spoke model. For Bridge, the activity level on leasing tours, deals and new prospects has remained close to last year, and COVID has mainly impacted the size of deals rather than the number of them. Other suburban landlords are also reporting new traction in inquiries and leasing tours for flexible space, offering tangible evidence of success and opportunity for owners and investors. `}</p>
    <p>{`Though no one can predict with certainty on how the script for the next decade will unfold, it is clear that suburban flex office space is going to have a significant impact on the broader CRE landscape. Office owners who remain agile and quickly adjust to rapid cultural shifts while delivering new models that tenants now expect will create the most opportunities for long-term growth and success. `}</p>
    <p><strong parentName="p">{`Allwork.Space: With companies and flex space providers gravitating towards suburban markets, what does this mean for CBDs in tier 1 cities?`}</strong></p>
    <p>{`Just like we don’t believe working together in offices will go away, Bridge doesn’t believe cities will go away, either. There will be companies who continue to desire an office presence in these markets. However, the growth of flex space in suburban markets means properties in CBDs will need to work harder to deliver compelling offerings and amenities at the right price when competing to attract these companies. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      