import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-12-04T00:00:00.000Z",
  "title": "Future Of Work: How Flexible Space Can Help The Global Skills Emergency\n",
  "slug": "how-flexible-space-can-help-the-global-skills-emergency",
  "excerpt": ["One-third of the world's workforce must reskill by 2030, according to the WEF's Future of Jobs Report 2020.", "EdTech is becoming a valuable resource for reskilling; by 2023, IDC predicts that 40% of higher education institutions will evolve into digital entities.", "Flexible spaces are the perfect environment for social learning, as they enable a ‘learning by osmosis’ phenomenon."],
  "images": ["how-flexible-space-can-help-the-global-skills-emergency.png"],
  "backlink": "https://allwork.space/2020/12/future-of-work-how-flexible-space-can-help-the-global-skills-emergency/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Before COVID-19 hit, the World Economic Forum (WEF) predicted `}<a parentName="p" {...{
        "href": "https://www.weforum.org/agenda/2020/01/reskilling-revolution-jobs-future-skills/"
      }}>{`one-third of the world’s workforce`}</a>{` – that’s more than one billion people – will need to reskill by 2030.`}</p>
    <p>{`Well, things have gone from bad to worse.`}</p>
    <p>{`Now, the WEF is predicting a period of “`}<a parentName="p" {...{
        "href": "https://www.weforum.org/reports/the-future-of-jobs-report-2020/digest"
      }}>{`double disruption`}</a>{`” where the effects of the pandemic and automation will create a global skills emergency. As a result, 40% of workers will require some form of reskilling, according to The Future of Jobs Report 2020 from the WEF.`}</p>
    <p>{`To address this challenge, today’s workers must reskill for tomorrow’s jobs market – and this is where coworking and flexible spaces can help.`}</p>
    <h3>{`Education, education …coworking?`}</h3>
    <p>{`While many workers are turning to online learning resources to sharpen their skills, coworking spaces are perfectly placed to address one of the major ways we, as humans, learn — social learning.`}</p>
    <p>{`Social learning is very different from formal learning initiatives. Social learning takes many forms but it is essentially the ‘learning by osmosis’ phenomenon where you pick up information and advice in your day-to-day work.`}</p>
    <p>{`In fact, the `}<a parentName="p" {...{
        "href": "https://insights.learnlight.com/en/articles/what-is-social-learning-and-how-can-hr-benefit-from-it/"
      }}>{`70-20-10 model of learning`}</a>{` is widely recognised, where 70% of learning is gained on the job, 20% from work relationships (including formal coaching and mentoring schemes), and 10% from formal courses and learning interventions. Social learning comprises the first two points – making up 90% of all work-based learning.`}</p>
    <p>{`What’s more, the social learning phenomenon is rising in popularity with 94% of business leaders expecting employees to pick up new skills on the job, a sharp uptake from 65% in 2018, according to the WEF report.`}</p>
    <p>{`As the pandemic hampers our social learning abilities, coworking spaces are perfectly placed to buck this trend. Flexible workspaces could provide people with a safe space to learn and collaborate again.`}</p>
    <p>{`But how can you do this in a physically distanced and safe manner?`}</p>
    <p>{`There are `}<a parentName="p" {...{
        "href": "https://allwork.space/2020/03/how-3-coworking-spaces-are-tapping-into-lifelong-learning/"
      }}>{`many ways`}</a>{` coworking spaces can nurture a culture of lifelong learning for their members.`}</p>
    <p>{`To encourage social learning, workspace owners can offer regular training to their members. Depending on the layout of the space, open plan areas and event spaces can be used to hold these events.`}</p>
    <p>{`For those who can’t come into the space in-person, coworking spaces make ideal digital classrooms, which can provide members with a range of value-added educational services.`}</p>
    <p>{`Tools like Google Classroom, Facebook Workplace, PukkaTeam and FocusMate, for example, provide virtual spaces for effective collaboration and social learning.`}</p>
    <p>{`Workspace owners may even want to toy with the idea of using `}<a parentName="p" {...{
        "href": "https://allwork.space/tag/virtual-reality/"
      }}>{`virtual reality`}</a>{` to further boost social learning capabilities. A `}<a parentName="p" {...{
        "href": "https://www.pwc.com/us/vlearning?utm_source=idg&utm_campaign=oculus22147&utm_medium=brandpost&utm_content=training_transformation"
      }}>{`PwC study`}</a>{` found that learners using VR were 275% more confident to act on what they learned after training.`}</p>
    <p>{`Mobile learning – education or training conducted using a mobile device – is another popular option to reach out to learners. Micro-learning initiatives – which deliver short bursts of content to learners — can also help workers access educational resources in a quick and easy manner.`}</p>
    <p>{`Such accessible learning initiatives are now important for learners. Research reveals while many people want to learn to improve their future employability, `}<a parentName="p" {...{
        "href": "http://www.ceosurvey.pwc./"
      }}>{`few have the opportunity or time to do so`}</a>{`, only carving out an average of `}<a parentName="p" {...{
        "href": "https://hbr.org/2019/02/making-learning-a-part-of-everyday-work"
      }}>{`five minutes of formal learning every day`}</a>{`.`}</p>
    <h3>{`Introducing EdTech`}</h3>
    <p>{`EdTech is a burgeoning market with many new platforms and apps hitting the market every day. Traditional educators are also transforming into online providers. In fact, by 2023, `}<a parentName="p" {...{
        "href": "https://www.idc.com/research/viewtoc.jsp?containerId=AP46009220"
      }}>{`IDC predicts`}</a>{` that 40% of higher education institutions will evolve into digital entities.`}</p>
    <p>{`There are plenty of EdTech options to choose from. `}<a parentName="p" {...{
        "href": "https://www.docebo.com/"
      }}>{`Docebo`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.absorblms.com/"
      }}>{`Absorb`}</a>{`, for example, are two cloud-based learning management systems, which use gamification to engage with learners through an intuitive interface and rich feature set.`}</p>
    <p><a parentName="p" {...{
        "href": "https://quizlet.com/"
      }}>{`Quizlet`}</a>{` is another online studying tool where users can create digital quizzes and flashcards, using machine learning to personalise the learning experience. `}<a parentName="p" {...{
        "href": "http://www.cognii.com/"
      }}>{`Cognii`}</a>{` is another AI-enabled tool to individualise learning and expedite the feedback process.`}</p>
    <p>{`To summarise, there are a wealth of online and offline learning initiatives now available to help workspace occupiers survive (and thrive) in the ongoing global skills crisis. As we move forward, there isn’t a one-size-fits-all solution – but educational content can help individuals and teams interact and prepare for the years ahead.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      