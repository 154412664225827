import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-09-25T00:00:00.000Z",
  "title": "Future Of Work: Key Considerations To Create An Effective Hybrid Workplace\n",
  "slug": "future-of-work-key-considerations-to-create-an-effective-hybrid-workplace",
  "excerpt": ["As remote work becomes a normal part of work, organizations are prioritizing digital transformation.", "Leaders are focusing on how to blend the virtual and physical workplace and enable location-independent communication, meetings, collaboration, and work.", "For this to happen, a company’s digital workplace must seamlessly integrate and coexist with the physical workplace, requiring the right blend of technology, leadership and management."],
  "images": ["Future-of-Work-Template-Articles-1280-x-720-5-800x450.png"],
  "backlink": "https://allwork.space/2020/09/future-of-work-key-considerations-to-create-an-effective-hybrid-workplace/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Over the past few years organizations started to look at digital transformation to future-proof their businesses. The global pandemic fully accelerated this trend as it forced organizations across industries to allow employees to work remotely and set up digital infrastructures that would allow workers to be productive outside of the office. `}</p>
    <p>{`As remote work becomes a normal part of work, organizations are prioritizing their digital transformation efforts, focusing particularly on how to blend the virtual and physical workplace. `}</p>
    <p><a parentName="p" {...{
        "href": "https://enterprise.verizon.com/resources/reports/recreating-work-as-a-blend-of-virtual-physical-experiences.pdf"
      }}>{`Recent research by Harvard Business Review Analytic Services`}</a>{` explores the current and future state of the digital workplace. Sponsored by Verizon, the research examines what it takes to successfully manage remote workers and how business leaders can help their organization adapt to new ways of working. `}</p>
    <h3>{`The Future Isn’t All Work from Home`}</h3>
    <p>{`While there certainly will be companies that embrace work from home on a permanent basis, the majority of organizations will opt for a hybrid work model. `}</p>
    <p>{`“The anticipated increase in remote work stems not just from tangible benefits such as time and cost savings, but also from the simple fact that remote work has proven its viability in people’s own experiences. More than half of respondents (58%) who anticipate an increase say it’s because remote work has been normalized and well tested. Twenty-seven percent cite improved quality of life for employees, and 20% say it has increased productivity and improved outcomes.”`}</p>
    <p>{`As a result, organizations are focusing on expanding their employees’ ability to communicate, meet, collaborate, and work in a location-independent fashion. `}</p>
    <p>{`But the rise in remote work doesn’t mean that the physical workplace will disappear. `}</p>
    <p>{`“Organizations will selectively choose which types of work, people, and engagements require a physical presence — and where they can gain efficiencies with virtual work.”`}</p>
    <h3>{`Digital Workplace: Enabling Remote Workers to Thrive`}</h3>
    <p>{`The right technology tools and the right leadership and management styles are needed in order to truly support remote work and create an effective blended virtual/physical workplace. `}</p>
    <p>{`The research found that most organizations and leaders expect the future workplace to blend physical and digital work. This means that a company’s digital workplace must seamlessly integrate and coexist with the physical workplace. `}</p>
    <p>{`For this integration to happen, “business leaders must be intentional in their approach to the digital workplace to maximize its benefits. One finding of particular note from the survey is that organizations that have a digital workplace strategy are consistently more likely to see greater returns from their investments in all dimensions than those that don’t.”`}</p>
    <p>{`Digital workplace investments have improved business agility, business continuity, collaboration, and productivity. However, the research notes that “there are different levels of maturity when it comes to digital work.`}</p>
    <p>{`“More than a third of respondents to the survey (39%) have advanced digital workplaces, with mobility and online collaboration being a normal way of working, and with most of their applications and services online. These are the leaders. A similar number, 38%, are followers, with widespread use of mobile devices, a growing number of online applications and services, and only some standardization of collaboration tools across departments or business units.`}</p>
    <p>{`“Having a patchwork of online services and limited standardization slows followers in their shift to the digital workplace. Meanwhile, the 23% who trail as laggards have only a few online applications and services, and little or no standardization of tools.”`}</p>
    <p>{`Organizations that are reaping the benefits of digital workplaces are the ones that have a cohesive strategy behind their decisions. `}</p>
    <h3>{`Virtual vs Physical: How to Determine Which Format Is Best for Specific Work?`}</h3>
    <p>{`The research found that throughout the pandemic, organizations have been able to identify which types of work are better suited for physical vs virtual workplaces. `}</p>
    <p>{`“Work that may be more effective in person could include product development meetings, onboarding new employees so they can ‘feel the culture,’ and meetings with new clients.”`}</p>
    <p>{`The research found that 40% of respondents say that it is harder to get desired results from digital collaboration than from in-person engagements. `}</p>
    <p>{`On the other hand, routine team or client meetings and conferences will likely shift to a virtual setting on a more permanent basis. This is especially true if employees had to travel in order to attend such events. `}</p>
    <p>{`While onboarding new employees may be done in-person in the near future, in the long-term, part of the onboarding experience could be done virtually, once virtual and augmented reality are widely accessible and reliable. `}</p>
    <h3>{`Tech and Management Considerations`}</h3>
    <p>{`The research identified key technology and management considerations that need to be in place in order to create an effective virtual/physical workplace. `}</p>
    <h4>{`Connectivity`}</h4>
    <p>{`“Network performance is something that should be factored into the future, with 79% saying that ensuring their infrastructure can support digital work will be very important to get value from their investments, rating the importance an “8–10” on a 10-point scale.”`}</p>
    <p>{`While WiFi is still the predominant means by which workers access the digital workplace, the research predicts that “the more widespread use of 5G mobile networking could change that.”`}</p>
    <p><strong parentName="p">{`Most Widely Used Technologies`}</strong></p>
    <ul>
      <li parentName="ul">{`Video conferencing`}</li>
      <li parentName="ul">{`File sharing`}</li>
      <li parentName="ul">{`Instant messaging and chat`}</li>
      <li parentName="ul">{`Shared calendars`}</li>
      <li parentName="ul">{`Collaboration tools`}</li>
      <li parentName="ul">{`The cloud`}</li>
      <li parentName="ul">{`Interactive training`}</li>
      <li parentName="ul">{`UX Trumps Features`}</li>
    </ul>
    <p>{`The research found that “survey respondents expect to get the most value from tools that are easy to use (74% say this is very important) versus those that are feature rich, which only 44% say is very important. Thinking longer term, approximately two-thirds of respondents (65%) also say it’s very important that tools integrate into a cohesive digital work environment.”`}</p>
    <h4>{`Security Is a Top Priority`}</h4>
    <p>{`Security concerns remain a top barrier to digital transformation and creating digital workplaces. This is concerning, as 86% of respondents stated that “it will be very important to address data security to get the most value from their digital investments.”`}</p>
    <p>{`To create a secure virtual and physical work environment, organizations need to make sure that:`}</p>
    <ul>
      <li parentName="ul">{`Their technology is secure both when accessed in-person and virtually. `}</li>
      <li parentName="ul">{`That workers are educated and trained on security best practices.`}</li>
      <li parentName="ul">{`That security protocols and processes are easy to follow.`}</li>
    </ul>
    <h4>{`A Shift in Leadership and Management Styles`}</h4>
    <p>{`“Business leaders and managers must learn a new set of skills and establish new norms for the workplace.”`}</p>
    <p>{`Some key areas where leaders and managers may need to shift their styles include:`}</p>
    <ul>
      <li parentName="ul">{`Supervision: check in on people, but avoid micromanaging and surveillance practices. `}</li>
      <li parentName="ul">{`Set clear expectations: to avoid micromanagement and surveillance practices, the research report suggests that managers be clear about expectations on how people should behave and work when outside of the office. `}</li>
      <li parentName="ul">{`New work norms: block scheduling, team office hours, and increased flexibility should be a key part of any blended workplace. `}</li>
      <li parentName="ul">{`Team recognition: managers will likely need to increase their focus on recognition to keep employees engaged and energized with team projects.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      