import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-08-14T00:00:00.000Z",
  "title": "Future Of Work: No One Knows What The New Normal Is\n",
  "slug": "future-of-work-no-one-knows-what-the-new-normal-is",
  "excerpt": ["For Christina Disler, an HR expert and organizational coach, the new normal is “moment by moment”.", "With hybrid work models now in full effect, organizations need new leadership models that support new work arrangements.", "The principles of mindfulness as a practice –  a state of awareness plus intention – can help people better lead their organizations now and in the future."],
  "images": ["no-one-knows-what-the-new-normal-is.png"],
  "backlink": "https://allwork.space/2020/08/future-of-work-no-one-knows-what-the-new-normal-is/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`“If there is one thing the pandemic has taught us, it is that there is no knowing what the new normal really is.” – Christina Disler, Founder of `}<a parentName="p" {...{
        "href": "http://www.werklab.com/"
      }}>{`Werklab`}</a>{` and `}<a parentName="p" {...{
        "href": "http://www.thewerk.co/"
      }}>{`The Werk`}</a></p>
    <p><img parentName="p" {...{
        "src": "https://allwork.space/wp-content/uploads/2020/08/Untitled-design.png",
        "alt": null
      }}></img></p>
    <p>{`Over the past three months, there has been a lot of talk around the new normal. `}</p>
    <p>{`But what exactly is the new normal?`}</p>
    <p>{`According to Christina Disler, no one really knows. `}</p>
    <p>{`“If you ask me, the new normal is moment by moment. We can do our best to plan and think about the future, but what we should really take out of this whole experience (the pandemic and not knowing what will happen in the next week, let alone the next month or year), is that we need to do a better job as individuals and organizations to develop our resilience.”`}</p>
    <p>{`Disler, who has a background in HR and organizational coaching, believes that the principles of mindfulness can help leaders better lead their organizations now and in the future. `}</p>
    <p>{`“If we focus more on our present, rather than thinking about what might or might not be, we would be better serving our future. `}</p>
    <p>{`“Our ability to adapt and be resilient is tied to our ability to be in the present and be aware of what’s happening.”`}</p>
    <p><img parentName="p" {...{
        "src": "https://allwork.space/wp-content/uploads/2020/08/WerkLab-Vancouver-open-space.jpg",
        "alt": null
      }}></img></p>
    <p>{`“The new normal is moment by moment” – Christina Disler (Werklab Vancouver)`}</p>
    <p>{`But, what is mindfulness?`}</p>
    <p>{`“For Disler, mindfulness is awareness plus intention; it’s a state of being. Contrary to what many believe, mindfulness is not simply meditation, “it’s a skill we can develop; it’s our ability to rebound and come to the present moment.”`}</p>
    <h3>{`The Leadership Model of Days Gone By`}</h3>
    <p>{`“So much leadership in the past has been future-focused. This has created and nurtured a culture of overdrive and burnout. Think about it, how many times have you been unable to celebrate achieving a goal because you were already thinking about working on achieving the next one?”`}</p>
    <p>{`In addition to the stress-inducing chase of the future and it’s associated planning, professionals have been conditioned to separate work from their personal lives, something that causes internal tension, according to Disler. `}</p>
    <p>{`This can have adverse effects on an individual, but also on a company. `}</p>
    <p>{`“Innovation and creativity are optimized when we can be connected with ourselves. So, when we armor up and become someone else in the workplace, we are not meeting our full capacity  of doing our best work.”`}</p>
    <h3>{`Why We Need New Leadership Models and How Mindfulness Can Help`}</h3>
    <p>{`With hybrid work models now in full effect, organizations need new leadership models that support new work arrangements.`}</p>
    <p>{`“How we choose to show up on a leadership capacity is so much more magnified now than ever before. The office served as a way to maintain and define company culture; it worked as a sort of container.`}</p>
    <p>{`“Since we don’t have access to such a strong rooting in the office at the moment, our culture is much more dependent on other factors, like how someone feels working for a company, how easily they can communicate with others, and how supported they feel.”`}</p>
    <p><img parentName="p" {...{
        "src": "https://allwork.space/wp-content/uploads/2020/08/WerkLab-Vancouver-collaboration.jpg",
        "alt": null
      }}></img></p>
    <p>{`With regular office life on pause, our culture is focuses on other things – like how supported people feel, or how easily they communicate with others (Werklab Vancouver)`}</p>
    <p>{`Disler believes that some core principles of mindfulness can help leaders and organizations be better prepared to support employees and be ready for what the future might bring. `}</p>
    <p>{`Core principles of mindfulness:`}</p>
    <ul>
      <li parentName="ul">{`Trust`}</li>
      <li parentName="ul">{`Acceptance`}</li>
      <li parentName="ul">{`Patience`}</li>
      <li parentName="ul">{`Letting go `}</li>
      <li parentName="ul">{`Conscious awareness.`}</li>
    </ul>
    <p>{`With people working remotely, effective communication has become a key priority. “We have to be very intentional with how we choose to communicate with one another, especially now that we no longer have the convenience to hop over to a colleague’s desk or chat during our lunch breaks. This means we have to establish a new cadence and rapport around our communication.”`}</p>
    <p>{`Trust is a key element of successful leadership. `}</p>
    <p>{`“Our old structures of companies, which were very hierarchical, are naturally becoming more flat as more people work remotely. Leaders can no longer see employees at their desks from 9 to 5 from the comfort of their corner office; which means that leaders have to trust teams and team members to do their jobs to the best of their ability.”`}</p>
    <p>{`Beyond trust, leaders can also set an example by being accepting and patient with employees. This is particularly important from a mental health standpoint. `}</p>
    <p>{`“We already have enough energetic weight with all that’s happening in the world, from politics to the pandemic. The way we lead teams has to come from a different place if we want to help people better cope with the stress and anxiety that might be associated with current situations. `}</p>
    <p>{`“If leaders are able to accept that people might be struggling with their mental health and if they are patient with each team member, they will find that people will find it easier to let go of fear and anxiety. At the end of the day, the wellbeing of an individual impacts the entire system, from how they work and their perception of the company to how they interact with others.”`}</p>
    <p>{`By practicing mindful leadership, organizations will not only be better supporting their employees, but also experiencing valuable organizational advantages. `}</p>
    <p>{`According to Disler, mindful leadership positively impacts efficiency, it improves communication, it supports trust, and increases collaboration. “But the number one thing for organizations is that mindful leadership can support agility, the ability to pivot and adapt and be more acutely aware of what needs to be done and when.”`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      