import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-10-18T00:00:00.000Z",
  "title": "Which EU Countries Are Best Prepared for the Future of Work?\n",
  "slug": "which-eu-countries-are-best-prepared-for-the-future-of-work",
  "excerpt": ["The Lisbon Council’s new report, ‘The 2019 Future of Work Index’, assessed how the world of work is changing and which countries are adequately prepared for it.", "To succeed in the future of work, countries need to have a workforce that is mobilised and integrated, with a high level of digital literacy and skills.", "The Index found that Sweden, Denmark, and The Netherlands top the list, with the UK coming fifth."],
  "images": ["39-which-eu-countries-are-best-prepared-for-the-future-of-work.jpg"],
  "backlink": "https://allwork.space/2019/10/which-eu-countries-are-best-prepared-for-the-future-of-work"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The Lisbon Council recently published the report “The 2019 Future of Work Index: How the World of Work is Changing and How Policy Needs to Change with It” developed by The Future of Work Laboratory. The report sought to answer questions like, how is the world of work changing? What are the consequences? Are countries adequately prepared for the future of work?`}</p>
    <p>{`The 2019 Future of Work Index is a 16-indicator ranking built around three pillars: (1) the modern workforce, (2) new jobs and new tools, and (3) transition effectiveness.`}</p>
    <p>{`The modern workforce pillar, also known as workplace inclusion, “assesses the level and depth to which traditionally marginalised groups participate in the workforce.” The second pillar measures “the enthusiasm and passion with which individual countries are embracing digital technology and moving towards modern ways of organising economic life. Last but not least, the third pillar — transition effectiveness — “measures the speed with which countries are adapting their social models, ensuring that real protection is on offer against the background of a fast evolving set of social needs and offering genuine security in a time of deep-seated economic change.”`}</p>
    <p>{`The Index identified six key trends that are shaping the future of work, and should therefore, shape the policies of each country.`}</p>
    <h3>{`1. Changing Workforce`}</h3>
    <p>{`People are living longer and therefore working longer. The workforce is also much more diverse today than it was in the past, particularly as more women are now incorporated into it.`}</p>
    <h3>{`2. Evolving Workplace`}</h3>
    <p>{`Though flexible working policies are increasingly being implemented by companies, there is still much to be done, particularly as modern families have a concurrent need for more readily accessible childcare and more flexible work-time arrangements, given that both parents are part of the workforce.`}</p>
    <h3>{`3. Global Economy`}</h3>
    <p>{`“The economy itself is more open and less forgiving than it used to be.” Knowledge work is the way of the future, and therefore it’s imperative that countries and companies invest in educating people. In the report’s own words, if “you’re not able or ready to retrain to work with modern tools and methods, you are probably in big trouble.”`}</p>
    <h3>{`4. New Careers, New Paths`}</h3>
    <p>{`“Life-time employment has become a thing of the past.” Which makes learnability a key issue that companies and countries alike need to address. Change is now the only constant, and people need to have access to the right tools and resources to adapt.`}</p>
    <h3>{`5. Rise of Independent Work and Freelancing`}</h3>
    <p>{`“Against the backdrop of an increasingly competitive global economy and the ever-shifting competitive landscape within it (…) the fastest growing part of the workforce is made up of part-time and short-term (temporary) workers.”`}</p>
    <h3>{`6. Policy Lags`}</h3>
    <p>{`“The implications of these changes have not yet been fully understood – let alone`}</p>
    <p>{`incorporated into a consistent body of thoughtful social legislation that provides a social safety`}</p>
    <p>{`net fit for the modern age.”`}</p>
    <p>{`So, with all of the above taken into consideration, which countries are best prepared for the future of work? And which ones are the least ready?`}</p>
    <p>{`Before we list the countries, the report states the following:`}</p>
    <p>{`“The aim is not to flatter some or embarrass others. To the contrary, every country has areas where they can improve. The aim is to help countries understand what those areas are – where the weak spots in their social fabric and policy framework lie – and to find ideas and inspiration from other countries that might be doing things a little bit better.”`}</p>
    <h2>{`The Top 5 Countries that Are Future Ready`}</h2>
    <ol>
      <li parentName="ol">{`Sweden`}</li>
      <li parentName="ol">{`Denmark`}</li>
      <li parentName="ol">{`The Netherlands`}</li>
      <li parentName="ol">{`Finland`}</li>
      <li parentName="ol">{`United Kingdom`}</li>
    </ol>
    <p>{`The Index found that Sweden, Denmark, and The Netherlands top the list, in that order. All three countries scored well on the new jobs and new tools indicator. This means that these countries are well prepared to transition into a mostly knowledge-driven economy. Furthermore, these countries were also among the top five and top six on the transition effectiveness and modern workforce indicators.`}</p>
    <p>{`Finland ranked fourth; though the country ranked well on transition effectiveness, the country lacks workplace inclusion, which brought its overall performance in the index down. The UK came in fifth and it ranked No. 1 in workplace inclusion; however it ranked lower on transition effectiveness.`}</p>
    <h2>{`The Top 5 Least Prepared Countries for the Future of Work (lower score first)`}</h2>
    <ol>
      <li parentName="ol">{`Greece`}</li>
      <li parentName="ol">{`Bulgaria`}</li>
      <li parentName="ol">{`Croatia`}</li>
      <li parentName="ol">{`Romania`}</li>
      <li parentName="ol">{`Italy`}</li>
    </ol>
    <p>{`The report found that Greece “suffers from poor performance in all three categories” while Italy performed worst among Europe’s large, industrialised economies.`}</p>
    <p>{`“Put simply, with an annual gross domestic product of €1.7 trillion and a population of 61 million, Italy will have to do better if Europe is to do better.”`}</p>
    <h2>{`Other Key Findings From the Report`}</h2>
    <ul>
      <li parentName="ul">{`Germany ranked 8th; it performed well on workplace inclusion, however the country “urgently needs better, more modern policies to help its evolution.”`}</li>
      <li parentName="ul">{`Estonia ranked 6th overall and it was the only “new member state” that reached the top 10. It performed well on the modern workforce and transition effectiveness pillar, however it needs to work on its new jobs and tools aspect.`}</li>
      <li parentName="ul">{`Belgium came in 17th, below the EU average. “On new jobs and new tools, it is above the EU Average at No.8; but its workforce remains weak on inclusion, giving it a shocking No.23 finish on this key indicator.”`}</li>
      <li parentName="ul">{`There is a large gap between the top and bottom performers — over 60 points according to the scoring between Sweden and Greece.`}</li>
      <li parentName="ul">{`To succeed in the future of work, countries need to have a workforce that is mobilised and integrated; “economies that exclude workers on the basis of age, nationality or sex are doing themselves no favours.”`}</li>
      <li parentName="ul">{`It’s important that countries and companies address the digital literacy and digital skill levels of the workforce.`}</li>
      <li parentName="ul">{`“Politics and policymaking in the modern age are in many ways a matter of preparing for and managing change.”`}</li>
      <li parentName="ul">{`While continuous education is a key part of the future of work, it’s equally important for people to have access to labour and product markets. “It doesn’t matter how well-prepared people are if the jobs and opportunities aren’t there too.”`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      