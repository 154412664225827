import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-07-05T00:00:00.000Z",
  "title": "Future Of Work: Are You Digitally Fit?\n",
  "slug": "future-of-work-are-you-digitally-fit",
  "excerpt": ["JLL discusses how to future proof your company by becoming ‘digitally fit’.", "In a panel hosted by JLL, specialists discussed how to maximize CRE technologies.", "Becoming ‘digitally fit’ helps companies differentiate from the competition.", "Future fit, digital enterprises are able to increase profitability and create seamless workplace experiences."],
  "images": ["17-are-you-digitally-fit.jpg"],
  "backlink": "https://allwork.space/2019/07/ai-in-the-built-environment-4-key-areas-it-will-impact/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Last week, JLL hosted a webinar on how the most forward-thinking, digitally fit CRE leaders are implementing technology in their organizations and how, in doing so, they are differentiating themselves from the competition.`}</p>
    <p>{`During the hour-long webinar, Ardell Bunt, Head of Sales EMEA Corporate Solutions; Tom Carroll, Head of Corporate Research and strategy, EMEA; Dr. Marie Puybaraud, Head of Research, Global Corporate Solutions; and Michael Ewert, Head of Business Intelligence & Business Solutions, EMEA talked about technology, digitization, and key areas of CRE tech growth.`}</p>
    <p>{`Dr. Puybaraud kicked off the webinar by stating that “digital is touching everything we do, and it’s overwhelming. There’s so much noise and change that organizations are struggling to figure out the right path.”`}</p>
    <p>{`A recent Future of Work report by JLL found that adoption of technology is set to increase in the future, and that technology is, in fact, the top investment priority for CRE leaders. Dr. Puybaraud shared that 48% of CRE leaders plan to outsource more of their technology and that 51% are actively increasing collaboration with their IT departments.`}</p>
    <p>{`According to panelists, “this marks a change because as an industry CRE leaders have talked about technology and digitization for several years now, and this is transforming into investment.”`}</p>
    <h2>{`Getting Digitally Fit`}</h2>
    <p>{`The top 4 recommendations shared by Dr. Puybaraud are:`}</p>
    <h3>{`1. Collaborate with IT`}</h3>
    <p>{`Working with IT is key to creating a digital strategy and securing support around common priorities.`}</p>
    <h3>{`2. Build a CRE Dream Team`}</h3>
    <p>{`To be a digitally fit company, you need to boost your CRE team’s skills. This can be done by sourcing digital talent and leveraging external partners.`}</p>
    <h3>{`3. Invest, Test, and Pilot`}</h3>
    <p>{`To be digitally fit, companies need to invest in new technologies. More importantly, they need to test them and focus on whether these solutions are effective in enabling the workforce.`}</p>
    <h3>{`4. Focus on Metrics`}</h3>
    <p>{`Metrics drive better outcomes, simple as that. Figure out key metrics in order to track progress and gather data that can help make more informed, data-backed decisions.`}</p>
    <p>{`Future fit, digital enterprises are leading the future of work because through technology, they have been able to enhance enterprise profitability and create better, seamless workplace experiences. According to JLL data, the average profit growth of future fit companies averages 31% over three years. Companies that aren’t digitally fit average 19% in the same time period.`}</p>
    <p>{`To truly be future fit, organizations are looking externally to partners in order to get the expertise and support they need, as well as to drive broader business goals and increased profitability. The top areas for outsourcing growth include: facilities and property management, CRE technology, energy management, project construction, employee experience, and data analytics and business intelligence.`}</p>
    <h2>{`Why Digitally Fit is Better`}</h2>
    <p>{`Tom Carroll argued that future fit companies have a better level of integration and coordination between their groups, specifically HR and IT. The greatest value added by becoming a digitally fit company include: enhanced human experience, enhanced financial performance, operational excellence, digital drive, and continuous innovation.`}</p>
    <p>{`Future fit companies are those that outpace their competition in tech adoption, which yields better results. Some key technologies adopted by future fit companies include:`}</p>
    <ul>
      <li parentName="ul">{`IoT and smart technologies`}</li>
      <li parentName="ul">{`Automated end-to-end workflow solutions`}</li>
      <li parentName="ul">{`VR and immersive technology`}</li>
      <li parentName="ul">{`Self-service business intelligence`}</li>
      <li parentName="ul">{`Integrated workplace management systems`}</li>
      <li parentName="ul">{`Machine learning`}</li>
      <li parentName="ul">{`Advanced technologies (drones, robotics, 3D printing).`}</li>
    </ul>
    <h3>{`What’s Stopping Companies from Becoming Digitally Fit?`}</h3>
    <p>{`The top constraints to adding strategic value include the fact that digitalization is not easy and that it requires significant initial investment. Furthermore, JLL found that 35% of companies lack C-suite alignment and commitment, which becomes a barrier to adopting the necessary CRE technology.`}</p>
    <p>{`Last but not least, JLL also found that many companies and their leaders lack knowledge of effective data and analytics, which limits their ability to add value to their organization.`}</p>
    <p>{`Future fit enterprises have been able to overcome these barriers by having a proactive attitude towards technology. They have also focused on the following key criteria:`}</p>
    <ul>
      <li parentName="ul">{`They collaborate deeply with IT and HR`}</li>
      <li parentName="ul">{`They prioritize and incentivize innovation`}</li>
      <li parentName="ul">{`They invest in IT and digital solutions`}</li>
      <li parentName="ul">{`They re-skill and upskill their CRE teams`}</li>
      <li parentName="ul">{`They have formed strategic partnerships to achieve goals`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      