import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-12-27T00:00:00.000Z",
  "title": "Future Of Work: Will The Concept Of A ‘job’ Be Destroyed By The Fourth Industrial Revolution?\n",
  "slug": "future-of-work-will-the-concept-of-a-job-be-destroyed-by-the-fourth-industrial-revolution",
  "excerpt": ["The world of work is changing at lightning speed, with many referring to it as the fourth industrial revolution.", "Technologies such as artificial intelligence and machine learning are automating routine tasks, and taking over human jobs.", "In an interview with Allwork.Space, Doris Viljoen, Senior Futurist at the Institute for Futures Research and USB-ED, explained how people can prepare for these changes and which skills will be crucial for success in the future workplace."],
  "images": ["49-future-of-work-will-the-concept-of-a-job-be-destroyed-by-the-fourth-industrial-revolution.png"],
  "backlink": "https://allwork.space/2019/12/future-of-work-will-the-concept-of-a-job-be-destroyed-by-the-fourth-industrial-revolution/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The world of work is changing at lightning speed. Many are deeming this the Fourth Industrial Revolution, a revolution powered by artificial intelligence, robotic process automation, machine learning, the Internet of Things, and big data, to name a few.`}</p>
    <p>{`These technologies are already automating routine jobs, and they likely will continue to do so in the future. Doris Viljoen, Senior Futurist at the Institute for Futures Research and USB-ED, believes that this will happen specifically in the white and pink collar sectors. Yet, the idea of a future dominated by robots is far from being a reality.`}</p>
    <p>{`Previous industrial revolutions destroyed many jobs, but they also created more jobs. This will happen again; in fact it’s already happening. Viljoen argues that “we’ve already seen a growth in 3D designers, ML specialists and rogue biohackers, and can expect even more jobs to be created than we have yet to realise.”`}</p>
    <p>{`Beyond jobs, new technologies have enabled digital platforms that allow individuals to be part of a national and global economy without the need for proximity or being part of a company structure. This has also dramatically changed the world of work, as more workers opt for gig work over fixed employment.`}</p>
    <p>{`Allwork.Space spoke with Doris Viljoen, Senior Futurist at the Institute for Futures Research and USB-ED, to learn how companies can prepare for the future of work, why the concept of a job will evolve, and what skills will be crucial for success in the future.`}</p>
    <p><strong parentName="p">{`Allwork.Space: In our email exchanges, you stated that the concept of a job itself, which was created by the First Industrial Revolution, may well be destroyed by the Fourth. Why will this happen and what will replace the concept?`}</strong></p>
    <p><strong parentName="p">{`Doris Viljoen`}</strong>{`: The concept of a job as we know it could be altered significantly; we may have to find new words to describe what we do. Maybe instead of a job we’ll call it something like economic activity. This is due to the fact that a person will probably be economically active in different ways, doing different things for different people simultaneously.`}</p>
    <p>{`Or, who knows, we may own a team of robots that work on our behalf while we sip cocktails on the beach?`}</p>
    <p><strong parentName="p">{`Allwork.Space: Cocktails on the beach wouldn’t be too bad. Anyways, part of the reason the concept of a job will be destroyed is because some jobs will be increasingly fulfilled by robots. But, as new technologies emerge, us humans will need to develop new skills for emerging professional roles. What are some of the key areas/sectors you believe will create new jobs and, more importantly, what types of skills will we need to develop as individuals to succeed in them?`}</strong></p>
    <p>{`I strongly believe that connecting, creating and enabling are the areas of new jobs.`}</p>
    <p>{`We will need to learn to connect people with other people, connect people with things, and connect things with things. We’ll have to increasingly focus on creating experiences — for customers and employees — and we will have to become experts at enabling communication and creating platforms.`}</p>
    <p>{`To do this, we will need to develop digital fluency, communication, and ethical awareness skills. Personally, I also believe people will need to develop curiosity as a skill. People will have to be curious, not intimidated by new information or ways of doing things.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "734px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/396b3730be1109b9823a3cadb552a27f/c6d67/49-img-1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "68.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACN0lEQVQ4y61US2sTURS+GwVRKogLEX+KT8SdKCg+UJCuhSJU1JqoG/E3SMVH1Y2LoqbUH9BU3bTmNa15v5v3zGSSmSSTZJLPc66JpuBKcuHjnu+c7zwu3HvFcDjENCEw5SX6Awf2oIfuoP8HvQn7X3zSzxjncy1x4fsznPG6CY9werQfX1vAKa9rF2d77Pvtd+Pk2kOcIJxbf0K2C2fXH0Ps81zBQc91if2fr+Lwyk3s+XQJh1Zu4ADxI6u3IJbPYy/5WDNDYN+M5xqOrs6S/zKOfZmVMfHxIsTTnx8w51/EfPAVHihLEnO+Rdzxv8CC8hb3Q29wl2OhJck5Nh94CdfWe9wLvpac82/7nsOtvIPo9G2ULQ1auwGz20Kza0lesXRY3Tb5DagtA6bdgmGb0EnHftbVO02kjAJyjTLSRhEN24KIGnl8LSvYrEWRs6rIE0JaCkE1iYKlYktPY4Ni8UYBO1YNsXoehZaKtFmG1esgXM9hW88ga1Zg9TsQ6WYJipZEhgRcLFzP4ltJwUY1InnM2EGEmiZJN26WaVZQamtUsI2wnoW3GJSNuaEoUrcEjc17kqZQtDT8tQTiVChrVvFDjdH0MQSoKSeFjRx4CN1uSiQoJ6gm4CPNZjUKkWoW5cg5Grnc1lFqaZJXRnaECgQowa/G5XQ8aYROwafapgZpsyTvIt/BntOH4OcyIPByhgN0nVGQRMx57zhdCdvp/b3kZDNYu+uljI3hqOj/rF1veSqfwkThX6tR5NP7ucrPAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "services sector: increasing roles",
            "title": "services sector: increasing roles",
            "src": "/static/396b3730be1109b9823a3cadb552a27f/c6d67/49-img-1.png",
            "srcSet": ["/static/396b3730be1109b9823a3cadb552a27f/772e8/49-img-1.png 200w", "/static/396b3730be1109b9823a3cadb552a27f/e17e5/49-img-1.png 400w", "/static/396b3730be1109b9823a3cadb552a27f/c6d67/49-img-1.png 734w"],
            "sizes": "(max-width: 734px) 100vw, 734px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`A few examples of possible ‘next jobs’ in the service sector.`}</p>
    <p><strong parentName="p">{`Allwork.Space: Not only is the 4th Industrial Revolution changing the world of work by destroying and creating more jobs, but also by changing the expectations of customers and employees. Among the four trends that you think will shift the way organisations work, you included the Public Lab. What is the Public Lab and how will it affect company culture and performance?`}</strong></p>
    <p>{`In a few words, the Public Lab refers to Innovative spaces and experiences that expose a brand’s work and staff to public view. This is a direct response to consumers’ increasing interest in knowing key information of how large companies operate.`}</p>
    <p>{`Today, people expect organisations to be responsible in all of their activities and they expect to be allowed access to the information that could indicate how the organisation runs its operations. This counts for customers and employees, as well as interested parties.`}</p>
    <p>{`Organisations are quite used to this kind of pressure from outside (customers and other stakeholders), but increasingly we are seeing this kind of pressure from existing and potential employees. Considering talent attraction is among the top business challenges, addressing this is key.`}</p>
    <p>{`There’s also another aspect to the Public Lab and it is the expectation that both customers and employees have to be treated individually, with the potential for a customised, co-created product or service (in the case of customers) and terms of employment (in the case of employees).`}</p>
    <p><strong parentName="p">{`Suggested Reading: “`}<a parentName="strong" {...{
          "href": "https://allwork.space/2019/06/future-of-work-the-shift-towards-employee-owned-hr/"
        }}>{`Future of Work: the Shift towards Employee-owned HR`}</a>{`”`}</strong></p>
    <p><strong parentName="p">{`Allwork.Space: With so many changes happening so fast, it’s only natural for companies to rethink their talent management strategies. What are some key ways in which you believe talent management will need to evolve in order to cater to tomorrow’s talent?`}</strong></p>
    <p>{`First and foremost, I believe it will have to be a co-created process. Companies will have to accept that talent will move in and out of their organisation, so HR departments need to think of ways to create a process where people can easily join, leave, and rejoin the company. In the future, it will be highly likely that companies will be sharing their talent with other organisations and business will be more of a gig-like environment.`}</p>
    <p><strong parentName="p">{`Allwork.Space: Speaking about gig-like environments, you argue that entrepreneurship and the gig economy are steadily on the rise, with many workers opting for these professional paths rather than within a company. What does this mean for companies in the future and how can they keep attracting talent?`}</strong></p>
    <p>{`I think it will reach a point where we will have to reconsider our notion of talent; in the future, it may be the talent that we have access to, rather than the talent that we ‘own’ through full-time employment. In terms of talent management in such an environment, HR managers may be wise to learn from the marketers and salespeople – there are many potential synergies between customer service and retention and future talent management.`}</p>
    <p>{`Allwork.Space: I think we’re already seeing a bit of this. While companies used to focus on creating a unique customer experience, increasingly companies are starting to focus on creating unique employee experiences. As we head into 2020, what are some key trends you’re looking forward to?`}</p>
    <p>{`In the developing world, where in many cases there are large numbers of people with lower levels of skills, job losses as a result of automation may be significant. The people losing these jobs may have very limited options for other employment. However, the great news is that platforms and gig-work could allow people in remote areas to access economic opportunities, especially as 5G networks start being rolled out, as this could truly enable work-from-anywhere.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      