import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-11-15T00:00:00.000Z",
  "title": "Future Of Work: Remote Workers Are Happier, Loyal And More Productive\n",
  "slug": "future-of-work-remote-workers-are-happier-loyal-and-more-productive",
  "excerpt": ["A new report focuses on how remote work impacts employment opportunities and job satisfaction.", "It also reveals the challenges faced by remote workers, such as more time spent in meetings and feelings of isolation.", "The workplace will become increasingly flexible, as more than 40% of remote workers plan to work away from the office more often."],
  "images": ["43-future-of-work-remote-workers-are-happier-loyal-and-more-productive.jpg"],
  "backlink": "https://allwork.space/2019/11/future-of-work-remote-workers-are-happier-loyal-and-more-productive/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Owl Labs recently published its 2019 State of Remote Work. The report surveyed 1,202 full-time workers across the United States who work remotely, and analyzed how remote work impacts employment opportunities, job satisfaction, happiness, loyalty, and productivity. It also assessed the challenges remote workers face in their jobs.`}</p>
    <h3>{`Remote Work Is the Present`}</h3>
    <p>{`The report starts off by saying that remote work is the present of work with nearly two thirds of US workers polled stating that they work remotely at least some of the time.`}</p>
    <p>{`The report also found that the US is slightly behind the global average in its adoption of remote work; however, those that work remotely in the US tend to do it more frequently than the global average, with remote workers in the US working remotely full-time 66% more frequently than those in other countries.`}</p>
    <h3>{`Who’s Working Remotely and Why`}</h3>
    <p>{`As for who’s working remotely, the report found that there are 18% more executives working remotely than there are working on-site. Senior executives and those above work at least once per week remotely, 34% more than those in lower positions.`}</p>
    <p>{`Interestingly, 75% more on-site workers have worked in their current positions for less than a year, meaning that new employees work remotely less frequently as there may be company policies in place that only allow workers to work remotely once they’ve been in a position for a specific period of time.`}</p>
    <p>{`The report found that remote workers tend to be most represented in the following departments:`}</p>
    <ul>
      <li parentName="ul">{`Customer Support, service, and success – 17%`}</li>
      <li parentName="ul">{`Facilities, operations, and IT – 16%`}</li>
      <li parentName="ul">{`Administrative – 14%`}</li>
      <li parentName="ul">{`Sales – 12%`}</li>
      <li parentName="ul">{`Other – 8%`}</li>
      <li parentName="ul">{`Product and engineering – 7%`}</li>
      <li parentName="ul">{`Legal and finance – 7%`}</li>
      <li parentName="ul">{`HR – 7%`}</li>
      <li parentName="ul">{`Executives and company leadership – 5%`}</li>
      <li parentName="ul">{`Marketing – 4%`}</li>
      <li parentName="ul">{`Education – 3%.`}</li>
    </ul>
    <p>{`The report found that the top 4 reasons professionals choose to work remotely are:`}</p>
    <ul>
      <li parentName="ul">{`Better work-life balance – 91%`}</li>
      <li parentName="ul">{`Increased productivity and better focus – 79%`}</li>
      <li parentName="ul">{`Less stress – 78%`}</li>
      <li parentName="ul">{`No commute – 78%`}</li>
      <li parentName="ul">{`Salaries`}</li>
    </ul>
    <p>{`Owl Labs found that “the share of remote workers by income disproportionately favors the higher income categories.”`}</p>
    <p>{`The good news — for remote workers — is that they are 2.2 times more likely to earn $100,000 per year compared to their on-site peers. Although 74% of remote workers earn less than $100,000 per year, that number jumps to 92% for on-site workers; moreover, 26% of remote workers earn over $100,000 per year, a significant number compared to the 8% of on-site workers that earn that much.`}</p>
    <p>{`Compensation also plays an important role in the realm of perks and benefits. 34% of those surveyed by Owl Labs reported that they would be willing to take a pay cut of up to 5% in order to work remotely; 24% a pay cut of up to 10%; and 20% a pay cut of more than 10%.`}</p>
    <p>{`Alternatively, 6 out of 10 remote workers would expect additional compensation to stop working remotely, and 55% of them stated they would likely look for another job if they were no longer allowed to work remotely.`}</p>
    <h3>{`Benefits`}</h3>
    <p>{`Contrary to popular belief that remote workers don’t get any work done, the Owl Labs survey found that remote workers carry out more than 40 hours per week, 43% more than on-site workers. It gets even more interesting when workers are asked why they work longer hours; while on-site employees say they work more hours because they have to, remote workers say they do it because they like to.`}</p>
    <p>{`Not only does remote work increase productivity, but it also contributes to employee happiness and makes them feel more trusted. Remote workers also claim to be better able to achieve work-life balance, they are less stressed, they are more loyal to their company, and they are more inclined to recommend their employer to a friend.`}</p>
    <ul>
      <li parentName="ul">{`Remote workers are 29% more happy in their jobs than on-site workers.`}</li>
      <li parentName="ul">{`83% of survey respondents agree that the ability to work remotely would make them happier.`}</li>
      <li parentName="ul">{`81% of survey respondents agree that the ability to work remotely would make them more likely to recommend their company to a friend.`}</li>
      <li parentName="ul">{`80% of all survey respondents agree that the ability to work remotely would make them less stressed.`}</li>
      <li parentName="ul">{`74% of survey respondents agree that the ability to work remotely would make them less likely to leave their employer.`}</li>
      <li parentName="ul">{`71% of survey respondents agree that the ability to work remotely would make them more likely to choose one employer over another in their next job.`}</li>
      <li parentName="ul">{`Remote workers say they are likely to stay in their current job for the next five years 13% more than on-site workers.`}</li>
    </ul>
    <h3>{`Challenges`}</h3>
    <p>{`While remote work offers many benefits, it does have its set of challenges, including the fact that remote workers have to attend more meetings each week than on-site workers.`}</p>
    <p>{`According to the report, 14% of remote workers dedicate time to more than 10 meetings per week. Remote workers also say that meetings reduce their productivity 1.8 times more than on-site workers.`}</p>
    <p>{`But this is a minor challenge when compared to the fact that most remote workers and remote managers receive no training on how to work remotely. This could be a factor that contributes to the divorce between remote worker vs manager expectations and worries.`}</p>
    <p>{`While managers are “most concerned about reduced employee productivity (82%), reduced employee focus (82%), lower employee engagement and satisfaction (81%), and whether their remote employees are getting their work done (80%)”, remote workers often cite loneliness and isolations as their main challenge.`}</p>
    <h3>{`Remote Work Is Also the Future`}</h3>
    <p>{`Although the report claims that remote work is the present, it’s important to note that it will play a much stronger role in the future of work.`}</p>
    <p>{`More than 40% of remote workers plan to work remotely more frequently in the future and more than 50% of on-site workers saying they want to work remotely in the future. Only 19% of polled professionals have no interest in working remotely at all.`}</p>
    <p><a parentName="p" {...{
        "href": "https://allwork.space/2019/09/future-of-work-the-war-for-talent-is-about-to-get-fiercer/"
      }}>{`With the war for talent expected to become fiercer`}</a>{`, companies will likely increasingly turn to remote and flexible work to attract and retain their best employees.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      