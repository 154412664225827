import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { Seo } from '.';
import styles from '../styles/base.scss';
import { useStaticQuery, graphql } from 'gatsby';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { DefaultComponentProps } from '../core';

// Global values imported/defined starts
const {
  primaryLight,
  primaryMain,
  primaryDark,
  primaryContrastText,
  secondaryLight,
  secondaryMain,
  secondaryDark,
  secondaryContrastText,
  htmlFontSize,
} = styles;
const importedFontWeights = {
  light: 300,
  regular: 400,
  semiBold: 600,
  bold: 700,
};
const spacingFactorValues = [0, 4, 8, 16, 32, 64, 128];
const fontSizeRemValues = spacingFactorValues.map(value => value / htmlFontSize);
const shadows = [
  'none',
  '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
  '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
  '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
  '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
  '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
  '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
  '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
  '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
  '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
  '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
  '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
  '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
  '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
  '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
  '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
  '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
  '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
  '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
  '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
  '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
  '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
  '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
  '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
] as any;
// Global values imported/defined ends

let theme = createMuiTheme({
  palette: {
    primary: {
      light: primaryLight,
      main: primaryMain,
      dark: primaryDark,
      contrastText: primaryContrastText,
    },
    secondary: {
      light: secondaryLight,
      main: secondaryMain,
      dark: secondaryDark,
      contrastText: secondaryContrastText,
    },
    text: {
      primary: '#525960',
    },
  },
  typography: {
    fontFamily: ['Montserrat'].join(','),
    fontSize: +htmlFontSize,
    h1: {
      fontWeight: importedFontWeights.bold,
      fontSize: `${fontSizeRemValues[5]}rem`,
      letterSpacing: '0.313rem',
      textTransform: 'uppercase',
    },
    h2: {
      fontWeight: importedFontWeights.bold,
      fontSize: `${fontSizeRemValues[4]}rem`,
      letterSpacing: '0.125rem',
    },
    h3: {
      fontWeight: importedFontWeights.semiBold,
      fontSize: `1.65rem`,
    },
    h4: {
      fontWeight: importedFontWeights.semiBold,
      fontSize: `1.325rem`,
    },
    subtitle1: {
      fontWeight: importedFontWeights.regular,
      fontSize: `${fontSizeRemValues[3]}rem`,
      letterSpacing: '0.125rem',
    },
    subtitle2: {
      fontSize: `${fontSizeRemValues[2]}rem`,
    },
    caption: {
      fontWeight: importedFontWeights.light,
      fontSize: `${fontSizeRemValues[3]}rem`,
    },
    body1: {
      color: 'textPrimary',
    },
    body2: {
      color: 'textPrimary',
    },
  },
  spacing: (factor: number) => spacingFactorValues[factor as number],
  overrides: {
    MuiList: {
      root: {
        fontSize: `${fontSizeRemValues[3]}rem`,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
      },
    },
    MuiInputBase: {
      root: {
        color: primaryContrastText,
      },
    },
    MuiTextField: {
      root: {
        color: primaryContrastText,
      },
    },
  },
  shadows: shadows,
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 640,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
});

type LayoutProps = {} & Pick<DefaultComponentProps, 'classname' | 'children'>;

theme = responsiveFontSizes(theme);

export const Layout = ({ children }: LayoutProps) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `,
  );

  return (
    <>
      <ThemeProvider theme={theme}>
        <Seo />
        {children}
      </ThemeProvider>
    </>
  );
};

export default Layout;
