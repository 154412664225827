import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-05-31T00:00:00.000Z",
  "title": "AI In The Built Environment: 4 Key Areas It Will Impact\n",
  "slug": "the-future-of-work-proptech-3-0-powered-by-blockchain-and-ai",
  "excerpt": ["With PropTech investment faltering, what does the future hold for real estate technology?", "Despite a recent decrease in real estate technology investments, 2019 is expected to see a resurgence in PropTech investment.", "It’s a fast-growing industry, and investors believe the challenge for PropTech companies this year will be to stand out from the competition.", "The smart building sector is considered the least challenged PropTech segment and given the sheer size of the market, some sources expect PropTech adoption to accelerate in this field."],
  "images": ["13-PROPTECH-3-POWERED-BY-BLOCKCHAIN-AND-AI.png"],
  "backlink": "https://allwork.space/2019/05/the-future-of-work-proptech-3-0-powered-by-blockchain-and-ai/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "https://www.cretech.com/wp-content/uploads/2019/02/CREtech-2018-End-Year-Report-1.pdf"
      }}>{`According to a report by CREtech`}</a>{`, “investors deployed $9.6 billion in 2018 `}{`[in real estate technology]`}{`, a 23 percent decrease in total dollar volume since 2017.”`}</p>
    <p>{`The report blames market volatility and uncertainty for this dip. And although investor confidence in PropTech dropped last year, `}<a parentName="p" {...{
        "href": "https://www.metaprop.org/confidence-index"
      }}>{`Metaprop’s 2018 End of Year Confidence Index`}</a>{` found that 60% of PropTech investors plan on making more investments in 2019 compared to 2018.`}</p>
    <p>{`In a fast-growing industry and market, investors believe the challenge for PropTech companies this year will be to stand out from the competition. Speaking to Metaprop, an investor stated that “we expect to see a rise of technologies that automate traditional tasks that bridge lenders, brokers, agents, and settlement service providers.”`}</p>
    <h2>{`The Age of PropTech 3.0`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.altusgroup.com/wp-content/uploads/2019/01/CRE-Innovation-Report-2019.pdf"
      }}>{`Altus Group’s 2019 CRE Innovation Report`}</a>{` found that “driving efficiency through automation is a key priority for CRE executives.” Furthermore, the report found that CRE executives are “uncertain on the potential role and impact of blockchain” as one of the next disruptive forces. And yet 37% believe blockchain will start having an impact on the CRE industry within the next two years.`}</p>
    <p>{`That 37% is right.`}</p>
    <p>{`Research published by the `}<a parentName="p" {...{
        "href": "https://www.sbs.ox.ac.uk/sites/default/files/2018-07/PropTech3.0.pdf"
      }}>{`University of Oxford`}</a>{` believes the age for PropTech 3.0 is approaching. PropTech 3.0 is technology for real estate that will be powered by blockchain and artificial intelligence. And with investors interested in backing PropTech companies that stand out from the competition, PropTech companies that are able to integrate blockchain and artificial intelligence into their product will probably end up on the right side.`}</p>
    <p>{`According to research from the University of Oxford, blockchain can significantly impact the real estate industry by eliminating the need to be dependent on intermediaries. Real estate transactions “require time, proximity and relationships. By contrast, a distributed ledger such as blockchain has the capacity to send data, without friction, to all relevant parties.”`}</p>
    <p>{`Incorporating blockchain into PropTech can enable “lease transactions arranged directly between lessor and lessee; land acquisitions and dispositions including title and parcel details; title, ownership and planning histories being available during the acquisition and due diligence process; and the exchange of maintenance records in building.”`}</p>
    <p>{`A platform powered by blockchain would also create a word of digital asset management, which would register ownership and manage and distribute revenues directly.`}</p>
    <p>{`With CRE executives looking to drive efficiency, efficiency in real estate transactions is a good place to start. But, blockchain and artificial intelligence can do a lot more than simplify real estate transactions.`}</p>
    <p>{`Metaprop’s 2018 End of Year Confidence Index also found that one of the areas PropTech investors are most interested in is smart buildings.`}</p>
    <p>{`Smart buildings, and smart cities, are powered by the Internet of Things, artificial intelligence, and big-data driven analytics. And according to the University of Oxford, “the blockchain is also argued to be the natural medium for feedback” from all of these technologies.`}</p>
    <p>{`This means that blockchain can be used to build applications like smart contracts and services. It can also make more information available and lead to less error, less duplication, and less inefficiency, which translates to lower costs and better building and city performance.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.forbes.com/sites/garybarker/2019/05/22/trending-technologies-the-roadmap-to-ai-in-proptech/#4aa38e45d06c"
      }}>{`Writing for Forbes`}</a>{`, Gary Barker argues that “AI can improve and enhance almost all digital consumer experiences delivered by property agencies: it can help consumers get to the right information more quickly and can add significant value in recommendations based on their choices.”`}</p>
    <p>{`However, Barker also argues that PropTech is not quite at that stage yet. It’s also worth adding that real estate is a slow moving asset class, and the industry itself is highly conservative, which is why, according to Altus Group’s research, many CRE executives remain uncertain about a number of emerging technologies.`}</p>
    <p>{`Still, “it should be recognised that the smart building sector is the least challenged PropTech segment.” According to the University of Oxford, the demand is clear, the market is huge, and the technology is increasingly available. This means that, at least in this particular segment, PropTech adoption will accelerate.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      