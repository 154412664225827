import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-07-28T00:00:00.000Z",
  "title": "Are Smart Buildings Actually That Intelligent?\n",
  "slug": "are-smart-buildings-actually-that-intelligent",
  "excerpt": ["Many supposedly smart buildings still rely on a complex medley of unintegrated systems, interfaces and gadgets.", "Where are all the truly smart buildings?Smart building technologies need to converge to provide real value.", "A clear framework is required to help address the stalemate for smart buildings.", "However, a handful of buildings are leading the way for smart tech."],
  "images": ["21-are-smart-buildings-actually-that-intelligent.jpg"],
  "backlink": "https://allwork.space/2019/06/are-smart-buildings-actually-that-intelligent/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Have you ever wondered why you can’t turn on a building like an iPhone? The smart building is a buzzphrase that’s been, well, buzzing around for years — and yet we don’t seem to be any closer to true innovation.`}</p>
    <p>{`In fact, most supposedly-smart buildings lag behind our consumer devices because they are `}<a parentName="p" {...{
        "href": "https://allwork.space/2018/08/the-challenges-of-smart-buildings-for-flexible-workspaces/"
      }}>{`failing to combine many disparate technologies into one seamless user experience`}</a>{`. Instead, we still rely on a plethora of unintegrated systems that require a range of interfaces and gadgets to run.`}</p>
    <p>{`Which doesn’t seem very smart at all.`}</p>
    <p>{`This practice is at odds with, for example, smartphones, which have successfully converged our MP3 players, cameras, media players and even the humble calculator into one device.`}</p>
    <p>{`So, how can we address such disparity?`}</p>
    <p>{`The industry first needs a clear framework when it comes to defining a smart building.`}</p>
    <p>{`Our definitions are starting to streamline. According to a `}<a parentName="p" {...{
        "href": "https://www.sciencedirect.com/science/article/pii/S1110016818300887#b0065"
      }}>{`recent paper`}</a>{`, The Intelligent Building Institute (IBI) of the United States, as well as the UK-based European Intelligent Building Group (EIBG), claim an `}<a parentName="p" {...{
        "href": "https://books.google.co.uk/books?hl=en&lr=&id=7O8fjtbdFkcC&oi=fnd&pg=PR2&ots=jWoVQ1dsB-&sig=ugd9b1si-TMnSZ1Du6xFn3stkNI&redir_esc=y#v=onepage&q&f=false"
      }}>{`intelligent building`}</a>{` is: “one which provides a productive and cost-effective environment through optimisation of its four basic elements including structures, systems, services and management and the interrelationships between them.”`}</p>
    <p>{`This is an important point. It clearly demonstrates that, for a building to be truly intelligent, it needs a high level of integration between its smart technologies. It’s not enough to plug in Alexa and a few iPads.`}</p>
    <p>{`In fact, a water-tight smart building strategy is needed to drive employee engagement, cost savings, energy efficiencies and other such benefits, according to a `}<a parentName="p" {...{
        "href": "https://www2.deloitte.com/insights/us/en/topics/digital-transformation/smart-buildings-people-centered-digital-workplace.html"
      }}>{`recent report`}</a>{` from Deloitte, which also states: “An effective strategy should define a holistic set of requirements that spans across functional areas such as IT, operations, human resources, corporate real estate, and security. It should also identify the team of key stakeholders based on these requirements, and prioritise use cases from the requirements to establish realistic time frames and make the right investments in proofs-of-concept and full-scale implementations. All of these can be essential to making the most of a smart building initiative.”`}</p>
    <p>{`We’re already seeing some cutting-edge examples of integrated technologies to create a truly intelligent building. Here are a handful of structures that are leading the way:`}</p>
    <h3>{`The Hyper-Connected`}</h3>
    <p>{`The Edge in Amsterdam certainly is a shining light in automation with Bloomberg once naming it “`}<a parentName="p" {...{
        "href": "https://www.bloomberg.com/features/2015-the-edge-the-worlds-greenest-building/"
      }}>{`The Smartest Building in the World`}</a>{`”.`}</p>
    <p>{`The Edge assimilates to meet the needs of its occupants by connecting a vast range of data points through its smartphone app, which was developed with the building’s main tenant, consulting firm Deloitte.`}</p>
    <p>{`For example, when you drive onto the site, automatic number plate recognition cameras scan your car and allocate you a parking space on arrival. You’re then assigned an appropriate workstation based on your schedule to make sure you’re in the perfect spot for the day ahead. The app will even analyse your profile and choose a desk that meets your personal preferences.`}</p>
    <p>{`When you walk around the office, the app knows your temperature and light level preferences and changes the environment accordingly. The Edge also achieved the `}<a parentName="p" {...{
        "href": "https://www.breeam.com/filelibrary/BREEAM%20Awards/109611_Best_of_BREEAM_Awards_2016_WEB.pdf"
      }}>{`highest ever BREEAM sustainability score`}</a>{` of 98.4%.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/466f1bfa13d383c2a518adf5996f5cf2/84ae3/21-img-1.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBAwT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAv/aAAwDAQACEAMQAAABZMcVNwof/8QAGBABAQEBAQAAAAAAAAAAAAAAAQASEQL/2gAIAQEAAQUCOSzpe2m36b//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwGI/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8Bqv/EABgQAAMBAQAAAAAAAAAAAAAAAAABETEQ/9oACAEBAAY/AiSGtd0//8QAGhABAQEAAwEAAAAAAAAAAAAAAREAITFxgf/aAAgBAQABPyFpluUgHxrb5Ocq95oc7vL3/9oADAMBAAIAAwAAABBjz//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/EGn/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPxBl/8QAGxABAAMBAAMAAAAAAAAAAAAAAQARITFBYXH/2gAIAQEAAT8QbJVaLKv4RA+cDt2EiLgHECUd+5bmyPnYFoHmZP/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "21 img 1",
            "title": "21 img 1",
            "src": "/static/466f1bfa13d383c2a518adf5996f5cf2/4b190/21-img-1.jpg",
            "srcSet": ["/static/466f1bfa13d383c2a518adf5996f5cf2/e07e9/21-img-1.jpg 200w", "/static/466f1bfa13d383c2a518adf5996f5cf2/066f9/21-img-1.jpg 400w", "/static/466f1bfa13d383c2a518adf5996f5cf2/4b190/21-img-1.jpg 800w", "/static/466f1bfa13d383c2a518adf5996f5cf2/84ae3/21-img-1.jpg 1116w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`The Super Green`}</h3>
    <p>{`Opened in 2012 by Siemens, The Crystal in London is one of the world’s most sustainable buildings. In fact, this all-electric building has reportedly achieved the `}<a parentName="p" {...{
        "href": "https://www.thecrystal.org/about/"
      }}>{`highest certification`}</a>{` in both the BREEAM and LEED schemes.`}</p>
    <p>{`The Crystal Building Energy Management System controls all electrical and mechanical systems in the building, managing `}<a parentName="p" {...{
        "href": "https://www.thecrystal.org/wp-content/uploads/2015/04/The-Crystal-Sustainability-Features.pdf"
      }}>{`more than 3,500 data points`}</a>{` to optimise everything from its ground source heat pump to its rainwater harvesting system, black water treatment and its extensive solar roof panels (which generate 20% of the building’s electricity).`}</p>
    <p>{`As a result, CO2 emissions for the Siemens offices in the Crystal are around 70% lower than in comparable office buildings in the UK.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0f5317ba08c49e1d3f5120c9d7fae7ab/0f98f/21-img-2.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAHABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAMB/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAdiJYL//xAAYEAEBAAMAAAAAAAAAAAAAAAACAwABEv/aAAgBAQABBQKlWVuzwVXP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRAAAgMBAAAAAAAAAAAAAAAAAAERITGR/9oACAEBAAY/AopFvhkn/8QAGRAAAgMBAAAAAAAAAAAAAAAAABEBMWFR/9oACAEBAAE/IUlOCYhRsCoT2T//2gAMAwEAAgADAAAAEHQP/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/EGR//8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAh/9oACAECAQE/ENhb/8QAGhABAAIDAQAAAAAAAAAAAAAAAQARIUGBwf/aAAgBAQABPxB6wq0EnWMJXdEfZQgA0PnuZ//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "21 img 2",
            "title": "21 img 2",
            "src": "/static/0f5317ba08c49e1d3f5120c9d7fae7ab/4b190/21-img-2.jpg",
            "srcSet": ["/static/0f5317ba08c49e1d3f5120c9d7fae7ab/e07e9/21-img-2.jpg 200w", "/static/0f5317ba08c49e1d3f5120c9d7fae7ab/066f9/21-img-2.jpg 400w", "/static/0f5317ba08c49e1d3f5120c9d7fae7ab/4b190/21-img-2.jpg 800w", "/static/0f5317ba08c49e1d3f5120c9d7fae7ab/e5166/21-img-2.jpg 1200w", "/static/0f5317ba08c49e1d3f5120c9d7fae7ab/b17f8/21-img-2.jpg 1600w", "/static/0f5317ba08c49e1d3f5120c9d7fae7ab/0f98f/21-img-2.jpg 1920w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`Integrated IoT`}</h3>
    <p>{`Burj Khalifa in Dubai isn’t just the world’s tallest building, it’s also one of the most intelligent. A series of Honeywell smart devices and platforms based on the IoT improve the air quality, lighting and temperature at the site.`}</p>
    <p>{`These systems collect real-time data and push it to the IoT platform, identifying anomalies and any maintenance issues, allowing for the predictive and prescriptive maintenance of the building’s mechanical assets.`}</p>
    <p>{`Since launching this system at the Burj Khalifa, facility managers have `}<a parentName="p" {...{
        "href": "https://inbuildingtech.com/smart-buildings/burj-khalifa-smart-building/"
      }}>{`reportedly reduced`}</a>{` total maintenance hours by 40 per cent.`}</p>
    <h3>{`One to Watch…`}</h3>
    <p>{`The Times Square Theater in New York is due to get a $100 million makeover and open in late 2020. When it does, it will be kitted out with wiring and microphones to pick up phone signals that retailers can `}<a parentName="p" {...{
        "href": "https://www.cretech.com/news/intelligent-design/"
      }}>{`reportedly use to track customers`}</a>{` as they browse or price check items on Amazon.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      