import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-09-18T00:00:00.000Z",
  "title": "Future Of Work: Company Culture Is Very Powerful But It's Being Lost At Home\n",
  "slug": "company-culture-is-very-powerful-but-its-being-lost-at-home",
  "excerpt": ["Simon Rusk, from BE Offices, believes it is time we head back to the office and reconnect with our teams and work communities.", "According to him, it can be easy to forget how to work as a team if we continue working from home indefinitely.", "The problem is that after six months, many people have become accustomed to working within their home environments; it’s comfortable, if not entirely convenient."],
  "images": ["company-culture-is-very-powerful-but-its-being-lost-at-home.png"],
  "backlink": "https://allwork.space/2020/09/company-culture-is-very-powerful-but-its-being-lost-at-home/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Working from home has its benefits. As we’ve discovered over the past few months, working from home means no time-draining commute, fewer early mornings and in theory at least, better work/life flexibility.`}</p>
    <p>{`But six months on from the initial lockdown, have we reached the point where we have become too reliant on working from home? Is it time to step out of our comfort zones and begin re-connecting with our teams and workplace communities?`}</p>
    <p>{`For Simon Rusk, who co-founded BE Offices in 1993, it’s well past time. And he is leading by example.`}</p>
    <p><img parentName="p" {...{
        "src": "https://allwork.space/wp-content/uploads/2020/09/pastedImage-1.png",
        "alt": null
      }}></img></p>
    <p>{`“I’m going into the office as much as possible now, at least two or three times per week,” said Rusk during a phone interview with Allwork.Space. “We have a rota system in place for our team and we are encouraging them to do the same.”`}</p>
    <p>{`All of BE’s offices have been redesigned to enable physical distancing and fitted with COVID-19 safety measures. And while many staff members could theoretically continue working from home, Rusk is eager to bring them back together to reignite team cohesion and creativity.`}</p>
    <p>{`“The average age of our team is mid-twenties. Unless they come back in, it’s easy to forget how to work as a team, how to be in an office and interact with people. It starts to fall away.”`}</p>
    <h3>{`Tech platforms only offer “one way conversations”`}</h3>
    <p>{`Most of BE’s serviced offices are located in Central London or other city centres including Belfast and Birmingham. But surprisingly and despite widespread concerns about congested trains and buses, getting to the office isn’t causing a problem. According to Rusk, many parts of the capital including the West End and the Financial District are “like a ghost town” and even the underground trains are distinctly under-occupied.`}</p>
    <p>{`Although restrictions have been relaxed, it’s clear that many people are continuing to work from home. But for Rusk, this is causing multiple problems both in the short-term and further down the line.`}</p>
    <p>{`“Platforms like Zoom and Teams are very good for disseminating information. But it’s a one way conversation. In a big meeting when everyone is talking, it’s chaos!”`}</p>
    <p>{`It’s exhausting too. Even after many months, video still doesn’t feel like a permanent solution to collaborating in-person, and it probably never will. Part of this is because it’s `}<a parentName="p" {...{
        "href": "https://www.bbc.com/worklife/article/20200421-why-zoom-video-chats-are-so-exhausting"
      }}>{`harder to pick up non-verbal cues`}</a>{` like facial expressions, the tone and pitch of the voice, and body language — not to mention frustrating connectivity issues that create lags or distorted audio.`}</p>
    <p>{`Relying entirely on this form of communication also hampers growth opportunities.`}</p>
    <p>{`“If you want your company to stay as it is, and it’s very top-down where the leader disseminates information and doesn’t require an upwards flow of information or ideas, it’s fine. That’s what the tools give you.`}</p>
    <p>{`“But they don’t create environments for creativity.”`}</p>
    <p>{`Creativity is vital to enable companies to design new products or develop their services, attract new customers, and move towards sustainable growth. But that only happens through creativity — and that requires real human interaction.`}</p>
    <p>{`“Most ideas come through informal conversations, over the coffee machine, and when you’re in an office and you overhear conversations — that’s where creative energy comes from.`}</p>
    <p>{`“You don’t get that from working in isolation and communicating through tech platforms.”`}</p>
    <h3>{`Culture and creativity are victims of home-working`}</h3>
    <p>{`The problem is that after six months, many people have become accustomed to working within their home environments; it’s comfortable, if not entirely convenient.`}</p>
    <p>{`“We’ve become stalled. We are creatures of habit and we’ve got used to being at home, for better or worse, and it’s very hard for people to get out of their comfort zone.”`}</p>
    <p>{`Another victim of the home-based workforce is company culture.`}</p>
    <p>{`“That’s another reason why I have encouraged my staff back. Corporate culture is very powerful, but it’s a really difficult thing to build and it’s also very easy to lose.`}</p>
    <p>{`“It’s that intangible asset that companies have, and it’s created by the dynamic between groups of people. Working from home does not create corporate culture, in fact that’s how you lose it. People become narrow-focused, they focus purely on the job in front of them, on the screen, but their role and responsibility is actually way more than that.”`}</p>
    <h3>{`Isolation leads to stress and mental health concerns`}</h3>
    <p>{`And there is a darker side to working from home that’s impacting an entirely different health crisis: our mental health.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.ipse.co.uk/ipse-news/ipse-blog/coronavirus-mental-health-self-employed.html"
      }}>{`Research by UK-based IPSE`}</a>{` (Association of Independent Professionals and the Self-Employed) found a dramatic rise in the number of self-employed people saying they have “poor” or “very poor” mental health, which has increased from 6% to 26% since the beginning of the pandemic. The number of people saying they had “good” or “excellent” mental health has also dropped significantly, from 68% to 39%.`}</p>
    <p>{`“Your home is your safe space. The ability to switch off from work is vitally important. It’s important for your family life, for stress, and for your `}<a parentName="p" {...{
        "href": "https://allwork.space/2020/07/the-current-state-of-remote-work-and-its-impact-on-wellbeing/"
      }}>{`mental health`}</a>{`. But too often over the past few months, this has been dismissed.”`}</p>
    <p>{`As human beings, we may feel comfortable at home but when work invades our safe space, or when we feel isolated from teamwork and day-to-day decisions, it’s easy to lose the feeling of cohesion or belonging.`}</p>
    <p>{`“Ultimately we’ve all got to remember, humans are animals — we are social creatures.`}</p>
    <p>{`“Working from home distances you from people, you don’t speak with them or have meaningful conversations. You end up keeping people at arm’s length. Home is a very easy and controlled bubble to be in, but it loses a lot of what life’s about, including creativity.”`}</p>
    <h3>{`Sensible, well-balanced return to work`}</h3>
    <p>{`“To think there is no disadvantage to working from home is ridiculous. And our clients are starting to realise that.”`}</p>
    <p>{`Rusk believes that we have now reached a point where people should begin coming back to work in a way that’s sensibly balanced and well-managed.`}</p>
    <p>{`Team rotas, office time-sharing and staggered start times make it possible to bring everyone into the office safely at least two days per week, says Rusk, which enables organisations to fuel collaboration, ignite creativity, and rebuild their company culture.`}</p>
    <p>{`“The office once again becomes the focal point of a business. It’s where you create your team cohesion, your ideas, your dynamic, your culture; it’s where all those amazing intangible things come together and make good business happen.”`}</p>
    <h3>{`How do we get there?`}</h3>
    <p>{`There is currently a void between staying at home, in an environment that’s controlled and comfortable. Or there’s the leap of faith that’s required to step back into an environment that, after many months, feels distant and insecure.`}</p>
    <p>{`Working from home was always going to be temporary, but with no deadline or clear direction on when and how people should return to the workplace, Rusk believes that the first step needs to come from bigger companies. “If big companies don’t lead the way, the smaller ones aren’t going to encourage their people to come back to work.”`}</p>
    <p>{`When this happens, not only can we fulfill our human need for social interaction, but our workplaces can begin to rebuild their local economies and communities, too.`}</p>
    <p>{`“I think we all have a bigger responsibility here. What about all those smaller businesses that rely on the staff of big companies? The shops, cafes and small restaurants that rely on commuters and staff lunches? You could say as a big company, why do I care? They’re not my responsibility. But I think they are. Because when small companies go bust, the next ones to fall are the bigger companies.`}</p>
    <p>{`“All those small businesses are someone’s livelihood. By helping them, you help yourself in the long-run.`}</p>
    <p>{`“For big companies to turn their backs, I think it’s morally and financially wrong.”`}</p>
    <p>{`Ultimately, Rusk believes that large companies should lead the way and begin bringing people back to work in a way that’s balanced and sensible. “If someone is late because they waited for a less-congested train, it doesn’t matter. What’s important is being sensible and working with this seismic change,” added Rusk.`}</p>
    <p>{`It starts with a safe workplace and by educating people about safe practices, but it also requires a lot of the flexibility we’ve seen over the past few months. Instead of archaic nine-to-five thinking, companies should embrace flexibility and allow people to make practical decisions.`}</p>
    <p>{`Rusk believes that by refocusing attention to the office as a hub for collaboration, we will begin to move forwards and adapt to the next normal, where we can rebuild our teams, companies, and our interconnected communities.`}</p>
    <p>{`“We need to get this economy ticking over again,” added Rusk. “It doesn’t matter what business you’re in, we need to get back to work.”`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      