import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2021-01-08T00:00:00.000Z",
  "title": "Future Of Work: Hybrid Meetings Will Bring Balance To The Workplace\n",
  "slug": "hybrid-meetings-will-bring-balance-to-the-workplace",
  "excerpt": ["For now, online meetings are serving a very valuable purpose. But are they really the future of work?", "Just like the future of work, the future of meetings is hybrid, with a mix of remote and in-person meetings.", "Speaking at the Future WorkTech Forum, Lieven Bartier from Barco discussed how a hybrid meeting strategy can drive collaboration, creativity, and culture."],
  "images": ["hybrid-meetings-will-bring-balance-to-the-workplace.png"],
  "backlink": "https://allwork.space/2021/01/future-of-work-hybrid-meetings-will-bring-balance-to-the-workplace/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`During the pandemic, technology is helping people to stay connected. It fills the void left by physical distancing, at least partially, through the help of video calls, online collaboration tools, virtual meetings, and more.`}</p>
    <p>{`But Zoom fatigue is real.`}</p>
    <p>{`Video calls are exhausting. That’s because we expend more energy trying to process facial expressions and body language over a digital setting. In addition, we routinely suffer from performance anxiety, awkward silences, user error (“You’re on mute!”) and the inevitable tech malfunctions.`}</p>
    <p>{`For now, online meetings are serving a very valuable purpose. But are they really the future of work?`}</p>
    <p>{`The short answer is ‘yes, partly’.`}</p>
    <p>{`Now that the world has got to grips with remote work and virtual meetings, we’re not likely to leave it behind anytime soon. Businesses are becoming quite adept at saving money by reducing travel and in-person meetings.`}</p>
    <p>{`However, there is a productivity issue — and a rather important human issue — to address.`}</p>
    <p>{`“Video calls are taking their toll,” said Lieven Bartier from `}<a parentName="p" {...{
        "href": "https://www.barco.com/en/"
      }}>{`Barco`}</a>{` during the `}<a parentName="p" {...{
        "href": "https://futureworktech.com/"
      }}>{`Future WorkTech Forum`}</a>{` (held in November 2020). “The benefits of working from home no longer outweigh the downsides. People are missing out on collaboration, creativity, and culture.”`}</p>
    <p>{`Meetings play a huge part in driving collaboration, creativity, and culture — but according to Bartier, after nearly a year of collaborating via screens, “people are looking for new balance”.`}</p>
    <p>{`Going forward, the new balance will likely be 3 days in the office, 2 days at home.`}</p>
    <p>{`This comes from Barco’s own research (September 2020), which found that 85% want a combination of working from home and the office.`}</p>
    <p>{`The same goes for meetings.`}</p>
    <p>{`“The future of meetings is hybrid,” said Bartier. “A hybrid meeting is where face-to-face attendees connect virtually with remote attendees in a way that all participants feel equally engaged.”`}</p>
    <p>{`According to Barco’s research, there will be a three way split in the way meetings are conducted:`}</p>
    <ul>
      <li parentName="ul">{`38% all remote`}</li>
      <li parentName="ul">{`33% all in-person`}</li>
      <li parentName="ul">{`29% mixture of remote and in-person (hybrid)`}</li>
    </ul>
    <p>{`Their research also found that 67% of people prefer video conferencing over audio conferencing, and 75% prefer scheduled meetings rather than impromptu meetings.`}</p>
    <p>{`But with almost 80% of people using video conferencing every week, hybrid work will put new strain on technology.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“Hybrid work will put new stresses on your tech infrastructure. You must have the ability to maintain at-work and work-from-home tech at robust levels. This will be a new business requirement”`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Forrester (April 2020)`}</p>
    </blockquote>
    <p>{`Going forward, businesses must provide appropriate connectivity and infrastructure to enable meetings to happen in multiple locations, which will most likely include the home, the workplace, and other locations such as satellite offices.`}</p>
    <p>{`Indeed, with 49% saying remote collaboration does not come naturally, virtual meetings have room for improvement.`}</p>
    <p>{`“Our priority is a good experience,” added Bartier. “People want easier access and better usability, both now and in the future.”`}</p>
    <p>{`According to Bartier, there are 5 new balances in the future workplace:`}</p>
    <ol>
      <li parentName="ol">{`Working from home 2 days per week maximum`}</li>
      <li parentName="ol">{`Hybrid meetings become the new normal`}</li>
      <li parentName="ol">{`Planned and structured meetings are preferred over informal, impromptu huddles`}</li>
      <li parentName="ol">{`Most people host videoconferences by laptop rather than other mobile devices or meeting room platforms`}</li>
      <li parentName="ol">{`Usability and experience is key`}</li>
    </ol>
    <p>{`“The function of the workplace is changing. It’s no longer a place where people sit with a laptop — we are in exploration mode with meetings and new ways of working.”`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      