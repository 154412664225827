import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2018-09-03T00:00:00.000Z",
  "title": "The Future Of Work Is About Fostering Community\n",
  "slug": "the-future-of-work-is-about-fostering-community",
  "excerpt": ["The future of work relies on collaborative spaces to facilitate creativity.", "Rather than ‘stealing’ jobs, automation will create opportunities for human skills to flourish.", "The workplace will play a huge role in enabling – or preventing – collaboration and creativity."],
  "images": ["8-FOSTERING-COMMUNITY.png"],
  "backlink": "https://allwork.space/2018/09/the-future-of-work-is-about-fostering-community/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Though much of the talk around the `}<a parentName="p" {...{
        "href": "https://allwork.space/tag/future-of-work/"
      }}>{`future of work`}</a>{` centers around technology and its impact, the future of work is actually about people.`}</p>
    <p>{`When we talk about it, we talk about humans — how we work, where we work, with whom we work. Technology is just a tool that enables the future world of work; even though `}<a parentName="p" {...{
        "href": "https://allwork.space/2018/08/what-jobs-would-you-delegate-to-a-robot/"
      }}>{`AI and robotics are and will continue to “steal” some jobs`}</a>{`, these new tools will create the perfect opportunity for human knowledge and creativity to take center stage.`}</p>
    <p>{`“Two heads are better than one,” the old adage goes. When people come together, they are better equipped to solve problems, to generate new ideas, innovate, and disrupt.`}</p>
    <p>{`The above, to a certain extent, is what the future of work is about. It’s about bringing people together and forming teams, encouraging them to collaborate in order to create new services, products, knowledge, and solutions.`}</p>
    <p>{`It’s about creating dynamic, diverse, and agile communities, where an individual effort or skill contributes to a greater common purpose. It’s about connection.`}</p>
    <p>{`These teams or communities should be diverse, multi-generational and cross functional in order to create truly innovative and disruptive ideas. By working alongside people that come from different backgrounds, have different skills, and overall different thinking patterns, the solutions and ideas you come up with as a team will be more robust and holistic, as you will have likely approached a problem from different angles and considered a plethora of possible scenarios. Working in teams forces you to think outside the box, enhancing your creativity — something robots can’t yet compete with.`}</p>
    <p>{`This is the way to create value in the future of work.`}</p>
    <p>{`Fostering a sense of community and connection will affect both corporate employees as well as gig workers. Your ability to work with different people, at different times, and for different projects will highly determine your professional success.`}</p>
    <p>{`“Work will be highly collaborative, iterative and performed by teams that can assemble and disassemble quickly, responding in real time to external and internal signals.” – `}<a parentName="p" {...{
        "href": "https://www.forbes.com/sites/zackfriedman/2018/08/06/ibms-hr-chief-shares-best-advice-on-the-future-of-work/#183ac7363dc4"
      }}>{`Diane Gherson, Chief Human Resource Office at IBM`}</a></p>
    <p>{`In order to collaborate, you have to form relationships with different stakeholders. The actual workplace will have a huge impact on whether or not this happens.`}</p>
    <p><strong parentName="p">{`Related reading: `}<a parentName="strong" {...{
          "href": "https://allwork.space/2018/08/understanding-the-future-of-work-and-its-implications-on-the-workplace/"
        }}>{`The Future of Work and Its Implications on the Workplace`}</a>{`.`}</strong></p>
    <p>{`The workplace, whether corporate, off-site, or third-space (coworking, coffee shops, etc) needs to be agile and flexible, providing people with ample choice and a variety of spaces that lead to engagement and interaction through the removal of physical barriers.`}</p>
    <p>{`Coworking and flexible workspaces, `}<a parentName="p" {...{
        "href": "https://allwork.space/2018/08/why-coworking-spaces-function-as-social-economies/"
      }}>{`by functioning as a social economy`}</a>{` and providing a space where people from different industries and with different skill sets can work, are perfectly equipped and designed to fulfill the task of fostering a diverse community.`}</p>
    <p>{`“What we want to achieve with the HX experience is the fact that human experience is not going to work if you do not have that spirit of community within your workplace. It’s very important to have a culture that encourages communities to be creative, to let them be.” – `}<a parentName="p" {...{
        "href": "https://allwork.space/2017/06/up-close-and-personal-the-hx-workplace-with-jll-global-head-of-research-dr-marie-puybaraud/"
      }}>{`Dr Marie Puybaraud, Global Head of Research, JLL Corporate Solutions`}</a></p>
    <p>{`This human experience in the workplace is a key element of the future of work; human experience is what creates a deeper and more meaningful connection between individuals. This connection is what will bring people together to form responsive teams that are ready to tackle specific tasks and projects.`}</p>
    <p>{`“If disruption and uncertainty are inevitable and change is unavoidable, then the best strategy is to build a team and operating environment that can quickly adapt to evolving conditions.” — `}<a parentName="p" {...{
        "href": "https://www.futureofwork.jll/en/global/the-future-is-now/"
      }}>{`JLL, Future of Work Report`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      