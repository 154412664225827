import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-02-14T00:00:00.000Z",
  "title": "Future Of Work: How To Unlock Hidden Skills Within Your Workforce\n",
  "slug": "future-of-work-how-to-unlock-hidden-skills-within-your-workforce",
  "excerpt": ["The Inside Gig is a concept that recommends organizations look inside rather than outside when searching for new skills.", "According to authors Edie Goldberg and Kelley Steven-Waiss, unlocking hidden skills within an organization improves engagement and enables continuous learning.", "Allwork.Space spoke with Goldberg and Steven-Waiss about the ‘inside gig’ and why it’s imperative for organizations to adopt new talent models for the future of work."],
  "images": ["how-to-unlock-hidden-skills.png"],
  "backlink": "https://allwork.space/2020/02/future-of-work-how-to-unlock-hidden-skills-within-your-workforce/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`“A myriad of books describe why, what and how work is being disrupted. Yet many companies are held captive by out-of-date tools, policies and practices that won’t get them through this disruptive change. It is time to break the cycle.”`}</p>
    <p>{`The above is the first paragraph of Edie Goldberg and Kelley Steven-Waiss’ new book “`}<a parentName="p" {...{
        "href": "https://theinsidegig.com/"
      }}>{`The Inside Gig: How sharing untapped talent across boundaries unleashes organizational capacity.`}</a>{`”`}</p>
    <p>{`The Inside Gig is a concept brought to life by Goldberg and Steven-Waiss that proposes organizations look inside rather than outside when searching for new talent. According to the authors, unlocking the hidden skills within an organization will not only help employees stay engaged, but it also provides the perfect opportunity for continuous learning while rendering an organization much more agile.`}</p>
    <p>{`Allwork.Space spoke with Goldberg and Steven-Waiss about their book (`}<a parentName="p" {...{
        "href": "https://theinsidegig.com/"
      }}>{`available to preorder here`}</a>{`), the concept of the ‘inside gig’ and why it’s imperative for organizations to adopt new talent models.`}</p>
    <p><em parentName="p">{`Below are the highlights of our conversation. Responses have been edited for clarity.`}</em></p>
    <p><strong parentName="p">{`Allwork.Space: What inspired you to create the concept of the inside gig, and more importantly, what motivated you to write a book about it?`}</strong></p>
    <p>{`Edie Goldberg: A lot of this started many years ago when we were collaborating with HR colleagues about how to accelerate the development and change of the HR profession. What we observed was that people really weren’t developing the capabilities to lead companies in the future of work.`}</p>
    <p>{`We kept seeing data points that required organizations to have much more agile structures. Organizations were not ready to pivot or shift based on their changing business models and customer demands. Everywhere we looked, the need to be agile kept coming up.`}</p>
    <p>{`At the same time, we clearly observed that employees want continuous learning and growth opportunities. More importantly, we saw how if they are not getting these opportunities, they will walk out. The issue of continuous learning is so critical to today’s workforce because the half life of skills today is only five years.`}</p>
    <p>{`Think about it. Half of everything you know today will be obsolete in five years. This calls for companies to constantly put employees in situations where they are learning and growing.`}</p>
    <p>{`To make matters worse, millennials feel that companies are underutilizing their skills. Taking all of the above into account, we saw a deep need to create a new way to approach work; more specifically, a new way to approach talent management.`}</p>
    <p>{`Steven-Waiss: In a few words, we’re living a talent crisis. And rather than looking within to solve this crisis, organizations have been looking on the outside (the gig economy).`}</p>
    <p>{`Companies are not optimizing their talent resources. In fact, in many instances, they don’t really know who and what skills they have on the inside.`}</p>
    <p>{`We wrote this book because we believe that if companies can get the best of the gig economy without having to hire external talent, it’s a win win situation for organizations and employees alike.`}</p>
    <p>{`The inside gig is an approach that proposes a smoother way for employees to move sideways within an organization. We believe that there are plenty of advantages to hiring internal talent rather than external talent; people can come together much more quickly when they’re all part of and familiar with an organization.`}</p>
    <p><strong parentName="p">{`Allwork.Space: One important issue raised by the book is that sometimes it is harder for people to move within an organization than to search for growth opportunities outside of it. In your experience, what is the main challenge organizations face when implementing strategies like the inside gig that seek to make it easier for people to move from one area to another within the same company?`}</strong></p>
    <p>{`Steven-Waiss: The main challenge is the fact that for the inside gig model to work, there has to be quite a mindset shift among leaders. Managers need to move from the idea that talent is owned by leaders to one where talent is owned by the company.`}</p>
    <p>{`This mindset shift means that managers across departments can tap into talent beyond the people they manage. The idea that talent is owned by a company means that employees can move around within an organization rather than sticking to one area or department alone.`}</p>
    <p>{`In our experience, the biggest hurdle in this shift comes from leaders. Employees generally come to work and they are keen to contribute and make a difference; however more often than not, managers want to hoard the best talent for themselves.`}</p>
    <p><strong parentName="p">{`Allwork.Space: How can organizations approach this challenge? Specifically, what are some ways companies can create a culture of change so that managers and employees more readily embrace concepts like the inside gig?`}</strong></p>
    <p>{`Goldberg: Organizations need to start by calling a spade a spade. In theory, managers are supposed to manage people, but in reality, managers manage work. Therefore, organizations need to look at the existing leadership behaviors and how well they align new ways of working and how they conflict.`}</p>
    <p>{`Companies need to also understand that they can’t change everything overnight. This is why in the book we talk about change readiness assessments.`}</p>
    <p>{`Steven-Waiss: One concrete way we’re helping companies with change management is by splitting the role of managers into a work manager and a career coach. Managers today don’t have time to develop the people they manage or talk about their long-term career goals.`}</p>
    <p>{`A career coach’s job is to curate and deploy work based on a person’s skills and experience. They are responsible for inspiring people to have the job they want and encourage them to develop new skills.`}</p>
    <p><strong parentName="p">{`Allwork.Space: On the topic of managers, the book mentions that oftentimes it is managers who are reluctant to allow their people to help out or engage in work from other departments. In your experience, have you seen employees resist this type of model as well?`}</strong></p>
    <p>{`Steven-Waiss: People resist initially; managers in particular because they have been taught to be in control of their talent and the notion of sharing talent across boundaries means that they may lose control of it. Then again, I believe it’s normal for people to resist something until they actually see desired results.`}</p>
    <p>{`Goldberg: In my experience, employees are willing to try out new opportunities. In fact, they crave them. There is a huge pull from employees because they are dying for opportunities to have an impact and learn something new. This pull catches on like wildfire among employees and eventually companies let the pull spread.`}</p>
    <p>{`So I would say there is no resistance from employees, at least not in the same way as from managers and leaders.`}</p>
    <p><strong parentName="p">{`Allwork.Space: One of the ways the book proposes to make the inside gig model work is by creating a sort of marketplace for people within the organization to share their passions and skills. What are some key elements or features that this marketplace should include?`}</strong></p>
    <p>{`Steven-Waiss: First and foremost, the marketplace should be organized based on how a company sees work (i.e. product, strategy, engineering, IT, marketing, etc.). Then, you want to make it easy for people to understand where you want to go or what projects you want employees to work on.`}</p>
    <p>{`Goldberg: The foundation of the marketplace should be the information database. To create such a database, the marketplace should have at least two profiles: individual, and project profiles.`}</p>
    <p>{`Individual profiles are where employees can add their current skill sets, the skills they would like to learn, and the topics that they are passionate about.`}</p>
    <p>{`The project profile is basically a page where employees can find a project description. What is the project about? How much time will people need to invest in it? What is the timeframe for the project? What skills are needed for it?`}</p>
    <p>{`Another key element would be to include a section where employees can learn about how they can leverage their existing skills in different areas/applications (i.e. what areas/teams of the organization can take advantage of engineering skills beside the engineering department?)`}</p>
    <p>{`Last but not least, the marketplace should be dynamic. Meaning that just as people can search for and find projects, there should be a feature that notifies people when their skill set is a match for a new project.`}</p>
    <p><strong parentName="p">{`Allwork.Space: You’ve mentioned it throughout our call today and it’s also mentioned constantly in the book: learnability. Why is learnability so important today and why is it imperative that organizations build their own talent?`}</strong></p>
    <p>{`Goldberg: In the future, we will see a lot of companies hire talent based on their ability to learn new things; a person’s ability to be agile. If you can’t learn quickly, you are going to find yourself out of a job and not being able to find your footing again.`}</p>
    <p>{`From the organization’s point of view, if they do not provide learning and growth opportunities for their talent, then people will walk out.`}</p>
    <p>{`Steven-Waiss: Learning is probably the most important skill set of the future; learning and relearning several times. The ability to reimagine oneself will be the core of what will make someone successful.`}</p>
    <p>{`Organizations should be concerned about this because they need to maintain agility to grow and adapt to changing market needs. Organizations are made up of people, so people need to grow and change in order for organizations to do so as well. Therefore, it only follows that organizations should be responsible for providing employees with the right tools and resources for them to reimagine themselves.`}</p>
    <p><strong parentName="p">{`Allwork.Space: The inside gig is basically a talent model that helps organizations discover new talent without having to search for new skills outside. At the same time, it’s also a model that can provide continuous learning opportunities for employees. What are some of the first steps that organizations need to take to begin implementing the inside gig? Is it a costly process?`}</strong></p>
    <p>{`Steven-Waiss: I think the first thing organizations need to do is to have a clear vision for how they see their organization in the future.`}</p>
    <p>{`They need to establish the type of skill sets that they are in need for and be willing to move in the right direction to acquire them. This requires that companies take an honest look at their cultural barriers, their leaders, and their overall willingness to go through change.`}</p>
    <p>{`Once companies identify the skill sets they currently have and the ones they need, they should launch a pilot program. Once the pilot is launched, companies need to examine if there are any barriers and any other needs that haven’t been addressed.`}</p>
    <p>{`The next step would be to share success stories. If the pilot program was successful, then the success needs to be shared with all stakeholders. To measure success, companies need to make sure that they have decided on a way to measure results.`}</p>
    <p>{`Last but not least, companies need to be willing to reiterate. The inside gig, and any new operating model, is not a destination; it’s an ongoing journey.`}</p>
    <p>{`Goldberg: I would only add to the above that companies need to build a strong business case for why they want to implement a new talent model. It’s also important that leaders and managers understand that it is imperative to change existing working models as they are no longer sustainable in the long run.`}</p>
    <p><strong parentName="p">{`If you’d like to read “The Inside Gig” you can preorder your book `}<a parentName="strong" {...{
          "href": "https://theinsidegig.com/"
        }}>{`here`}</a>{` or through `}<a parentName="strong" {...{
          "href": "https://www.amazon.com/Inside-Gig-Boundaries-Unleashes-Organizational/dp/1928055605"
        }}>{`Amazon`}</a>{`.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      