import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-10-04T00:00:00.000Z",
  "title": "Future Of Work: What Makes Us Human Will Make Us Employable In The Future\n",
  "slug": "future-of-work-what-makes-us-human-will-make-us-employable-in-the-future",
  "excerpt": ["Many people fear technological change, but the way we work and live with technology and AI is already a fundamental part of our lives.", "At the HR Conference Barcelona 2019, Robert Kropp learned that technology can help liberate our human skills, which is vital for the future of work.", "AI “is the great story of our time” and will help people transform the world in a way that makes a positive difference."],
  "images": ["35-WHAT-MAKES-US-HUMAN-WILL-MAKE-US-EMPLOYABLE-IN-THE-FUTURE.jpg"],
  "backlink": "https://allwork.space/2019/10/ai-shouldnt-be-feared-the-future-of-work-needs-human-values-skills-and-communities/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The 6th International HR Conference took place today (October 4th) in Barcelona and it gathered experts from across industries to discuss the role of people, technology, and humanity in the future of work. The event, organized by Advantage Consultores, took off with a presentation titled “Moonshots for HR” by Sylvia Taudien from Advantage Consultores.`}</p>
    <p>{`Taudien introduced attendees to the concept of moonshot thinking, which encourages leaders to thing 10 times more as opposed to just 10% more. Moonshot thinking is what will drive and move CEOs to the future; it requires that they answer the question: what is the one area of their work where they should shoot for 10x growth, rather than 10%?`}</p>
    <p>{`Technology, specifically data, can power moonshot thinking; it can provide leaders with real-time, actionable data that can help them foresee the future of their particular sector. Data will also play a key role in the shift from a human workforce to an augmented workforce by providing leaders with people analytics, which can lead to a better understanding of how people work and what elements help them thrive.`}</p>
    <p>{`People analytics will be key to company success in the future as, Taudien argues, “we need a human transformation, a human revolution”. Technology can help unlock human potential. More importantly, technology can also help companies identify burnout employees, it can provide insights to know how to better deploy people and keep a company agile, it can help them tackle diversity and inclusion efforts, and it can help companies identify future leaders.`}</p>
    <p>{`Taudien also spoke about global HR trends, highlighting that the top priority for HR departments should be employee happiness; taking into consideration that there will be 5 generations working together in the future.`}</p>
    <p>{`According to Taudien, CEOs want 4 main things: sustainable growth, a culture of innovation, a clear path to follow, and speed to value. To achieve this, companies need humans; humans bring creativity and judgement, and it is humans that help move a company’s bottom line forward.`}</p>
    <h2>{`Humans are the future of work`}</h2>
    <p>{`In fact, humans were at the heart of most presentations…afterall, it is a human resources conference. While panelists addressed the role of technology in the world of work, the underlying element that will differentiate companies in the future is their ability to remain human.`}</p>
    <p>{`Manish Bahl, from the Center for the Future of Work, talked about the future of work in the age of AI. According to Bahl, “AI is the great story of our time,” arguing that in the next few years, AI will be all around us, from helping educate our children to help us save on energy.`}</p>
    <p>{`But there’s another element of AI that people can’t shake off their heads, and it’s the fear that it generates around layoffs and changing company structures.`}</p>
    <p>{`Bahl commented that Oxford claims that 47% of jobs will disappear in the next 25 years. There are 3 possible scenarios within this topic: (1) jobs that will be replaced by AI, (2) jobs that will be enhanced by AI, and (3) jobs that will be invented by AI.`}</p>
    <p>{`Bahl insists that it’s time to go from the jobs of the past to jobs of the future. One fundamental issue that needs to be addressed with this change is that companies and individuals cannot confuse a job with a task.`}</p>
    <p>{`While tasks will be automated, a job will not.`}</p>
    <p>{`AI and other emerging technologies can improve productivity and efficiency. Machinery will liberate and free humans from monotonous work. However, this doesn’t mean humans don’t have a challenging time ahead.`}</p>
    <p>{`“We need to upskill ourselves.”`}</p>
    <p>{`When doing this, it’s important that individuals keep in mind what separates humans from machines; in Bahl’s words “humans are good at the art of the job” (visual cues, emotion, consciousness, etc.); while on the other hand “machines are good at the science of the job” meaning computational capabilities.`}</p>
    <p>{`For example, in the HR world, machines can enhance the role of a recruiter by analyzing and automating the screening of resumes. It can save up to 40% of the time a month (which is great, especially considering people only spend about 40% of their time doing the job they were actually hired to do) and it can make the hiring process fairer, smarter, and more engaging.`}</p>
    <p>{`Bahl also talked about the jobs of the future, stating that they will have three themes: ethical behaviors, security and safety, and dreams.`}</p>
    <h3>{`Ethical behaviors jobs`}</h3>
    <p>{`These jobs will focus on the purpose of work and will require roles such as a Chief Purpose Planner and a Communications Catalyst. These roles will be in charge of establishing an organization’s underlying purpose and making sure that it’s effectively communicated with all stakeholders.`}</p>
    <h3>{`Security and safety jobs`}</h3>
    <p>{`Roles in this category will go along the lines of a Machine Risk Officer. These people will be in charge of having an actionable plan should a machine fail (like if a self-driving car gets into a crash). They will also lead efforts to build trust with machines and address the ethics of using machines in order to protect a company.`}</p>
    <h3>{`Dreams jobs`}</h3>
    <p>{`These will be roles that move the arrow from science fiction to science fact. These roles could be in charge of developing products and services of the future, like flying cars. These roles will have a strong focus on engineering, development, prototyping, and market launch.`}</p>
    <p>{`“What makes us human will make us employable in the future.” – Manish Bahl`}</p>
    <p>{`In order to succeed in future roles, individuals must work on enhancing their human centric skills, including problem solving, flexibility, creativity, and context; and they must also learn how to work with machines. But first and foremost, companies need to deflate their fear among employees and provide them with tools to enhance their existing roles.`}</p>
    <p>{`Martin Wezowski, a designer and futurist, echoed a lot of what Taudien and Bahl said. Wezowski defined the future of work as: a future that fosters and optimizes our individual gifts to create a higher value, in feelings, wisdom, and self actualization, for ourselves and the communities around us.`}</p>
    <p>{`Like Bahl, he strongly believes that there is no human vs machine scenario, rather it will be symbiosis where both will need to work as one. The most successful solutions in the future, according to Wezowski, will be the most empathetic symbiosis between machine intelligence and human ingenuity.`}</p>
    <p>{`This is why he believes the future of work is about imagination, creativity, and strategy. Organizations will need to shift towards more purposeful thinking. Organizations, governments, and individuals are tasked with the challenge and responsibility to “design a future we all want to live (and work) in.”`}</p>
    <p>{`The first half of the event concluded with the presentation “The Renaissance of Our Species” by Neil Harbisson from the Cyborg Foundation.`}</p>
    <p>{`Harbisson’s presentation moved away from AI to introduce the concept of AS (artificial sense) and cyborgs (a new type of human species). Cyborgs, according to Harbisson, are the union between cybernetics and organisms.`}</p>
    <p>{`For him, technology can not only help us know things, but also feel things. It’s about having the potential and ability to design our own senses. Artificial Senses, (AS), is sort of the opposite of artificial intelligence.`}</p>
    <p>{`“In AS the stimuli is gathered by the technology but the intelligence is created by the human – as opposed to Artificial Intelligence (AI) where the intelligence is created by the machine itself.”`}</p>
    <p>{`In either case, however, humans remain at the heart of the future of work, of technology, and of life.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      