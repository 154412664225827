import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const defaultProps = {
  lang: 'en',
  title: '',
  description: '',
  meta: [],
};

type SeoProps = {} & typeof defaultProps;

export const Seo = ({ title, description, lang, meta }: SeoProps) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `,
  );

  title = title || site.siteMetadata.title;
  description = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{ lang }}
      defaultTitle={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ].concat(meta)}
    ></Helmet>
  );
};

Seo.defaultProps = defaultProps;

export default Seo;
