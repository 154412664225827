import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-06-05T00:00:00.000Z",
  "title": "Future Of Work: 3 Key Ways The Workplace Will Change Because Of The Pandemic\n",
  "slug": "future-of-work-3-key-ways-the-workplace-will-change-because-of-the-pandemic",
  "excerpt": ["Temperature checks, sanitization stations, and use of face masks are likely to be short-lived in the workplace.", "Long-term, potentially permanent, changes that the workplace will experience due to the pandemic include an uptick in biophilic design and an increased focus in technology.", "Organizations realized that people can, in fact, work from anywhere, which means that the role the workplace fulfills is likely to change."],
  "images": ["future-of-work-3-key-ways-the-workplace-will-change-because-of-the-pandemic.png"],
  "backlink": "https://allwork.space/2020/06/future-of-work-3-key-ways-the-workplace-will-change-because-of-the-pandemic/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The COVID-19 pandemic disrupted and impacted businesses across industries in ways that many had never imagined. `}</p>
    <p>{`As businesses move from crisis management to long-term planning, there are many questions that business leaders need to address. How will the pandemic impact behavior long-term? What does that mean for our line of business? How can we attract clients/consumers back while safeguarding their health? How can we best support our employees?`}</p>
    <p>{`While many are still waiting to go “back to normal”, others question whether that is an option at all. `}</p>
    <p>{`As businesses are slowly allowed to resume their regular operations, they are faced with the challenge to adhere to government guidelines and regulations. `}</p>
    <p>{`In the workplace, this means that businesses will likely have to implement temperature checks, sanitization stations, and enforce physical distancing measures. Though important, these changes are likely to be short-lived, up until a vaccine is created and deployed. `}</p>
    <p>{`But the workplace will also see other changes take place, changes that will most likely be permanent and long-lived. `}</p>
    <h2>{`3 Key Changes the Workplace Will See because of the Pandemic`}</h2>
    <h3>{`1. An uptick in biophilia`}</h3>
    <p>{`Biophilic design had already been gaining some popularity before the pandemic struck. As businesses look for strategies to better support employees in the workplace as they return, we will likely see a steady uptick of businesses incorporating biophilia into their office space. `}</p>
    <p>{`The reason behind this is that this crisis has given people a clearer appreciation of nature. More importantly, `}<a parentName="p" {...{
        "href": "https://allwork.space/2019/04/14-patterns-of-biophilic-design-to-improve-health-and-wellbeing-in-the-workplace/"
      }}>{`biophilic design`}</a>{` has been  proven to have a variety of benefits, some worth highlighting include:`}</p>
    <ul>
      <li parentName="ul">{`It can help reduce stress. `}</li>
      <li parentName="ul">{`It can expedite healing. `}</li>
      <li parentName="ul">{`It improves cognitive function. `}</li>
      <li parentName="ul">{`It improves overall wellbeing. `}</li>
      <li parentName="ul">{`It drives productivity. `}</li>
    </ul>
    <p>{`Given that many people will likely have increased levels of stress and anxiety as they go back to the office, biophilic elements in the workplace can go a long way in improving the overall workplace experience during times of crisis and re-adjustment.`}</p>
    <h3>{`2. Digital transformation`}</h3>
    <p>{`The office will become an increasingly digital workplace as organizations lean on technology to provide a touchless experience.`}</p>
    <p>{`Beyond providing a touchless experience, digital transformation will be a priority for businesses as many will continue to encourage remote work to a certain extent. This means that organizations will need to have a mix of analog and digital tools to support different types of work modes. `}</p>
    <p>{`The way technology is deployed in the office is likely to change in the long-term. Some key changes we might observe include:`}</p>
    <ul>
      <li parentName="ul">{`Improved video conferencing capabilities. `}</li>
      <li parentName="ul">{`Use of emerging technologies (the IoT, artificial intelligence) to monitor facilities (air quality, temperature, lighting sensors, etc.). `}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://allwork.space/2020/05/future-of-work-how-the-iot-ai-and-robotics-can-help-keep-workplaces-clean-and-safe/"
        }}>{`Use of technology to improve cleaning protocols.`}</a>{` `}</li>
      <li parentName="ul">{`Use of technology to offer hands free access. `}</li>
      <li parentName="ul">{`Cloud computing to enable remote collaboration. `}</li>
      <li parentName="ul">{`A stronger focus on privacy and security. `}</li>
    </ul>
    <h3>{`3. The workplace as a social space`}</h3>
    <p>{`As organizations realize that people can, in fact, work from anywhere, thy key role of the office is likely to change. `}</p>
    <p>{`Rather than being the only place to work, the workplace will become a place of gathering and collaboration. `}</p>
    <p>{`This is very important, especially as employees working from home have reported that what they miss the most about the office is people. Workers miss the chance encounters they had with colleagues and no digital platform has yet been able to successfully emulate in-person office interactions. `}</p>
    <p>{`Organizations, therefore, will need to reimagine their workplaces as a social place that brings people together, which will ultimately also improve business performance. `}</p>
    <p>{`As companies reimagine their workplace, they need to take into consideration issues like de-densifying the office, optimal square footage per individual, the right mix of social spaces vs individual areas, how the workplace can support remote work, etc.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      