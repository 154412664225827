import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-07-12T00:00:00.000Z",
  "title": "AI In The Built Environment: 4 Key Areas It Will Impact\n",
  "slug": "ai-in-the-built-environment-4-key-areas-it-will-impact",
  "excerpt": ["4 ways AI can help solve the challenges of managing real estate.", "PropTech, and specifically AI, can help solve the many different challenges involved with using and managing real estate.", "A recent whitepaper, ‘AI for the Built World’, claims that PropTech has reached an exciting time of acceleration.", "From build-out and design to sales and operations, here are 4 ways AI can have a positive impact on the built environment."],
  "images": ["18-ai-in-the-built-environment-4-key-areas-it-will-impact.jpg"],
  "backlink": "https://allwork.space/2019/07/ai-in-the-built-environment-4-key-areas-it-will-impact/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "https://allwork.space/2019/01/proptech-what-is-it-and-how-does-it-fit-with-coworking/"
      }}>{`PropTech is a type of technology that is tailored to improve specific ways in which we utilise and manage real estate`}</a>{`. In a recent whitepaper “Artificial Intelligence (AI) for the Built World”, Navitas Capital argues that PropTech “has reached a truly exciting time of growth and acceleration.” Scaled capital infusion and faster startup formation, combined with a massive adoption wave and successful disruption and investment exits have led to a PropTech resurgence.`}</p>
    <p><a parentName="p" {...{
        "href": "https://allwork.space/2019/05/the-future-of-work-proptech-3-0-powered-by-blockchain-and-ai/"
      }}>{`Earlier this year, Allwork.Space argued`}</a>{` that the next age of PropTech will be powered by artificial intelligence (AI) and the blockchain. In the whitepaper, Navitas Capital claims that “AI will be the single largest innovation catalyst creating shifts in value in the built world, affecting the way our industry invests, builds, operates, and maintains real estate.”`}</p>
    <p>{`Why?`}</p>
    <p>{`Because AI can create “end-to-end vertical solutions that independently capture large, unique data sets, run Machine Learning or Deep Learning on these data sets, and create outputs that either replace or augment an existing solution in the market.”`}</p>
    <p>{`In simpler words, AI can enable the better functioning of specific solutions.`}</p>
    <h2>{`4 Key Applications of AI in Real Estate Areas`}</h2>
    <h3>{`1. Build and Design`}</h3>
    <p>{`AI has the potential to significantly impact the build out and design process of a building. For example, AI tools like digital architecture and visualization can make it easier to document the construction and design process of a property. Moreover, AI can help create standard best practices and help humans better understand and identify timing of delays and bottlenecks, predict progress, and quote costs more accurately.`}</p>
    <p>{`Lastly, given that AI gathers immense amounts of data, it can document data on the “types, aesthetics, locations, and driving criteria of desirable real estate.” This can help property owners and developers make informed decisions on where and how it’s best to build.`}</p>
    <h3>{`2. Buying and Selling`}</h3>
    <p>{`“Data leads to a process of standardization around specific data benchmarks, helping create institutional-grade, transparent data to support freely trading assets, or fractions of those assets, in the capital markets.”`}</p>
    <p>{`In this area, AI can help automate buying and selling decisions by providing an interface that can generate predictive and actionable insights. Furthermore, since data leads to standardization, not only can it automate the process, but it can also help make it much more transparent.`}</p>
    <h3>{`3. Operate and Maintain`}</h3>
    <p>{`“While many have the impression real estate simply requires patience and long-term capital to reap rewards, the operations, repair and maintenance of real estate assets remain huge cost centers that are both labor-intensive and far from optimized.”`}</p>
    <p>{`Enter AI.`}</p>
    <p>{`There are now various AI solutions out there that are helping to automate and standardize maintenance, inspections, leasing and showing, and workflow processes. This is possibly one of the most significant areas of real estate that AI can impact, as it can help increase building efficiency by as much as 70%.`}</p>
    <p>{`In this particular area, Navitas Capital believes there are “huge opportunities particularly in lead generation and conversion within the leasing space.” This is due to the fact that AI provides immense amounts of data that allow for tenant demands to be more easily matched with existing properties.`}</p>
    <h3>{`4. Optimization`}</h3>
    <p>{`This is the area where AI gets really exciting. Much more than automating processes, AI has the ability to “better recognize patterns and predict tenant inbounds, requests, repair issues, late payments, etc.” It can also measure, track and identify patterns on foot traffic, space utilization, and tenant behavior.`}</p>
    <p>{`All of this is valuable data that property owners and developers can utilize to create better, more efficient, and more attractive next-generation buildings. This has become especially important “as more and more of the workforce becomes remote, (and) the purpose of clearly delineated spaces is becoming blurred.”`}</p>
    <p>{`Data and AI, therefore, present a large opportunity as it can empower owners and developers to create and offer multi-purpose spaces that capture more of a person’s day to day life.`}</p>
    <p>{`“Understanding patterns on how people interact with their built environment unlocks countless opportunities, whether in building optimization (i.e. turning off the lights at 10pm and increasing AC at 3pm), inspection and underwriting (knowing the 3rd floor houses heavy machinery, putting pressure on certain support structures), or architecture and design (i.e. knowing where to incorporate green space and common areas for optimal usage).”`}</p>
    <h2>{`Key Challenges`}</h2>
    <p>{`The application of AI in real estate doesn’t come without its challenges. Navitas Capital identified the following as being the largest barriers to immediate adoption of AI:`}</p>
    <p>{`The quality of data in real estate
Change management and lack of understanding of what AI is
The cultural narrative surrounding robots vs humans
Privacy and data security issues
Societal repercussions (AI can make real estate more efficient, but at the cost of requiring less human capital).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      