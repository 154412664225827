import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2020-05-22T00:00:00.000Z",
  "title": "Future Of Work: 5 Trends That Are Here To Stay After Covid-19\n",
  "slug": "future-of-work-5-trends-that-are-here-to-stay-after-covid-19",
  "excerpt": ["The health crisis is accelerating workplace trends that have been emerging in recent years.", "These include remote work policies, decentralized offices, and having the choice to work from satellite offices closer to home.", "As we move toward the future of work, these trends will likely become permanent even after the pandemic is over."],
  "images": ["5-trends.png"],
  "backlink": "https://allwork.space/2020/05/future-of-work-5-trends-that-are-here-to-stay-after-covid-19/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`As we begin to imagine life after lockdown, let’s look at some of the trends massively driven by COVID-19 that are here to stay once the pandemic is over.`}</p>
    <h3>{`1. Commuting will never be the same again`}</h3>
    <p>{`This is particularly true in cities where most people commuted by way of public transportation. Various cities are unlikely to experience the high amount of commuter traffic they used to see prior to the pandemic.`}</p>
    <p>{`The coronavirus pandemic has likely marked the end of crowded buses, trains, and metro stations during peak hours, at least for the foreseeable future.`}</p>
    <p>{`There are a few reasons behind this trend. The first is that a lot of people will be wary of using public transportation unless it's absolutely necessary. Another reason is that many organizations will likely introduce staggered shifts or partial remote work policies, meaning that fewer people will have to commute on a daily basis or at the same time.`}</p>
    <p>{`In cities where most people commute by car, commuting is also likely to change. Mainly, workers might experience reduced commute times; fewer people going into the office means less traffic during peak hours.`}</p>
    <h3>{`2. Flexible work`}</h3>
    <p>{`Some companies have already announced that they plan on allowing employees to work remotely on a permanent basis, if they choose to. Other organizations are considering implementing partial flexible work and remote work policies that will allow workers to work from home (or a coworking space or coffee shop) some days of the week.`}</p>
    <p>{`As such, rigid work schedules and work models are not likely to come back even after lockdowns are fully lifted and businesses pick up their activity.`}</p>
    <p>{`The reason behind this is that workers, managers, and executives have all gotten a taste of flexible work, and most recognize the benefits. Though working from home has come with its own set of challenges for many, the reality is that most workers have enjoyed having more flexibility in their schedules.`}</p>
    <p>{`As we continue to move towards the future of work, flexible work policies (remote hiring, flexible schedules, telecommuting) are here to stay.`}</p>
    <h3>{`3. Decentralized offices`}</h3>
    <p>{`Physical distancing is here to stay for the long-run. This means that organizations are having to rethink the workplace and consider ways to de-densify their offices.`}</p>
    <p>{`Although staggered shifts and flexible work policies will help keep offices less dense, we are likely going to see more of the decentralized office.`}</p>
    <p>{`We saw this during the pandemic when most people were working from home. In the future, this will be demonstrated by organizations setting up satellite offices in a hub and spoke model — which can be best served by coworking spaces, business centers, and serviced offices.`}</p>
    <p>{`Depending on each company’s policies, access to satellite offices may be sponsored by the company or it may be a cost that workers absorb themselves. In any case, the idea of a single large company headquarters is likely to change.`}</p>
    <p>{`One of the main reasons why organizations and employees alike will choose to work from coworking spaces vs from home is that people are more productive when they are around others who are working. Besides, many people have gotten a taste of what it’s like to work from home, and have learned that home is not an optimal environment to get work done, particularly if there are others in the house with you.`}</p>
    <h3>{`4. Rethinking meetings`}</h3>
    <p>{`Meetings are a necessary evil… whether they take place in-person or online.`}</p>
    <p>{`As we move towards the future of work, organizations will have to rethink their meetings policies and strategies. Recent research has found that video meetings are more tiring than in-person meetings “because we expend more energy trying to process facial expressions and body language.”`}</p>
    <p>{`To prevent Zoom fatigue from consuming entire teams, organizations are more likely to encourage other forms of communication (i.e. phone calls, instant messaging, emails). Additionally, rather than having an entire day filled with online video conferences, companies are more likely to determine which meetings truly require video and which ones can instead be substituted by a quick phone call or an in-person meeting.`}</p>
    <h3>{`5. Bringing back the line between work and life`}</h3>
    <p>{`Over the past several years, the line between work and life has increasingly blurred, if not entirely disappeared in some instances.`}</p>
    <p>{`With more people working from home than ever, keeping that line present will become a key priority to prevent burnout and stress.`}</p>
    <p>{`Though people enjoy flexible schedules and the increased flexibility that comes from working remotely, many are finding that it’s important to establish some limits, otherwise they would be “on” all the time and this could create a culture where people become used to others being available 24/7.`}</p>
    <p>{`As we continue to move towards the future of work, some workers will actively seek ways  in which to keep their work lives and their personal lives separate.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      