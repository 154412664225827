import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2021-02-05T00:00:00.000Z",
  "title": "Future Of Work: Hybrid Work Models Could Help Businesses Save 500B A Year\n",
  "slug": "hybrid-work-models-could-help-businesses-save-500b-a-year",
  "excerpt": ["A new report highlights the business benefits of flexible and remote work, which includes billions of dollars in cost savings.", "Most employees don’t want to work remotely or from the office full-time, the majority of workers prefer a hybrid approach.", "This comes from five key areas. productivity, real estate costs, reduced absenteeism, business continuity, and reduced staff turnover."],
  "images": ["hybrid-work-models-could-help-businesses-save-500b-a-year.png"],
  "backlink": "https://allwork.space/2021/02/future-of-work-hybrid-work-models-could-help-businesses-save-500b-a-year/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A recent `}<a parentName="p" {...{
        "href": "https://globalworkplaceanalytics.com/whitepapers"
      }}>{`report by Global Workplace Analytics and Design Public Group (DPG)`}</a>{` found that “employers could collectively save over $500B a year — roughly equal to the GDP of Sweden, Belgium, or Poland—or almost $11k for each employee who works at home half of the time.” `}</p>
    <p>{`The business benefits of remote work have been widely documented for years now; however, it took a global pandemic to fully convince businesses to embrace the remote work model. Quite frankly, many didn’t have any other option if they wanted their businesses to stay afloat.  `}</p>
    <p>{`With the vaccine rollout already underway, many are expecting businesses to call employees back to the office sometime this year. However, it’s unlikely that employees will want to go back to the office full-time, and employers should listen.  `}</p>
    <p>{`While the debate about remote work has always been a polar issue, the reality is that most employees don’t want to work remotely or from the office full-time; the majority of workers would prefer a hybrid approach.  `}</p>
    <p>{`“While the portion of the workforce that wants to work fully remote nearly doubled during the pandemic—10 percent of U.S. office employees were already fully remote before the pandemic and 19 percent say they want to be when it’s over—the majority prefer a mix of both. In the U.S., the preference averages out to be 2.5 days a week.” `}</p>
    <p>{`Both employers and employees actually stand to benefit from hybrid work models. Here’s a quick breakdown from the report.  `}</p>
    <h2>{`The Business Case for Remote Work`}</h2>
    <p>{`A typical U.S. employer stands to save $11,000 a year for each employee that works remotely 2-3 times a week. This adds up to $1M for every thousand employees.  `}</p>
    <p>{`Where exactly do these savings come from? `}</p>
    <ul>
      <li parentName="ul">{`Increased productivity `}</li>
      <li parentName="ul">{`Reduced real estate costs `}</li>
      <li parentName="ul">{`Reduced absenteeism `}</li>
      <li parentName="ul">{`Increased business continuity `}</li>
      <li parentName="ul">{`Reduced turnover.  `}</li>
    </ul>
    <p>{`Let’s take a closer look at each of the above.  `}</p>
    <h3>{`Productivity`}</h3>
    <p>{`By reducing time and energy drains (like a daily commute), remote work increases productivity levels among employees.  `}</p>
    <p>{`According to the report, minor drains can cost an extra 15 to 25 minutes due to the recovery time needed from distractions.  `}</p>
    <p>{`Respondents from a survey stated that they lost 35 minutes less a day due to interruptions at home vs the office.  `}</p>
    <p>{`“Keeping with the assumption of half-time remote work, over a period of a year that equates to an extra nine days of concentration or $3,600 worth of productivity.”  `}</p>
    <p>{`The majority of workers also stated that they spent the time they would have spent commuting, working.  `}</p>
    <p>{`Fewer distractions and commute time spent working increase productivity by the equivalent of 16 workdays a year.  `}</p>
    <p>{`“Across a population of 1,000 halftime remote workers, that would add $7.4M of productivity per year. At a cost of $99k per employee, that would be like getting 74 new employees at no cost.” `}</p>
    <h3>{`Real Estate Costs`}</h3>
    <p>{`Prior to the pandemic, businesses had shifted their real estate strategy to greater office density as a way to reduce overhead costs.  `}</p>
    <p>{`The coronavirus pandemic will reverse this shift and accelerate the trend towards activity-based work designs.`}</p>
    <p>{`Moving to an activity-based work model, combined with part-time remote work could help businesses save millions on real estate costs while improving their agility and workplace effectiveness.  `}</p>
    <p>{`“An employer paying $7,700 per employee per year for office space would save nearly $2k per half-time remote worker per year if they reduced their real estate footprint by just 25 percent for every half-time remote worker.” Annually speaking, that 25% reduction would amount to savings of $1.9M.  `}</p>
    <h3>{`Absenteeism`}</h3>
    <p>{`The majority of unscheduled absences are not due to sickness, but rather to family issues, stress, or simply not wanting to work.  `}</p>
    <p>{`Below are some ways remote work helps reduce absenteeism levels: `}</p>
    <ul>
      <li parentName="ul">{`Less exposure to sick coworkers `}</li>
      <li parentName="ul">{`Less exposure to environmental and occupational hazards `}</li>
      <li parentName="ul">{`Makes it more feasible to work even when they’re not feeling well enough to go to the office `}</li>
      <li parentName="ul">{`Increased willingness to return to work after becoming parents `}</li>
      <li parentName="ul">{`Makes it easier for workers to return to work after illness or surgery `}</li>
      <li parentName="ul">{`Makes it easier for workers to handle personal or family appointments `}</li>
      <li parentName="ul">{`Reduces stress associated with commuting and office politics `}</li>
      <li parentName="ul">{`Remote workers report being happier, therefore they need less mental health days `}</li>
      <li parentName="ul">{`Increased time for self-care, which can improve productivity and reduce sickness.  `}</li>
    </ul>
    <p>{`A reduction of 30% on absenteeism levels would represent savings of $1.2K per employee, adding to $1.2M per year for 1,000 part-time remote workers.  `}</p>
    <h3>{`Business Continuity`}</h3>
    <p>{`In simple terms, without remote work, most companies would have not been able to continue their operations during the coronavirus pandemic.  `}</p>
    <p>{`Remote work allows employees to keep working, even in the event of a disaster—like the current global pandemic. But the benefit of business continuity isn’t reserved only for global disasters; it can help business continuity in the event of a snowstorm, hurricane, or a fire.  `}</p>
    <p>{`Financially speaking, “being able to work remotely just one day a year they would otherwise not be able to get to the office would save an organization nearly $400 per year for every half-time remote worker.” Annual costs avoided per 1,000 employees would add up to $396K.  `}</p>
    <h3>{`Turnover`}</h3>
    <p>{`Research has consistently found that employee turnover can cost a company between 10 and 213% of salary. That’s only for the time that goes into finding someone, interviewing, onboarding, and training them.  `}</p>
    <p>{`Turnover also affects productivity and team dynamics. Losing an employee and hiring a new one can also cause: `}</p>
    <ul>
      <li parentName="ul">{`Project delays `}</li>
      <li parentName="ul">{`Disrupt team dynamics `}</li>
      <li parentName="ul">{`Stall customer on-boarding.  `}</li>
    </ul>
    <p>{`Companies that don’t allow employees to work remotely in the future risk losing their most talented workers.  `}</p>
    <p>{`The report found that “82 percent of those working from home want to continue doing so at least one day a week when the threat of COVID-19 is over and a third would take a pay cut of up to 5 percent for the opportunity and nearly a quarter say they would forego over 10 percent of salary.” `}</p>
    <p>{`A 10% reduction in turnover could represent savings of over $500K per year per 1,000 part-time remote workers.  `}</p>
    <h2>{`The Future Is Hybrid`}</h2>
    <p>{`The coronavirus pandemic has accelerated the shift to remote work. Companies cannot continue to entertain the idea of “going back to normal”.  `}</p>
    <p><a parentName="p" {...{
        "href": "https://allwork.space/2021/02/were-not-going-back-the-post-crisis-workplace-is-hybrid/"
      }}>{`The future of work`}</a>{` is hybrid, and companies need to prepare for it.  `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      