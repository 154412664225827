import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "publish": true,
  "date": "2019-10-04T00:00:00.000Z",
  "title": "Future Of Work: Purpose Is The New Black\n",
  "slug": "future-of-work-purpose-is-the-new-black",
  "excerpt": ["Web-based work management software company, Workfront, recently published the findings of its 2020 State of Work survey.", "The survey found that there are four fundamental attributes shared by teams and corporations that consistently outpace rivals.", "The survey also found that meetings and technology applications are getting in the way of work, and that company purpose is key to future success."],
  "images": ["34-PURPOSE-IS-THE-NEW-BLACK.jpg"],
  "backlink": "https://allwork.space/2019/10/future-of-work-purpose-is-the-new-black/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "https://www.workfront.com/"
      }}>{`Workfront`}</a>{`, a web-based work management and project management software company, published this week the findings of its sixth annual state of work survey. The survey aims to understand how people get things done at work, how they collaborate in large organizations, and how technology is changing the way work gets done.`}</p>
    <p>{`Technology has played a key role in changing customer expectations, in driving the rise of a global workforce, and in revolutionizing the way people and companies interact. All of this has created a new business focus; companies are increasingly moving from building and shipping products or services, to continuously delivering high-value customer and employee experiences to maintain market differentiation, value, and growth.`}</p>
    <h2>{`4 Fundamental Attributes to Succeed in the Future of Work`}</h2>
    <p>{`Workfront found that there are four fundamental attributes shared by teams and corporations that consistently outpace their rivals.`}</p>
    <h3>{`1. They start with visibility and context`}</h3>
    <p>{`To maintain their position as leading companies over a long-period of time, successful companies “actively communicate company strategy through departments, teams, and all the way out to each person.”`}</p>
    <p>{`The modern workforce cares about purpose; clear communication is key to sharing a company’s overarching purpose and to help each person understand their own context within the company.`}</p>
    <p>{`Speaking to Allwork.Space, Steven ZoBell, Chief Technology Officer at Workfront, stated that:`}</p>
    <p>{`“One of the biggest things that stood out is that it’s not just millennials who care about purpose. People across generations want to see that there is a purpose behind a company; those (companies) with purpose have higher levels of engagement at the team and individual level.”`}</p>
    <p>{`Providing visibility and context makes people feel more connected to the overall outcome of a company, improving company performance.`}</p>
    <h3>{`2. They actively manage work`}</h3>
    <p>{`Outperforming companies “have the ability to put relentless pressure on work performance while simultaneously creating a culture that is positive, open, and honest.” More importantly, these companies use data to align and make decisions about the status and performance of teams and projects.`}</p>
    <p>{`Actively managing work means that they are making informed, real-time data-driven decisions rather than relying on assumptions. It means that when companies provide visibility and context, they don’t just share which decisions are being made, but also the how and why these decisions were made.`}</p>
    <p>{`To effectively manage work, companies need to embrace data. Unfortunately, Workfront’s survey found that only 46% of employees believe business decisions are made based on data; more concerning is the fact that 25% stated that they aren’t even sure how company decisions are made at all.`}</p>
    <p>{`Adding to the report findings, ZoBell argued that visibility and context are a key part of actively and effectively managing work, stating that “communicating how decisions are being made and sharing the data that they are using not only improves performance, but also talent loyalty and engagement.”`}</p>
    <h3>{`3. They deploy technology to help people get work done`}</h3>
    <p>{`“Work management drives technology strategy, and these organizations support their people with the applications and systems they want and need, anything from instant messaging to product design tools or the latest creative suite.”`}</p>
    <p>{`It’s important to note that it’s not just about deploying technology, it’s about deploying the right technology and having a clear strategy for how each technology will be used and why.`}</p>
    <p>{`“There’s a lot of platforms out there that distract more than empower staff. Employing the right tech and having a clear methodology and mentality around tech radically changes how people use it. Technology platforms are not a mere communication mechanism, rather they’re a tool to help drive towards the company’s overall outcome. Otherwise, tech becomes another workplace distraction,” ZoBell added.`}</p>
    <p>{`The goal of using technology is to support dynamic work processes and to capture information that supports visibility and context, and provides valuable data.`}</p>
    <h3>{`4. They focus on agility as a core competency`}</h3>
    <p>{`“Rather than simply make existing work easier or more fluid, they actually take on a more dynamic approach, working across departmental seams, changing more often, empowering new leaders, and redeploying themselves at the individual, team, or even organizational level to drive new market opportunities.”`}</p>
    <h2>{`The State of Work, 2020`}</h2>
    <p>{`People are the heart of work. To truly understand the state of work, it’s important to look at how people feel about it, how they are approaching it, and what’s driving and hindering them from success.`}</p>
    <p>{`Workfront found that the modern workforce is “ready to move forward, eager to engage in high-impact, strategic efforts, and yet at the same time struggling and frustrated in their efforts to get work done.”`}</p>
    <p>{`The survey found that:`}</p>
    <ul>
      <li parentName="ul">{`89% of respondents believe their role matters`}</li>
      <li parentName="ul">{`78% say their job represents more than a paycheck`}</li>
      <li parentName="ul">{`91% state that they are proud of the work they do`}</li>
      <li parentName="ul">{`65% want to be rewarded more on results than just deliverables…`}</li>
    </ul>
    <p>{`On the other hand, it also found that`}</p>
    <ul>
      <li parentName="ul">{`Employees only spend about 40% of their time doing the job they were actually hired to do`}</li>
      <li parentName="ul">{`91% of employees crave modern technology solutions`}</li>
      <li parentName="ul">{`88% believe that technology is an important part of the employee experience`}</li>
      <li parentName="ul">{`71% want a solution that provides them with a single destination to understand and manage work, but 69% say they don’t have that solution in place`}</li>
      <li parentName="ul">{`Workers are interrupted on average 13.9 times per day.`}</li>
    </ul>
    <h2>{`Work is getting in the way of work`}</h2>
    <p>{`“Even in the middle of a technology and connectivity revolution, today’s workforce still devotes a tremendous amount of time to low-value activity.” In fact, throughout the course of the six years the survey has taken place, Workfront has found that “knowledge workers on average spend just 40% of their work week on the job they were hired to do.”`}</p>
    <p>{`The main culprit? Unnecessary and wasteful meetings. But then there’s also other things getting in the way of work…emails, oversight, poor work prioritization, lack of processes for workflow, digital tools (like instant messaging), social media, among others.`}</p>
    <p>{`For many, it seems that the same tools used to enable communication and collaboration are actually hindering productivity. ZoBell argues that this leads to increased levels of stress and frustration. “On the one hand you have workers that are eager to get the job done, and they have so much that they need to do, but the amount of time they actually have to focus is rather minimal.`}</p>
    <p>{`“Rather than spending time on discussing how a team is going to work and who is going to work on what, people should spend that time actually working.”`}</p>
    <h2>{`Ease and simplicity at work`}</h2>
    <p>{`The survey found that 86% of respondents say that next-generation employees expect workplace technology that looks more like Amazon and Instagram, with 94% saying searching at work should match the ease of Googling.`}</p>
    <p>{`It’s about the experience, and those companies that provide the most seamless workplace experience will attract and retain the best talent. Technology plays a key role in this aspect, with 87% of respondents stating that they believe companies should reconsider the way they think about tech, and 84% saying businesses are missing out by not moving to more modern solutions.`}</p>
    <p>{`However, a seamless workplace experience requires more than just modern tech, it also requires a single destination to understand and manage work.`}</p>
    <h2>{`The rise of Chief Work Officers`}</h2>
    <p>{`To address the need to have a one-stop shop where people can understand and manage work, Workfront introduced respondents with the idea of a Chief Work Officer. A Chief Work Officer is a person that coordinates people, work, content, process, and performance; basically the person is in charge of overseeing the complete experience of those working in the company.`}</p>
    <p>{`This requires that companies make a strategic shift towards work management based on experience. “It’s time for enterprises to consider work management as a holistic practice and begin treating work–people, their actions, and the intellectual capital they create–as a tier-one asset.”`}</p>
    <h3>{`Prepping for the future`}</h3>
    <p><a parentName="p" {...{
        "href": "https://allwork.space/2019/09/future-of-work-the-war-for-talent-is-about-to-get-fiercer/"
      }}>{`The war for talent is about to get fiercer`}</a>{`, and companies hoping to recruit the best talent will need to embrace modern technology, have a purpose, and clearly visibilize their culture.`}</p>
    <p>{`“As you’re recruiting people and during the interview process, talk about how work gets done in your organization and how you perform in an agile way. Candidates want to know what it looks like to work in a company,” ZoBell says.`}</p>
    <p>{`But don’t make the mistake of confusing perks with culture, ZoBell adds.`}</p>
    <p>{`“The culture of a company is how they think and act to drive results; it’s not about great coffee, on-site yoga classes, and free food.”`}</p>
    <p>{`Culture is what keeps employees, the right employees. But to attract the right employees, you need to visiblize and effectively convey your culture.`}</p>
    <p>{`Workfront’s report recommends companies consider the following questions to equip their enterprise for a shift to strategic work management:`}</p>
    <ul>
      <li parentName="ul">{`Does every person in my organization understand our company strategy and their role in our success?`}</li>
      <li parentName="ul">{`Do leaders in our organization work to ensure people and teams can focus on the work they’ve been hired to lead, drive, and accomplish?`}</li>
      <li parentName="ul">{`Are we measuring and incentivizing our people for accomplishing tasks and finishing projects, or achieving strategic outcomes?`}</li>
      <li parentName="ul">{`Are the technology tools we use helping people accomplish their strategic objectives?`}</li>
      <li parentName="ul">{`Do we provide all of our team members with the ‘why’ behind their work?`}</li>
      <li parentName="ul">{`Are we helping our people and teams align with strategic decisions?`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      